import React, { useState, useRef, useEffect } from 'react'
import LoadingScreen from 'react-loading-screen'
import { Button, Col, Container, Form, FormGroup, Row } from 'react-bootstrap'
import { Input, Label } from 'reactstrap'
import log from '../Assets/Images/log.png'
import { MdMailOutline } from 'react-icons/md'
import { FiLock, FiUser } from 'react-icons/fi'
import { BsTelephone } from 'react-icons/bs'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { LogInAction, RegisterAction } from '../../redux/actions/LogInAction'
import { PhoneInput } from 'react-contact-number-input'
import { MultiSelect } from 'react-multi-select-component'

//Assets
import logoo from '../Assets/Images/logoo1.png'
import sender from '../Assets/Images/box-svgrepo-com.svg'
import newSender from '../Assets/Images/NewSender.jpeg'
import senderBox from '../Assets/Images/SenderBoxImage.png'
import triller from '../Assets/Images/triller.png'

//React Strap Components
import { Modal, ModalBody } from 'reactstrap'
import LoggedinNavbar from '../Component/LoggedinNavbar'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import countryList from 'react-select-country-list'
import Select from 'react-select'
import SecondNav from '../Component/SecondNav'
import { getFirebaseToken } from '../../firebase'

function RegisterPage() {
  const [phoneCode, setPhoneCode] = useState('')
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const [selectedCountries, setSelectedCountries] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const [multiSelectMessage, setMultiSelectMessage] = useState(null)
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [gender, setGender] = useState(null)
  const [initialRole, setInitialRole] = useState(null)
  const [phone, setPhone] = useState(null)
  const [email, setEmail] = useState('')

  const [country, setCountry] = useState('')

  const [password, setPassword] = useState(null)
  const [phoneError, setPhoneError] = useState(null)
  // const [text, setText] = useState('');

  const option = useMemo(() => countryList().getData(), [])
  // console.log(option, "hello abcdef");
  const CountryList = useMemo(() => countryList().getData(), [])

  const { t } = useTranslation()

  const token = localStorage.getItem('token') // determine if authorized, from context or however you're doing it
  useEffect(() => {
    if (token) {
      navigate('/main_profile')
    }
  }, [])
  const handleProfileClick = () => {
    navigate('/main_profile')
  }
  const handlePhoneCodeOnChange = (value) => {
    if (value.hasError) {
      setPhoneError(t(value.message))
    } else {
      setPhoneError(null)
    }
    console.log(
      JSON.stringify(value?.validData?.phoneNumber) +
        '========phone number with country code',
    )
    setPhone(value?.validData?.phoneNumber)
  }
  const handleLoginFormData = (data) => {
    if (data?.status) {
      console.log(data)
      if (data?.token) {
        localStorage.setItem('token', data?.token)
        setModal(true)
      }
      setLoading(false)
    } else {
      setMessage(data?.message)
      setLoading(false)
      setError(true)
    }
  }
  const [deviceToken, setDeviceToken] = useState('')
  const handleDeviceToken = (data) => {
    setDeviceToken(data)
    console.log('divicetoken', data)
  }

  useEffect(() => {
    // setLoading(false);
    getFirebaseToken(handleDeviceToken)
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    if (topDestinationCountries?.length > 2) {
      let temp = {
        country_one: topDestinationCountries[0],
        country_two: topDestinationCountries[1],
        country_three: topDestinationCountries[2],
      }
      let data = {
        email: email,
        password: password,
        lastName: lastName,
        firstName: firstName,
        country,
        phone_no: phone,
        device_token: deviceToken,
        initialRole: initialRole,
        topDestinationCountries: temp,
      }

      console.log(JSON.stringify(data) + '=======data')
      const response = dispatch(RegisterAction(data, handleLoginFormData))

      console.log(response)
    }else{
      setMessage("Please Select at least top three destination countries")
      setLoading(false)
      // setError(true)
    }
  }
  const [topDestinationCountries, setTopDestinationCountries] = useState([])

  console.log(topDestinationCountries, 'top3')
  const onChangeMultiSelectCountries = (data) => {
    if(data?.length < 4){
      let temp = []
      data?.map((value) => {
        temp.push(value?.label)
      })
      setTopDestinationCountries(temp)
      setSelectedCountries(data)
    }else{
      setMultiSelectMessage("You can select upto three countries")
      setLoading(false)
      setError(true)
    }
   
    // console.log(data,"onchange");
  }
  return (
    <div>
      <LoadingScreen
        loading={loading}
        bgColor="#f1f1f1"
        spinnerColor="#9ee5f8"
        textColor="#676767"
        logoSrc={logoo}
        text={t('Hang tight! while we do our magic')}
      >
        <LoggedinNavbar />
        <Container className="width100Container marginl-0">
          <Row className="RegisterPageMainRow p-0">
            <Col lg={7} md={6} sm={12} className="p-0">
              <div className="pt-40per-register displayNoneClass">
                <img
                  src={log}
                  alt="log-img"
                  className="img-login"
                  width="100%"
                />
              </div>
            </Col>
            <Col lg={5} md={6} sm={12}>
              <div className="pt-53per-register textAlignCenter">
                <h1 className="fontSize50-700">
                  {t('Welcome to')} <span className="orange-color">Viahop</span>
                  !
                </h1>
                <p className="fontSize16-500">{t('Register Your Account')}</p>
              </div>
              {error && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {t('Oops')}! <br />
                    {t(message)}
                  </div>
                </div>
              )}
              <div className="pt-3 marginLeftRightClass">
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label
                      htmlFor="fname"
                      className="fontSize14-600 dimgray-color"
                    >
                      {' '}
                      {t('First Name')}{' '}
                    </Label>
                    <Input
                      id="fname"
                      name="fname"
                      style={{ paddingRight: 45 }}
                      type="text"
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                    <FiUser className="mail-icon-login" />
                  </FormGroup>
                  <FormGroup className="pt-2">
                    <Label
                      htmlFor="lname"
                      className="fontSize14-600 dimgray-color"
                    >
                      {' '}
                      {t('Last Name')}{' '}
                    </Label>
                    <Input
                      id="lname"
                      name="lname"
                      style={{ paddingRight: 45 }}
                      type="text"
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                    <FiUser className="mail-icon-login" />
                  </FormGroup>
                  <FormGroup className="pt-2">
                    <Label
                      htmlFor="gender"
                      className="fontSize14-600 dimgray-color"
                    >
                      {t('Title')}
                    </Label>
                    <div className="input-group">
                      <select
                        name="gender"
                        id="gender"
                        placeholder="Your Title"
                        onChange={(e) => setGender(e.target.value)}
                        className="form-control"
                      >
                        <option disabled>{t('Your Title')}</option>
                        <option value="male">{t('Mr')}.</option>
                        <option value="female">{t('Ms')}.</option>
                      </select>
                    </div>
                  </FormGroup>
                  <FormGroup className="pt-2">
                    <Label
                      htmlFor="number"
                      className="fontSize14-600 dimgray-color"
                    >
                      {' '}
                      {t('WhatsApp Number')}{' '}
                    </Label>
                    <PhoneInput
                      containerClass=""
                      countryCode="uae"
                      onChange={handlePhoneCodeOnChange}
                      placeholder={t('Enter WhatsApp Number')}
                    />
                    <BsTelephone className="mail-icon-login" />
                  </FormGroup>
                  <span style={{ fontStyle: 'italic', color: 'red' }}>
                    {phoneError}
                  </span>
                  <div className="pt-2">
                    <Label
                      htmlFor="country"
                      className="fontSize14-600 dimgray-color"
                    >
                      {' '}
                      {t('Country')}{' '}
                    </Label>
                    <Select
                      options={option}
                      className="countrySelect"
                      classNamePrefix="countrySelect"
                      classNames="countrySelect"
                      // value={country}
                      placeholder={t('Select Country')}
                      styles={{
                        border: 'none',
                      }}
                      onChange={(value) => {
                        setCountry(value.label)
                      }}
                    />
                  </div>
                  <FormGroup className="pt-2">
                    <Label
                      htmlFor="gender"
                      className="fontSize14-600 dimgray-color"
                    >
                      {t('Tell us how you plan to use Viahop')}
                    </Label>
                    <div className="input-group">
                      <select
                        name="initialRole"
                        id="initialRole"
                        placeholder="Please Select"
                        onChange={(e) => setInitialRole(e.target.value)}
                        className="form-control"
                      >
                        <option disabled>{t('Please Select')}</option>
                        <option value="Sender">{t('Sender')}</option>
                        <option value="Traveller">{t('Traveller')}</option>
                        <option value="Sender and Traveller">
                          {t('Sender and Traveller')}
                        </option>
                      </select>
                    </div>
                  </FormGroup>
                  <FormGroup className="pt-2" fullWidth>
                    <Label
                      htmlFor="gender"
                      className="fontSize14-600 dimgray-color"
                    >
                      {t(
                        'Select top 3 countries of destination you are sending or traveling to',
                      )}
                    </Label>
                    <span style={{ fontStyle: 'italic', color: 'red' }}>
                    {multiSelectMessage}
                  </span>
                    <div className="input-group">
                      <MultiSelect
                        options={CountryList}
                        value={selectedCountries}
                        onChange={(e) => onChangeMultiSelectCountries(e)}
                        // disableSearch={true}
                        labelledBy="Select"
                        className="countrySelect"
                        classNamePrefix="countrySelect"
                        classNames="countrySelect"
                        hasSelectAll={false}
                        styles={{
                          border: 'none',
                        }}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="pt-2">
                    <Label
                      htmlFor="email"
                      className="fontSize14-600 dimgray-color"
                    >
                      {' '}
                      {t('Email')}{' '}
                    </Label>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      style={{ paddingRight: 45 }}
                      onChange={(e) => {
                        setEmail(e.target.value.toLowerCase())
                      }}
                      required
                      value={email}
                    />
                    <MdMailOutline className="mail-icon-login" />
                  </FormGroup>
                  <FormGroup className="py-2">
                    <Label
                      htmlFor="password"
                      className="fontSize14-600 dimgray-color"
                    >
                      {' '}
                      {t('Password')}{' '}
                    </Label>
                    <Input
                      id="password"
                      style={{ paddingRight: 45 }}
                      name="password"
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <FiLock className="mail-icon-login" />
                  </FormGroup>{' '}
                  <div className="d-grid gap-2 pt-3 text-decor">
                    <Button
                      type="submit"
                      className="orange-background fontSize16-500 bordt-none"
                      size="md"
                      block
                      disabled={loading}
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>{t('Register')}</span>
                    </Button>
                  </div>
                  <div className="py-4">
                    <p className="fontSize16-500 dimgray-color text-center">
                      {t("Don't have an account")}?{' '}
                      <Link to="/login" className="text-decor">
                        {' '}
                        <span className="orange-color">{t('Sign in')}</span>
                      </Link>{' '}
                    </p>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="how-work-modal"
        backdrop="static"
      >
        <ModalBody>
          <Row>
            <Col lg={12} md={12} sm={12} className="py-4">
              <div className="pt-4">
                <h1 className="fontSize50-700 text-center">
                  {t('Welcome to')}{' '}
                  <span className="orange-color">{t('Viahop')}</span>
                </h1>
                <p className="fontSize32-600 text-center">
                  {t('Get started As')}:
                </p>
              </div>
              <div>
                <Row className="justify-content-center align-items-center">
                  <Col lg={5} md={6} sm={12}>
                    <div>
                      <p className="fontSize16-400 darkgray text-center">
                        {t('Need to send anything')}?
                      </p>
                    </div>
                    <Link
                      to="/create_sender_offer"
                      className="howwork-text darkgraytext d-block howpad-top15px text-center text-decor"
                    >
                      <div className="text-center mb-3 howwork borderb senderImgClass">
                        <img src={senderBox} alt="sender" />
                        <p className="fontSize18-500 pt-4 mb-0 darkgray">
                          {t('Create Shipment Order')}
                        </p>
                      </div>
                      <span className="fontSize18-500  text-center mt-3 pt-3">
                        {t('Sender')}
                      </span>
                    </Link>
                  </Col>
                  <Col lg={5} md={6} sm={12}>
                    <div>
                      <p className="fontSize16-400 darkgray text-center">
                        {t('Travelling & having extra luggage space')}?
                      </p>
                    </div>
                    <Link
                      to="/create_traveller_offer"
                      className="howwork-text darkgraytext d-block text-center text-decor"
                    >
                      <div className="text-center py-4 mb-3 howwork borderb">
                        <img
                          src={triller}
                          alt="triller"
                          className="img-fluid"
                        />
                        <p className="fontSize18-500 pt-3 mb-0 darkgray">
                          {t('Create Traveler Offer')}
                        </p>
                      </div>
                      <span className="fontSize18-500   text-center mt-3 pt-3">
                        {t('Traveler')}
                      </span>
                    </Link>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col lg={12}>
                  <div className="text-center pt-5">
                    <Link to="/main_profile">
                      <Button
                        type="button"
                        className="btnlanding-hov ms-4 profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                      >
                        {t('Go to Profile')}
                      </Button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default RegisterPage
