import React, { useState } from 'react'

//React Routing
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { sendSupportMail } from '../../redux/actions/UserAction'

//React Bootstrap Components
import { Button, Col, Container, Row } from 'react-bootstrap'

//Assets
import profile from '../Assets/Images/profile.png'
import xpress_landing from '../Assets/Images/via_landing.jpg'
import Vector from '../Assets/Images/Vector.png'
import { FiUser } from 'react-icons/fi'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import { MdMailOutline } from 'react-icons/md'
import button_loader from '../Assets/Loader/button_loader.gif'

//User Define Components
import MultitabButton from '../SamllComponent/MultitabButton'
import SliderTestmonuial from '../SamllComponent/SliderTestmonuial'
import LoggedinNavbar from '../Component/LoggedinNavbar'

//React Strap Components
import { Form, FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap'

//CSS
import '../SamllComponent/MultitabBtn.css'

//React Hooks
import { useEffect } from 'react'

//Redux Actions
import {
  getAllTravellerOffers,
  getTravellerOfferDetails,
  getAllSenderOffers,
  createSenderCounterOffer,
  createTravellerCounterOffer,
  acceptUserSenderOffer,
  acceptUserTravellerOffer,
  getCountryName,
} from '../../redux/actions/TravellerOfferAction'
import HeroSectionSlider from '../SamllComponent/HeroSectionSlider'
import HowItWorkVideo from '../SamllComponent/HowItWorkVideo'
function LandingPage(args) {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [modal, setModal] = useState(false)
  const [contactModal, setContactModal] = useState(false)
  const [name, setName] = useState('')
  const [mail, setMail] = useState('')
  const [textarea, setTextarea] = useState('')
  const [required, setRequried] = useState('')
  const [loading, setLoading] = useState(true)

  // User Details reducer
  const { userDetails } = useSelector((state) => state.UserReducer)
  const [travellerModal, setTravellerModal] = useState(false)
  const [counterTravellerOfferModal, setCounterTravellerOfferModal] = useState(
    false,
  )
  const [buttonLoader, setButtonLoader] = useState(false)
  const [counterOfferAmount, setCounterOfferAmount] = useState('')
  const [counterOfferDescription, setCounterOfferDescription] = useState('')
  const [acceptSenderOffer, setAcceptSenderOffer] = useState(false)
  const [packageDetails, setPackageDetails] = useState('')
  const [offerId, setOfferId] = useState()
  const [travellerOfferId, setTravellerOfferId] = useState('')
  const [acceptTravellerOffer, setAcceptTravellerOffer] = useState(false)

  const [videoUrl,setVideoUrl] = useState(t("https://faisalayaz.com/viahop/viahop-russian.mp4"))
  const handleCounterOfferAmount = (event) => {
    const value = event.target.value
    if (/^[1-9]\d*$/.test(value)) {
      setCounterOfferAmount(value)
    } else {
      setCounterOfferAmount('')
    }
  }
  const handleCounterOfferDescription = (event) => {
    setCounterOfferDescription(event.target.value)
  }
  const toggleAcceptTravellerOffer = (id) => {
    setTravellerOfferId(id)
    setAcceptTravellerOffer(!acceptTravellerOffer)
  }
  const toggleTravellerCounterOffer = () => {
    setCounterTravellerOfferModal(!counterTravellerOfferModal)
  }

  const toggleTravellerOfferDetail = () => setTravellerModal(!travellerModal)

  const handlePackageDetails = (event) => {
    setPackageDetails(event.target.value)
  }
  const handleSubmitTravellerCounterOffer = (e) => {
    setButtonLoader(true)
    e.preventDefault()
    let data = {
      offerId: offerId,
      description: counterOfferDescription,
      packageDetail: packageDetails,
      offeredAmount: counterOfferAmount,
    }
    dispatch(
      createTravellerCounterOffer(data, handleResponseTravellerCounterOffer),
    )
  }
  const handleResponse = (data) => {
    if (data.status) {
    } else {
      //   setLoading(false);
    }
  }
  const handleResponseTravellerCounterOffer = (data) => {
    setButtonLoader(false)
    if (data.status) {
      Swal.fire({
        title: t('Success'),
        type: 'success',
        text: t('Your offer is made successfully'),
      })
      setTimeout(() => {
        setCounterTravellerOfferModal(!counterTravellerOfferModal)
      }, 3000)
      // setVerificationModal(false)
    } else {
      Swal.fire({
        title: t('Denied'),
        type: 'danger',
        text: t('Oops! Something went wrong.'),
      })
      //   setLoading(false);
    }
  }
  const [travellerOfferDetail, setTravellerOfferDetail] = useState([])
  const handleTravellerOfferDetails = (value, isLogin) => {
    if (isLogin) {
      navigate('/login')
    }
    setOfferId(value?.id)
    setTravellerOfferDetail(value)
    setTravellerModal(!travellerModal)
  }
  useEffect(() => {
    dispatch(getAllTravellerOffers())
    dispatch(getAllSenderOffers())
    dispatch(getCountryName())
  }, [dispatch])

  const token = localStorage.getItem('token')
  const toggle = () => setModal(!modal)
  const { travellerOffersList, heroSectionData, howItWorksData ,language} = useSelector(
    (state) => state.TravellerOfferReducer,
  )
  console.log("language============", language);
  console.log('herosectiondata', heroSectionData)
  console.log('howItWorksData', howItWorksData)
  const handleStartGivingServices = (str) => {
    if (str === 'sender') {
      if (token) {
        if (
          userDetails?.user?.address != null &&
          userDetails?.user?.address != '' &&
          userDetails?.user?.is_phone_verified != 0 &&
          userDetails?.user?.is_id_verified != 0 &&
          userDetails?.user?.is_email_verified != 0
        ) {
          navigate('/create_sender_offer')
        } else {
          Swal.fire({
            title: t('Whoops!'),
            type: 'danger',
            text: t(
              'You need to get verified before you can create the sender and traveler offer. Don’t sweat it - just finish your verification [address, email, WhatsApp number] and you’re ready to go',
            ),
            footer:
              '<a href="/main_profile?key=second">' +
              t('Link to Verification Page') +
              '</a>',
          })
        }
      } else {
        navigate('/login')
      }
    } else if (str === 'traveller') {
      if (token) {
        if (userDetails?.user?.address !== null) {
          navigate('/create_traveller_offer')
        } else {
          Swal.fire({
            title: t('Whoops!'),
            type: 'danger',
            text: t(
              'You need to get verified before you can create the sender and traveler offer. Don’t sweat it - just finish your verification [address, email, WhatsApp number] and you’re ready to go',
            ),
            footer:
              '<a href="/main_profile?key=second">' +
              t('Link to Verification Page') +
              '</a>',
          })
        }
      } else {
        navigate('/login')
      }
    }
  }

  // Contact us modal functions starts here
  const contactUsForm = () => {
    setContactModal(!contactModal)
  }
  const closeContactUsModal = () => {
    setContactModal(false)
  }
  const handelClear = () => {
    let newName = ''
    setName(newName)
    let newMail = ''
    setMail(newMail)
    let newArea = ''
    setTextarea(newArea)
  }
  const handleSupportData = () => {
    if (name === '' && mail === '' && textarea === '') {
      setRequried(t('Please Write Something in all fields'))
    } else {
      setLoading(true)
      setRequried(false)
      let data = {
        fullName: name,
        email: mail,
        message: textarea,
      }
      dispatch(sendSupportMail(data, handleSupportResponse))
    }
  }
  const handleSupportResponse = (data) => {
    setLoading(false)
    console.log(JSON.stringify(data) + '==========support api response')
    if (data.status) {
      handelClear()
      Swal.fire({
        title: 'Success',
        type: 'success',
        text: t('Your Message is Conveyed'),
      })
      setContactModal(!contactModal)
    } else {
      Swal.fire({
        title: 'Failed',
        type: 'failed',
        text: t('Oops! Your Request is Denied'),
      })
    }
  }
  setTimeout(() => {
    setLoading(false)
  }, [2000])
  // Contact us modal functions ends here

  //User Details
  useEffect(() => {
    console.log(JSON.stringify(userDetails) + '==================')
    setPhoneVerify(userDetails?.user?.is_phone_verified)
    setEmailVerify(userDetails?.user?.is_email_verified)
  }, [userDetails])
  //Sender Offers
  const [offerDetails, setSenderOfferDetail] = useState([])
  const { senderOffersList } = useSelector(
    (state) => state.TravellerOfferReducer,
  )
  const [phoneVerify, setPhoneVerify] = useState(null)
  const [emailVerify, setEmailVerify] = useState(null)

  const handleSenderOfferDetails = (value, isLogin) => {
    if (isLogin) {
      navigate('/login')
    }

    if (phoneVerify === 0) {
      Swal.fire({
        title: t('Whoops!'),
        type: 'danger',
        text: t(
          'You need to get verified before you can create the sender and traveler offer. Don’t sweat it - just finish your verification [address, email, WhatsApp number] and you’re ready to go',
        ),
        footer:
          '<a href="/main_profile?key=second">' +
          t('Link to Verification Page') +
          '</a>',
      })
    } else if (emailVerify === 0) {
      Swal.fire({
        title: t('Whoops!'),
        type: 'danger',
        text: t(
          'You need to get verified before you can create the sender and traveler offer. Don’t sweat it - just finish your verification [address, email, WhatsApp number] and you’re ready to go',
        ),
        footer:
          '<a href="/main_profile?key=second">' +
          t('Link to Verification Page') +
          '</a>',
      })
    } else if (
      userDetails?.user?.address == null ||
      userDetails?.user?.address == ''
    ) {
      Swal.fire({
        title: t('Whoops!'),
        type: 'danger',
        text: t(
          'You need to get verified before you can create the sender and traveler offer. Don’t sweat it - just finish your verification [address, email, WhatsApp number] and you’re ready to go',
        ),
        footer:
          '<a href="/main_profile?key=second">' +
          t('Link to Verification Page') +
          '</a>',
      })
    } else {
      setSenderOfferDetail(value)
      setOfferId(value?.id)
      setModal(!modal)
    }
  }

  const [senderOfferId, setSenderOfferId] = useState('')
  const [counterOfferModal, setCounterOfferModal] = useState(false)
  const toggleCounterOffer = () => {
    setCounterOfferModal(!counterOfferModal)
  }

  const toggleAcceptSenderOffer = (id) => {
    setSenderOfferId(id)
    setAcceptSenderOffer(!acceptSenderOffer)
  }

  const handleSubmitSenderCounterOffer = (e) => {
    setButtonLoader(true)
    e.preventDefault()
    let data = {
      offerId: offerId,
      description: counterOfferDescription,
      offeredAmount: counterOfferAmount,
    }
    dispatch(createSenderCounterOffer(data, handleResponseCounterOffer))
  }
  const handleResponseCounterOffer = (data) => {
    setButtonLoader(false)
    if (data.status) {
      Swal.fire({
        title: t('Success'),
        type: 'success',
        text: t('Your offer is made successfully'),
      })
      setTimeout(() => {
        setCounterOfferModal(!counterOfferModal)
      }, 3000)
      // setVerificationModal(false)
    } else {
      Swal.fire({
        title: t('Failed'),
        type: 'danger',
        text: t('Oops! Something went wrong.'),
      })
    }
  }
  var senderOfferCounter = 0
  var travellerOfferCounter = 0

  const handleSubmitAcceptSenderOffer = () => {
    setButtonLoader(true)
    let data = {
      senderOfferId: senderOfferId,
    }
    dispatch(acceptUserSenderOffer(data, handleResponseAcceptSenderOffer))
    setTimeout(() => {
      setAcceptSenderOffer(false)
    }, 3000)
  }
  const handleResponseAcceptSenderOffer = (data) => {
    setButtonLoader(false)
    if (data.status) {
      Swal.fire({
        title: t('Success'),
        type: 'success',
        text: t('Offer accepted'),
      })
    } else {
      Swal.fire({
        title: t('Success'),
        type: 'success',
        text: t('Oops! Something went wrong'),
      })
    }
  }

  return (
    <div>
      <LoggedinNavbar />
      {/* <SecondNav /> */}
      <section>
        <Container>
          <Row className="align-items-center mt-3">
            <Col lg={5} md={6} sm={12}>
              <div className="ms-lg-0 ms-md-0 ms-3">
                <h1 className="fontSize56-700-new text-dark">
                  {t('Send and Travel with')}{' '}
                  <span className="orange-color">{t('Viahop')} </span>
                </h1>
                <p className="fontSize23-400-New pt-4">
                  {t('We connect travelers with senders to quickly')},{' '}
                  {t(
                    'securely and affordably send parcels and packages across the globe',
                  )}
                </p>
                <h3 className="orange-color text-center fontSize32-600">
                  {t('Get Started As')}:
                </h3>
              </div>
              <Row>
                <Col lg={6} md={6} sm={12}>
                  {/* <Link to="/masterform one" className="text-decor"> */}
                  <div className="d-grid gap-2 py-3 mx-lg-0 mx-md-0 mx-5 text-decor">
                    <Button
                      className="profiletop-btnoutline btnlanding-hov py-2 fontSize18-500 bordt-none"
                      size="lg"
                      block
                      onClick={() => handleStartGivingServices('sender')}
                    >
                      {t('Sender')}
                    </Button>
                  </div>
                  {/* </Link> */}
                </Col>
                <Col lg={6} md={6} sm={12}>
                  {/* <Link to="/masterform three" className="text-decor"> */}
                  <div className="d-grid gap-2 py-3 mx-lg-0  mx-md-0 mx-5 text-decor">
                    <Button
                      className=" py-2 profiletop-btnoutline btnlanding-hov fontSize18-500 bordt-none"
                      size="lg"
                      block
                      onClick={() => {
                        handleStartGivingServices('traveller')
                      }}
                    >
                      {t('Traveler')}
                    </Button>
                  </div>
                  {/* </Link> */}
                </Col>
              </Row>
              <div>
                <a href="#howitworks" className="text-decor">
                  <div className="d-grid gap-2 py-3 mx-lg-0  mx-md-0 mx-5">
                    <Button
                      className=" py-2  profiletop-btnoutline btnlanding-hov fontSize18-500 bordt-none"
                      size="lg"
                      block
                    >
                      {t('How It Works')}?
                    </Button>
                  </div>
                </a>
              </div>
            </Col>
            <Col lg={7} md={6} sm={12} className="pt-0">
              {language == 'russian' ? (
                <Row className="">
                  <HeroSectionSlider images='russian' />
                </Row>
              ) : (
                <Row className="">
                  <HeroSectionSlider images='english' />
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </section>

      {/* <MoveParcel /> */}

      <section className="bg-white" id="faqsse">
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="mx-lg-0 mx-md-0 mx-2">
                <h1 className="fontSize50-700 text-center py-3">
                  {t('Why Choose')}
                  <span className="orange-color"> Viahop ?</span>
                </h1>
                <p className="fontSize19-400 text-center mb-0">
                  {t(
                    'Our courier Platform allows traveler securely earn and senders save and get their packages sent in no time',
                  )}
                  .{' '}
                </p>
                <p className="fontSize19-400 text-center mb-0">
                  {t(
                    'Our Platform is incredibly quicker than any conventional logistics systems while',
                  )}{' '}
                </p>
                <p className="fontSize19-400 text-center">
                  {t(
                    'also being one of the safest ways to get your packages to the locations you need',
                  )}
                  .
                </p>
              </div>
            </Col>
          </Row>
          {/* 2nd */}
          <Row className="pt-4">
            <Col lg={6} md={12} sm={12}>
              <div className="boxshado p-2 border-r">
                {/* <div>
                <img className="perks-logo-img" src={viahop icon} alt="Traveller Perks" />
                </div> */}
                <div className="ps-2 pt-2" style={{ paddingBottom: '1.8rem' }}>
                  <p className="fontSize24-600">{t('Traveler Perks')}</p>
                  <ul className="fontSize18-500-Home textgray">
                    <li>{t('Earn extra income')}</li>
                    <li className="py-2">
                      {t('Travel the world and get paid')}
                    </li>
                    <li className="pb-2">{t('Meet new people')} </li>
                    <li className="pb-2">
                      {t('First and Last mile delivery directly to from you')}
                    </li>
                    <li className="pb-2">{t('Keep track of your order')}</li>
                  </ul>
                  <Link
                    to="/"
                    className="fontSize19-500 orange-color text-decor pb-4"
                  >
                    {t('Learn More')}
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={6} md={12} sm={12} className="mt-lg-0 mt-4">
              <div className="boxshado p-2 border-r">
                {/* <div>
                  <img className="perks-logo-img" src={viahop icon} alt="Sender Perks" />
                </div> */}
                <div className="senderperksMainDiv p-2 pb-4">
                  <p className="fontSize24-600">{t('Sender Perks')}</p>
                  <ul className="fontSize18-500-Home textgray mb-5">
                    <li className="py-1">
                      {' '}
                      {t('Securely ship parcels in no time')}
                    </li>
                    <li className="py-1">
                      {t('Save on courier services costs')}{' '}
                    </li>
                    {/* <li className="py-1">
                      Shop anywhere and get it delivered to you{" "}
                    </li> */}
                    <li className="py-1">
                      {t('First and Last mile delivery directly to from you')}{' '}
                    </li>
                    <li className="py-1">{t('Keep track of the order')}</li>
                  </ul>
                  <Link
                    to="/"
                    className="fontSize19-500 orange-color text-decor pb-4"
                  >
                    {t('Learn More')}
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col lg={12}>
              <h6>
                {t('Here are some of traveller offers you can send with')}
              </h6>
              <Row className="travellOfferListTable">
                <Col lg={12} md={12} sm={12} style={{ marginTop: '10px' }}>
                  <table className="table custom_tabel_border rounded-2">
                    <thead>
                      <tr
                        style={{ backgroundColor: '#F69110', color: 'white' }}
                      >
                        <th scope="col">{t('Order ID')}</th>
                        {/* <th scope="col">{t('Order Created Date')}</th> */}
                        <th scope="col">{t('From')}</th>
                        <th scope="col">{t('To')}</th>
                        <th scope="col">{t('Package')}</th>
                        <th scope="col">{t('When')}</th>
                        {/* <th scope="col">{t('Offer Price')}</th> */}
                        {/* <th scope="col">{t('Status')}</th>
                      <th scope="col">{t('Click for details')}</th> */}
                        <th scope="col">{t('Action')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {travellerOffersList?.length > 0 ? (
                        travellerOffersList.map((value, index) => {
                          if (travellerOfferCounter < 5) {
                            if (value?.is_accepted != 1) {
                              if (value?.user_id != userDetails?.user?.id) {
                                travellerOfferCounter++
                                return (
                                  <tr>
                                    <td>TO-{value.id} </td>
                                    {/* <td>{value?.createdAt?.split('T')[0]}</td> */}
                                    <td> {value?.s_country}</td>
                                    <td>{value?.d_country}</td>
                                    <td>{t(value?.package_type)}</td>{' '}
                                    <td>
                                      {value?.offer_time?.split('D')?.[1] ==
                                      'ays'
                                        ? t('Within ') +
                                          value?.offer_time?.split('D')?.[0] +
                                          ' ' +
                                          t('Days')
                                        : t(value?.offer_time)}
                                    </td>
                                    {/* <td>{value?.offer_price}$</td> */}
                                    {/* <td>{t(value?.status)}</td> */}
                                    <td>
                                      {userDetails?.user?.id > 0 ? (
                                        <a
                                          href="#"
                                          onClick={() =>
                                            handleTravellerOfferDetails(
                                              value,
                                              false,
                                            )
                                          }
                                          className="table_detail_a"
                                        >
                                          {t('See Details')}
                                        </a>
                                      ) : (
                                        <a
                                          href="#"
                                          onClick={() =>
                                            handleTravellerOfferDetails(
                                              value?.id,
                                              true,
                                            )
                                          }
                                          className="table_detail_a"
                                        >
                                          {t('Login to View')}
                                        </a>
                                      )}
                                    </td>
                                  </tr>
                                )
                              } else {
                                ;<tr>
                                  <td colspan="9">
                                    <h3
                                      className="text-center"
                                      style={{
                                        color: 'gray',
                                        fontSize: '1.2rem',
                                      }}
                                    >
                                      {t('No Offer Found')}
                                    </h3>
                                  </td>
                                </tr>
                              }
                            }
                          }
                        })
                      ) : (
                        <tr>
                          <td colspan="9">
                            <h3
                              className="text-center"
                              style={{ color: 'gray', fontSize: '1.2rem' }}
                            >
                              {t('No Offer Found')}
                            </h3>
                          </td>
                        </tr>
                      )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={6} style={{ textAlign: 'right' }}>
                          <Link to="/explore">
                            <Button className="px-3  back-orange bordt-none">
                              {t('Explore More Offers')}
                            </Button>
                          </Link>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col lg={12}>
              <h6>
                {t(
                  'Here are some of Sender Offers you can earn on while travelling',
                )}
              </h6>
              <Row className="senderOfferListTable">
                <Col md={12} className="mt-2">
                  <table className="table custom_tabel_border rounded-2">
                    <thead>
                      <tr
                        style={{ backgroundColor: '#F69110', color: 'white' }}
                      >
                        <th scope="col">{t('Order ID')}</th>
                        {/* <th scope="col">{t("Order Created Date")}</th> */}
                        <th scope="col">{t('From')}</th>
                        <th scope="col">{t('To')}</th>
                        <th scope="col">{t('Package')}</th>
                        <th scope="col">{t('When')}</th>
                        <th scope="col">{t('Offer Price')}</th>
                        {/* <th scope="col">{t("Status")}</th> */}
                        <th scope="col">{t('Action')}</th>
                      </tr>
                    </thead>

                    <tbody>
                      {senderOffersList?.length > 0 ? (
                        senderOffersList.map((value, index) => {
                          if (senderOfferCounter < 5) {
                            if (value?.is_accepted != 1) {
                              if (value?.user_id != userDetails?.user?.id) {
                                senderOfferCounter++
                                return (
                                  <tr>
                                    <td>SO-{value.id} </td>
                                    {/* <td>{value?.createdAt?.split("T")[0]}</td> */}
                                    <td>{value?.s_country}</td>
                                    <td>{value?.d_country}</td>
                                    <td> {t(value?.package_type)}</td>
                                    <td>
                                      {value?.offer_time?.split('D')?.[1] ==
                                      'ays'
                                        ? t('Within ') +
                                          value?.offer_time?.split('D')?.[0] +
                                          ' ' +
                                          t('Days')
                                        : t(value?.offer_time)}
                                    </td>
                                    <td>{value?.service_cost}$</td>
                                    {/* <td>{t(value?.status)}</td> */}
                                    <td>
                                      {userDetails?.user?.id > 0 ? (
                                        <a
                                          href="#"
                                          onClick={() =>
                                            handleSenderOfferDetails(
                                              value,
                                              false,
                                            )
                                          }
                                          className="table_detail_a"
                                        >
                                          {t('See Details')}
                                        </a>
                                      ) : (
                                        <a
                                          href="#"
                                          onClick={() =>
                                            handleSenderOfferDetails(
                                              value?.id,
                                              true,
                                            )
                                          }
                                          className="table_detail_a"
                                        >
                                          {t('Login to View')}
                                        </a>
                                      )}
                                    </td>
                                  </tr>
                                )
                              }
                            }
                          }
                        })
                      ) : (
                        <tr>
                          <td colspan="9">
                            <h3
                              className="text-center"
                              style={{ color: 'gray', fontSize: '1.2rem' }}
                            >
                              {t('No Offer Found')}
                            </h3>
                          </td>
                        </tr>
                      )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="7" style={{ textAlign: 'right' }}>
                          <Link to="/explore">
                            <Button className="px-3  back-orange bordt-none">
                              {t('Explore More Offers')}
                            </Button>
                          </Link>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* Row 4th */}
          <section id="howitworks"></section>
          <Row className="pt-5">
            <Col lg={12} md={12} sm={12}>
              <div>
                <h1 className="fontSize50-700 text-center py-3">
                  {t('How')} <span className="orange-color">Viahop </span>{' '}
                  {t('Works')}?
                </h1>
                <p className="fontSize19-400 text-center mx-lg-0 mx-md-0 mx-3 mb-0">
                  {t(
                    'Viahop is disrupting the courier and traveler industries by making it simpler',
                  )}
                  ,{' '}
                  {t(
                    'safer and more accessible than ever to send things via air travelers',
                  )}
                  .
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="container p-0 justifyContentOn4K">
        <Row className="howViahopWorkSection mt-lg-0 mt-md-0 mt-3">
          <Col lg={6} md={12} sm={12} className="flexClassOn1024 mt-lg-4 mt-md-0 mt-0">
              {language == 'russian' ? 
              <HowItWorkVideo src="https://hhq.acr.mybluehost.me/viahop/viahop-russian.mp4"/>
              :
              <HowItWorkVideo src="https://hhq.acr.mybluehost.me/viahop/viahop-english.mov"/>
              }
              
          </Col>
          <Col lg={6} md={12} sm={12} className="viahopWorkMultibutton">
            <div className="pt-5">
              <MultitabButton />
            </div>
          </Col>
        </Row>
      </div>
      <section className="pb-5" id="aboutus">
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="pb-5">
                <h2 className="fontSize50-700 text-center">
                  {t('This Is What')}{' '}
                </h2>
                <h2 className="fontSize50-700 text-center">
                  <span className="orange-color">{t('Our Users')}</span>
                  {t('Say About Us')}
                </h2>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center pt-5">
            <Col lg={8} md={12} sm={12}>
              <div>
                <img
                  src={Vector}
                  alt="Vector"
                  className="comaicon-testmonial"
                  width="80"
                />
              </div>
              <div className="bg-white height-38 bg-light  shadoe border-rad10">
                <div className="bg-white bg-white sect-dicslider  shadoe border-rad10">
                  <div className="bg-white bg-secondary thrd-divslider P-4 shadoe border-rad10">
                    <SliderTestmonuial />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="landing-lastback mt-5 py-5" id="terms">
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <div>
                <h1 className="orange-color fontSize50-700">
                  {t('Need Any Help')}?
                </h1>
                <Row className="pt-3 contactSectionRow">
                  <Col lg={8} md={12} sm={12}>
                    <p className="fontSize16-400 mb-0 darkgray">
                      {t(
                        'Feel free to Ping us anytime in case you need any help regarding any topic or have query about us',
                      )}
                      .{' '}
                    </p>
                    <p className="fontSize16-400 darkgray">
                      {t(
                        'We are there for you and we would love to assist you in any case of assistance you need',
                      )}
                      .
                    </p>
                  </Col>
                  <Col lg={4} md={5} sm={12}>
                    <div className="textright-iconf mt-50pxlanding">
                      <Button
                        className="orange-background fontSize16-400 mt-3 py-2 bordt-none px-4"
                        onClick={contactUsForm}
                      >
                        {t('Contact')} / {t('Leave Review')}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* modal top */}
      {/* Contact Modal Starts Here */}
      <Modal
        isOpen={contactModal}
        toggle={contactModal}
        className="how-work-modal"
        backdrop="static"
      >
        <ModalBody>
          <Row>
            <Col lg={12} md={12} sm={12} className="py-4">
              <div>
                <Form>
                  <Row className="justify-content-center">
                    <div className="lightorage-color text-center">
                      <h1 className=" py-3 fontSize24-600 orange-color">
                        {t('Contact Us')}
                      </h1>
                    </div>
                    <Col lg={12} md={12} sm={12}>
                      <div className="pt-5">
                        <Form>
                          <Row>
                            <Col lg={6} md={6} sm={12}>
                              <FormGroup>
                                <Label
                                  for="fname"
                                  className="fontSize14-600 dimgray-color"
                                >
                                  {t('Full Name')}
                                </Label>
                                <Input
                                  className="kasy kasy-contact"
                                  value={name}
                                  onChange={(e) => {
                                    setName(e.target.value)
                                  }}
                                  id="fname"
                                  name="fname"
                                  type="text"
                                  required={required}
                                />
                                <FiUser className="mail-icon-login" />
                              </FormGroup>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                              <FormGroup className="">
                                <Label
                                  for="email"
                                  className="fontSize14-600 dimgray-color"
                                >
                                  {' '}
                                  {t('Email')}{' '}
                                </Label>
                                <Input
                                  className="kasy kasy-contact me-5"
                                  value={mail}
                                  onChange={(e) => {
                                    setMail(e.target.value.toLocaleLowerCase())
                                  }}
                                  id="email"
                                  name="email"
                                  type="email"
                                  required={required}
                                />
                                <MdMailOutline className="mail-icon-login" />
                              </FormGroup>
                            </Col>
                          </Row>
                          <FormGroup row className="pt-3">
                            <Label
                              for="exampleText"
                              sm={12}
                              className="fontSize14-600 dimgray-color"
                            >
                              {t('How may we Help you?')}
                            </Label>
                            <Col sm={12}>
                              <Input
                                value={textarea}
                                onChange={(e) => {
                                  setTextarea(e.target.value)
                                }}
                                id="exampleText"
                                name="text"
                                type="textarea"
                                className="hight-inp"
                                required={required}
                              />
                            </Col>
                          </FormGroup>
                          <p>
                            <span style={{ color: 'red' }}>{required}</span>
                          </p>
                          <div className="textright-iconf pt-5">
                            <Button
                              onClick={closeContactUsModal}
                              className="navdropdown-twohov profiletop-btnoutline mt-2 me-4 wafa fontSize16-500 bordt-none"
                              size="md"
                            >
                              {t('Close')}
                            </Button>
                            <Button
                              onClick={handelClear}
                              className="navdropdown-twohov profiletop-btnoutline mt-2 me-4 wafa fontSize16-500 bordt-none"
                              size="md"
                            >
                              {t('Clear Data')}
                            </Button>
                            <Button
                              // type="submit"
                              onClick={handleSupportData}
                              className="navdropdown-twohov profiletop-btnoutline mt-2 me-4 wafa fontSize16-500 bordt-none"
                              size="md"
                            >
                              {loading ? (
                                <img
                                  className="img-fluid buttonLoaderImg"
                                  src={button_loader}
                                  alt="loader"
                                />
                              ) : (
                                t('Submit')
                              )}
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      {/* Contact Modal Ends Here */}

      <Modal
        isOpen={travellerModal}
        toggle={toggleTravellerOfferDetail}
        className="how-work-modal"
        backdrop="static"
      >
        <ModalBody>
          <Row>
            <Col lg={12} md={12} sm={12} className="py-4">
              <div>
                <Row>
                  <Col lg={12}>
                    <Row>
                      <div className="text-center">
                        <h1 style={{ fontWeight: '700', color: '#F69110' }}>
                          {travellerOfferDetail?.s_country + ' '} to{' '}
                          {' ' + travellerOfferDetail?.d_country}
                        </h1>
                        <p>{t('Traveller Offer')}</p>
                      </div>
                      <Col lg={12} className="mt-4">
                        <div
                          style={{
                            border: '1px solid black',
                            borderRadius: '8px',
                            padding: '16px',
                          }}
                        >
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">{t('Order ID')}</p>
                            <p className="mb-0">{travellerOfferDetail?.id}</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">
                              {t('Order Created Date')}:
                            </p>
                            <p className="mb-0">
                              {travellerOfferDetail?.createdAt?.split('T')[0]}
                            </p>
                          </div>
                          {/* <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">{t('Created By')}:</p>
                            <p className="mb-0">
                              {travellerOfferDetail?.user?.firstName +
                                ' ' +
                                travellerOfferDetail?.user?.lastName}
                            </p>
                          </div> */}
                        </div>
                      </Col>

                      <Col lg={6} className="mt-4">
                        <div
                          style={{
                            border: '1px solid black',
                            borderRadius: '8px',
                            padding: '16px',
                          }}
                        >
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">{t('From Country')}:</p>
                            <p className="mb-0">
                              {travellerOfferDetail?.s_country}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">{t('From City')}:</p>
                            <p className="mb-0">
                              {travellerOfferDetail?.s_city}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">{t('Address')}:</p>
                            <p className="mb-0">
                              {travellerOfferDetail?.s_address}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} className="mt-4 align-self-center">
                        <div
                          style={{
                            border: '1px solid black',
                            borderRadius: '8px',
                            padding: '16px',
                          }}
                        >
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">{t('To Country')}:</p>
                            <p className="mb-0">
                              {travellerOfferDetail?.d_country}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">{t('To City')}:</p>
                            <p className="mb-0">
                              {travellerOfferDetail?.d_city}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">{t('Address')}:</p>
                            <p className="mb-0">
                              {travellerOfferDetail?.d_address}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={12} className="mt-4">
                        <div
                          // className="mt-4"
                          style={{
                            border: '1px solid black',
                            borderRadius: '8px',
                            padding: '16px',
                          }}
                        >
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">{t('When')}:</p>
                            <p className="mb-0">
                              {travellerOfferDetail?.offer_time?.split(
                                'D',
                              )?.[1] == 'ays'
                                ? travellerOfferDetail?.offer_time?.split(
                                    'D',
                                  )?.[0] + ' Days'
                                : travellerOfferDetail?.offer_time}
                            </p>
                          </div>
                        </div>
                        <div
                          className="mt-4"
                          style={{
                            border: '1px solid black',
                            borderRadius: '8px',
                            padding: '16px',
                          }}
                        >
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">{t('Package')}:</p>
                            <p className="mb-0">
                              {t(travellerOfferDetail?.package_type)}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">
                              {t('Dimensions')} {t('cm')}:
                            </p>
                            <p className="mb-0">
                              {travellerOfferDetail?.package_height +
                                'x' +
                                travellerOfferDetail?.package_width +
                                'x' +
                                travellerOfferDetail?.package_length}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">
                              {t('Package Weight')}:
                            </p>
                            <p className="mb-0">
                              {travellerOfferDetail?.max_weight}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">{t('Description')}:</p>
                            <p className="mb-0">
                              {travellerOfferDetail?.additional_note}
                            </p>
                          </div>
                        </div>
                        <div
                          className="mt-4"
                          style={{
                            border: '1px solid black',
                            borderRadius: '8px',
                            padding: '16px',
                          }}
                        >
                          <div className="d-flex justify-content-between">
                            {/* <p className="fw-bold mb-0">{t("Offer Price")}:</p> */}
                            {/* <p className="mb-0">
                              {travellerOfferDetail?.offer_price} $
                            </p> */}
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">{t('Status')}:</p>
                            <p className="mb-0">
                              {t(travellerOfferDetail?.status)}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <div className="text-center pt-5">
                      <Button
                        onClick={toggleTravellerCounterOffer}
                        type="button"
                        className="btnlanding-hov ms-4 profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                      >
                        {t('Make Proposal')}
                      </Button>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="text-center pt-5">
                      <Button
                        onClick={toggleTravellerOfferDetail}
                        type="button"
                        className="btnlanding-hov ms-4 profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                      >
                        {t('Close')}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      {/* Traveller Counter Offer */}
      <Modal
        isOpen={counterTravellerOfferModal}
        toggle={toggleTravellerCounterOffer}
        className="how-work-modal"
        backdrop="static"
      >
        <ModalBody>
          <Row>
            <Col lg={12} md={12} sm={12} className="py-4">
              <div>
                <Form>
                  <Row>
                    <div className="lightorage-color text-center">
                      <h1 className=" py-3 fontSize24-600 orange-color">
                        {t('Make Proposal')}
                      </h1>
                    </div>
                    <Col lg={12} md={12} sm={12}>
                      <FormGroup className="pt-3">
                        <Label
                          for="counterOfferAmount"
                          className="fontSize14-600 dimgray-color"
                        >
                          {' '}
                          {t('Offer Amount')}{' '}
                        </Label>
                        <Input
                          onChange={handleCounterOfferAmount}
                          id="counterOfferAmount"
                          name="counterOfferAmount"
                          type="text"
                          value={counterOfferAmount}
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={12} md={12} sm={12}>
                      <FormGroup className="pt-3">
                        <Label
                          for="counterPackageDetails"
                          className="fontSize14-600 dimgray-color"
                        >
                          {' '}
                          {t('Package Details')}{' '}
                        </Label>
                        <Input
                          onChange={handlePackageDetails}
                          id="counterPackageDetails"
                          name="counterPackageDetails"
                          type="counterPackageDetails"
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={12} md={12} sm={12}>
                      <FormGroup className="pt-3">
                        <Label
                          for="number"
                          className="fontSize14-600 dimgray-color"
                        >
                          {' '}
                          {t('Offer Description')}{' '}
                        </Label>

                        <Input
                          type="textarea"
                          onChange={handleCounterOfferDescription}
                          id="number"
                          name="number"
                          placeholder={t('Enter Offer Descriptions')}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="textright-iconf py-5">
                    <Button
                      type="button"
                      onClick={toggleTravellerCounterOffer}
                      className="btnlanding-hov profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                    >
                      {t('Cancel')}
                    </Button>
                    {/* <Link to="/login"> */}
                    <Button
                      type="button"
                      onClick={handleSubmitTravellerCounterOffer}
                      className="btnlanding-hov ms-4 profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                      disabled={buttonLoader}
                    >
                      {buttonLoader ? (
                        <img
                          className="img-fluid buttonLoaderImg"
                          src={button_loader}
                          alt="loading"
                        />
                      ) : (
                        <>{t('Save')}</>
                      )}
                    </Button>
                    {/* </Link> */}
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="how-work-modal"
        backdrop="static"
      >
        <ModalBody>
          <Row>
            <Col lg={12} md={12} sm={12} className="py-4">
              <div>
                <Row>
                  <div className="text-center">
                    <h1 style={{ fontWeight: '700', color: '#F69110' }}>
                      {offerDetails?.s_country + ' '} to{' '}
                      {' ' + offerDetails?.d_country}
                    </h1>
                    <p>{t('Sender Offer')}</p>
                  </div>
                  <Col lg={12} className="mt-4">
                    <div
                      style={{
                        border: '1px solid black',
                        borderRadius: '8px',
                        padding: '16px',
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t('Order ID')}:</p>
                        <p className="mb-0">{offerDetails?.id}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">
                          {t('Order Created Date')}:
                        </p>
                        <p className="mb-0">
                          {offerDetails?.createdAt?.split('T')[0]}
                        </p>
                      </div>
                      {/* <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t("Created By")}:</p>
                        <p className="mb-0">
                          {offerDetails?.user?.firstName +
                            " " +
                            offerDetails?.user?.lastName}
                        </p>
                      </div> */}
                    </div>
                  </Col>
                  {/* <Col lg={6} className="mt-4"></Col> */}
                  <Col lg={12} className="mt-3">
                    <div
                      className=""
                      style={{
                        border: '1px solid black',
                        borderRadius: '8px',
                        padding: '16px',
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t('From Country')}:</p>
                        <p className="mb-0">{offerDetails?.s_country + ' '}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t('From City')}:</p>
                        <p className="mb-0">{offerDetails?.s_city + ' '}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t('From Address')}:</p>
                        <p className="mb-0">{offerDetails?.s_address + ' '}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">
                          {t('First Mile Delivery')}:
                        </p>
                        <p className="mb-0">
                          {offerDetails?.is_first_mile_pickup == 1
                            ? t('Yes')
                            : t('NO')}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12} className="mt-3 align-self-center">
                    <div
                      style={{
                        border: '1px solid black',
                        borderRadius: '8px',
                        padding: '16px',
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t('To Country')}:</p>
                        <p className="mb-0">{offerDetails?.d_country + ' '}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t('To City')}:</p>
                        <p className="mb-0">{offerDetails?.d_city + ' '}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t('To Address')}:</p>
                        <p className="mb-0">{offerDetails?.d_address + ' '}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">
                          {t('Last mile delivery')}:
                        </p>
                        <p className="mb-0">
                          {offerDetails?.is_last_mile_delivery == 1
                            ? t('Yes')
                            : t('NO')}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12} className="mt-4">
                    <div
                      // className="mt-4"
                      style={{
                        border: '1px solid black',
                        borderRadius: '8px',
                        padding: '16px',
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t('When')}:</p>
                        <p className="mb-0">
                          {offerDetails?.offer_time?.split('D')?.[1] == 'ays'
                            ? offerDetails?.offer_time?.split('D')?.[0] +
                              t('Days')
                            : offerDetails?.offer_time}
                        </p>
                      </div>
                    </div>
                    <div
                      className="mt-4"
                      style={{
                        border: '1px solid black',
                        borderRadius: '8px',
                        padding: '16px',
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t('Package')}:</p>
                        <p className="mb-0">{t(offerDetails?.package_type)}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t('Dimensions')} :</p>
                        <p className="mb-0">
                          {offerDetails?.package_height +
                            'x' +
                            offerDetails?.package_width +
                            'x' +
                            offerDetails?.package_length}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t('Weight')} :</p>
                        <p className="mb-0">{offerDetails?.package_weight}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t('Description')}:</p>
                        <p className="mb-0">{offerDetails?.additional_note}</p>
                      </div>
                    </div>
                    <div
                      className="mt-4"
                      style={{
                        border: '1px solid black',
                        borderRadius: '8px',
                        padding: '16px',
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t('Offer Price')}:</p>
                        <p className="mb-0">{offerDetails?.service_cost} $</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t('Status')}:</p>
                        <p className="mb-0">{t(offerDetails?.status)}</p>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={4}>
                    <div className="text-center pt-5">
                      <Button
                        onClick={() =>
                          toggleAcceptSenderOffer(offerDetails?.id)
                        }
                        type="button"
                        className="btnlanding-hov ms-4 profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                      >
                        {t('Accept Offer')}
                      </Button>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="text-center pt-5">
                      <Button
                        onClick={toggleCounterOffer}
                        type="button"
                        className="btnlanding-hov ms-4 profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                      >
                        {t('Make Counter Offers')}
                      </Button>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="text-center pt-5">
                      <Button
                        onClick={toggle}
                        type="button"
                        className="btnlanding-hov ms-4 profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                      >
                        {t('Close')}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      {/* Accept Sender Offer */}
      <Modal
        isOpen={acceptSenderOffer}
        toggle={toggleAcceptSenderOffer}
        className="how-work-modal"
        backdrop="static"
      >
        <ModalBody>
          <Row>
            <Col lg={12} md={12} sm={12} className="py-4">
              <div>
                <Form>
                  <Row>
                    <div className="lightorage-color text-center">
                      <h1 className=" py-3 fontSize24-600 orange-color">
                        {t('Accept Sender Offer')}
                      </h1>
                    </div>

                    <Col lg={12} md={12} sm={12}>
                      <FormGroup className="pt-3">
                        <Label
                          for="number"
                          className="fontSize14-600 dimgray-color"
                        >
                          {' '}
                          {t('Add Personal Note')} {t('Optional')}
                        </Label>

                        <Input
                          type="textarea"
                          // onChange={handleCounterOfferDescription}
                          id="number"
                          name="number"
                          placeholder={t('Enter Offer Descriptions')}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="textright-iconf py-5">
                    <Button
                      type="button"
                      onClick={toggleAcceptSenderOffer}
                      className="btnlanding-hov profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                    >
                      {t('Cancel')}
                    </Button>
                    {/* <Link to="/login"> */}
                    <Button
                      type="button"
                      onClick={handleSubmitAcceptSenderOffer}
                      className="btnlanding-hov ms-4 profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                      disabled={buttonLoader}
                    >
                      {buttonLoader ? (
                        <img
                          className="img-fluid buttonLoaderImg"
                          src={button_loader}
                          alt="loading"
                        />
                      ) : (
                        t('Accept')
                      )}
                    </Button>
                    {/* </Link> */}
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      {/* Sender Counter Offer */}
      <Modal
        isOpen={counterOfferModal}
        toggle={toggleCounterOffer}
        className="how-work-modal"
        backdrop="static"
      >
        <ModalBody>
          <Row>
            <Col lg={12} md={12} sm={12} className="py-4">
              <div>
                <Form>
                  <Row>
                    <div className="lightorage-color text-center">
                      <h1 className=" py-3 fontSize24-600 orange-color">
                        {t('Create Sender Counter Offer')}
                      </h1>
                    </div>
                    <Col lg={12} md={12} sm={12}>
                      <FormGroup className="pt-3">
                        <Label
                          for="counterOfferAmount"
                          className="fontSize14-600 dimgray-color"
                        >
                          {' '}
                          {t('Offer Amount')}{' '}
                        </Label>
                        <Input
                          onChange={handleCounterOfferAmount}
                          id="counterOfferAmount"
                          name="counterOfferAmount"
                          type="text"
                          value={counterOfferAmount}
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={12} md={12} sm={12}>
                      <FormGroup className="pt-3">
                        <Label
                          for="number"
                          className="fontSize14-600 dimgray-color"
                        >
                          {' '}
                          {t('Offer Description')}{' '}
                        </Label>

                        <Input
                          type="textarea"
                          onChange={handleCounterOfferDescription}
                          id="number"
                          name="number"
                          placeholder={t('Enter Offer Descriptions')}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="textright-iconf py-5">
                    <Button
                      type="button"
                      onClick={toggleCounterOffer}
                      className="btnlanding-hov profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                    >
                      {t('Cancel')}
                    </Button>
                    {/* <Link to="/login"> */}
                    <Button
                      type="button"
                      onClick={handleSubmitSenderCounterOffer}
                      className="btnlanding-hov ms-4 profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                      disabled={buttonLoader}
                    >
                      {buttonLoader ? (
                        <img
                          className="img-fluid buttonLoaderImg"
                          src={button_loader}
                          alt="loading"
                        />
                      ) : (
                        t('Save')
                      )}
                    </Button>
                    {/* </Link> */}
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default LandingPage
