import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { getAllMyRating } from "../../redux/actions/RatingAction";

function SeeMyRating() {
  const dispatch = useDispatch();
  const { getAllMyRatings } = useSelector((state) => state.RatingReducer);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAllMyRating());
  }, []);

  return (
    <div style={{ overflow: "scroll" }}>
      <table className="table custom_tabel_border_2">
        <thead style={{ backgroundColor: "#F69110", color: "white" }}>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{t("Order")} #</th>
            <th scope="col">{t("Date")}</th>
            <th scope="col">{t("Rated As")}</th>
            <th scope="col">{t("Rating")}</th>
            <th scope="col">{t("Comment")}</th>
          </tr>
        </thead>
        <tbody>
          {getAllMyRatings?.length > 0 ? (
            getAllMyRatings.map((value, index) => {
              return (
                <tr>
                  <td>{++index}</td>
                  <td>--</td>
                  <td>--</td>
                  <td>--</td>
                  <td>{value?.id}</td>
                  <td>--</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={10}>
                <h3 style={{ color: "gray", fontSize: "1.2rem" }}>
                  {t("There is no Rating")}
                </h3>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default SeeMyRating;
