import OTPInput, { ResendOTP } from "otp-input-react";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Modal, ModalBody } from "reactstrap";
import button_loader from "../Assets/Loader/button_loader.gif";
import {
  verifyEmailOtp,
  verifyUserEmail,
  verifyUserPhone,
  getResetPasswordOtp,
  verifyPhoneOtp,
  verifyForgotOtp,
} from "../../redux/actions/UserAction";
import "../Otp/OtpStyle.css";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

export default function OtpCom({
  type,
  mobileNumberFromForget,
  handleClose,
  phoneSuccessToggle,
}) {
  const dispatch = useDispatch();
  const [otp, setOTP] = useState(null);
  const [erro, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);

  const { t } = useTranslation();
  const { userDetails } = useSelector((state) => state.UserReducer);
  console.log(
    mobileNumberFromForget + "========== mobile number from forget page"
  );
  useEffect(() => {
    setEmail(userDetails?.user?.email);
    setPhone(userDetails?.user?.phone_no);
    console.log(JSON.stringify(userDetails) + "======== user details here");
  });
  console.log(otp + "=============");

  const navigate = useNavigate();
  const handleResponse = (data) => {
    if (type == "f") {
      setLoading(false);
      if (data.status) {
        Swal.fire({
          title: t("Success"),
          type: t("success"),
          text: t("We have sent you Login Credentials on your email"),
        });
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        setError(data.message);
      }
    }
    console.log(data);
    if (data.status) {
      setLoading(false);
      if (type === "p") {
        phoneSuccessToggle();
      } else {
        handleClose();
      }
    } else {
      setLoading(false);
      setError(data?.message);
    }
  };
  const handleOtpVerification = () => {
    setLoading(true);
    let data = {
      otp: otp,
    };
    console.log("email verify otp");
    dispatch(verifyEmailOtp(data, handleResponse));
  };

  const handlePhoneOtpVerification = () => {
    setLoading(true);
    let data = {
      otp: otp,
    };
    console.log("phone verify otp");
    dispatch(verifyPhoneOtp(data, handleResponse));
  };
  const handleResetOtpVerification = () => {
    setLoading(true);
    let data = {
      otp: otp,
    };
    dispatch(verifyForgotOtp(data, handleResponse));
  };

  const handleResendOtp = () => {
    if (type == "e") {
      let emailData = {
        email: email,
      };
      dispatch(verifyUserEmail(emailData, handleResendResponse));
      console.log("Resend Email OTP function called");
    } else if (type == "f") {
      if (
        mobileNumberFromForget != "" &&
        mobileNumberFromForget != "undefined" &&
        mobileNumberFromForget != null
      ) {
        console.log("I am in if condition");
        console.log(mobileNumberFromForget);
        let phoneData = {
          phone_no: mobileNumberFromForget,
        };
        dispatch(getResetPasswordOtp(phoneData, handleResendResponse));
      } else {
        console.log("Phone number is null or undefined");
      }
    } else if (type == "p") {
      let phoneData = {
        phone: phone,
      };
      dispatch(verifyUserPhone(phoneData, handleResendResponse));
      console.log("Resend Phone OTP function called");
    }
  };
  const handleResendResponse = (data) => {
    if (data.status) {
      Swal.fire({
        title: t("Success"),
        type: t("success"),
        text: data.message,
      });
    } else {
      Swal.fire({
        title: t("Failed"),
        type: t("failed"),
        text: data.message,
      });
    }
  };
  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col lg={8} md={9} sm={12}>
            <div className="text-center m-auto pt-4 otp_component">
              <OTPInput
                value={otp}
                onChange={setOTP}
                autoFocus
                OTPLength={5}
                className="otp-input text-center justify-content-center"
                otpType="number"
                disabled={false}
              />
            </div>
            <div className="text-center mt-2">
              <span style={{ color: "red" }}>{erro}</span>
            </div>
            {/* <Link to='/otp_verify' className="text-decor">  */}
            <div className="d-grid gap-2 pt-2 pb-4">
              <Button
                variant="primary"
                size="lg"
                className="orange-background bordt-none"
                onClick={
                  type == "e"
                    ? handleOtpVerification
                    : type == "f"
                    ? handleResetOtpVerification
                    : handlePhoneOtpVerification
                }
                disabled={loading}
              >
                {loading ? (
                  <>
                    {t("Processing...")}
                    <img
                      className="img-fluid buttonLoaderImg"
                      src={button_loader}
                      alt="loader"
                    />
                  </>
                ) : (
                  t("Verify")
                )}
              </Button>
            </div>
            {/* </Link> */}
            <div>
              <p className="fontSize16-500 text-center mb-0">
                {t("Didn’t recieve the OTP")}?
              </p>
            </div>
            <ResendOTP
              className="justify-content-center bto"
              onResendClick={handleResendOtp}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}
