import React, { useState, useRef, useEffect } from "react";
import LoadingScreen from "react-loading-screen";

import { Button, Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import { Input, Label } from "reactstrap";
import log from "../Assets/Images/log.png";
import { MdMailOutline } from "react-icons/md";
import { FiLock } from "react-icons/fi";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LogInAction } from "../../redux/actions/LogInAction";
import logoo from "../Assets/Images/logoo1.png";
import LoggedinNavbar from "../Component/LoggedinNavbar";
import { useTranslation } from "react-i18next";
import SecondNav from "../Component/SecondNav";
import { getFirebaseToken } from "../../firebase";

function LoginPage() {
  const form = useRef();
  const checkBtn = useRef();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [errorMessage, setMessage] = useState("");
  const { t } = useTranslation();

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const token = localStorage.getItem("token"); // determine if authorized, from context or however you're doing it
  useEffect(() => {
    if (token) {
      navigate("/main_profile");

      function handleClick() {
        console.log("click event triggered");
      }

      document.body.addEventListener("click", handleClick);
      return () => {
        // 👇️ remove event listener when the component unmounts
        document.body.removeEventListener("click", handleClick);
      };
    }
  }, []);
  const handleLoginFormData = (data) => {
    if (data?.status) {
      setLoading(false);
      localStorage.setItem("token", data?.token);
      // localStorage.setItem("userRole", data.data.userRole);
      navigate("/main_profile");
    } else {
      setMessage(data?.message);
      setLoading(false);
      setError(true);
    }
  };

  const [deviceToken, setDeviceToken] = useState("");
  const handleDeviceToken = (data) => {
    setDeviceToken(data);
    console.log("divicetoken", data);
    console.log("divicetoken1", deviceToken);
  };

  useEffect(() => {
    // setLoading(false);
    getFirebaseToken(handleDeviceToken);
  }, []);
  const handleSubmit = (e) => {
    console.log("divicetoken2", deviceToken);
    e.preventDefault();
    getFirebaseToken(handleDeviceToken).then(() => {
      setLoading(true);

      if (deviceToken !== "") {
        let data = {
          device_token: deviceToken,
          email: email,
          password: password,
        };
        dispatch(LogInAction(data, handleLoginFormData));
      } else {
        let data = {
          email: email,
          password: password,
        };
        dispatch(LogInAction(data, handleLoginFormData));
      }
    });
  };

  return (
    <div>
      <LoadingScreen
        loading={loading}
        bgColor="#f1f1f1"
        spinnerColor="#9ee5f8"
        textColor="#676767"
        logoSrc={logoo}
        text={t("Hang tight! while we do our magic")}
      >
        <LoggedinNavbar />
        {/* <SecondNav /> */}
        <Container className="width100Container marginl-0">
          <Row className="loginPageMainRow p-0">
            <Col lg={7} md={6} sm={12} className="p-0">
              <div className="pt-40per-login displayNoneClass">
                <img
                  src={log}
                  alt="log-img"
                  className="img-regis"
                  width="100%"
                />
              </div>
            </Col>
            <Col lg={5} md={6} sm={12}>
              <div className="pt-53per-login textAlignCenter">
                <h1 className="fontSize50-700 ">
                  {t("Welcome to")} <span className="orange-color">Viahop</span>
                  !
                </h1>
                <p className="fontSize16-500">{t("Login Your account")}</p>
              </div>
              {error && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {t("Oops")}! <br />
                    {t(errorMessage)}
                  </div>
                </div>
              )}
              <div className="pt-4 marginLeftRightClass">
                <Form onSubmit={handleSubmit} ref={form}>
                  <FormGroup>
                    <Label for="email" className="fontSize14-600 dimgray-color">
                      {" "}
                      {t("Email")}{" "}
                    </Label>
                    <Input
                      id="email"
                      style={{ fontFamily: "Poppins", paddingRight: 45 }}
                      onChange={(e) => {
                        setEmail(e.target.value.toLowerCase());
                      }}
                      value={email}
                      name="email"
                      type="email"
                      className="fa fa-envelope"
                      required
                    />
                    <MdMailOutline className="mail-icon-login" />
                  </FormGroup>{" "}
                  <FormGroup className="py-3">
                    <Label
                      for="password"
                      className="fontSize14-600 dimgray-color"
                    >
                      {" "}
                      {t("Password")}{" "}
                    </Label>
                    <Input
                      id="password"
                      name="password"
                      style={{ paddingRight: 45 }}
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <FiLock className="mail-icon-login" />
                  </FormGroup>{" "}
                  <div className="d-flex justify-content-between">
                    <FormGroup className="">
                      <Label for="password" className="fontSize16-400 ">
                        <Input
                          id="password"
                          name="password"
                          type="checkbox"
                          className="loginchek"
                          style={{ border: "2px solid" }}
                        />{" "}
                        {t("Remember me")}
                      </Label>
                    </FormGroup>{" "}
                    <Link to="/forgot" className="text-dark text-decor">
                      {" "}
                      <span className="fontSize16-400">
                        {" "}
                        {t("Forgot Password")}?
                      </span>
                    </Link>
                  </div>
                  <div className="d-grid gap-2 pt-3 text-decor">
                    <Button
                      type="submit"
                      className="orange-background fontSize16-500 bordt-none"
                      size="md"
                      block
                      disabled={loading}
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>{t("Login")}</span>
                    </Button>
                  </div>
                  <div className="py-4">
                    <p className="fontSize16-500 dimgray-color text-center">
                      {t("Don't have an account")}?{" "}
                      <Link to="/register" className="text-decor">
                        {" "}
                        <span className="orange-color"> {t("Sign up")}</span>
                      </Link>{" "}
                    </p>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    </div>
  );
}

export default LoginPage;
