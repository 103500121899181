import { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Link, useNavigate } from 'react-router-dom'
import '../SamllComponent/MultitabBtn.css'
import logoo from '../Assets/Images/logoo1.png'
import LoadingScreen from 'react-loading-screen'

import {
  getSenderOffers,
  senderOfferPayment,
} from '../../redux/actions/TravellerOfferAction'
import { useDispatch, useSelector } from 'react-redux'
import OfferDetails from './OfferDetails'
import RateYourDelivery from './RateYourDelivery'
import PayTheBill from './PayTheBill'
import { Button } from 'reactstrap'
import MyOfferDetails from './MyOfferDetails'
import { useTranslation } from 'react-i18next'
function MySenderOffers(props) {
  const dispatch = useDispatch()

  const [orderId, setOrderId] = useState('')
  const [sAddress, setSourceAddress] = useState('')
  const [dAddress, setDestinationAddress] = useState('')
  const [offerTime, setOfferTime] = useState('')
  const [loading, setLoading] = useState(true)
  const [loader, setLoader] = useState(false)
  const { allSenderOffers } = useSelector(
    (state) => state.TravellerOfferReducer,
  )
  const [totalSenderOffers, setTotalSenderOffers] = useState(null)

  console.log('allSenderOffers===========', allSenderOffers?.senderOffers)
  // useEffect(()=>{
  //   console.log(
  //     JSON.stringify(allSenderOffers) +
  //       "=============all sener offer in multi tab button"
  //   );
  // })
  const [isDetail, setShowDetail] = useState(false)
  const [offerId, setOfferID] = useState(false)
  const [isRated, setRateDelivery] = useState(false)
  const [isUnpaid, setPayBill] = useState(false)
  const [deliveryId, setDeliveryID] = useState(false)
  const [deliveryUserId, setDeliveryUserID] = useState(false)
  const [eventKey, setEventKey] = useState('home')

  const navigate = useNavigate()
  const { t } = useTranslation()

  // useEffect(() => {
  //   setShowDetail(false);
  //   setRateDelivery(false);
  //   setPayBill(false);

  //   dispatch(getSenderOffers());
  // }, [dispatch, isDetail, handleShowDetails, handleShowDetailsSenderOffer]);

  const [offerDetailData, setOfferDetailData] = useState(null)
  const [isDetailSenderOffer, setShowSenderOfferDetail] = useState(false)
  const [offerType, setOfferType] = useState('')

  useEffect(() => {
    setShowDetail(false)
    setRateDelivery(false)
    setPayBill(false)

    dispatch(getSenderOffers())
  }, [dispatch, eventKey])

  const handleallSenderOffers = () => {
    const array = allSenderOffers?.senderOffers?.filter(
      (val) => val.status === 'Complete and Paid',
    )
    return array
  }
  const handleacceptedTravelerOffers = () => {
    const array = allSenderOffers?.acceptedTravelerOffers?.filter(
      (val) => val.status === 'Complete and Paid',
    )
    return array
  }

  const handleOfferDetail = (data, type) => {
    setOfferDetailData(data)
    if (type == 'sender') {
      setShowSenderOfferDetail(true)
    } else {
      setShowDetail(true)
    }
  }
  const handleShowDetails = () => {
    setShowDetail(false)
    setEventKey('home')
  }
  const handleShowDetailsSenderOffer = () => {
    setShowSenderOfferDetail(false)
    setEventKey('home')
  }
  if (isDetailSenderOffer) {
    return (
      <MyOfferDetails
        userType={'sender'}
        offerDetailData={offerDetailData}
        offerType={'sender'}
        isShowDetails={handleShowDetailsSenderOffer}
        offerAcceptedbyMe={false}
        id={offerId}
      />
    )
  }
  if (isDetail) {
    return (
      <MyOfferDetails
        userType={'sender'}
        offerDetailData={offerDetailData}
        allSenderOffers={allSenderOffers}
        offerType={'traveller'}
        offerAcceptedbyMe={true}
        isShowDetails={handleShowDetails}
        id={offerId}
      />
    )
  }

  // Nauman's Code Starts
  const handleRateDelivery = (value, type) => {
    setOfferType(type)
    setRateDelivery(true)
    setDeliveryID(value?.id)
    setDeliveryUserID(value?.user_id)
    // console.log(value, "123123asdasd");
  }
  const handleRatePage = () => {
    setRateDelivery(false)
    setEventKey('profile')
  }

  if (isRated) {
    return (
      <RateYourDelivery
        offerType={offerType}
        isRatingPage={handleRatePage}
        id={deliveryId}
        userId={deliveryUserId}
      />
    )
  }

  const handlePaymentMethod = (id) => {
    setPayBill(true)
    setDeliveryID(id)
  }
  const handlePaymentPage = () => {
    setPayBill(false)
    setEventKey('profile')
  }
  if (isUnpaid) {
    return <PayTheBill isPaymentPage={handlePaymentPage} id={deliveryId} />
  }
  // Nauman's Code Ends

  const handleStatusUpdate = (e) => {}

  //sender Offer Payment
  const handleSubmitSenderOfferPayment = (senderOfferId) => {
    let data = {
      offerType: 'senderOffer',
      offerId: senderOfferId,
    }
    dispatch(senderOfferPayment(data, handleResponseSenderOfferPayment))
  }
  const handleResponseSenderOfferPayment = (data) => {
    if (data?.status) {
      console.log(data?.session?.url + '======checkout session')
      window.location.href = data?.session?.url
    }
  }

  //sender Offer Payment
  const handleSubmitTravellerOfferPayment = (travellerOfferId) => {
    setLoader(true)
    let data = {
      offerType: 'travelerOffer',
      offerId: travellerOfferId,
    }
    dispatch(senderOfferPayment(data, handleResponseTravellerOfferPayment))
  }
  const handleResponseTravellerOfferPayment = (data) => {
    if (data?.status) {
      setLoader(false)
      console.log(data?.session?.url + '======checkout session')
      window.location.href = data?.session?.url
    }
  }
  return (
    <>
      <LoadingScreen
        loading={loader}
        bgColor="#f1f1f1"
        spinnerColor="#9ee5f8"
        textColor="#676767"
        logoSrc={logoo}
        text={t('Hang tight while we do our magic!')}
      >
        <Tabs
          defaultActiveKey={eventKey}
          id="fill-tab-example"
          className="mb-3 mx-auto multi-btntwo fontSize16-500 "
          fill
        >
          <Tab
            eventKey="home"
            title={t('Active')}
            style={{ overflow: 'scroll' }}
            // className="py-3 "
          >
            <Button
              className="px-3 ms-2 mb-3 back-orange bordt-none"
              onClick={() => {
                navigate('/create_sender_offer')
              }}
            >
              {t('Create Sender Offer')}
            </Button>
            <p className="fontSize24-600 txtleft darkgraytext pb-0">
              {t('Your sender Offer')}
            </p>
            <table className="table custom_tabel_border_2">
              <thead style={{ backgroundColor: '#F69110', color: 'white' }}>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{t('Order')}#</th>
                  <th scope="col">{t('Order Created Date')}</th>
                  <th scope="col">{t('From')}</th>
                  <th scope="col">{t('To')}</th>
                  <th scope="col">{t('Type')}</th>
                  <th scope="col">{t('Shipment Date')}</th>
                  <th scope="col">{t('Match Status')}</th>
                  <th scope="col">{t('Amount')}</th>
                  {/* <th scope="col">Verification</th> */}
                  <th scope="col">{t('Delivery status')}</th>
                  <th scope="col">{t('Action')}</th>
                </tr>
              </thead>
              <tbody>
                {allSenderOffers?.senderOffers?.length > 0 ? (
                  allSenderOffers?.senderOffers.map((value, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>SO-{value?.id}</td>
                        <td>{value?.createdAt?.split('T')[0]}</td>
                        <td>{value?.s_country}</td>
                        <td>{value?.d_country}</td>
                        <td>{t(value?.package_type)}</td>
                        <td>{t(value?.offer_time)}</td>
                        <td>{t(value?.status)}</td>
                        <td>{Math.round(value?.total_cost) + ' $'}</td>
                        {/* <td>--</td> */}
                        <td>{t(value?.status)}</td>
                        <td>
                          <a
                            href="#"
                            onClick={() => handleOfferDetail(value, 'sender')}
                            className="table_detail_a"
                          >
                            {t('See Details')}
                          </a>
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={10}>
                      <h3 style={{ color: 'gray', fontSize: '1.2rem' }}>
                        {t('There is no Active Offer')}
                      </h3>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {/* <p className="fontSize24-600 txtleft darkgraytext pb-0">
              {t('Your Accepted Offer')}
            </p>
            <table className="table custom_tabel_border_2">
              <thead style={{ backgroundColor: '#F69110', color: 'white' }}>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{t('Order')}#</th>
                  <th scope="col">{t('Order Created Date')}</th>
                  <th scope="col">{t('From')}</th>
                  <th scope="col">{t('To')}</th>
                  <th scope="col">{t('Type')}</th>
                  <th scope="col">{t('Shipment Date')}</th>
                  <th scope="col">{t('Match Status')}</th>
                  <th scope="col">{t('Amount')}</th>
                  <th scope="col">{t('Delivery status')}</th>
                  <th scope="col">{t('Action')}</th>
                </tr>
              </thead>
              <tbody>
                {allSenderOffers?.acceptedTravelerOffers?.length > 0 ? (
                  allSenderOffers?.acceptedTravelerOffers.map(
                    (value, index) => {
                      return (
                        <tr>
                          <td>{++index}</td>
                          <td>TO-{value.traveler_offer_id}</td>
                          <td>{value?.createdAt?.split('T')[0]}</td>
                          <td> {value?.travelerOffer?.s_country}</td>
                          <td> {value?.travelerOffer?.d_country}</td>
                          <td>{t(value?.travelerOffer?.package_type)}</td>
                          <td>{t(value?.travelerOffer?.offer_time)}</td>
                          <td>{t(value?.travelerOffer?.status)}</td>
                          <td>
                            {value?.travelerOffer?.is_accepted == 1 ? (
                              <>{value?.travelerOffer?.offer_price}$ </>
                            ) : (
                              <span className="text-danger">
                                {' '}
                                {t('Unpaid')}
                              </span>
                            )}
                          </td>
                          <td>{t(value?.travelerOffer?.status)}</td>
                          <td>
                            <a
                              href="#"
                              onClick={() =>
                                handleOfferDetail(
                                  value?.travelerOffer,
                                  'traveller',
                                )
                              }
                              className="table_detail_a"
                            >
                              {t('See Details')}
                            </a>
                          </td>
                        </tr>
                      )
                    },
                  )
                ) : (
                  <tr>
                    <td colspan="10">
                      <h3 style={{ color: 'gray', fontSize: '1.2rem' }}>
                        {t('There is no Accepted Order')}
                      </h3>
                    </td>
                  </tr>
                )}
              </tbody>
            </table> */}
          </Tab>
          <Tab
            eventKey="profile"
            style={{ overflow: 'scroll' }}
            title={t('History')}
          >
            {' '}
            <Button
              className="px-3 ms-2 mb-3 back-orange bordt-none"
              onClick={() => {
                navigate('/create_sender_offer')
              }}
            >
              {t('Create Sender Offer')}
            </Button>
            <p className="fontSize24-600 txtleft darkgraytext pb-0">
              {t('Your Created Offer')}
            </p>
            <table className="table custom_tabel_border_2">
              <thead style={{ backgroundColor: '#F69110', color: 'white' }}>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{t('Order')}#</th>
                  <th scope="col">{t('From')}</th>
                  <th scope="col">{t('To')}</th>
                  <th scope="col">{t('Type')}</th>
                  <th scope="col">{t('Order Date')}</th>
                  <th scope="col">{t('Match Status')}</th>
                  <th scope="col">{t('Payment')}</th>
                  <th scope="col">{t('Delivery status')}</th>
                  <th scope="col">{t('Rate Your Delivery')}</th>
                </tr>
              </thead>
              <tbody>
                {allSenderOffers?.senderOffers?.length > 0 ? (
                  allSenderOffers?.senderOffers.map((value, index) => {
                    if (value?.status == 'Complete and Paid') {
                      return (
                        <tr>
                          <td>{++index}</td>
                          <td>SO-{value?.id}</td>
                          <td>{value?.s_country}</td>
                          <td>{value?.d_country}</td>
                          <td>{t(value?.package_type)}</td>
                          <td>{t(value?.createdAt?.split('T')[0])}</td>
                          <td>{t(value?.status)}</td>
                          <td>{value?.total_cost + ' $'}</td>
                          <td>{t(value?.status)}</td>
                          <td>
                            <a
                              href="#"
                              onClick={() =>
                                handleRateDelivery(value, 'senderOffer')
                              }
                              className="table_detail_a"
                              style={{ textDecorationLine: 'inherit' }}
                            >
                              <span className="orange-color">
                                {t('Add Rating')}
                              </span>
                            </a>
                          </td>
                        </tr>
                      )
                    }
                  })
                ) : (
                  <tr>
                    <td colSpan={10}>
                      <h3 style={{ color: 'gray', fontSize: '1.2rem' }}>
                        {t('There is no Active Offer')}
                      </h3>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {/* <p className="fontSize24-600 txtleft darkgraytext pb-0">
              {t('Your Accepted Offer')}:
            </p>
            <table className="table custom_tabel_border_2">
              <thead style={{ backgroundColor: '#F69110', color: 'white' }}>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{t('Order')}#</th>
                  <th scope="col">{t('From')}</th>
                  <th scope="col">{t('To')}</th>
                  <th scope="col">{t('Type')}</th>
                  <th scope="col">{t('Order Date')}</th>
                  <th scope="col">{t('Match Status')}</th>
                  <th scope="col">{t('Payment')}</th>
                  <th scope="col">{t('Delivery status')}</th>
                  <th scope="col">{t('Rate Your Delivery')}</th>
                </tr>
              </thead>
              <tbody>
                {allSenderOffers?.acceptedTravelerOffers?.length > 0 ? (
                  allSenderOffers?.acceptedTravelerOffers.map(
                    (value, index) => {
                      if (value?.travelerOffer?.status == 'Complete and Paid') {
                        return (
                          <tr>
                            <td>{++index}</td>
                            <td>{value?.id}</td>
                            <td>{value?.travelerOffer?.s_country}</td>
                            <td>{value?.travelerOffer?.d_country}</td>
                            <td>{t(value?.travelerOffer?.package_type)}</td>
                            <td>
                              {t(
                                value?.travelerOffer?.createdAt?.split('T')[0],
                              )}
                            </td>
                            <td>{t(value?.travelerOffer?.status)}</td>
                            <td>
                              {' '}
                              <span className="greeencolor">
                                <span className="greeencolor">
                                  {' '}
                                  {value?.travelerOffer?.payment_status !=
                                  t('pending')
                                    ? t('paid')
                                    : t('unpaid')}
                                </span>
                              </span>
                            </td>
                            <td>{t(value?.travelerOffer?.status)}</td>
                            <td>
                              <a
                                href="#"
                                onClick={() => {
                                  handleRateDelivery(
                                    value?.travelerOffer,
                                    'travelerOffer',
                                  )
                                }}
                                className="table_detail_a"
                              >
                                {t('Add Rating')}
                              </a>
                            </td>
                          </tr>
                        )
                      }
                    },
                  )
                ) : (
                  <tr>
                    <td colSpan={10}>
                      <h3 style={{ color: 'gray', fontSize: '1.2rem' }}>
                        {t('There is no Completed Offer')}
                      </h3>
                    </td>
                  </tr>
                )}
              </tbody>
            </table> */}
          </Tab>
        </Tabs>
      </LoadingScreen>
    </>
  )
}

export default MySenderOffers
