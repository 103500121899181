import React from 'react'
import '../App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
//Components
import ProtectedRoute from './protectedRoute'
import RegisterPage from '../View/Authen/RegisterPage'
import LoginPage from '../View/Authen/LoginPage'
import LoggedInUser from '../View/Frontend/LoggedInUser'
import LandingPage from '../View/Frontend/LandingPage'
import MainProfile from '../View/Frontend/MainProfile'
import FprgotPage from '../View/Authen/FprgotPage'
import AcountVerify from '../View/Authen/AcountVerify'
import CreateSenderOfferForm from '../View/MultiStepTwo/CreateSenderOfferForm'
import CreateTravellerOfferForm from '../View/MultiStepThree/CreateTravellerOfferForm'
import Explore from '../View/Frontend/Explore'
import About from '../View/Component/About'
import Term from '../View/Component/Term'
import Fq from '../View/Component/Fq'
import StripePaymentSuccess from '../View/Component/StripePaymentSuccess'
import StripePaymentCancel from '../View/Component/StripePaymentCancel'
import Chat from '../View/Component/Chat'
import SenderOffers from '../View/Frontend/SenderOffers'
import TravelerOffers from '../View/Frontend/TravelerOffers'
import VerificationProtectedRoute from './VerificationProtectedRoute'
import Blogs from '../View/Frontend/Blogs'
import BlogDetails from '../View/Frontend/BlogDetails'

function AppRoutes() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* <Route exact path="/" element={<PublicRoutes />}> */}

          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/forgot" element={<FprgotPage />} />
          <Route path="/acount_verify" element={<AcountVerify />} />
          <Route path="/about" element={<About />} />
          <Route path="/payment-success" element={<StripePaymentSuccess />} />
          <Route path="/payment-cancel" element={<StripePaymentCancel />} />
          <Route path="/term" element={<Term />} />
          <Route path="/fq" element={<Fq />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogDetails" element={<BlogDetails />} />

          {/* </Route> */}
          <Route path="/explore" element={<Explore />} />
          <Route path="/senderOffers" element={<SenderOffers />} />
          <Route path="/travellerOffers" element={<TravelerOffers />} />
          <Route exact path="/" element={<ProtectedRoute />}>
            <Route path="/logged_in" element={<LoggedInUser />} />
            <Route path="/main_profile" element={<MainProfile />} />
            <Route exact path="/" element={<VerificationProtectedRoute />}>
              <Route path="/create_sender_offer" element={<CreateSenderOfferForm />} />
              <Route
                path="/create_traveller_offer"
                element={<CreateTravellerOfferForm />}
              />
            </Route>
            {/* <Route path="/explore" element={<Explore />} />
            <Route path="/senderOffers" element={<SenderOffers />} />
            <Route path="/travellerOffers" element={<TravelerOffers />} /> */}
            <Route path="/chat" element={<Chat />} />
          </Route>
        </Routes>
      </div>
    </Router>
  )
}

export default AppRoutes
