import { LOGIN_DATA } from "../types";

const initialState = {
  LoginData: {},
};

const LogInReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_DATA: {
      return {
        ...state,
        LoginData: action.payload,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default LogInReducer;
