import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import enLocales from "./en";
import rsLocales from "./rs";

// ----------------------------------------------------------------------

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: enLocales },
      rs: { translations: rsLocales },
    },
    lng: localStorage.getItem("i18nextLng") || "en",

    ns: ["translations"],
    defaultNS: "translations",
    loadPath: "/locales/{{lng}}/{{ns}}.json?v=" + new Date().getTime(), // add timestamp to loadPath
    fallbackLng: "en",
    debug: true,

    interpolation: {
      escapeValue: false,
    },
  });

// i18n
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     resources: {
//       en: { translation: enLocales },
//       fr: { translation: rsLocales },
//     },
//     fallbackLng: "en",
//     interpolation: { escapeValue: false },
//   });

export default i18n;
