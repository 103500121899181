import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import auth from "./auth";
import settings from "./settings";

import UserReducer from "./UserReducer";
import TravellerOfferReducer from "./TravellerOfferReducer";
import CountriesReducer from "../reducers/CountriesReducer";
import CitiesReducer from "./CitiesReducer";
import PaymentMethodReducer from "../reducers/PaymentMethodReducer";
import RatingReducer from "../reducers/RatingReducer";
import AdminReducer from "../reducers/AdminReducer";

import LogInReducer from "./LogInReducer";

import DeleteUserAccountReducer from "./DeleteUserAccountReducer";
const rootReducer = persistReducer(
  {
    key: "root", // key is required
    storage, // storage is now required
    whitelist: ["auth"],
  },

  combineReducers({
    auth,
    settings,
    DeleteUserAccountReducer,
    UserReducer,
    LogInReducer,
    TravellerOfferReducer,
    CountriesReducer,
    CitiesReducer,
    PaymentMethodReducer,
    RatingReducer,
    AdminReducer,
  })
);

export default rootReducer;
