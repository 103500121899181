import React from "react";
import LoggedinNavbar from "../Component/LoggedinNavbar";
import { Container } from "reactstrap";
import BlogDetailsBanner from "../Component/BlogDetailsBanner";
import { useLocation } from "react-router-dom";

export default function BlogDetails() {
  const location = useLocation();
  const { blogDetail } = location.state;

  return (
    <>
      <LoggedinNavbar />
      <Container className=" mt-3 mb-5 d-flex justify-content-center">
        <BlogDetailsBanner blogImages={blogDetail?.blogImages} />
      </Container>
      <Container className="mt-2 mb-5">
        <div className="d-flex">
          {blogDetail?.blogTags.map((value) => {
            return (
              <span className="back-orange text-white py-2 px-3 me-4 rounded">
                {" "}
                {value?.tag}{" "}
              </span>
            );
          })}
        </div>
      </Container>
      <Container>
        <div
          className="blogContent"
          dangerouslySetInnerHTML={{
            __html: blogDetail?.long_description,
          }}
        ></div>
      </Container>
      <Container className="mb-5">
        <h3>Description</h3>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officiis
          deleniti totam impedit vero voluptatem cupiditate culpa minus, veniam
          inventore placeat laboriosam necessitatibus voluptatum tempora quasi.
          Porro voluptatibus eius autem non odio necessitatibus voluptates
          dolorum perferendis laudantium, est, reprehenderit in recusandae qui?
          Laudantium illo asperiores consequuntur, deserunt exercitationem eos
          tempora illum eligendi? Cum cupiditate atque quibusdam, totam laborum
          ad fugit quae, natus praesentium vero voluptates hic quos. Nobis quae
          magni incidunt unde dolore ipsam eligendi ea soluta quaerat ducimus,
          sunt laborum reiciendis ut consequatur esse dicta laboriosam ipsa
          tenetur voluptatem placeat ullam. Ab quidem error quos id rem totam
          mollitia voluptatem?
        </p>
      </Container>
    </>
  );
}
