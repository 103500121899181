import { GET_ALL_COUNTRIES_PAYMENT_METHOD, GET_SINGLE_COUNTRY_PAYMENT_METHOD } from "../types";

const initialState = {
  allCountriesPaymentMethods: [],
  singleCountryPaymentMethod: [],
};

const PaymentMethodReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_COUNTRIES_PAYMENT_METHOD: {
      return {
        ...state,
        allCountriesPaymentMethods: action.payload,
      };
    }
    case GET_SINGLE_COUNTRY_PAYMENT_METHOD: {
      return {
        ...state,
        singleCountryPaymentMethod: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default PaymentMethodReducer;
