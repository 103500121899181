import React, { Component } from "react";
import { useRef } from "react";
import { useState } from "react";
import "../Assets/Css/Dropzone.css";
import gray_camera from "../Assets/Images/gray_camera.png";
// import Dropzone from 'react-dropzone';

function Dropzone({ props, handleFiles }) {
  const [hightlight, setHighLight] = useState(false);
  const [preview, setPreview] = useState(null);
  const [file, setFile] = useState();
  const fileInputRef = useRef();

  function openFileDialog() {
    fileInputRef.current.click();
  }

  function onFilesAdded(evt) {
    console.log("event occurr");
    setFile(evt.target.files);
    const files = evt.target.files;

    console.log(files + "===========image");
    const array = fileListToArray(files);
    handleFiles(files[0]);
    const currentFile = files[0];
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        setPreview(reader.result);
      },
      false
    );
    reader.readAsDataURL(currentFile);
    // console.log(JSON.stringify(files[0])+"=====image")
  }

  function onDragOver(evt) {
    evt.preventDefault();
    setHighLight(true);
  }

  function onDragLeave() {
    setHighLight(false);
  }

  function onDrop(event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    const array = fileListToArray(files);

    setHighLight(false);
  }

  function fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  console.log(file && file[0], "filessssss");

  return (
    <div
      className={`Dropzone ${hightlight ? "Highlight" : ""}`}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onClick={openFileDialog}
      style={{ cursor: props?.disabled ? "default" : "pointer" }}
    >
      <input
        ref={fileInputRef}
        className="FileInput"
        type="file"
        multiple
        required
        onChange={onFilesAdded}
        // accept="image/*"
      />
      {preview !== null ? (
        <>
          {file[0]?.name.split(".")[1] === "pdf" ? (
            <p>pdf uploaded</p>
          ) : (
            <img alt="upload" className="IconAfterUploadImg" src={preview} />
          )}
        </>
      ) : (
        <>
          <span className="fontSize14-600 dimgray-color pt-4">
            Drag and Drop Files here.
          </span>
          <img alt="upload" className="Icon" src={gray_camera} />
        </>
      )}
    </div>
  );
}

export default Dropzone;
