import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, FormGroup, Row } from "react-bootstrap";
import { getSenderOfferDetails } from "../../redux/actions/TravellerOfferAction";
import "../Assets/Css/Sidebar.css";
import { BsSearch } from "react-icons/bs";
import Swal from "sweetalert2";
import button_loader from "../Assets/Loader/button_loader.gif";

import {
  Button,
  Modal,
  ModalBody,
  Form,
  Input,
  InputGroup,
  Label,
} from "reactstrap";

import {
  createSenderCounterOffer,
  acceptUserSenderOffer,
  getAllSenderOffers,
} from "../../redux/actions/TravellerOfferAction";
import {  useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoggedinNavbar from "../Component/LoggedinNavbar";
export default function SenderOffers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(getAllSenderOffers());
  }, []);
  const { senderOffersList } = useSelector(
    (state) => state.TravellerOfferReducer
  );

  const { t } = useTranslation();

  const { offerDetails } = useSelector((state) => state.TravellerOfferReducer);

  const { userDetails } = useSelector((state) => state.UserReducer);
  const [counterOfferAmount, setCounterOfferAmount] = useState("");
  const [counterOfferDescription, setCounterOfferDescription] = useState("");
  const [counterOfferModal, setCounterOfferModal] = useState(false);
  const [offerId, setOfferId] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);

  const [acceptSenderOffer, setAcceptSenderOffer] = useState(false);
  const [senderOfferId, setSenderOfferId] = useState("");
  const [modal, setModal] = useState(false);
  const [phoneVerify, setPhoneVerify] = useState(null);
  const [emailVerify, setEmailVerify] = useState(null);
 
  const filterData = location?.state?.filterData;
  useEffect(() => {
    console.log(JSON.stringify(userDetails) + "==================");
    setPhoneVerify(userDetails?.user?.is_phone_verified);
    setEmailVerify(userDetails?.user?.is_email_verified);
  }, [userDetails]);
  const [filterDataConst, setFilterData] = useState(filterData);
  useEffect(() => {
    if (senderOffersList?.length > 0) {
      setFilterData(senderOffersList);
      console.log(
        JSON.stringify(senderOffersList[0]?.offer_time) +
          "======= sender offers list"
      );
    }
  }, senderOffersList);

  const toggle = () => setModal(!modal);
  const handleBack = () => {
    navigate("/explore");
  };

  const toggleCounterOffer = () => {
    setCounterOfferModal(!counterOfferModal);
  };
  const handleCounterOfferAmount = (event) => {
    let value = event.target.value;

    if (/^[0-9]\d*$/.test(value)) {
      setCounterOfferAmount(event.target.value);
    } else {
      setCounterOfferAmount("");
    }
  };
  const handleCounterOfferDescription = (event) => {
    setCounterOfferDescription(event.target.value);
  };

  const toggleAcceptSenderOffer = (id) => {
    setSenderOfferId(id);
    setAcceptSenderOffer(!acceptSenderOffer);
  };
  const handleResponse = (data) => {
    if (data.status) {
      // setVerificationModal(false)
    } else {
      //   setLoading(false);
    }
  };
  const handleSubmitSenderCounterOffer = (e) => {
    setButtonLoader(true);
    e.preventDefault();
    let data = {
      offerId: offerId,
      description: counterOfferDescription,
      offeredAmount: counterOfferAmount,
    };
    dispatch(createSenderCounterOffer(data, handleResponseCounterOffer));
  };
  const handleResponseCounterOffer = (data) => {
    setButtonLoader(false);
    if (data.status) {
      Swal.fire({
        title: t("Success"),
        type: "success",
        text: t("Your offer is made successfully"),
      });
      setTimeout(() => {
        setCounterOfferModal(!counterOfferModal);
      }, 3000);
      // setVerificationModal(false)
    } else {
      Swal.fire({
        title: t("Failed"),
        type: "danger",
        text: t("Oops! Something went wrong."),
      });
    }
  };

  const handleSenderOfferDetails = (id,isLogin) => {
    if(isLogin){
      navigate('/login')
    }

      if (phoneVerify === 0) {
      Swal.fire({
        title: t("Whoops!"),
        type: "danger",
        text: t("You need to get verified before you can create the sender and traveler offer. Don’t sweat it - just finish your verification [address, email, WhatsApp number] and you’re ready to go"),
        footer:
          '<a href="/main_profile?key=second">'+ t('Link to Verification Page')+'</a>',
      });
    } else if (emailVerify === 0) {
      Swal.fire({
        title: t("Whoops!"),
        type: "danger",
        text: t("You need to get verified before you can create the sender and traveler offer. Don’t sweat it - just finish your verification [address, email, WhatsApp number] and you’re ready to go"),
        footer:
          '<a href="/main_profile?key=second">'+ t('Link to Verification Page')+'</a>',
      });
    } else if (
      userDetails?.user?.address == null ||
      userDetails?.user?.address == ""
    ) {
      Swal.fire({
        title: t("Whoops!"),
        type: "danger",
        text: t("You need to get verified before you can create the sender and traveler offer. Don’t sweat it - just finish your verification [address, email, WhatsApp number] and you’re ready to go"),
        footer:
          '<a href="/main_profile?key=second">'+ t('Link to Verification Page')+'</a>',
      });
    } else {
      setOfferId(id);
      console.log(id + "======= offer id");
      let data = {
        senderOfferId: id,
      };
      dispatch(getSenderOfferDetails(data, handleResponse));
      setModal(!modal);
    }
    
  };
  const [destinationCountry, setDestination] = useState("all");
  const handleSelectDestinationCountry = (event) => {
    setDestination(event.target.value);
  };
  const [sourceCountry, setSource] = useState("all");
  const handleSelectSourceCountry = (event) => {
    setSource(event.target.value);
  };
  const [date, setDate] = useState("all");
  const handleSelectDate = (event) => {
    setDate(event.target.value);
  };
  const [type, setType] = useState("all");
  const handleSelectType = (event) => {
    setType(event.target.value);
  };

  const [showField, setShowField] = useState(false);
  const [showFlexibleDays, setShowFlexibleDays] = useState(false);
  const handleDateType = (event) => {
    if (event.target.value === "Date") {
      setShowFlexibleDays(false);
      setShowField(true);
    } else if (event.target.value === "DaysFlexible") {
      setShowField(false);
      setShowFlexibleDays(true);
    } else {
      setShowField(false);
      setShowFlexibleDays(false);
      setDate(event.target.value);
    }
  };
  var countries = senderOffersList?.map((value) => {
    if (value?.is_accepted != 1) {
      if (value?.user_id != userDetails?.user?.id) {
        return value.s_country;
      }
    }
  });
  var destinCountry = senderOffersList?.map((value) => {
    if (value?.is_accepted != 1) {
      if (value?.user_id != userDetails?.user?.id) {
        return value?.d_country;
      }
    }
  });

  // var offer_time = senderOffersList?.map((value) => {
  //   if (value?.is_accepted != 1) {
  //     if (value?.user_id != userDetails?.user?.id) {
  //       if (value?.offer_time.includes("ys")) {
  //         return value.offer_time;
  //       }
  //     }
  //   }
  // });
  const [filterError, setFilterError] = useState(false);
  const handleClearFilterData = () => {
    if (senderOffersList?.length > 0) setFilterData(senderOffersList);
  };
  const handleFilterData = () => {
    let tempData = [];
    // console.log(sourceCountry, destinationCountry, date, type);
    if (
      sourceCountry == "all" &&
      destinationCountry == "all" &&
      date == "all" &&
      type == "all"
    ) {
      setFilterData(senderOffersList);
    } else if (
      sourceCountry == null &&
      destinationCountry == null &&
      date == null &&
      type == null
    ) {
      setFilterError(true);
    } else {
      senderOffersList.map((value) => {
        setFilterError(false);
        if (
          (value?.s_country == sourceCountry || sourceCountry == "all") &&
          (value?.d_country == destinationCountry ||
            destinationCountry == "all") &&
          (value?.offer_time == date || date == "all") &&
          (value?.package_type == type || type == "all")
        ) {
          if (tempData?.find((i) => i.id === value?.id)) return;
          console.log(tempData, "zxcvbzxvzv");
          console.log(
            tempData?.find((i) => i.id === value?.id),
            "zxcvbzxvzv1"
          );

          tempData.push(value);
        }
      });
      setFilterData(tempData);
    }
  };

  const handleSubmitAcceptSenderOffer = () => {
    setButtonLoader(true);
    let data = {
      senderOfferId: senderOfferId,
    };
    dispatch(acceptUserSenderOffer(data, handleResponseAcceptSenderOffer));
    setTimeout(() => {
      setAcceptSenderOffer(false);
    }, 3000);
  };
  if (countries?.length > 0) {
    var filterdCountries = [...new Set(countries)];
  }
  if (destinCountry?.length > 0) {
    var filterdDestinCountries = [...new Set(destinCountry)];
  }
  const handleResponseAcceptSenderOffer = (data) => {
    setButtonLoader(false);
    if (data.status) {
      Swal.fire({
        title: t("Success"),
        type: "success",
        text: t("Offer accepted"),
      });
    } else {
      Swal.fire({
        title: t("Success"),
        type: "success",
        text: t("Oops! Something went wrong"),
      });
    }
  };

  return (
    <div>
    <LoggedinNavbar />
      <Container className="mt-5 mb-5">
        <Form>
          <Row className="mt-2">
            <Col lg={12} md={12} sm={12}>
              <InputGroup>
                <button
                  className="mb-4"
                  onClick={handleBack}
                  style={{ fontSize: "large" }}
                >
                  <i
                    className="fa-solid fa-arrow-left"
                    style={{ cursor: "pointer" }}
                  ></i>
                  {"  "} <b> {t("Go Back")} </b>
                </button>
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col lg={3} md={3} sm={6} className="mt-2">
              <FormGroup>
                <Label className="ms-1" htmlFor="to">
                  {t("From")}
                </Label>
                <br />
                <div className="input-group">
                  <select
                    className="form-control"
                    onChange={handleSelectSourceCountry}
                  >
                    <option selected value={"all"}>
                      {t("Select All")}
                    </option>
                    {filterdCountries?.length > 0 &&
                      filterdCountries.map((value, index) => {
                        if (value != undefined) {
                          return (
                            <option className="optionCountry" value={value}>
                              {value + " "}
                            </option>
                          );
                        }
                      })}
                    {/* <option value={"all"}>Select All</option>; */}
                  </select>
                </div>
              </FormGroup>
            </Col>
            <Col lg={3} md={3} sm={6} className="mt-2">
              <FormGroup>
                <Label className="ms-1" htmlFor="to">
                  {t("To")}
                </Label>
                <br />
                <div className="input-group">
                  <select
                    className="form-control"
                    onChange={handleSelectDestinationCountry}
                  >
                    <option selected value={"all"}>
                      {t("Select All")}
                    </option>
                    {filterdDestinCountries?.length > 0 &&
                      filterdDestinCountries.map((value) => {
                        if (value != undefined) {
                          return <option value={value}>{value + " "}</option>;
                        }
                      })}
                    {/* <option value={"all"}>Select All</option>; */}
                  </select>
                </div>
              </FormGroup>
            </Col>
            <Col lg={3} md={3} sm={6} className="mt-2">
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <FormGroup>
                    <Label className="ms-1" htmlFor="to">
                      {t("Select Date Type")}
                    </Label>
                    <br />
                    <div className="input-group">
                      <select
                        className="form-control"
                        onChange={handleDateType}
                      >
                        <option selected value={"all"}>
                          {t("Select All")}
                        </option>
                        ;<option value="Date">{t("Date")}</option>
                        <option value="ASAP">{t("ASAP")}</option>
                        <option value="DaysFlexible">
                          {t("Day's Flexible")}
                        </option>
                      </select>
                    </div>
                  </FormGroup>
                </Col>
                <Col lg={12} md={12} sm={12}>
                  {showField && (
                    <FormGroup>
                      <Label htmlFor="date">{t("Date")}</Label>
                      <br />
                      <Input
                        onChange={handleSelectDate}
                        type="date"
                        name="date"
                        min={new Date().toISOString().split("T")[0]}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </FormGroup>
                  )}
                </Col>
                <Col lg={12} md={12} sm={12}>
                  {showFlexibleDays && (
                    <FormGroup>
                      <Label htmlFor="FlexibleDays">{t("Flexible Days")}</Label>
                      <br />

                      <Input
                        onChange={handleSelectDate}
                        type="text"
                        name="text"
                        placeholder={t("Write Flexible Days")}
                      />
                    </FormGroup>
                  )}
                </Col>
              </Row>
            </Col>
            <Col lg={3} md={3} sm={6} className="mt-2">
              <FormGroup>
                <Label className="ms-1" htmlFor="type">
                  {t("Shipment Type")}
                </Label>
                <br />
                <div className="input-group">
                  <select className="form-control" onChange={handleSelectType}>
                    <option selected value={"all"}>
                      {t("Select All")}
                    </option>
                    ;
                    <option value="document envelope">
                      {t("document envelope")}
                    </option>
                    <option value="small box">{t("small box")}</option>
                    <option value="medium box">{t("medium box")}</option>
                    <option value="large box">{t("large box")}</option>
                    <option value="other packages">
                      {t("other packages")}
                    </option>
                    {/* <option value={"all"}>Select All</option> */}
                  </select>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} className="d-flex">
              <div className="justify-content-start">
                {filterError && (
                  <span style={{ color: "red" }}>
                    {t("Fill all the fields to see filtered results")}.
                  </span>
                )}
              </div>
              <InputGroup className="justify-content-end">
                <Button
                  className="px-3 back-orange bordt-none"
                  onClick={() => handleClearFilterData()}
                >
                  {t("Select All")}
                </Button>
                <Button
                  className="px-3 ms-1 back-orange bordt-none"
                  onClick={() => handleFilterData()}
                >
                  {t("Search")} <BsSearch />
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Form>
        <Row className="mt-5">
          <h1>{t("Sender Offers")}</h1>
          <Col lg={12}>
            <Row className="senderOfferListTable">
              <Col md={12} className="mt-2">
                <table className="table custom_tabel_border rounded-2">
                  <thead>
                    <tr style={{ backgroundColor: "#F69110", color: "white" }}>
                      <th scope="col">{t("Order ID")}</th>
                      <th scope="col">{t("Order Created Date")}</th>
                      <th scope="col">{t("From")}</th>
                      <th scope="col">{t("To")}</th>
                      <th scope="col">{t("Package")}</th>
                      <th scope="col">{t("When")}</th>
                      <th scope="col">{t("Offer Price")}</th>
                      <th scope="col">{t("Status")}</th>
                      <th scope="col">{t("Click for details")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {filterDataConst?.length > 0 ? (
                      filterDataConst.map((value, index) => {
                        if (value?.is_accepted != 1) {
                          if (value?.user_id != userDetails?.user?.id) {
                            if (value?.user?.is_email_verified == 1) {
                              if (value?.user?.is_phone_verified == 1) {
                                if (value?.user?.is_id_verified == 1) {
                                  return (
                                    <tr>
                                      <td>SO-{value.id} </td>
                                      <td>{value?.createdAt?.split("T")[0]}</td>
                                      <td>{value?.s_country + " "}</td>
                                      <td>{" " + value?.d_country}</td>
                                      <td> {t(value?.package_type)}</td>{" "}
                                      <td>{value?.offer_time}</td>
                                      <td>{value?.service_cost}$</td>
                                      <td>{t(value?.status)}</td>
                                      <td>
                                      {userDetails?.user?.id > 0 ? 
                                        <a
                                          href="#"
                                          onClick={() =>
                                            handleSenderOfferDetails(value?.id,false)
                                          }
                                          className="table_detail_a"
                                        >
                                          {t("See Details")}
                                        </a>:
                                        <a
                                          href="#"
                                          onClick={() =>
                                            handleSenderOfferDetails(
                                              value?.id,true
                                            )
                                          }
                                          className="table_detail_a"
                                        >
                                          {t("Login to View")}
                                        </a>
                                      }
                                        
                                      </td>
                                    </tr>
                                  );
                                }
                              }
                            }
                          }
                        }
                      })
                    ) : (
                      <tr>
                        <td colspan="9">
                          <h3
                            className="text-center"
                            style={{ color: "gray", fontSize: "1.2rem" }}
                          >
                            {t("No Offer Found")}
                          </h3>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* Sender Offer Details */}
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="how-work-modal"
        backdrop="static"
      >
        <ModalBody>
          <Row>
            <Col lg={12} md={12} sm={12} className="py-4">
              <div>
                <Row>
                  <div className="text-center">
                    <h1 style={{ fontWeight: "700", color: "#F69110" }}>
                      {offerDetails?.s_country + " "} to{" "}
                      {" " + offerDetails?.d_country}
                    </h1>
                    <p>{t("Sender Offer")}</p>
                  </div>
                  <Col lg={12} className="mt-4">
                    <div
                      style={{
                        border: "1px solid black",
                        borderRadius: "8px",
                        padding: "16px",
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t("Order ID")}:</p>
                        <p className="mb-0">{offerDetails?.id}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">
                          {t("Order Created Date")}:
                        </p>
                        <p className="mb-0">
                          {offerDetails?.createdAt?.split("T")[0]}
                        </p>
                      </div>
                      {/* <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t("Created By")}:</p>
                        <p className="mb-0">
                          {offerDetails?.user?.firstName +
                            " " +
                            offerDetails?.user?.lastName}
                        </p>
                      </div> */}
                    </div>
                  </Col>
                  {/* <Col lg={6} className="mt-4"></Col> */}
                  <Col lg={12} className="mt-3">
                    <div
                      className=""
                      style={{
                        border: "1px solid black",
                        borderRadius: "8px",
                        padding: "16px",
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t("From Country")}:</p>
                        <p className="mb-0">{offerDetails?.s_country + " "}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t("From City")}:</p>
                        <p className="mb-0">{offerDetails?.s_city + " "}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t("From Address")}:</p>
                        <p className="mb-0">{offerDetails?.s_address + " "}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">
                          {t("First Mile Delivery")}:
                        </p>
                        <p className="mb-0">
                          {offerDetails?.is_first_mile_pickup == 1
                            ? t("Yes")
                            : t("NO")}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12} className="mt-3 align-self-center">
                    <div
                      style={{
                        border: "1px solid black",
                        borderRadius: "8px",
                        padding: "16px",
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t("To Country")}:</p>
                        <p className="mb-0">{offerDetails?.d_country + " "}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t("To City")}:</p>
                        <p className="mb-0">{offerDetails?.d_city + " "}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t("To Address")}:</p>
                        <p className="mb-0">{offerDetails?.d_address + " "}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">
                          {t("Last mile delivery")}:
                        </p>
                        <p className="mb-0">
                          {offerDetails?.is_last_mile_delivery == 1
                            ? t("Yes")
                            : t("NO")}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12} className="mt-4">
                    <div
                      // className="mt-4"
                      style={{
                        border: "1px solid black",
                        borderRadius: "8px",
                        padding: "16px",
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t("When")}:</p>
                        <p className="mb-0">
                        {offerDetails?.offer_time?.split("D")?.[1] == "ays"
                          ? offerDetails?.offer_time?.split("D")?.[0] +
                            t("Days")
                          : offerDetails?.offer_time}
                        </p>
                      </div>
                    </div>
                    <div
                      className="mt-4"
                      style={{
                        border: "1px solid black",
                        borderRadius: "8px",
                        padding: "16px",
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t("Package")}:</p>
                        <p className="mb-0">{t(offerDetails?.package_type)}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t("Dimensions")} :</p>
                        <p className="mb-0">
                          {offerDetails?.package_height +
                            "x" +
                            offerDetails?.package_width +
                            "x" +
                            offerDetails?.package_length}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t("Weight")} :</p>
                        <p className="mb-0">{offerDetails?.package_weight}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t("Description")}:</p>
                        <p className="mb-0">{offerDetails?.additional_note}</p>
                      </div>
                    </div>
                    <div
                      className="mt-4"
                      style={{
                        border: "1px solid black",
                        borderRadius: "8px",
                        padding: "16px",
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t("Offer Price")}:</p>
                        <p className="mb-0">{offerDetails?.service_cost} $</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="fw-bold mb-0">{t("Status")}:</p>
                        <p className="mb-0">{t(offerDetails?.status)}</p>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={4}>
                    <div className="text-center pt-5">
                      <Button
                        onClick={() =>
                          toggleAcceptSenderOffer(offerDetails?.id)
                        }
                        type="button"
                        className="btnlanding-hov ms-4 profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                      >
                        {t("Accept Offer")}
                      </Button>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="text-center pt-5">
                      <Button
                        onClick={toggleCounterOffer}
                        type="button"
                        className="btnlanding-hov ms-4 profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                      >
                        {t("Make Counter Offers")}
                      </Button>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="text-center pt-5">
                      <Button
                        onClick={toggle}
                        type="button"
                        className="btnlanding-hov ms-4 profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                      >
                        {t("Close")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      {/* Sender Counter Offer */}
      <Modal
        isOpen={counterOfferModal}
        toggle={toggleCounterOffer}
        className="how-work-modal"
        backdrop="static"
      >
        <ModalBody>
          <Row>
            <Col lg={12} md={12} sm={12} className="py-4">
              <div>
                <Form>
                  <Row>
                    <div className="lightorage-color text-center">
                      <h1 className=" py-3 fontSize24-600 orange-color">
                        {t("Create Sender Counter Offer")}
                      </h1>
                    </div>
                    <Col lg={12} md={12} sm={12}>
                      <FormGroup className="pt-3">
                        <Label
                          for="counterOfferAmount"
                          className="fontSize14-600 dimgray-color"
                        >
                          {" "}
                          {t("Offer Amount")}{" "}
                        </Label>
                        <Input
                          onChange={handleCounterOfferAmount}
                          id="counterOfferAmount"
                          name="counterOfferAmount"
                          type="text"
                          value={counterOfferAmount}
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={12} md={12} sm={12}>
                      <FormGroup className="pt-3">
                        <Label
                          for="number"
                          className="fontSize14-600 dimgray-color"
                        >
                          {" "}
                          {t("Offer Description")}{" "}
                        </Label>

                        <Input
                          type="textarea"
                          onChange={handleCounterOfferDescription}
                          id="number"
                          name="number"
                          placeholder={t("Enter Offer Descriptions")}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="textright-iconf py-5">
                    <Button
                      type="button"
                      onClick={toggleCounterOffer}
                      className="btnlanding-hov profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                    >
                      {t("Cancel")}
                    </Button>
                    {/* <Link to="/login"> */}
                    <Button
                      type="button"
                      onClick={handleSubmitSenderCounterOffer}
                      className="btnlanding-hov ms-4 profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                      disabled={buttonLoader}
                    >
                      {buttonLoader ? (
                        <img
                          className="img-fluid buttonLoaderImg"
                          src={button_loader}
                          alt="loading"
                        />
                      ) : (
                        t("Save")
                      )}
                    </Button>
                    {/* </Link> */}
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      {/* Accept Sender Offer */}
      <Modal
        isOpen={acceptSenderOffer}
        toggle={toggleAcceptSenderOffer}
        className="how-work-modal"
        backdrop="static"
      >
        <ModalBody>
          <Row>
            <Col lg={12} md={12} sm={12} className="py-4">
              <div>
                <Form>
                  <Row>
                    <div className="lightorage-color text-center">
                      <h1 className=" py-3 fontSize24-600 orange-color">
                        {t("Accept Sender Offer")}
                      </h1>
                    </div>

                    <Col lg={12} md={12} sm={12}>
                      <FormGroup className="pt-3">
                        <Label
                          for="number"
                          className="fontSize14-600 dimgray-color"
                        >
                          {" "}
                          {t("Add Personal Note")} {t("Optional")}
                        </Label>

                        <Input
                          type="textarea"
                          // onChange={handleCounterOfferDescription}
                          id="number"
                          name="number"
                          placeholder={t("Enter Offer Descriptions")}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="textright-iconf py-5">
                    <Button
                      type="button"
                      onClick={toggleAcceptSenderOffer}
                      className="btnlanding-hov profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                    >
                      {t("Cancel")}
                    </Button>
                    {/* <Link to="/login"> */}
                    <Button
                      type="button"
                      onClick={handleSubmitAcceptSenderOffer}
                      className="btnlanding-hov ms-4 profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                      disabled={buttonLoader}
                    >
                      {buttonLoader ? (
                        <img
                          className="img-fluid buttonLoaderImg"
                          src={button_loader}
                          alt="loading"
                        />
                      ) : (
                        t("Accept")
                      )}
                    </Button>
                    {/* </Link> */}
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
}
