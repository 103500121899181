import React, { useState } from "react";
import { Container, Form, Row } from "react-bootstrap";
import { Button, Col, FormGroup, Input, Label } from "reactstrap";
import logo from "../Assets/Images/logo.png";
import forgot from "../Assets/Images/forgot.png";
import { BsTelephone } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { getResetPasswordOtp } from "../../redux/actions/UserAction";
import { useDispatch, useSelector } from "react-redux";
import { PhoneInput } from "react-contact-number-input";
import logoAndName from "../Assets/Images/logoo1.png";
import button_loader from "../Assets/Loader/button_loader.gif";
import { useTranslation } from "react-i18next";

function FprgotPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [mobileNumber, setMobileNumber] = useState(null);
  const [required, setRequried] = useState(false);
  const [phoneError, setPhoneError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handlePhoneChange = (event) => {
    setMobileNumber(event.target.value);
  };
  const handleResponse = (data) => {
    setLoading(false);
    if (data.status) {
      // setVerificationModal(false)
      navigate("/acount_verify", { state: { mobileNumber } });
    } else {
      // setLoading(false);
    }
  };
  const handlePhoneCodeOnChange = (value) => {
    if (value.hasError) {
      setRequried(true);
      setPhoneError(t(value.message));
    } else {
      setRequried(true);
      setPhoneError(null);
    }
    console.log(
      JSON.stringify(value?.validData?.phoneNumber) +
        "========phone number with country code"
    );

    setMobileNumber(value?.validData?.phoneNumber);
  };
  const handleOtpSend = () => {
    console.log(mobileNumber + "=====mobile number");
    if (
      mobileNumber != "" &&
      mobileNumber != "undefined" &&
      mobileNumber != null
    ) {
      setLoading(true);
      console.log("true");
      console.log(mobileNumber + "phone number in if condition");
      let data = {
        phone_no: mobileNumber,
      };
      dispatch(getResetPasswordOtp(data, handleResponse));
    } else {
      console.log("required");
      setPhoneError(t("WhatsApp number is required"));
      setRequried(true);
    }
  };
  return (
    <div>
      <section>
        <Container fluid className="">
          <Row className="orange-background">
            <Col
              lg={12}
              md={12}
              sm={12}
              className=" bg-white pb-5"
              // style={{
              //   borderTopLeftRadius: "36px",
              //   borderBottomLeftRadius: "36px",
              // }}
            >
              <div className="pt-3">
                <p className="fontSize24-600 text-center">
                  {t("Forgot Your")}{" "}
                  <span className="orange-color"> {t("Password")}</span>
                </p>
              </div>
              <div className="text-center">
                <img
                  src={logoAndName}
                  alt="logo"
                  style={{ maxWidth: "200px", height: "auto" }}
                />
              </div>
              <div className="pt-4">
                <p className="mb-0 fontSize16-400 text-center">
                  {t("Enter your registered WhatsApp number to get a Reset")}{" "}
                </p>
                <p className="fontSize16-400 text-center">
                  {" "}
                  {t("link and create new password")}.
                </p>
              </div>

              <Form>
                <Row className="justify-content-center px-2">
                  <Col lg={4} md={6} sm={12}>
                    <FormGroup className="">
                      <Label
                        for="number"
                        className="fontSize14-600 dimgray-color"
                      >
                        {" "}
                      </Label>
                      {/* <Input id="number" name="number" type="text" placeholder=" Phone Number"  onChange={handlePhoneChange} value={mobileNumber}/>
                       */}
                      <PhoneInput
                        containerClass=""
                        countryCode="uae"
                        onChange={handlePhoneCodeOnChange}
                        placeholder={t("Phone Number")}
                      />
                      <BsTelephone className="mail-icon-login" />
                      {required && (
                        <span style={{ color: "red" }}>
                          <i>{phoneError}</i>
                        </span>
                      )}
                    </FormGroup>
                    <div>
                      <div className="text-decor">
                        <div className="d-grid gap-2 py-3">
                          <Button
                            onClick={handleOtpSend}
                            className="orange-background px-5 fontSize16-500 bordt-none"
                            // classes before: navdropdown-twohov profiletop-btnoutline
                            style={{ color: "white" }}
                            size="md"
                            block
                            disabled={loading}
                          >
                            {loading ? (
                              <>
                                {" "}
                                {t("Procesing")}{" "}
                                <img
                                  src={button_loader}
                                  alt="loading"
                                  className="buttonLoaderImg"
                                />
                              </>
                            ) : (
                              t("Send OTP")
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default FprgotPage;
