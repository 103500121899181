import axios from 'axios'
import { ApiUrl } from '../../ApiUrl'
import {
  GET_ALL_COUNTRIES_PAYMENT_METHOD,
  GET_SINGLE_COUNTRY_PAYMENT_METHOD,
} from '../types'

export const getAllCountriesPaymentMethod = () => (dispatch) => {
  const accessToken = localStorage.getItem('token')

  var config = {
    method: 'get',
    url: ApiUrl.server_url + 'auth/zone-payment-list',
    headers: {
      Authorization: 'Bearer ' + accessToken,
      'Content-Type': 'application/json',
    },
  }

  axios(config).then((res) => {
    // if (!res.data.status) {
    //   localStorage.removeItem("token");
    // }
    dispatch({
      type: GET_ALL_COUNTRIES_PAYMENT_METHOD,
      payload: res.data.data,
    })
  })
}

export const getSingleCountryPaymentMethod = (data, handleResponse) => (
  dispatch,
) => {
  const accessToken = localStorage.getItem('token')
  var config = {
    method: 'post',
    url: ApiUrl.server_url + 'auth/country-payment',
    headers: {
      Authorization: 'Bearer ' + accessToken,
      'Content-Type': 'application/json',
    },
    data: data,
  }

  axios(config)
    .then((res) => {
      handleResponse(res.data)
      dispatch({
        type: GET_SINGLE_COUNTRY_PAYMENT_METHOD,
        payload: res.data.data,
      })
    })
    .catch((error) => {
      // handleResponse(error?.response)
    })
}
