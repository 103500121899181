import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Container, FormGroup, Row } from 'react-bootstrap'
import { getTravellerOfferDetails } from '../../redux/actions/TravellerOfferAction'
import '../Assets/Css/Sidebar.css'
import { BsSearch } from 'react-icons/bs'

import {
  Button,
  Modal,
  ModalBody,
  Form,
  Input,
  InputGroup,
  Label,
} from 'reactstrap'
import {
  getAllTravellerOffers,
  getAllSenderOffers,
  createTravellerCounterOffer,
  acceptUserTravellerOffer,
  acceptUserSenderOffer,
} from '../../redux/actions/TravellerOfferAction'
import { useLocation, useNavigate } from 'react-router-dom'
// import { getUserData } from "../../redux/actions/UserAction";
// import { Navigate } from "react-router-dom";
import Swal from 'sweetalert2'
import button_loader from '../Assets/Loader/button_loader.gif'
import { useTranslation } from 'react-i18next'
import LoggedinNavbar from '../Component/LoggedinNavbar'

export default function TravelerOffers() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { travellerOffersList } = useSelector(
    (state) => state.TravellerOfferReducer,
  )
  const travelfilterData = location?.state?.travelfilterData
  // console.log(JSON.stringify(travelfilterData) + '=========== filter data in traveller offers page')
  useEffect(() => {
    if (travellerOffersList?.length > 0) {
      setFilterData(travellerOffersList)
    }
  }, [travellerOffersList])
  const { t } = useTranslation()

  const [filterDataConst, setFilterData] = useState(travelfilterData)
  const { userDetails } = useSelector((state) => state.UserReducer)

  const [buttonLoader, setButtonLoader] = useState(false)
  const [counterOfferAmount, setCounterOfferAmount] = useState('')
  const [counterOfferDescription, setCounterOfferDescription] = useState('')
  const [acceptSenderOffer, setAcceptSenderOffer] = useState(false)
  const [packageDetails, setPackageDetails] = useState('')
  const [offerId, setOfferId] = useState('')
  const [senderOfferId, setSenderOfferId] = useState('')
  const [travellerOfferId, setTravellerOfferId] = useState('')
  const [acceptTravellerOffer, setAcceptTravellerOffer] = useState(false)
  const [modal, setModal] = useState(false)
  const [travellerModal, setTravellerModal] = useState(false)
  const [counterOfferModal, setCounterOfferModal] = useState(false)
  const [counterTravellerOfferModal, setCounterTravellerOfferModal] = useState(
    false,
  )

  const toggle = () => setModal(!modal)
  const handleBack = () => {
    navigate('/explore')
  }
  const toggleTravellerOfferDetail = () => setTravellerModal(!travellerModal)
  const handleCounterOfferAmount = (event) => {
    const value = event.target.value
    if (/^[1-9]\d*$/.test(value)) {
      setCounterOfferAmount(value)
    } else {
      setCounterOfferAmount('')
    }
  }
  const toggleAcceptSenderOffer = (id) => {
    setSenderOfferId(id)
    setAcceptSenderOffer(!acceptSenderOffer)
  }
  const handleCounterOfferDescription = (event) => {
    setCounterOfferDescription(event.target.value)
  }
  const toggleAcceptTravellerOffer = (id) => {
    setTravellerOfferId(id)
    setAcceptTravellerOffer(!acceptTravellerOffer)
  }
  const toggleTravellerCounterOffer = () => {
    setCounterTravellerOfferModal(!counterTravellerOfferModal)
  }
  useEffect(() => {
    dispatch(getAllTravellerOffers())
  }, [])
  const { offerDetails } = useSelector((state) => state.TravellerOfferReducer)
  const { travellerOfferDetail } = useSelector(
    (state) => state.TravellerOfferReducer,
  )

  const handlePackageDetails = (event) => {
    setPackageDetails(event.target.value)
  }
  const handleSubmitTravellerCounterOffer = (e) => {
    setButtonLoader(true)
    e.preventDefault()
    let data = {
      offerId: offerId,
      description: counterOfferDescription,
      packageDetail: packageDetails,
      offeredAmount: counterOfferAmount,
    }
    dispatch(
      createTravellerCounterOffer(data, handleResponseTravellerCounterOffer),
    )
  }
  const handleResponse = (data) => {
    if (data.status) {
      
    } else {
      //   setLoading(false);
    }
  }
  const handleResponseTravellerCounterOffer = (data) => {
    setButtonLoader(false)
    if (data.status) {
      Swal.fire({
        title: t('Success'),
        type: 'success',
        text: t('Your offer is made successfully'),
      })
      setTimeout(() => {
        setCounterTravellerOfferModal(!counterTravellerOfferModal)
      }, 3000)
      // setVerificationModal(false)
    } else {
      Swal.fire({
        title: t('Denied'),
        type: 'danger',
        text: t('Oops! Something went wrong.'),
      })
      //   setLoading(false);
    }
  }

  const handleTravellerOfferDetails = (id, isLogin) => {
    if (isLogin) {
      navigate('/login')
    }
    setOfferId(id)
    console.log(id + '==========offer id')
    let data = {
      travelerOfferId: id,
    }
    dispatch(getTravellerOfferDetails(data, handleResponse))

    setTravellerModal(!travellerModal)
  }
  const [destinationCountry, setDestination] = useState('all')
  const handleSelectDestinationCountry = (event) => {
    setDestination(event.target.value)
  }
  const [sourceCountry, setSource] = useState('all')
  const handleSelectSourceCountry = (event) => {
    setSource(event.target.value)
  }
  const [date, setDate] = useState('all')
  const handleSelectDate = (event) => {
    setDate(event.target.value)
  }
  const [type, setType] = useState('all')
  const handleSelectType = (event) => {
    setType(event.target.value)
  }

  const [showField, setShowField] = useState(false)
  const [showFlexibleDays, setShowFlexibleDays] = useState(false)
  const handleDateType = (event) => {
    if (event.target.value === 'Date') {
      setShowFlexibleDays(false)
      setShowField(true)
      setDate(event.target.value)
    } else if (event.target.value === 'DaysFlexible') {
      setShowField(false)
      setShowFlexibleDays(true)
      handleFlexibleDays()
    } else {
      setShowFlexibleDays(false)
      setShowField(false)
      setDate(event.target.value)
    }
  }
  const handleFlexibleDays = (event) => {
    let days = event.target.value
    let set_value = days + 'Days'
    console.log(set_value + '======== seted days value')
    setDate(set_value)
  }
  var countries = travellerOffersList?.map((value) => {
    if (value?.is_accepted != 1) {
      if (value?.user_id != userDetails?.user?.id) {
        return value.s_country
      }
    }
  })
  var destinCountry = travellerOffersList?.map((value) => {
    if (value?.is_accepted != 1) {
      if (value?.user_id != userDetails?.user?.id) {
        return value?.d_country
      }
    }
  })

  // var offer_time = travellerOffersList?.map((value) => {
  //   if (value?.is_accepted != 1) {
  //     if (value?.user_id != userDetails?.user?.id) {
  //       if (value?.offer_time.includes("ys")) {
  //         return value.offer_time;
  //       }
  //     }
  //   }
  // });
  const [filterError, setFilterError] = useState(false)
  const handleClearFilterData = () => {
    if (travellerOffersList?.length > 0) {
      setFilterData(travellerOffersList)
    }
  }
  // const handleFilterData = () => {
  //   let tempData = [];
  //   console.log(sourceCountry, destinationCountry, date, type);
  //   if (
  //     sourceCountry == "all" &&
  //     destinationCountry == "all" &&
  //     date == "all" &&
  //     type == "all"
  //   ) {
  //     setFilterData(travellerOffersList);
  //   } else if (
  //     sourceCountry == null &&
  //     destinationCountry == null &&
  //     date == null &&
  //     type == null
  //   ) {
  //     setFilterError(true);
  //   } else {
  //     setFilterError(false);
  //     travellerOffersList.map((value) => {
  //       if (
  //         value?.s_country == sourceCountry &&
  //         value?.d_country == destinationCountry &&
  //         value?.offer_time == date &&
  //         value?.package_type == type
  //       ) {
  //         tempData.push(value);
  //       }
  //     });
  //     setFilterData(tempData);
  //   }
  //   // console.log(JSON.stringify(tempData) + "====filterd data");
  // };
  const handleFilterData = () => {
    let tempData = []
    // console.log(sourceCountry, destinationCountry, date, type);
    if (
      sourceCountry == 'all' &&
      destinationCountry == 'all' &&
      date == 'all' &&
      type == 'all'
    ) {
      setFilterData(travellerOffersList)
    } else if (
      sourceCountry == null &&
      destinationCountry == null &&
      date == null &&
      type == null
    ) {
      setFilterError(true)
    } else {
      travellerOffersList?.map((value) => {
        setFilterError(false)
        // if () {
        if (
          sourceCountry == 'all' &&
          value?.d_country == destinationCountry &&
          // value?.createdAt?.split("T")[0] == date &&
          value?.offer_time == date &&
          value?.package_type == type
        ) {
          tempData.push(value)
        }
        // }
        // if () {
        if (
          destinationCountry == 'all' &&
          value?.s_country == sourceCountry &&
          value?.offer_time == date &&
          value?.package_type == type
        ) {
          tempData.push(value)
        }
        // }
        // if () {
        if (
          type == 'all' &&
          value?.s_country == sourceCountry &&
          value?.d_country == destinationCountry &&
          // value?.createdAt?.split("T")[0] == date &&
          value?.offer_time == date
        ) {
          tempData.push(value)
          // }
        }
        if (
          date == 'all' &&
          value?.s_country == sourceCountry &&
          value?.d_country == destinationCountry &&
          // value?.createdAt?.split("T")[0] == date &&

          value?.package_type == type
        ) {
          tempData.push(value)
        }
        if (
          (value?.s_country == sourceCountry || sourceCountry == 'all') &&
          (value?.d_country == destinationCountry ||
            destinationCountry == 'all') &&
          (value?.offer_time == date || date == 'all') &&
          (value?.package_type == type || type == 'all')
        ) {
          tempData.push(value)
        }
      })
      setFilterData(tempData)
    }
    console.log('sourceCountry', sourceCountry)
    console.log('destinCountry', destinationCountry)
    console.log('type', type)
    console.log('date', date)
    console.log(JSON.stringify(tempData) + '====filterd data')
  }

  const handleSubmitTravellerSenderOffer = () => {
    setButtonLoader(true)
    let data = {
      travelerOfferId: travellerOfferId,
    }
    dispatch(acceptUserTravellerOffer(data, handleResponseAcceptTravellerOffer))
    setTimeout(() => {
      setAcceptTravellerOffer(false)
    }, 3000)
  }
  if (countries?.length > 0) {
    var filterdCountries = [...new Set(countries)]
  }
  if (destinCountry?.length > 0) {
    var filterdDestinCountries = [...new Set(destinCountry)]
  }
  const handleResponseAcceptTravellerOffer = (data) => {
    setButtonLoader(false)
    if (data.status) {
      Swal.fire({
        title: 'Success',
        type: 'success',
        text: t('Offer Accepted Successfully'),
      })
    } else {
      Swal.fire({
        title: 'Success',
        type: 'success',
        text: data.message,
      })
    }
  }

  return (
    <div>
      <LoggedinNavbar />
      <Container className="mt-5 mb-5">
        <Form>
          <Row className="mt-2">
            <Col lg={12} md={12} sm={12}>
              <InputGroup>
                <button
                  className="mb-4"
                  onClick={handleBack}
                  style={{ fontSize: 'large' }}
                >
                  <i
                    className="fa-solid fa-arrow-left"
                    style={{ cursor: 'pointer' }}
                  ></i>
                  {'  '} <b> {t('Go Back')} </b>
                </button>
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col lg={3} md={3} sm={12} className="mt-2">
              <FormGroup>
                <Label className="ms-1" htmlFor="to">
                  {t('From')}
                </Label>
                <br />
                <div className="input-group">
                  <select
                    className="form-control"
                    onChange={handleSelectSourceCountry}
                  >
                    <option selected value={'all'}>
                      {t('Select All')}
                    </option>
                    {filterdCountries?.length > 0 &&
                      filterdCountries.map((value, index) => {
                        if (value != undefined) {
                          return <option value={value}>{value + ' '}</option>
                        }
                      })}
                  </select>
                </div>
              </FormGroup>
            </Col>
            <Col lg={3} md={3} sm={12} className="mt-2">
              <FormGroup>
                <Label className="mx-1" htmlFor="to">
                  {t('To')}
                </Label>
                <br />
                <div className="input-group">
                  <select
                    className="form-control"
                    onChange={handleSelectDestinationCountry}
                  >
                    <option selected value={'all'}>
                      {t('Select All')}
                    </option>
                    {filterdDestinCountries?.length > 0 &&
                      filterdDestinCountries.map((value) => {
                        if (value != undefined) {
                          return <option value={value}>{' ' + value}</option>
                        }
                      })}
                  </select>
                </div>
              </FormGroup>
            </Col>
            <Col lg={3} md={3} sm={12} className="mt-2">
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <FormGroup>
                    <Label className="ms-1" htmlFor="to">
                      {t('Select Date Type')}
                    </Label>
                    <br />
                    <div className="input-group">
                      <select
                        className="form-control"
                        onChange={handleDateType}
                      >
                        <option selected value={'all'}>
                          {t('Select All')}
                        </option>
                        <option value="Date">{t('Date')}</option>
                        <option value="ASAP">{t('ASAP')}</option>
                        <option value="DaysFlexible">
                          {t("Day's Flexible")}
                        </option>
                      </select>
                    </div>
                  </FormGroup>
                </Col>
                <Col lg={12} md={12} sm={12}>
                  {showField && (
                    <FormGroup>
                      <Label className="mt-2" htmlFor="date">
                        {t('Date')}
                      </Label>
                      <br />
                      <Input
                        onChange={handleSelectDate}
                        type="date"
                        name="date"
                        min={new Date().toISOString().split('T')[0]}
                        onKeyDown={(e) => {
                          e.preventDefault()
                        }}
                      />
                    </FormGroup>
                  )}
                </Col>
                <Col lg={12} md={12} sm={12}>
                  {showFlexibleDays && (
                    <FormGroup>
                      <Label className="mt-2" htmlFor="FlexibleDays">
                        {t('Flexible Days')}
                      </Label>
                      <br />
                     
                      <Input
                        onChange={handleFlexibleDays}
                        type="number"
                        min={0}
                        name="FlexibleDays"
                        placeholder={t('Write Flexible Days')}
                      />
                    </FormGroup>
                  )}
                </Col>
              </Row>
            </Col>
            <Col lg={3} md={3} sm={12} className="mt-2">
              <FormGroup>
                <Label className="ms-1" htmlFor="type">
                  {t('Shipment Type')}
                </Label>
                <br />
                <div className="input-group">
                  <select className="form-control" onChange={handleSelectType}>
                    <option selected value={'all'}>
                      {t('Select All')}
                    </option>
                    <option value="document envelope">
                      {t('document envelope')}
                    </option>
                    <option value="small box">{t('small box')}</option>
                    <option value="medium box">{t('medium box')}</option>
                    <option value="large box">{t('large box')}</option>
                    <option value="other packages">
                      {t('other packages')}
                    </option>
                  </select>
                </div>
              </FormGroup>
            </Col>
          </Row>
        
          <Row>
            <Col lg={12} md={12} sm={12} className="d-flex">
              <div className="justify-content-start">
                {filterError && (
                  <span style={{ color: 'red' }}>
                    {t('Fill all the fields to see the filtered results')}
                  </span>
                )}
              </div>
              <InputGroup className="justify-content-end">
                <Button
                  type="button"
                  onClick={() => handleClearFilterData()}
                  className="px-3 back-orange bordt-none"
                >
                  {t('Select All')}
                </Button>
                <Button
                  type="button"
                  onClick={() => handleFilterData()}
                  className="px-3 ms-2 back-orange bordt-none"
                >
                  {t('Search')} <BsSearch />
                </Button>
              </InputGroup>
            </Col>
          </Row>{' '}
        </Form>
        <Row className="mt-5">
          <Col lg={12}>
            <h1>{t('Traveller Offers')}</h1>
            <Row className="travellOfferListTable">
              <Col lg={12} md={12} sm={12} style={{ marginTop: '10px' }}>
                <table className="table custom_tabel_border rounded-2">
                  <thead>
                    <tr style={{ backgroundColor: '#F69110', color: 'white' }}>
                      <th scope="col">{t('Order ID')}</th>
                      <th scope="col">{t('Order Created Date')}</th>
                      <th scope="col">{t('From')}</th>
                      <th scope="col">{t('To')}</th>
                      <th scope="col">{t('Package')}</th>
                      <th scope="col">{t('When')}</th>
                      {/* <th scope="col">{t("Offer Price")}</th> */}
                      <th scope="col">{t('Status')}</th>
                      <th scope="col">{t('Click for details')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterDataConst?.length > 0 ? (
                      filterDataConst.map((value, index) => {
                        if (value?.is_accepted != 1) {
                          if (value?.user_id != userDetails?.user?.id) {
                            if (value?.user?.is_email_verified == 1) {
                              if (value?.user?.is_phone_verified == 1) {
                                if (value?.user?.is_id_verified == 1) {
                                  return (
                                    <tr>
                                      <td>TO-{value.id} </td>
                                      <td>{value?.createdAt?.split('T')[0]}</td>
                                      <td> {value?.s_country + ' '}</td>
                                      <td>{' ' + value?.d_country}</td>
                                      <td>{t(value?.package_type)}</td>{' '}
                                      <td>{value?.offer_time}</td>
                                      {/* <td>{value?.offer_price}$</td> */}
                                      <td>{t(value?.status)}</td>
                                      <td>
                                        {userDetails?.user?.id > 0 ? (
                                          <a
                                            href="#"
                                            onClick={() =>
                                              handleTravellerOfferDetails(
                                                value?.id,
                                                false,
                                              )
                                            }
                                            className="table_detail_a"
                                          >
                                            {t('See Details')}
                                          </a>
                                        ) : (
                                          <a
                                            href="#"
                                            onClick={() =>
                                              handleTravellerOfferDetails(
                                                value?.id,
                                                true,
                                              )
                                            }
                                            className="table_detail_a"
                                          >
                                            {t('Login to View')}
                                          </a>
                                        )}
                                      </td>
                                    </tr>
                                  )
                                }
                              }
                            }
                          } else {
                            ;<tr>
                              <td colspan="9">
                                <h3
                                  className="text-center"
                                  style={{ color: 'gray', fontSize: '1.2rem' }}
                                >
                                  {t('No Offer Found')}
                                </h3>
                              </td>
                            </tr>
                          }
                        }
                      })
                    ) : (
                      <tr>
                        <td colspan="9">
                          <h3
                            className="text-center"
                            style={{ color: 'gray', fontSize: '1.2rem' }}
                          >
                            {t('No Offer Found')}
                          </h3>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* Traveller Offer Details */}
      <Modal
        isOpen={travellerModal}
        toggle={toggleTravellerOfferDetail}
        className="how-work-modal"
        backdrop="static"
      >
        <ModalBody>
          <Row>
            <Col lg={12} md={12} sm={12} className="py-4">
              <div>
                <Row>
                  <Col lg={12}>
                    <Row>
                      <div className="text-center">
                        <h1 style={{ fontWeight: '700', color: '#F69110' }}>
                          {travellerOfferDetail?.s_country + ' '} to{' '}
                          {' ' + travellerOfferDetail?.d_country}
                        </h1>
                        <p>{t('Traveller Offer')}</p>
                      </div>
                      <Col lg={12} className="mt-4">
                        <div
                          style={{
                            border: '1px solid black',
                            borderRadius: '8px',
                            padding: '16px',
                          }}
                        >
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">{t('Order ID')}</p>
                            <p className="mb-0">{travellerOfferDetail?.id}</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">
                              {t('Order Created Date')}:
                            </p>
                            <p className="mb-0">
                              {travellerOfferDetail?.createdAt?.split('T')[0]}
                            </p>
                          </div>
                          {/* <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">{t('Created By')}:</p>
                            <p className="mb-0">
                              {travellerOfferDetail?.user?.firstName +
                                ' ' +
                                travellerOfferDetail?.user?.lastName}
                            </p>
                          </div> */}
                        </div>
                      </Col>

                      <Col lg={6} className="mt-4">
                      <div
                          style={{
                            border: '1px solid black',
                            borderRadius: '8px',
                            padding: '16px',
                          }}
                        >
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">{t('From Country')}:</p>
                            <p className="mb-0">
                              {travellerOfferDetail?.s_country}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">{t('From City')}:</p>
                            <p className="mb-0">{travellerOfferDetail?.s_city}</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">{t('Address')}:</p>
                            <p className="mb-0">{travellerOfferDetail?.s_address}</p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} className="mt-4 align-self-center">
                        <div
                          style={{
                            border: '1px solid black',
                            borderRadius: '8px',
                            padding: '16px',
                          }}
                        >
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">{t('To Country')}:</p>
                            <p className="mb-0">
                              {travellerOfferDetail?.d_country}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">{t('To City')}:</p>
                            <p className="mb-0">{travellerOfferDetail?.d_city}</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">{t('Address')}:</p>
                            <p className="mb-0">{travellerOfferDetail?.d_address}</p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={12} className="mt-4">
                        <div
                          // className="mt-4"
                          style={{
                            border: '1px solid black',
                            borderRadius: '8px',
                            padding: '16px',
                          }}
                        >
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">{t('When')}:</p>
                            <p className="mb-0">
                              {travellerOfferDetail?.offer_time?.split(
                                'D',
                              )?.[1] == 'ays'
                                ? travellerOfferDetail?.offer_time?.split(
                                    'D',
                                  )?.[0] + ' Days'
                                : travellerOfferDetail?.offer_time}
                            </p>
                          </div>
                        </div>
                        <div
                          className="mt-4"
                          style={{
                            border: '1px solid black',
                            borderRadius: '8px',
                            padding: '16px',
                          }}
                        >
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">{t('Package')}:</p>
                            <p className="mb-0">
                              {t(travellerOfferDetail?.package_type)}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">
                              {t('Dimensions')} {t('cm')}:
                            </p>
                            <p className="mb-0">
                              {travellerOfferDetail?.package_height +
                                'x' +
                                travellerOfferDetail?.package_width +
                                'x' +
                                travellerOfferDetail?.package_length}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">
                              {t('Package Weight')}:
                            </p>
                            <p className="mb-0">
                              {travellerOfferDetail?.max_weight}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">{t('Description')}:</p>
                            <p className="mb-0">
                              {travellerOfferDetail?.additional_note}
                            </p>
                          </div>
                        </div>
                        <div
                          className="mt-4"
                          style={{
                            border: '1px solid black',
                            borderRadius: '8px',
                            padding: '16px',
                          }}
                        >
                          <div className="d-flex justify-content-between">
                            {/* <p className="fw-bold mb-0">{t("Offer Price")}:</p> */}
                            {/* <p className="mb-0">
                              {travellerOfferDetail?.offer_price} $
                            </p> */}
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold mb-0">{t('Status')}:</p>
                            <p className="mb-0">
                              {t(travellerOfferDetail?.status)}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                <Col lg={6}>
                    <div className="text-center pt-5">
                      <Button
                        onClick={toggleTravellerCounterOffer}
                        type="button"
                        className="btnlanding-hov ms-4 profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                      >
                        {t("Make Proposal")}
                      </Button>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="text-center pt-5">
                      <Button
                        onClick={toggleTravellerOfferDetail}
                        type="button"
                        className="btnlanding-hov ms-4 profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                      >
                        {t('Close')}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      {/* Traveller Counter Offer */}
      <Modal
        isOpen={counterTravellerOfferModal}
        toggle={toggleTravellerCounterOffer}
        className="how-work-modal"
        backdrop="static"
      >
        <ModalBody>
          <Row>
            <Col lg={12} md={12} sm={12} className="py-4">
              <div>
                <Form>
                  <Row>
                    <div className="lightorage-color text-center">
                      <h1 className=" py-3 fontSize24-600 orange-color">
                        {t('Make Proposal')}
                      </h1>
                    </div>
                    <Col lg={12} md={12} sm={12}>
                      <FormGroup className="pt-3">
                        <Label
                          for="counterOfferAmount"
                          className="fontSize14-600 dimgray-color"
                        >
                          {' '}
                          {t('Offer Amount')}{' '}
                        </Label>
                        <Input
                          onChange={handleCounterOfferAmount}
                          id="counterOfferAmount"
                          name="counterOfferAmount"
                          type="text"
                          value={counterOfferAmount}
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={12} md={12} sm={12}>
                      <FormGroup className="pt-3">
                        <Label
                          for="counterPackageDetails"
                          className="fontSize14-600 dimgray-color"
                        >
                          {' '}
                          {t('Package Details')}{' '}
                        </Label>
                        <Input
                          onChange={handlePackageDetails}
                          id="counterPackageDetails"
                          name="counterPackageDetails"
                          type="counterPackageDetails"
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={12} md={12} sm={12}>
                      <FormGroup className="pt-3">
                        <Label
                          for="number"
                          className="fontSize14-600 dimgray-color"
                        >
                          {' '}
                          {t('Offer Description')}{' '}
                        </Label>

                        <Input
                          type="textarea"
                          onChange={handleCounterOfferDescription}
                          id="number"
                          name="number"
                          placeholder={t('Enter Offer Descriptions')}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="textright-iconf py-5">
                    <Button
                      type="button"
                      onClick={toggleTravellerCounterOffer}
                      className="btnlanding-hov profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                    >
                      {t('Cancel')}
                    </Button>
                    {/* <Link to="/login"> */}
                    <Button
                      type="button"
                      onClick={handleSubmitTravellerCounterOffer}
                      className="btnlanding-hov ms-4 profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                      disabled={buttonLoader}
                    >
                      {buttonLoader ? (
                        <img
                          className="img-fluid buttonLoaderImg"
                          src={button_loader}
                          alt="loading"
                        />
                      ) : (
                        <>{t('Save')}</>
                      )}
                    </Button>
                    {/* </Link> */}
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      {/* Accept Traveller Offer */}
      <Modal
        isOpen={acceptTravellerOffer}
        toggle={toggleAcceptTravellerOffer}
        className="how-work-modal"
        backdrop="static"
      >
        <ModalBody>
          <Row>
            <Col lg={12} md={12} sm={12} className="py-4">
              <div>
                <Form>
                  <Row>
                    <div className="lightorage-color text-center">
                      <h1 className=" py-3 fontSize24-600 orange-color">
                        {t('Accept Traveller Offer')}
                      </h1>
                    </div>

                    <Col lg={12} md={12} sm={12}>
                      <FormGroup className="pt-3">
                        <Label
                          for="number"
                          className="fontSize14-600 dimgray-color"
                        >
                          {' '}
                          {t('Add Personal Note')} {'(Optional)'}
                        </Label>

                        <Input
                          type="textarea"
                          // onChange={handleCounterOfferDescription}
                          id="number"
                          name="number"
                          placeholder={t('Enter Offer Descriptions')}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="textright-iconf py-5">
                    <Button
                      type="button"
                      onClick={toggleAcceptTravellerOffer}
                      className="btnlanding-hov profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                    >
                      {t('Cancel')}
                    </Button>
                    {/* <Link to="/login"> */}
                    <Button
                      type="button"
                      onClick={handleSubmitTravellerSenderOffer}
                      className="btnlanding-hov ms-4 profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                      disabled={buttonLoader}
                    >
                      {buttonLoader ? (
                        <img
                          className="img-fluid buttonLoaderImg"
                          src={button_loader}
                          alt="Loading"
                        />
                      ) : (
                        <>{t('Accept')}</>
                      )}
                    </Button>
                    {/* </Link> */}
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  )
}
