import React, { useEffect, useState } from "react";

//React Strap Components
import { FormGroup, Label, Input, Button, Alert } from "reactstrap";

//React Icons
import { ImLocation2 } from "react-icons/im";

//React Bootstrap Components
import { Form } from "react-bootstrap";

import { getAllCountriesList } from "../../redux/actions/CountriesAction";
import { getAllCities } from "../../redux/actions/CitiesAction";

//Google Auto Complete
import { usePlacesWidget } from "react-google-autocomplete";

import "tippy.js/dist/tippy.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// import DestinationAddressList from './DestinationAddressList';

const Step1One = ({
  currentStep,
  additionalNote,
  sourceCity,
  sourceCountry,
  destinationCity,
  destinationAddress,
  sourceAddress,
  destinationCountry,
  lastMileDelivery,
  firstMilePickup,
  sourceCountryObject,
  destinationCountryObject,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { allCountriesList } = useSelector((state) => state.CountriesReducer);
  const { allCitiesList } = useSelector((state) => state.CitiesReducer);
  useEffect(() => {
    dispatch(getAllCountriesList());
    dispatch(getAllCities());
  }, []);

  const [sourceCountryId, setSourceCountryId] = useState(null);
  const [destinationCountryId, setDestinationCountryID] = useState(null);
  const [region, setRegion] = useState("");
  const [lastMileChecked, setLastMile] = useState(false);
  const [firstMileChecked, setFIrstMile] = useState(false);
  const [disabledFirstMile, setDisabledFirstMile] = useState(false);
  const [disabledLastMile, setDisabledLastMile] = useState(false);
  const [addressDisabled, setAddressDisabled] = useState(false);
  const [addressVal, setAddressVal] = useState("");
  const [addressDestinationDisabled, setAddressDestinationDisabled] =
    useState(false);
  const [addressDestinationVal, setAddressDestinationVal] = useState("");

  let filteredSourceCities = allCitiesList.map((value) => {
    if (value?.country_id == sourceCountryId) {
      return value?.name;
    }
  });
  let filterDestinationCities = allCitiesList.map((value) => {
    if (value?.country_id == destinationCountryId) {
      return value?.name;
    }
  });

  useEffect(() => {
    setAddressDisabled(disabledFirstMile);
  }, [disabledFirstMile]);
  useEffect(() => {
    setAddressDestinationDisabled(disabledLastMile);
  }, [disabledLastMile]);

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyBtgKhG2FTRTdF2tggTyS27nJz2Y46EYT8",
    onPlaceSelected: (place) => {
      sourceCountry(place?.formatted_address);
      // console.log(place?.address_components?.[0]?.full_name);
      setRegion(place?.address_components?.[0]?.short_name);
    },
    options: {
      types: ["country"],
    },
  });
  const handleRegion = (data) => {
    // console.log(data + "======dest region");
    setRegion(data);
  };
  if (currentStep !== 1) {
    return null;
  }

  // console.log(JSON.stringify(filteredSourceCities) + "====== filtered cities");
  // New Code Starts Here
  const handleSelectSourceCountry = (event) => {
    var index = event.target.selectedIndex;
    var optionElement = event.target.childNodes[index];
    var option = optionElement.getAttribute("data-val");
    let selectedZone = JSON.parse(option);
    // console.log(JSON.stringify(selectedZone) + "========== selected zone");
    setSourceCountryId(selectedZone?.id);
    sourceCountry(selectedZone?.country);
    sourceCountryObject(selectedZone);

    let firstMilePickupAllow = selectedZone?.is_first_mile_pickup_possible;
    if (firstMilePickupAllow === 0) {
      setDisabledFirstMile(true);
      setFIrstMile(false);
    } else {
      setDisabledFirstMile(false);
    }
    // console.log(selectedZone?.country + "selected zone");
  };

  const handlesourceCity = (event) => {
    let sourceCityS = event.target.value;
    sourceCity(sourceCityS);
  };
  const handleDestinationCity = (event) => {
    let destinationCityD = event.target.value;
    destinationCity(destinationCityD);
  };

  const handleSelectDestinationCountry = (event) => {
    var index = event.target.selectedIndex;
    var optionElement = event.target.childNodes[index];
    var option = optionElement.getAttribute("data-val");
    let selectedDestinationZone = JSON.parse(option);
    setDestinationCountryID(selectedDestinationZone?.id);
    destinationCountry(selectedDestinationZone?.country);
    destinationCountryObject(selectedDestinationZone);

    let lastMilePickupAllow =
      selectedDestinationZone?.is_last_mile_delivery_possible;
    if (lastMilePickupAllow === 0) {
      setDisabledLastMile(true);
      setLastMile(false);
    } else {
      setDisabledLastMile(false);
    }
  };

  // New Code Ends Here

  const handleFirstMilePickup = (e) => {
    // console.log(e.target.checked);
    if (e.target.checked) {
      firstMilePickup(1);
    } else {
      firstMilePickup(0);
    }
    setFIrstMile(!firstMileChecked);
  };
  const handlelastMileDelivery = (e) => {
    if (e.target.checked) {
      lastMileDelivery(1);
    } else {
      lastMileDelivery(0);
    }
    setLastMile(!lastMileChecked);
  };

  const handleAdress = (e) => {
    let value = e.target.value;
    if (value === "First mile delivery from door") {
      setAddressDisabled(false);
      firstMilePickup(1);
    }
    if (value === "To be Handed By Traveller to sender directly") {
      firstMilePickup(0);
      setAddressDisabled(true);
      sourceAddress("To be Handed By Traveller to sender directly");
      setAddressVal("To be Handed By Traveller to sender directly");
    }
    if (
      value === "To be Handed By Traveller to Sender At Origin City Airport"
    ) {
      firstMilePickup(0);
      setAddressDisabled(true);
      sourceAddress("To be Handed By Traveller to Sender At Origin City Airport");
      setAddressVal("To be Handed By Traveller to Sender At Origin City Airport");
    }
  };
  const handleDestinationAdress = (e) => {
    let value = e.target.value;
    if (value === "Last mile Delivery") {
      setAddressDestinationDisabled(false);
      lastMileDelivery(1);
    }
    if (value === "To be Picked up by Receipient From Traveller at Airport") {
      setAddressDestinationDisabled(true);
      lastMileDelivery(0);
      destinationAddress(
        "To be Picked up by Receipient From Traveller at Airport"
      );
      setAddressDestinationVal(
        "To be Picked up by Receipient From Traveller at Airport"
      );
    }
    if (
      value ===
      "To be Picked up by Receipient From Traveller at Traveller's location"
    ) {
      lastMileDelivery(0);
      setAddressDestinationDisabled(true);
      destinationAddress(
        "To be Picked up by Receipient From Traveller at agreed location of Traveller"
      );
      setAddressDestinationVal(
        "To be Picked up by Receipient From Traveller at agreed location of Traveller"
      );
    }
  };

  return (
    <div className="pt-5">
      <div className="multiform-postion">
        <div className="lightorage-color mt-5 text-center">
          <h1 className=" py-3 fontSize24-600 orange-color">
            {t("Create Shipment Offer")}
          </h1>
        </div>
        <div className="text-center pt-3">
          <Button
            type="button"
            className="orange-background fontSize16-500 bordt-none"
            size="md"
          >
            {t("Pick up / Drop off Details")}
          </Button>
        </div>
      </div>
      {/*  */}

      <div className="pttwo-multotab">
        <Alert color="warning">
          {t("Note")}: {t("Fill in the *starred* fields for a smooth ride")}!
        </Alert>
        {/* <Tabs
          defaultActiveKey="from"
          id="fill-tab-example"
          className="mb-3 mx-auto multi-btntwo fontSize16-500 "
          fill
        > */}
        {/* <Tab eventKey="from" title="From" className="py-3 "> */}
        <div className="main-position">
          <div className="ps-3 pe-3 pt-4 pb-4 main-1">
            <p className="inner-1">{t("From")}</p>
            <FormGroup>
              <Label for="email" className="fontSize14-600 dimgray-color">
                {t("Country")} <span style={{ color: "red" }}>*</span>
              </Label>
              <div className="input-group">
                <select
                  name="sCountry"
                  id="sCountry"
                  className="form-control"
                  onChange={handleSelectSourceCountry}
                >
                  <option selected>{t("Select Source Country")}</option>
                  {allCountriesList?.length > 0 &&
                    allCountriesList.map((value) => {
                      if (value?.is_service_enabled == 1) {
                        return (
                          <option
                            data-val={JSON.stringify(value)}
                            value={value}
                          >
                            {value?.country + " "}
                          </option>
                        );
                      }
                    })}
                </select>
              </div>
              <ImLocation2 className="mail-icon-login orange-color position-relative" />
            </FormGroup>
            <FormGroup>
              <Label for="email" className="fontSize14-600 dimgray-color">
                {t("City")}
                <span style={{ color: "red" }}>*</span>
              </Label>
              <div className="input-group">
                <select
                  name="sCity"
                  id="sCity"
                  className="form-control"
                  onChange={handlesourceCity}
                >
                  <option selected>{t("Select Source City")}</option>
                  {filteredSourceCities?.length > 0 &&
                    filteredSourceCities.map((value) => {
                      if (value != undefined) {
                        return (
                          <option
                            // data-val={JSON.stringify(value)}
                            value={value}
                          >
                            {value + " "}
                          </option>
                        );
                      }
                    })}
                </select>
              </div>
              <ImLocation2 className="mail-icon-login orange-color position-relative" />
            </FormGroup>

            <Form.Group className="mb-3 badla" controlId="formBasicCheckbox">
              <Form.Check
                onChange={handleAdress}
                value="First mile delivery from door"
                type="radio"
                className="fontSize12-400 gray"
                name="address1"
                id="sAddress1"
                label={t("First mile delivery from door")}
                disabled={disabledFirstMile}
              />

              <Form.Check
                onChange={handleAdress}
                value="To be Handed By Traveller to sender directly"
                name="address1"
                id="sAddress2"
                className="fontSize12-400 gray"
                type="radio"
                label={t("To be Handed By Traveller to sender directly")}
                // disabled={disabledFirstMile}
              />

              <Form.Check
                type="radio"
                className="fontSize12-400 gray"
                name="address1"
                id="sAddress3"
                label={t(
                  "To be Handed By Traveller to Sender At Origin City Airport"
                )}
                onChange={handleAdress}
                value="To be Handed By Traveller to Sender At Origin City Airport"
                // disabled={disabledLastMile}
              />
            </Form.Group>

            <FormGroup>
              <Label for="package" className="fontSize14-600 dimgray-color">
                {t("Address")}
                <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="textarea"
                name="sourceAddress"
                id="sourceAddress"
                onChange={(e) => sourceAddress(e.target.value)}
                disabled={addressDisabled}
                className="h-muttistep-textarea"
                placeholder={
                  addressDisabled
                    ? t(addressVal)
                    : t("Please Enter Detailed Address and WhatsApp Number")
                }
              />
            </FormGroup>
          </div>
        </div>

        {/* </Tab> */}
        {/* <Tab eventKey="to" title="To"> */}
        <div className="main-position mt-5 mb-4">
          <div className="ps-3 pe-3 pt-4 pb-4 main-1">
            <p className="inner-1">{t("To")}</p>
            <FormGroup>
              <Label for="email" className="fontSize14-600 dimgray-color">
                {t("Country")} <span style={{ color: "red" }}>*</span>
              </Label>
              <div className="input-group">
                <select
                  name="dCountry"
                  id="dCountry"
                  className="form-control"
                  onChange={handleSelectDestinationCountry}
                >
                  <option selected>{t("Select Destination Country")}</option>
                  {allCountriesList?.length > 0 &&
                    allCountriesList.map((value) => {
                      if (value?.is_service_enabled == 1) {
                        return (
                          <option
                            data-val={JSON.stringify(value)}
                            value={value}
                          >
                            {value?.country + " "}
                          </option>
                        );
                      }
                    })}
                </select>
              </div>
              <ImLocation2 className="mail-icon-login orange-color position-relative" />
            </FormGroup>
            <FormGroup>
              <Label for="dcity" className="fontSize14-600 dimgray-color">
                {t("City")}
                <span style={{ color: "red" }}>*</span>
              </Label>
              <div className="input-group">
                <select
                  name="dcity"
                  id="dcity"
                  className="form-control"
                  onChange={handleDestinationCity}
                >
                  <option selected>{t("Select Destination City")}</option>
                  {filterDestinationCities?.length > 0 &&
                    filterDestinationCities.map((value) => {
                      if (value != undefined) {
                        return (
                          <option
                            // data-val={JSON.stringify(value)}
                            value={value}
                          >
                            {value + " "}
                          </option>
                        );
                      }
                    })}
                </select>
              </div>
              <ImLocation2 className="mail-icon-login orange-color position-relative" />
            </FormGroup>

            <Form.Group className="mb-3 badla" controlId="formBasicCheckbox">
              <Form.Check
                onChange={handleDestinationAdress}
                value="Last mile Delivery to door"
                type="radio"
                className="fontSize12-400 gray"
                name="address2"
                id="dAddress1"
                label={t("Last mile Delivery to door")}
                disabled={disabledLastMile}
              />

              <Form.Check
                onChange={handleDestinationAdress}
                value="To be Picked up by Receipient From Traveller at Airport"
                name="address2"
                id="dAddress2"
                className="fontSize12-400 gray"
                type="radio"
                label={t(
                  "To be Picked up by Receipient From Traveller at Airport"
                )}
                // disabled={disabledFirstMile}
              />

              <Form.Check
                type="radio"
                className="fontSize12-400 gray"
                name="address2"
                id="dAddress3"
                label={t(
                  "To be Picked up by Receipient From Traveller at Traveller's location"
                )}
                onChange={handleDestinationAdress}
                value="To be Picked up by Receipient From Traveller at Traveller's location"
                // disabled={disabledLastMile}
              />
            </Form.Group>

            <FormGroup>
              <Label for="package" className="fontSize14-600 dimgray-color">
                {t("Address")}
                <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="textarea"
                name="destinationAddress"
                id="destinationAddress"
                onChange={(e) => destinationAddress(e.target.value)}
                disabled={addressDestinationDisabled}
                className="h-muttistep-textarea"
                placeholder={
                  addressDestinationDisabled
                    ? t(addressDestinationVal)
                    : t("Please Enter Detailed Address and WhatsApp Number")
                }
              />
            </FormGroup>

            {/* <FormGroup>
              <Label for="package" className="fontSize14-600 dimgray-color">
                {t("Address")}
                <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="textarea"
                name="destinationAddress"
                id="destinationAddress"
                onChange={destinationAddress}
                className="h-muttistep-textarea"
                placeholder={t(
                  "Please Enter Detailed Address and Phone Number"
                )}
              />
            </FormGroup> */}
          </div>
        </div>
        {/* </Tab>
        </Tabs> */}
      </div>
      {/*  */}

      {/* <p className="darkgraytext fontSize14-600"> Package to be picked up at Departure Airport? </p> */}
      {/* <Form.Group className="mb-3 badla" controlId="formBasicCheckbox">
        <div className="d-flex">
          <Form.Check
            onChange={handleFirstMilePickup}
            checked={firstMileChecked}
            id="first-check"
            type="checkbox"
            label={t("First mile pick up")}
            for="first-check"
            disabled={disabledFirstMile}
          />
          <div className="aa pe-3 ps-3">
            <div>
              <Tippy
                content={t(
                  "First mile pick up - pick up the parcel or the box from the sender address by viahop"
                )}
              >
                <button>?</button>
              </Tippy>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <Form.Check
            type="checkbox"
            checked={lastMileChecked}
            className=" koth"
            id="second-check"
            label={t("Last mile delivery")}
            onChange={handlelastMileDelivery}
            for="second-check"
            disabled={disabledLastMile}
          />

          <div className="aa pe-3 ps-3">
            <div>
              <Tippy
                content={t(
                  "Last mile delivery - deliver by viahop the parcel or box to the sender address at destination."
                )}
              >
                <button type="button">?</button>
              </Tippy>
            </div>
          </div>
        </div>
      </Form.Group> */}

      <FormGroup>
        <Label for="package" className="fontSize14-600 dimgray-color">
          {t("Additional Notes")}
        </Label>
        <Input
          type="textarea"
          name="additionalNotes"
          id="additionalNotes"
          onChange={additionalNote}
          className="h-muttistep-textarea"
          placeholder={t("Additional notes on pick up and drop off")}
          required
        />
      </FormGroup>
    </div>
  );
};

export default Step1One;
