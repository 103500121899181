//Language
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";

//User Module
export const CLICK_PROFILE = "CLICK_PROFILE";
export const LOGIN_DATA = "LOGIN_DATA";
export const GET_USER_DETAIL = "GET_USER_DETAIL";
export const COMPLETE_PROFILE_USER = "COMPLETE_PROFILE_USER";
export const GET_DEVICE_TOKEN = "GET_DEVICE_TOKEN";
//Traveller Offer
export const GET_ALL_TRAVELER_OFFERS = "GET_ALL_TRAVELER_OFFERS";
export const GET_USER_TRAVELER_OFFERS = "GET_USER_TRAVELER_OFFERS";

export const GET_TRAVELER_OFFER = "GET_TRAVELER_OFFER";
export const TRAVELER_OFFERS = "TRAVELER_OFFERS";

//Sender Offer
export const GET_ALL_SENDER_OFFERS = "GET_ALL_SENDER_OFFERS";
export const GET_SENDER_OFFER = "GET_SENDER_OFFER";
export const CREATE_SENDER_OFFERS = "CREATE_SENDER_OFFERS";
export const SENDER_OFFERS = "SENDER_OFFERS";

// General
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_ALL_STATES = "GET_ALL_STATES";
export const GET_USER_DASHBOARD_STAT = "GET_USER_DASHBOARD_STAT";
export const SET_DEFAULT_ADDRESS = "SET_DEFAULT_ADDRESS";
export const DETETE_USER_ACCOUNT = "DETETE_USER_ACCOUNT";

export const GET_ALL_COUNTRIES = "GET_ALL_COUNTRIES";
export const GET_ALL_COUNTRIES_PAYMENT_METHOD =
  "GET_ALL_COUNTRIES_PAYMENT_METHOD";
export const GET_SINGLE_COUNTRY_PAYMENT_METHOD =
  "GET_SINGLE_COUNTRY_PAYMENT_METHOD";
export const GET_ALL_CITIES = "GET_ALL_CITIES";

export const GET_BLOG_LIST = "GET_BLOG_LIST";

//Offer Chat
export const GET_OFFER_CHAT_MESSAGES = "GET_OFFER_CHAT_MESSAGES";

//Rating
export const GIVE_RATING = "GIVE_RATING";
export const GET_MY_RATINGS = "GET_MY_RATINGS";
export const SEE_USER_RATINGS = "SEE_USER_RATINGS";

export const OFFER_PRICING = "OFFER_PRICING";
export const HERO_SECTION_DATA = "HERO_SECTION_DATA";
export const HOW_IT_WORK_DATA = "HOW_IT_WORK_DATA";

export const LANGUAGE = "LANGUAGE";