import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { Button, Col } from "reactstrap";
import button_loader from "../Assets/Loader/button_loader.gif";
import {
  getAllMyRating,
  giveRatingToUser,
} from "../../redux/actions/RatingAction";
import { useDispatch, useSelector } from "react-redux";
import { Rating } from "react-simple-star-rating";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

export default function RateYourDelivery(props) {
  const dispatch = useDispatch();
  const { getAllMyRatings } = useSelector((state) => state.RatingReducer);
  console.log("getAllMyRatings=========all", getAllMyRatings);
  console.log("getAllMyRatings=========props", props);
  const ratting = getAllMyRatings?.find((i) => i.id == props.id);
  console.log("getAllMyRatings=========2", ratting);

  useEffect(() => {
    dispatch(getAllMyRating());
  }, []);
  const [rating, setRating] = useState(ratting?.stars);
  const [ratingError, setRatingError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [review, setReview] = useState(getAllMyRatings?.[0]?.review);
  const { t } = useTranslation();

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);
    console.log(rate, "rateeeeeeeeeeeeeee");
  };
  // Optinal callback functions
  const onPointerEnter = () => console.log("Enter");
  const onPointerLeave = () => console.log("Leave");
  const onPointerMove = (value, index) => console.log(value, index);

  const handleRatingApi = () => {
    if (rating == 0) {
      setRatingError(true);
    } else {
      setRatingError(false);
      setLoading(true);
      let data = {
        toUserId: props.userId,
        offerId: props.id,
        stars: rating,
        review: review,
        offerType:props.offerType
      };
      dispatch(giveRatingToUser(data, handleRatingApiResponse));
    }
  };
  const handleRatingApiResponse = (data) => {
    setLoading(false);
    if (data.status) {
      Swal.fire({
        title: "Success",
        type: "success",
        text: data.message,
      });
      props.isRatingPage()
    } else {
      Swal.fire({
        title: "Failed",
        type: "failed",
        text: data.message,
      });
    }
  };

  return (
    <Row className="card">
      <div className="card-body">
        <Col lg={12}>
          <p style={{ textAlign: "right" }}>
            <button
              style={{
                margin: "5px",
                borderRadius: "50%",
                color: "white",
                padding: "2px 10px 2px 10px",
              }}
              type="button"
              className="btn back-orange"
              onClick={props.isRatingPage}
            >
              X
            </button>
          </p>

          <div className="borderbotm pb-3 d-flex justify-content-center">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-9 col-12 text-center justify-content-center">
                <form action="#">
                  <div className="row">
                    <div className="mt-2 col-12">
                      <Rating
                        onClick={handleRating}
                        initialValue={ratting?.stars}
                        onPointerEnter={onPointerEnter}
                        // onPointerLeave={onPointerLeave}
                        onPointerMove={onPointerMove}
                        /* Available Props */
                      />
                    </div>
                    <div className="mt-3 col-12">
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="4"
                          value={review}
                          disabled={ratting?.length > 0}
                          onChange={(e) => {
                            setReview(e.target.value);
                          }}
                          placeholder="Comment Your Experience"
                        ></textarea>
                      </div>
                      {ratingError && (
                        <span style={{ color: "red" }}>
                          {t("Please Give Some Rating First")}
                        </span>
                      )}
                    </div>
                    <div className="textright-iconf pt-1">
                      {/* {ratting?.length > 0 ? ( */}
                      <Button
                        className="btnlanding-hov ms-4 profiletop-btnoutline  mt-2 bordt-none fontSize20-600 px-5"
                        onClick={handleRatingApi}
                        disabled={ratting?.length > 0}
                      >
                        {loading ? (
                          <img
                            className="img-fluid buttonLoaderImgBgTrans"
                            src={button_loader}
                            alt="loader"
                            style={{
                              backgroundColor: "inherit !important",
                            }}
                          />
                        ) : (
                          t("Rate")
                        )}
                      </Button>
                      {/* ) : null} */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Col>
      </div>
    </Row>
  );
}
