import React, { useState, createContext, useEffect } from "react";

//Redux Provider
import { Provider } from "react-redux";

//Persist Gate
import { PersistGate } from "redux-persist/lib/integration/react";

//Redux Store
import storeConfig from "./redux/store";

//context
import LanguageContext from "./Context/LanguageContext";

//Routing
import AppRouter from "./routes/index";

//Context
import ProfileClickedContext from "./utils/context";

//Components
import Footer from "./View/Component/Footer";
import LoadingScreen from "react-loading-screen";
import logoo from "./View/Assets/Images/logoo1.png";
import { useTranslation } from "react-i18next";
import { getFirebaseToken, onMessageListener } from "./firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Fab from "./widgets/Fab";
import { setDeviceToken } from "./redux/actions/UserAction";

export default function App() {
  const [notification, setNotification] = useState({ title: "", body: "" });

  const { store, persistor } = storeConfig;
  const [profileClickedState, setProfileClickedState] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const ln = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  const [language, setLanguage] = useState(ln ? ln : "");
  //  const lang = createContext();
  // const dispatch = useDispatch

  const handleDeviceToken = (data) => {
    // dispatch(setDeviceToken(data))
    console.log("divicetoken", data);
  };

  React.useEffect(() => {
    // setLoading(false);
    getFirebaseToken(handleDeviceToken);
    setTimeout(() => {
      setLoading(false);
    }, [2000]);
  }, []);
  const profileClickedHandler = (value) => {
    setProfileClickedState(value);
  };

  onMessageListener()
    .then((payload) => {
      console.log(payload?.notification?.title, "haschxavsva");
      console.log(payload, "payload");
      notify(payload?.notification?.title);
      toggle();
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
    })
    .catch((err) => console.log("failed: ", err));
  const notify = (message) => toast(message);

  const useAudio = (url) => {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);

    const toggle = () => setPlaying(!playing);

    useEffect(() => {
      playing ? audio.play() : audio.pause();
    }, [playing]);

    useEffect(() => {
      audio.addEventListener("ended", () => setPlaying(false));
      return () => {
        audio.removeEventListener("ended", () => setPlaying(false));
      };
    }, []);

    return [playing, toggle];
  };

  const [playing, toggle] = useAudio(
    "https://faisalayaz.com/notification/notification.mp3"
  );

  return (
    <ProfileClickedContext.Provider
      value={{
        profileClicked: profileClickedState,
        profileClickedHandler: profileClickedHandler,
      }}
    >
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <LanguageContext.Provider value={{ language, setLanguage }}>
            <LoadingScreen
              loading={loading}
              bgColor="#f1f1f1"
              spinnerColor="#9ee5f8"
              textColor="#676767"
              logoSrc={logoo}
              text={t("Hang tight! while we do our magic")}
            >
              <Fab />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100vh",
                }}
              >
                <div>
                  <AppRouter />
                </div>
                <div>
                  <Footer />
                </div>
              </div>
            </LoadingScreen>
          </LanguageContext.Provider>
        </PersistGate>
      </Provider>
      <ToastContainer />
    </ProfileClickedContext.Provider>
  );
}
