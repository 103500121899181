import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BsInstagram, BsTwitter, BsYoutube } from "react-icons/bs";
import { FaFacebook, FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";

function Footer() {
  const { t } = useTranslation();
  return (
    <div style={{ marginBottom: "0px" }}>
      <footer className="orange-background">
        <div className="container-fluid px-5">
          <Row>
            <Col lg={6} md={6} sm={12}>
              <div>
                <p className="fontSize16-400 text-white pt-4">
                  © {t("2023 Viahop. All Rights Reserved")}.{" "}
                </p>
                {/* <p className="fontSize16-400 text-white pt-4">
                Uplands Inspire LLC FZE
                </p> */}
              </div>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <div className="textright-iconf py-3">
              <a href="https://www.instagram.com/viahop/" target="_blank" rel="noreferrer"><Button className="footer-btnstyle me-3">
                  {" "}
                  <BsInstagram className="footer-iconstyle ms-2" />{" "}
                </Button></a>
                <a href="https://www.facebook.com/viahop1" target="_blank" rel="noreferrer"><Button className="footer-btnstyle me-3">
                  {" "}
                  <FaFacebook className="footer-iconstyle ms-2" />{" "}
                </Button>
                </a>
                <Button className="footer-btnstyle me-3">
                  {" "}
                  <BsTwitter className="footer-iconstyle ms-2" />{" "}
                </Button>
                <Button className="footer-btnstyle me-3">
                  {" "}
                  <BsYoutube className="footer-iconstyle ms-2" />{" "}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
