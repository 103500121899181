import React from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../Component/Header'
import LoggedinNavbar from '../Component/LoggedinNavbar'
import SecondNav from './SecondNav'

export default function Fq() {
  const token = localStorage.getItem('token')
  const { t } = useTranslation()
  return (
    <>
      <LoggedinNavbar />
      {/* <SecondNav /> */}
      <div className="container mt-5 mb-5 pb-5">
        <div className="row justify-content-center head-about mt-4 mb-4">
          <div className="col-lg-8 col-md-10 col-sm-12">
            <h1 className="text-center">{t('FAQs')}</h1>
            {/* <h1 style={{color:'black'}}> Viahop ?</h1> */}
            <div className="mt-5">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      {t('How Do I Use Viahop')}?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p className="word-spacing-5">
                        {t(
                          'To use Viahop, you can either register as a sender or traveler. As a sender, you can post a request for a delivery, specify an item and its destination. As a traveler, you can also list your travel dates and what you can take with you. As a Sender or Traveler you can also search from the list of existing offers and orders on platform which you can accept or counteroffer. Once traveler and sender accept each other’s terms sender will be prompted to payment through Viahop escrow account and delivery can take place. Both parties will receive all the details about the sender and the item and both can track its progress. When the item has arrived, both parties can rate the sender and the traveler.',
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      {t(
                        'What verifications do I need to complete in order to view and list the sender and travel orders',
                      )}
                      ?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p className="word-spacing-5">
                        {t(
                          'In the Verification tab of you profile, you would need to verify via OTP code your Email and WhatsApp number, upload your National ID or passport in order to proceed with listing your order. Please be advised that we take your confidentiality seriously and do not disclose to anyone your ID details. All copies are stored in a fully encrypted database with no access from third parties, travelers or senders.',
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      {t('Does Viahop charge a fee')}?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p className="word-spacing-5">
                        {t(
                          'Yes, Viahop charges a minimum fee (5%-10% depending on geographies served) for using our service. Additionally if sender opts in for any additional services (first mile, last mile, insurance etc) these fees will be added to service fee. We strive to be transparent and disclose full fee details at the offer acceptance stage.',
                        )}
                        .
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      {t('how is the payment processed to traveller')}?
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingfour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p className="word-spacing-5">
                        {t(
                          'Upon service acceptance Sender makes a credit card payment to Viahop escrow account. Upon delivery acceptance, the amount will be further remitted by Viahop to Traveler at the agreed payment option.',
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFive">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      {t(
                        'How do we know that the package does not contain illegal things',
                      )}
                      ?
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p className="word-spacing-5">
                        {t(
                          'As per the terms and conditions signed by senders and travelers, the sender bears full responsibility for the package contents, their export/import restrictions, and legality. Please remind the sender of any customs declarations that may be required at the destination country and provide the necessary information and details that may be needed. Following items are not allowed for shipment to/from any origin/destination at any time: Illegal items, weapons or explosives, animals, flammable goods or hazardous materials, drugs, alcohol or tobacco, perishable goods, stolen items or items involved in a crime,  money or other financial instruments, precious stones or goods, human remains or body parts. However for the full list please refer to your origin/destination country import export regulation.',
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingSix">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      {t('Do airlines allow this kind of service')}?
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSix"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p className="word-spacing-5">
                        {t(
                          "Yes, traveler serves as on board courier and is allowed by airlines. Before sending an item, it is sender's responsibility to check with the airline and customs to ensure that they allow the item to be sent.",
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingSeven">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      {t('What package types are supported by Viahop')}?
                    </button>
                  </h2>
                  <div
                    id="collapseSeven"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSeven"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p className="word-spacing-5">
                        {t('Viahop supports various packages and envelopes, including 1) document envelopes, 2) small box packages, 3) medium box packages, and 4) large box packages. Please be advised that some package types are not enabled for particular countries. Please contact customer support for more information or inquiry on this. Document Envelopes: Document envelopes are thin and lightweight, typically used for sending documents and lightweight items such as photographs. They must weigh less than 3 kg and have dimensions of up to 40 x 30 cm. Small Box Packages: Small box packages are typically used for smaller items such as phones and other similar sized electronics. They must weigh below 5 kg and have dimensions of up to 40 x 30 x 20 cm. Medium Box Packages: Medium box packages are typically used for laptops and other similar sized electronics. They must weigh below 8 kg and have dimensions of up to 60 x 30 x 30 cm. Large Box Packages: Large box packages are typically used for larger items such as microwaves. They usually weigh up to 15kg and have dimensions of up to 80 x 40 x 40 cm. Please be advised that traveler has the right to inspect the items being couriered and has the right to decline the service upon inspection. If the box is unsealed by manufacturer, its contents should be declared by sender and can be inspected by traveler.',
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingEight">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      {t(
                        'How does first mile last mile pick up and delivery work with Viahop',
                      )}
                      ?
                    </button>
                  </h2>
                  <div
                    id="collapseEight"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingEight"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p className="word-spacing-5">
                        {t(
                          'On select destinations Viahop offers First mile pick up and Last mile delivery for door to door service. For the first mile pick up our in country courier will pick up Sender’s item from pick up address and take it to Traveler. When Traveler fulfills the delivery to destination country our last mile delivery courier in the destination country  will pick up the item and take it directly to the destination address.',
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingNine">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseNine"
                      aria-expanded="false"
                      aria-controls="collapseNine"
                    >
                      {t(
                        'What kind of insurance coverage does Viahop offer for deliveries',
                      )}
                      ?
                    </button>
                  </h2>
                  <div
                    id="collapseNine"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingNine"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p className="word-spacing-5">
                        {t(
                          'Yes, Viahop does offer insurance for deliveries as additional service. Our insurance covers the cost of any damage or loss for items sent via our platform, up to the specified limits and based on value declared. However, please note that our insurance is only available for select geographies. Please check with the customer service team for more details.',
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTen">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTen"
                      aria-expanded="false"
                      aria-controls="collapseTen"
                    >
                      {t('What if shipment is damaged or lost')}?
                    </button>
                  </h2>
                  <div
                    id="collapseTen"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTen"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p className="word-spacing-5">
                        {t(
                          'In the event of a shipment being damaged or lost, the sender and the traveler are both responsible for filing a claim. It is suggested to add Viahop insurance when sending an item to cover any potential losses (not all origin/destination countries are eligible for insurance services).',
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingEleven">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEleven"
                      aria-expanded="false"
                      aria-controls="collapseEleven"
                    >
                      {t('How do I select add on services from the order page')}
                      ?
                    </button>
                  </h2>
                  <div
                    id="collapseEleven"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingEleven"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p className="word-spacing-5">
                        {t(
                          'If add on services (insurance, first mile, last mile) are enabled for your origin and destination country, you will be able to select these options during Create order stage. However, if services are not available in these countries, then you will not be able to select these additional services.',
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwele">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwele"
                      aria-expanded="false"
                      aria-controls="collapseTwele"
                    >
                      {t(
                        'What is Viahop recommended price and how is it calculated',
                      )}
                      ?
                    </button>
                  </h2>
                  <div
                    id="collapseTwele"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwele"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p className="word-spacing-5">
                        {t(
                          'Viahop recommended price is a courier service price recommendation to travelers and senders for the shipment. It is a mere ballpark figure and does not need to be followed by parties if they decide to have their own offers and counteroffers. This recommended price however is based on the countries and package types involved.  This recommended price is an estimate of service fee (what traveler receives) and does not include any add on services and Viahop commission, which are fixed.',
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
