import { GIVE_RATING, GET_MY_RATINGS, SEE_USER_RATINGS } from "../types";

const initialState = {
  giveRating: [],
  getAllMyRatings: [],
  getUserRating: [],
};

const RatingReducer = function (state = initialState, action) {
  switch (action.type) {
    case GIVE_RATING: {
      return {
        ...state,
        giveRating: action.payload,
      };
    }
    case GET_MY_RATINGS: {
      return {
        ...state,
        getAllMyRatings: action.payload,
      };
    }
    case SEE_USER_RATINGS: {
        return{
            ...state,
            getUserRating: action.payload,
        };
    }
    default:
      return {
        ...state,
      };
  }
};

export default RatingReducer;
