import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Fab = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  const handleFab = () => {
    setIsVisible(!isVisible);
  };
  return (
    <div className="fab" style={{ zIndex: 3 }}>
      <div
        className="text p-4 bg-white shadow-lg"
        style={{
          position: "relative",
          bottom: 90,
          zIndex: 10000,
          display: isVisible ? "block" : "none",
        }}
      >
        <div className="text-black-50 fw-semibold">{t("Get Help")}</div>
        <a
          href="http://wa.me/+971585889516"
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <div className="mt-3">
            <span className="_19f1f41b">
              <span className="text-black fs-6">{t("Ask in WhatsApp")}</span>
            </span>
          </div>
        </a>
        <a
          href="https://t.me/Viahop_Global"
          target="_blank"
          className="_72c7baf4 _27651f77"
          style={{ textDecoration: "none" }}
        >
          <div className="mt-3">
            <span>
              <span className="text-black fs-6">{t("Ask in Telegram")}</span>
            </span>
          </div>
        </a>
      </div>
      <div
        style={{
          background: "#f69110",
          padding: 18,
          borderRadius: 50,
          width: 60,
          position: "absolute",
          bottom: 0,
          right: 0,
          cursor: "pointer",
          textAlign: "center",
        }}
        className="shadow-sm"
        onClick={handleFab}
      >
        {isVisible ? (
          <i
            className="fa fa-light fa-xmark"
            style={{ color: "#ffffff", fontSize: 24, textAlign: "center" }}
          ></i>
        ) : (
          <i
            className="fa fa-headset "
            style={{ color: "#ffffff", fontSize: 25 }}
          ></i>
        )}
      </div>
    </div>
  );
};

export default Fab;
