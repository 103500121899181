import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../Component/Header";
import LoggedinNavbar from "../Component/LoggedinNavbar";
import SecondNav from "./SecondNav";

export default function About() {
  const token = localStorage.getItem("token");
  const { t } = useTranslation();
  return (
    <>
      <LoggedinNavbar />
      {/* <SecondNav /> */}
      <div className="container mt-5 pt-5 mb-5 pb-4">
        <div className="row justify-content-evenly head-about">
          <div className="col-11 px-3">
            <h1 className="text-center">{t("About Us")}</h1>
            {/* <h1 style={{color:'black'}}>Our Users Say About Us</h1> */}
            <div className="mt-5">
              <p style={{ fontSize: "17px", textAlign: "justify" }}>
                <strong className="fs-4"> {t("Mission")}: </strong>{" "}
                {t(
                  "Our mission is to make the courier and traveler industries more accessible and traveling more affordable than ever before. We strive to provide our users with a secure, fast, and convenient way to send items, while also allowing travelers to earn extra income from their travels safely and securely."
                )}
                <br />
                <br />
                <strong className="fs-4">{t("Values")}: </strong>
                {t(
                  "At Viahop, we believe in the power of collaboration and are dedicated to creating a community of people who are passionate about making a difference. We are committed to providing our users with an affordable and cost-effective service. We also take the security of our users seriously and use the latest encryption technology to protect our users’ data. Finally, we are dedicated to providing the best customer experience possible and are constantly looking for ways to improve our services."
                )}
                <br />
                <br />
                <strong className="fs-4">Viahop </strong>
                {t(
                  "is a revolutionary courier marketplace that lets senders quickly, affordably and securely ship their packages and let travelers earn extra funds from their travel. We operate globally and are based in Dubai, UAE - a true global logistics hub connecting the world. We provide a secure and reliable service that puts our users first. Our platform connects senders with travelers to send items quickly, cheaply and securely, while also allowing travelers to earn extra income from their travels safely and securely."
                )}
                <br />
                <br />
                {t(
                  "At Viahop, we are committed to creating a community of senders and travelers who are reliable, trustworthy, and passionate about making the world a better place. We strive to create a safe and secure environment for our users, and we are constantly looking for ways to improve our services. We believe in the power of collaboration and are dedicated to creating a community of people who are passionate about making a difference."
                )}
                <br />
                <br />
                {t(
                  "We are committed to providing our users with an affordable and cost-effective service. We strive to keep our prices competitive and offer discounts and promotions to ensure that our users get the best value for their money. We also offer a range of payment options, including credit cards, PayPal, and bank transfers, to make sure that our users can pay for their orders in the most convenient way possible."
                )}
                <br />
                <br />
                {t(
                  "We take the security of our users seriously. We have a strict KYC procedure in place to help ensure that all users are who they say they are. We also use the latest encryption technology to protect our users’ data and ensure that all transactions are secure. Additionally, an electronic contract is signed by the sender and traveler when a delivery is claimed, which helps to ensure that the sender is responsible for the item being sent and that the traveler is responsible for its safe delivery."
                )}
                <br />
                <br />
                {t(
                  "We understand that speed is of the essence when it comes to sending items. That’s why we strive to provide our users with the fastest and most reliable service possible. Our platform connects senders with travelers who are nearby, allowing for quick and efficient delivery of items. We also offer tracking services, so that senders can keep track of their orders and ensure that their items arrive on time. With Viahop, you can be sure that your items will be delivered quickly and securely."
                )}
                <br />
                <br />
                {t("Join us on our mission to make the world a better place!")}
                <br />
              </p>
              {/* <h3 style={{fontWeight:'700',color:'#F69110'}}>Jaydon Bator</h3>
            <p>Professional Artist</p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
