import { GET_BLOG_LIST } from "../types";

const initialState = {
  allBlogList: [],
};

const AdminReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_BLOG_LIST: {
      return {
        ...state,
        allBlogList: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
export default AdminReducer;
