import React, { useEffect, useState } from "react";
import { FormGroup, Label, Input, Row, Col, Button } from "reactstrap";

import "../SamllComponent/MultitabBtn.css";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import cong from "../Assets/Images/cong.png";
import MultipleSlection from "../Component/MultipleSlection";
import { event } from "jquery";
import { useTranslation } from "react-i18next";

const Step3Three = ({
  currentStep,
  getMultiselectValue,
  maxWeight,
  packageWidth,
  packageLenght,
  packageHeight,
  fCountryObject,
  tCountryObject,
  // packageType,
}) => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const [unit, setUnit] = useState("KG");
  const [weight, setWeight] = useState("");
  const [lengthUnit, setLengthUnit] = useState("");
  const [length, setLength] = useState("");
  const [shipmentType, setShipmentType] = useState("Shipment Types");
  const [shipmentTypeValue, setshipmentTypeValue] = useState(null);
  const [allowedShipmentTypes, setAllowedShipmentTypes] = useState([]);
  const [disableFields, setDisableFields] = useState(false);
  const [widthUnit, setWidthUnit] = useState("");
  const [width, setWidth] = useState("");
  // const [getMultiselectValue, setMultiselectValue] = useState(null);

  const [heightUnit, setHeightUnit] = useState("");
  const [height, setHeight] = useState("");
  // useEffect(() => {
  //   console.log("here first use Effect")
  //   maxWeight(weight + " " + unit)
  // }, []);
  useEffect(() => {
    handleSetAllowedShipmentOptions();
  }, [tCountryObject]);

  useEffect(() => {
    maxWeight(weight + " " + unit);
  }, [unit, weight]);

  useEffect(() => {
    packageHeight(height + " " + heightUnit);
  }, [heightUnit, height, packageHeight]);

  useEffect(() => {
    packageWidth(width + " " + widthUnit);
  }, [widthUnit, width, packageWidth]);

  useEffect(() => {
    packageLenght(length + " " + lengthUnit);
  }, [lengthUnit, length, packageLenght]);

  // useEffect(() => {
  //   packageType(shipmentType);
  // }, [shipmentType]);

  useEffect(() => {
    getMultiselectValue(shipmentTypeValue);
  }, [shipmentTypeValue]);

  const handleMaxWeightUnit = (event) => {
    let value = event.target.value;

    if (/^[0-9]\d*$/.test(value)) {
      setWeight(event.target.value);
    } else {
      setWeight("");
    }
  };
  const handleUnit = (event) => {
    setUnit(event.target.value);
  };
  const handleLength = (event) => {
    setLength(event.target.value);
  };
  const handleLengthUnit = (event) => {
    setLengthUnit(event.target.value);
  };
  const handleWidth = (event) => {
    setWidth(event.target.value);
  };
  const handleWidthUnit = (event) => {
    setWidthUnit(event.target.value);
  };
  const handleHeight = (event) => {
    setHeight(event.target.value);
  };
  const handleHeightUnit = (event) => {
    setHeightUnit(event.target.value);
  };

  const handleSetAllowedShipmentOptions = () => {
    console.log(
      JSON.stringify(tCountryObject) + "to country object in step three"
    );
    console.log(
      allowedShipmentTypes + "========= allowed shipment types before setting"
    );
    if (
      tCountryObject?.is_document_possible == 1 &&
      fCountryObject?.is_document_possible == 1 &&
      !allowedShipmentTypes.find((i) => i === "document envelope")
    ) {
      setAllowedShipmentTypes((allowedShipmentTypes) => [
        ...allowedShipmentTypes,
        "document envelope",
      ]);
    }
    if (
      tCountryObject?.is_small_box_possible == 1 &&
      fCountryObject?.is_small_box_possible == 1 &&
      !allowedShipmentTypes.find((i) => i === "small box")
    ) {
      setAllowedShipmentTypes((allowedShipmentTypes) => [
        ...allowedShipmentTypes,
        "small box",
      ]);
    }
    if (
      fCountryObject?.is_medium_box_possible == 1 &&
      tCountryObject?.is_medium_box_possible == 1 &&
      !allowedShipmentTypes.find((i) => i === "medium box")
    ) {
      setAllowedShipmentTypes((allowedShipmentTypes) => [
        ...allowedShipmentTypes,
        "medium box",
      ]);
    }
    if (
      tCountryObject?.is_large_box_possible == 1 &&
      fCountryObject?.is_large_box_possible == 1 &&
      !allowedShipmentTypes.find((i) => i === "large box")
    ) {
      setAllowedShipmentTypes((allowedShipmentTypes) => [
        ...allowedShipmentTypes,
        "large box"
      ]);
    }
    if (
      (fCountryObject?.is_other_package_a == 1 &&
        tCountryObject?.is_other_package_a == 1 &&
        !allowedShipmentTypes.find((i) => i === "other packages")) ||
      (tCountryObject?.is_other_package_b == 1 &&
        fCountryObject?.is_other_package_b == 1 &&
        !allowedShipmentTypes.find((i) => i === "other packages"))
    ) {
      setAllowedShipmentTypes((allowedShipmentTypes) => [
        ...allowedShipmentTypes,
        "other packages",
      ]);
    }
   
  };

  const handleClose = () => setShow(false);
  if (currentStep !== 3) {
    return null;
  }
  const handleSelect = (data) => {
    console.log("data====" + JSON.stringify(data));
  };
  const handleShipmentType = (event) => {
    let shipment = event.target.value;
    setShipmentType(shipment);
    setshipmentTypeValue(shipment);
    if (shipment == "document envelope") {
      setDisableFields(true);
    } else {
      setDisableFields(false);
    }
  };

  return (
    <div className="pt-5" style={{ overflowX: "hidden" }}>
      <div className="multiform-postion">
        <div className="lightorage-color my-4 text-center">
          <h1 className=" py-3 fontSize24-600 orange-color">
            {" "}
            {t("Create Traveler Offer")}
          </h1>
        </div>
        <div className="text-center pt-3">
          <Button
            className="orange-background fontSize16-500 bordt-none"
            size="md"
            type="button"
          >
            {t("Package Details")}{" "}
          </Button>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-10 col-md-11 col-12">
          <Row className="justify-content-center">
            <Col lg={12} md={12} sm={12}>
              <div className="pttwo-multotab">
                {/* <CollapseBtnOne /> */}
                {/* <MultipleSlection onChangeOption={getMultiselectValue}/> */}
                <FormGroup>
                  <Label
                    for="exampleSelect"
                    className="fontSize14-600 dimgray-color"
                  >
                    {/* {shipmentType} */}
                    {t("Package")}
                    <span style={{ color: "red" }}>*</span>(
                    {t(
                      "Selection only allowed in both Destination and Origin Countries"
                    )}
                    )
                  </Label>
                  <select
                    className="form-control"
                    name="select"
                    id="exampleSelect"
                    onChange={handleShipmentType}
                  >
                    <option value="">{t("Please Select")}</option>
                    {allowedShipmentTypes?.length > 0 &&
                      allowedShipmentTypes?.map((value) => {
                        return <option value={value}>{t(value)}</option>;
                      })}
                  </select>
                </FormGroup>
                <FormGroup className="">
                  <Label
                    for="password"
                    className="fontSize14-600 dimgray-color"
                  >
                    {/* {shipmentType}  */}
                    {t("Max Weight")}
                    <span style={{ color: "red" }}>*</span>
                  </Label>

                  <div className="input-group">
                    <input
                      type="text"
                      onChange={handleMaxWeightUnit}
                      value={weight}
                      className="form-control"
                      aria-label="Package Weight"
                    />
                    <div className="input-group-append">
                      <select
                        name=""
                        onChange={handleUnit}
                        className="form-control"
                        id=""
                      >
                        <option value="KG" selected>
                          {t("KG")}
                        </option>
                        <option value="Pounds">{t("Pounds")}</option>
                      </select>
                    </div>
                  </div>
                </FormGroup>
              </div>
            </Col>
          </Row>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-4 col-12">
              <FormGroup>
                <Label for="password" className="fontSize14-600 dimgray-color">
                  {/* {shipmentType} */}
                  {t("Max Length")}
                  <span style={{ color: "red" }}>*</span>
                </Label>

                <div className="input-group">
                  <input
                    type="number"
                    required
                    min={0}
                    onChange={handleLength}
                    className="form-control"
                    aria-label="Package Weight"
                    disabled={disableFields}
                  />
                  <div className="input-group-append">
                    <select
                      name=""
                      onChange={handleLengthUnit}
                      className="form-control"
                      id=""
                      disabled={disableFields}
                    >
                      <option value="CM" selected>
                        {t("CM")}
                      </option>
                      <option value="INCH">{t("INCH")}</option>
                    </select>
                  </div>
                </div>
              </FormGroup>
            </div>
            <div className="col-lg-4 col-md-4 col-12">
              <FormGroup>
                <Label for="password" className="fontSize14-600 dimgray-color">
                  {/* {shipmentType}  */}
                  {t("Max Width")}
                  <span style={{ color: "red" }}> * </span>
                </Label>
                {/* <Input
              type="text"
              name="packageWidth"
              id="packageWidth"
              placeholder="CM or INCH"
              onChange={packageWidth}
              required
            /> */}
                <div className="input-group">
                  <input
                    type="number"
                    required
                    min={0}
                    onChange={handleWidth}
                    className="form-control"
                    aria-label="Package Weight"
                    disabled={disableFields}
                  />
                  <div className="input-group-append">
                    <select
                      name=""
                      onChange={handleWidthUnit}
                      className="form-control"
                      id=""
                      disabled={disableFields}
                    >
                      <option value="CM" selected>
                        CM
                      </option>
                      <option value="INCH">{t("INCH")}</option>
                    </select>
                  </div>
                </div>
              </FormGroup>
            </div>
            <div className="col-lg-4 col-md-4 col-12">
              <FormGroup>
                <Label for="password" className="fontSize14-600 dimgray-color">
                  {/* {shipmentType}  */}
                  {t("Max Height")}
                  <span style={{ color: "red" }}>*</span>
                </Label>
                {/* <Input
              type="text"
              name="packageHeight"
              id="packageHeight"
              placeholder="CM or INCH"
              onChange={packageHeight}
              required
            /> */}
                <div className="input-group">
                  <input
                    type="number"
                    required
                    min={0}
                    onChange={handleHeight}
                    className="form-control"
                    aria-label="Package Weight"
                    disabled={disableFields}
                  />
                  <div className="input-group-append">
                    <select
                      name=""
                      onChange={handleHeightUnit}
                      className="form-control"
                      id=""
                      disabled={disableFields}
                    >
                      <option value="CM" selected>
                        {t("CM")}
                      </option>
                      <option value="INCH">{t("INCH")}</option>
                    </select>
                  </div>
                </div>
              </FormGroup>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} className="stepthree-mpdal">
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="py-5 px-4 text-center">
                <img src={cong} alt="cong" width="200" />
                <p className="orange-color fontSize32-600 text-center">
                  {t("Congratulations")}!
                </p>
                <p className="textgray fontSize16-500 text-center">
                  {t("Order")}#TR-1232-24234-4324-5435
                </p>
                <p className="fontSize16-500 text-center mb-0">
                  {t("We have recieved your order details")},
                </p>
                <p className="fontSize16-500 text-center mb-0">
                  {t("You’ll be shortly notified on your contact info")}!
                </p>
                <div className="text-center pt-4">
                  <Button
                    className="orange-background fontSize16-500 bordt-none px-5"
                    size="md"
                  >
                    {t("Login")}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default Step3Three;
