import {
    GET_TRAVELER_OFFER,
    GET_ALL_TRAVELER_OFFERS,
    GET_ALL_SENDER_OFFERS,
    GET_SENDER_OFFER,
    SENDER_OFFERS,
    TRAVELER_OFFERS,
    GET_USER_TRAVELER_OFFERS,
    OFFER_PRICING,
    HERO_SECTION_DATA,
    HOW_IT_WORK_DATA,
    LANGUAGE
  } from "../types";
  
  const initialState = {
    offerDetails: {},
    offerPricing:{},
    allTravellerOffers: [],
    userTravellerOffers: [],
    allSenderOffers: [],
    allCategories: [],
    allStates: [],
    allCosts: [],
    travellerOffersList:[],
    senderOffersList:[],
    travellerOfferDetail:[],
    heroSectionData:[],
    howItWorksData:[],
    language:{}
  };
  
  const TravellerOfferReducer = function (state = initialState, action) {
    switch (action.type) {
      case LANGUAGE: {
        return {
          ...state,
          language: action.payload,
        };
      }
      case HERO_SECTION_DATA: {
        return {
          ...state,
          heroSectionData: action.payload,
        };
      }
      case HOW_IT_WORK_DATA: {
        return {
          ...state,
          howItWorksData: action.payload,
        };
      }
      case GET_TRAVELER_OFFER: {
        return {
          ...state,
          travellerOfferDetail: action.payload,
        };
      }
      case OFFER_PRICING: {
        return {
          ...state,
          offerPricing: action.payload,
        };
      }
      case GET_SENDER_OFFER: {
        return {
          ...state,
          offerDetails: action.payload,
        };
      }
      case GET_ALL_TRAVELER_OFFERS: {
        return {
          ...state,
          allTravellerOffers: action.payload,
        };
      }
      case GET_USER_TRAVELER_OFFERS: {
        return {
          ...state,
          userTravellerOffers: action.payload,
        };
      }
      case GET_ALL_SENDER_OFFERS: {
        return {
          ...state,
          allSenderOffers: action.payload,
        };
      }
      case TRAVELER_OFFERS: {
        return {
          ...state,
          travellerOffersList: action.payload,
        };
      }
      case SENDER_OFFERS: {
        return {
          ...state,
          senderOffersList: action.payload,
        };
      }
      default: {
        return {
          ...state,
        };
      }
    }
  };
  
  export default TravellerOfferReducer;
  