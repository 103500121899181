import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../Component/Header";
import LoggedinNavbar from "../Component/LoggedinNavbar";
import SecondNav from "./SecondNav";

export default function Term() {
  const token = localStorage.getItem("token");
  const { t } = useTranslation();
  return (
    <>
      <LoggedinNavbar />
      {/* <SecondNav /> */}
      <div className="container pt-5 mb-5 pb-5">
        <div className="row justify-content-center head-about mt-4 mb-4">
          <div className="col-11">
            <div className="text-center">
              <h1>{t("Terms Of Use")}</h1>
            </div>
            <div className="mt-5">
              <div className="mt-4">
                <h4 style={{ fontWeight: "600" }}>{t("Introduction")}</h4>
                <p>
                  {t("Welcome to Viahop")}! {t("By using our services")},
                  {t("you agree to our Terms and Conditions")}.{" "}
                  {t(
                    "Viahop provides a platform which allows Senders to post sender offers for his/her shipments and Travellers to bid on and accept those requests as well as create their own traveller offers .These Terms and Conditions govern your use of the Viahop platform."
                  )}
                </p>
              </div>
              <div className="mt-4">
                <h4 style={{ fontWeight: "600" }}>{t("Definitions")}</h4>
                <p>
                  {t(
                    "The following definitions apply to these Terms and Conditions"
                  )}
                  :
                </p>
                <p>
                  “{t("Sender")}”:{" "}
                  {t(
                    "A person who posts a request for a delivery on the Viahop platform or accepts a Travelle offer"
                  )}
                  .
                </p>
                <p>
                  “{t("Traveller")}”:{" "}
                  {t(
                    "A person who travels and either accepts senders offer or creates a traveller offer that was then accepted by sender on the Viahop platform"
                  )}
                  .
                </p>
                <p>
                  “{t("Viahop Platform")}”:{" "}
                  {t(
                    "The online platform operated by Viahop which allows Senders and Travellers to interact and transact"
                  )}
                  .
                </p>
              </div>
              <div className="mt-4">
                <h4 style={{ fontWeight: "600" }}>{t("Services")}</h4>
                <p>
                  {t(
                    "Viahop provides a platform which allows Senders and Travellers to interact and transact"
                  )}
                  .{" "}
                  {t(
                    "Senders can post requests for deliveries and Travellers can bid on and accept those requests and vice versa"
                  )}
                  .{" "}
                  {t(
                    "The services provided by Viahop are limited to the Platform and do not include any additional services"
                  )}
                  .
                </p>
              </div>
              <div className="mt-4">
                <h4 style={{ fontWeight: "600" }}>{t("Agreement")}</h4>
                <p>
                  {t("By using the Viahop Platform")},{" "}
                  {t(
                    "Senders and Travellers agree to be bound by these Terms and Conditions"
                  )}
                  .
                </p>
              </div>
              <div className="mt-4">
                <h4 style={{ fontWeight: "600" }}>{t("Eligibility")}</h4>
                <p>
                  {t("To use the Viahop Platform")},{" "}
                  {t(
                    "Senders and Travellers must be at least 18 years old and must provide all information requested by Viahop in order to use the Platform"
                  )}
                  .
                </p>
              </div>
              <div className="mt-4">
                <h4 style={{ fontWeight: "600" }}>{t("Payment")}</h4>
                <p>
                  {t(
                    "Payment for deliveries is handled through the Viahop Platform"
                  )}
                  .
                  {t(
                    "All payments must be made in full before a delivery is completed"
                  )}
                  .{" "}
                  {t(
                    "Payment to the Traveller might depend on the residency and payment method selected by Traveller"
                  )}
                  .
                </p>
              </div>
              <div className="mt-4">
                <h4 style={{ fontWeight: "600" }}>{t("Delivery")}</h4>
                <p>
                  {t(
                    "Viahop does not guarantee delivery times and the responsibility for delivering items lies solely with the Traveller"
                  )}
                  .{" "}
                  {t(
                    "Viahop is not responsible for any delays or failures in delivery"
                  )}
                  .
                </p>
              </div>
              <div className="mt-4">
                <h4 style={{ fontWeight: "600" }}>{t("Cancellation")}</h4>
                <p>
                  {t(
                    "Senders may cancel their delivery request at any time prior to the Traveller accepting the request"
                  )}
                  .{" "}
                  {t(
                    "If a delivery request is cancelled after the Traveller has accepted it"
                  )}
                  ,{" "}
                  {t(
                    "the Traveller may be entitled to a cancellation fee as determined by Viahop"
                  )}
                  .
                </p>
              </div>
              <div className="mt-4">
                <h4 style={{ fontWeight: "600" }}>{t("Liability")}</h4>
                <p>
                  {t(
                    "Viahop is not liable for any damages or losses incurred by Senders or Travellers as a result of using the Platform"
                  )}
                  .
                </p>
              </div>
              <div className="mt-4">
                <h4 style={{ fontWeight: "600" }}>{t("Indemnification")}</h4>
                <p>
                  {t("Senders and Travellers agree to indemnify")},{" "}
                  {t("defend")},{" "}
                  {t("and hold harmless Viahop from any and all claims")},{" "}
                  {t("damages")}, {t("liabilities")}, {t("costs")},{" "}
                  {t("and expenses")},{" "}
                  {t(
                    "including attorney fees arising out of or related to their use of the Platform"
                  )}
                  ..
                </p>
              </div>
              <div className="mt-4">
                <h4 style={{ fontWeight: "600" }}>{t("Termination")}</h4>
                <p>
                  {t("Viahop may terminate this Agreement at any time")},{" "}
                  {t("with or without cause")}.
                </p>
              </div>
              <div className="mt-4">
                <h4 style={{ fontWeight: "600" }}>{t("Changes")}</h4>
                <p>
                  {t(
                    "Viahop reserves the right to change these Terms and Conditions at any time"
                  )}
                  .{" "}
                  {t(
                    "Any changes will be effective immediately upon being posted on the Viahop Platform"
                  )}
                  .
                </p>
              </div>
              <div className="mt-4">
                <h4 style={{ fontWeight: "600" }}>{t("Governing Law")}</h4>
                <p>
                  {t(
                    "These Terms and Conditions shall be governed by and construed in accordance with the laws of the United Arab Emirates"
                  )}
                  .
                </p>
              </div>
              <div className="mt-4">
                <h4 style={{ fontWeight: "600" }}>{t("Contact")}</h4>
                <p>
                  {t(
                    "If you have any questions or comments about these Terms and Conditions"
                  )}
                  , {t("please contact us at")} support@viahop.net.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
