import axios from "axios";
import { ApiUrl } from "../../ApiUrl";
import {
  GET_USER_DETAIL,
  GET_ALL_STATES,
  GET_OFFER_CHAT_MESSAGES,
  GET_DEVICE_TOKEN,
} from "../types";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

export const getUserData = () => (dispatch) => {
  const accessToken = localStorage.getItem("token");

  var config = {
    method: "post",
    url: ApiUrl.server_url + "auth/get-user",
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  };

  axios(config).then((res) => {
    if (!res.data.status) {
      localStorage.removeItem("token");
    }
    dispatch({ type: GET_USER_DETAIL, payload: res.data });
  });
};
export const setDeviceToken = (data) => (dispatch) => {
  dispatch({ type: GET_DEVICE_TOKEN, payload: data });
};

export const getStates = () => (dispatch) => {
  const accessToken = localStorage.getItem("token");
  axios
    .get(ApiUrl.server_url + "v1/states/", {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((res) => {
      dispatch({ type: GET_ALL_STATES, payload: res.data });
    });
};

export const verifyUserEmail = (data, handleResponse) => (dispatch) => {
  const accessToken = localStorage.getItem("token");
  const allData = { ...data, lang: localStorage.getItem("i18nextLng") };
  axios
    .post(
      ApiUrl.server_url + "auth/verify-email-send-otp",
      allData,

      { headers: { Authorization: `Bearer ${accessToken}` } }
    )
    .then((res) => {
      dispatch(getUserData());
      handleResponse(res.data);
    })
    .catch((error) => {
      handleResponse(error?.response?.data);
    });
};

export const verifyEmailOtp = (data, handleResponse) => (dispatch) => {
  const accessToken = localStorage.getItem("token");
  axios
    .post(
      ApiUrl.server_url + "auth/verify-email-verify-otp",
      data,

      { headers: { Authorization: `Bearer ${accessToken}` } }
    )
    .then((res) => {
      dispatch(getUserData());
      handleResponse(res.data);
    })
    .catch((error) => {
      handleResponse(error?.response?.data);
    });
};

//Phone Number Verification
export const verifyUserPhone = (data, handleResponse) => (dispatch) => {
  const accessToken = localStorage.getItem("token");
  axios
    .post(
      ApiUrl.server_url + "auth/verify-phone-send-otp",
      data,

      { headers: { Authorization: `Bearer ${accessToken}` } }
    )
    .then((res) => {
      dispatch(getUserData());
      handleResponse(res.data);
    })
    .catch((error) => {
      handleResponse(error?.response?.data);
    });
};

// Reset Password Otp
export const getResetPasswordOtp = (data, handleResponse) => (dispatch) => {
  // const accessToken = localStorage.getItem("token");
  axios
    .post(
      ApiUrl.server_url + "auth/forgot-password-send-otp",
      data

      // { headers: { Authorization: `Bearer ${accessToken}` } }
    )
    .then((res) => {
      dispatch(getUserData());
      handleResponse(res.data);
      if (res.data.status === false) {
        toast.error("Phone number not found...!");
      }
    })
    .catch((error) => {
      handleResponse(error?.response?.data);
    });
};

//Verify Passport Image
// Reset Password Otp
export const uploadPassportImageToViahopServer =
  (data, handleResponse) => (dispatch) => {
    const accessToken = localStorage.getItem("token");

    axios
      .post(
        ApiUrl.server_url + "auth/upload-id-image",
        data,

        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((res) => {
        handleResponse(res.data);
      })
      .catch((error) => {
        handleResponse(error?.response?.data);
      });
  };

export const uploadPassportImage = (data, handleResponse) => (dispatch) => {
  const accessToken = localStorage.getItem("token");

  const formData = new FormData();
  formData.append("image", data);
  axios
    .post(
      "https://images.uplandsinspire.com/api/image/upload",
      formData,

      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((res) => {
      handleResponse(res.data);
      if (res.data.status) {
        console.log(
          JSON.stringify(res?.data?.data) + "=======image server response"
        );

        dispatch(
          uploadPassportImageToViahopServer({
            imageUrl: res?.data?.data?.image_url,
          })
        );
      }
    })
    .catch((error) => {
      handleResponse(error?.response?.data);
    });
};

//Verify Forgot Password
export const verifyForgotOtp = (data, handleResponse) => (dispatch) => {
  const accessToken = localStorage.getItem("token");
  const allData = { ...data, lang: localStorage.getItem("i18nextLng") };
  axios
    .post(
      ApiUrl.server_url + "auth/forgot-password-verify-otp",
      allData,

      { headers: { Authorization: `Bearer ${accessToken}` } }
    )
    .then((res) => {
      dispatch(getUserData());
      handleResponse(res.data);
    })
    .catch((error) => {
      handleResponse(error?.response?.data);
    });
};

export const verifyPhoneOtp = (data, handleResponse) => (dispatch) => {
  const accessToken = localStorage.getItem("token");
  axios
    .post(
      ApiUrl.server_url + "auth/verify-phone-verify-otp",
      data,

      { headers: { Authorization: `Bearer ${accessToken}` } }
    )
    .then((res) => {
      dispatch(getUserData());
      handleResponse(res.data);
    })
    .catch((error) => {
      handleResponse(error?.response?.data);
    });
};

export const completeProfileUser = (data, handleResponse) => (dispatch) => {
  const accessToken = localStorage.getItem("token");
  axios
    .post(ApiUrl.server_url + "auth/update-user", data, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((res) => {
      dispatch(getUserData());
      handleResponse(res.data);
    })
    .catch((error) => {
      handleResponse(error?.response?.data);
    });
};
// Reset Password
export const resetPassword = (data, handleResponse) => (dispatch) => {
  const accessToken = localStorage.getItem("token");
  axios
    .post(ApiUrl.server_url + "auth/reset-password", data, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((res) => {
      dispatch(getUserData);
      handleResponse(res.data);
    })
    .catch((error) => {
      handleResponse(error?.response?.data);
    });
};

// Send mail to support
export const sendSupportMail = (data, handleResponse) => (dispatch) => {
  const accessToken = localStorage.getItem("token");
  axios
    .post(ApiUrl.server_url + "auth/support-message", data, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((res) => {
      dispatch(getUserData());
      handleResponse(res.data);
    })
    .catch((error) => {
      handleResponse(error?.response?.data);
    });
};

// Chat messages
export const getOfferChatMessages = (data, handleResponse) => (dispatch) => {
  const accessToken = localStorage.getItem("token");
  axios
    .post(
      ApiUrl.server_url + "auth/offer-chat",
      data,

      { headers: { Authorization: `Bearer ${accessToken}` } }
    )
    .then((res) => {
      // dispatch(getUserData());
      dispatch({ type: GET_OFFER_CHAT_MESSAGES, payload: res.data });

      handleResponse(res?.data);
    })
    .catch((error) => {
      handleResponse(error?.response?.data);
    });
};

export const sendChatMessage = (data, handleResponse) => (dispatch) => {
  const accessToken = localStorage.getItem("token");
  axios
    .post(
      ApiUrl.server_url + "auth/chat-create",
      data,

      { headers: { Authorization: `Bearer ${accessToken}` } }
    )
    .then((res) => {
      let tempData = {
        startFromId: 0,
        offerId: data?.offer_id,
        offerType: data?.offer_type,
      };
      // dispatch(getOfferChatMessages(tempData));
      dispatch({ type: GET_OFFER_CHAT_MESSAGES, payload: res.data });

      handleResponse(res.data);
    })
    .catch((error) => {
      handleResponse(error?.response?.data);
    });
};
export const sendChatNotification = (data, handleResponse) => (dispatch) => {
  axios
    .post("https://fcm.googleapis.com/fcm/send", data, {
      headers: {
        Authorization: `key=AAAA_bZ5LEY:APA91bEvhraf1nDzIrS4AoJaiRfB2ZZTP7HH_6xxppVc4br6dlxKjS0r09gaiyynGr4wGZ03d1xrwAhrsx4MIMry_thzh5YMRjuoJzDF9W-o_pf00rmVxN0RST-_cgiDXtd8DR0RDMO-`,
      },
    })
    .then((res) => {
      handleResponse(res.data);
    })
    .catch((error) => {
      handleResponse(error?.response?.data);
    });
};
