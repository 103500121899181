import { GET_ALL_COUNTRIES } from "../types";

const initialState = {
  allCountriesList: [],
};

const CountriesReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_COUNTRIES: {
      return {
        ...state,
        allCountriesList: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
export default CountriesReducer;