import { Col, Row } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { useTranslation } from "react-i18next";
import "../SamllComponent/Skidertes.css";
// import elboy from '../Assets/Images/elboy.png'

function SliderTestmonuial() {
  const { t } = useTranslation();
  return (
    <Carousel variant="dark slider-mt" controls={true}>
      <Carousel.Item className="px-5">
        <p className="customParaHeightForSlider text-center py-3 fontsi12-res">
          “
          {t(
            "I recently used Viahop to deliver a package and I was very pleased with the experience"
          )}
          .{" "}
          {t(
            "The process was easy to use and the delivery was fast and secure"
          )}
          .{" "}
          {t(
            "I would definitely recommend Viahop to anyone looking for a reliable courier service"
          )}
          .”
        </p>
        <Row className="py-3 justify-content-center">
          {/* <Col lg={4} md={4} sm={12}>
            <div className="langimg-ml">
            <img src={elboy} alt="elboy" className="slidr-imgres" />
            </div>
          </Col> */}

          <Col lg={4} md={4} sm={12}>
            <div className="text-center">
              <p className="fontSize18-700 pb-0">{t("Natalia")}</p>
              <p className="fontSize16-400">{t("Traveler")}</p>
            </div>
          </Col>
        </Row>
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item className="px-5">
        <p className="customParaHeightForSlider text-center py-3 fontsi12-res">
          “{t("Happy to be a traveler")}.{" "}
          {t(
            "Sender was very friendly and provided all the necessary information"
          )}
          .{" "}
          {t(
            "I was paid quick and would recommend Viahop to anyone looking for a way to make extra income while travelling"
          )}
          .”
        </p>
        <Row className="py-3 justify-content-center">
          {/* <Col lg={4} md={4} sm={12}>
          <div className="text-right langimg-ml" >
          <img src={elboy} alt="elboy"  className="slidr-imgres" />
          </div>
          </Col> */}
          <Col lg={4} md={4} sm={12}>
            <div className="text-center">
              <p className="fontSize18-700 pb-0">{t("Ali")}</p>
              <p className="fontSize16-400">{t("Traveller")}</p>
            </div>
          </Col>
        </Row>
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item className="px-5">
        <p className="customParaHeightForSlider text-center py-3 fontsi12-res">
          “{t("Used Viahop to send a package to my family in another country")}.{" "}
          {t(
            "Traveller was very professional and delivered the package on time"
          )}
          . {t("I was also able to save money on courier services costs")}.{" "}
          {t("A")}+”
        </p>
        <Row className="py-3 justify-content-center">
          {/* <Col lg={4} md={4} sm={12}>
            <div className="langimg-ml"> 
            <img src={elboy} alt="elboy"  className="slidr-imgres" />
            </div>
          </Col> */}
          <Col lg={4} md={4} sm={12}>
            <div className="text-center">
              <p className="fontSize18-700 pb-0">{t("Rohan")}</p>
              <p className="fontSize16-400">{t("Sender")}</p>
            </div>
          </Col>
        </Row>
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item className="px-5">
        <p className="customParaHeightForSlider text-center py-3 fontsi12-res">
          “
          {t(
            "Was looking for such platform for a long time. Sent multiple documents. Looking forward to send many more"
          )}
          .”
        </p>
        <Row className="py-3 justify-content-center">
          {/* <Col lg={4} md={4} sm={12}>
            <div className="langimg-ml"> 
            <img src={elboy} alt="elboy"  className="slidr-imgres" />
            </div>
          </Col> */}
          <Col lg={4} md={4} sm={12}>
            <div className="text-center">
              <p className="fontSize18-700 pb-0">{t("Ayesha")}</p>
              <p className="fontSize16-400">{t("Sender")}</p>
            </div>
          </Col>
        </Row>
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default SliderTestmonuial;
