// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBkRga3sVvIS09884ZubGUkWwoVtFAr9Bw",
  authDomain: "viahop.firebaseapp.com",
  projectId: "viahop",
  storageBucket: "viahop.appspot.com",
  messagingSenderId: "1089688120390",
  appId: "1:1089688120390:web:840d013113e9455674ae47",
  measurementId: "G-C3V0ZP2Z89",
};

// Initialize Firebase

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getFirebaseToken = async (handleDeviceToken) => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BMDGQ7VK17q9dPWQVXvabWZmNRcSFNbzjGLKAgP3Q7TtcUq_QAqSiq6mXYoKLoVk8ctQzBdcHX22rQOjcPcR754",
    });
    if (currentToken) {
      console.log("current token for client: ", currentToken);
      handleDeviceToken(currentToken);
      // return currentToken;
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
    // return err;
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });
