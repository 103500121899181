import { LOGIN_DATA, GET_USER_DETAIL } from "../types";
import { ApiUrl } from "../../ApiUrl";
import axios from "axios";
// import { useNavigate } from "react-router-dom";
export const LogInAction = (data, handleLoginFormData) => (dispatch) => {
  //   const navigator = useNavigate();

  axios
    .post(`${ApiUrl.server_url}auth/signin`, data)
    .then((res) => {
      dispatch({ type: GET_USER_DETAIL, payload: res.data });
      handleLoginFormData(res.data);
      dispatch({
        type: LOGIN_DATA,
        payload: res.data,
      });
    })
    .catch((error) => {
      console.log(error + "=======error");
      handleLoginFormData(error);
    });
};

export const RegisterAction = (data, handleLoginFormData) => (dispatch) => {
  //   const navigator = useNavigate()

  axios
    .post(`${ApiUrl.server_url}auth/signup`, data)
    .then((res) => {
      handleLoginFormData(res.data);
      dispatch({
        type: LOGIN_DATA,
        payload: res.data,
      });
    })
    .catch((error) => {
      console.log(error + "=======error");
      handleLoginFormData(error);
    });
};

export const getUserData = () => (dispatch) => {
  const accessToken = localStorage.getItem("token");

  var config = {
    method: "post",
    url: ApiUrl.server_url + "auth/get-user",
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  };

  axios(config).then((res) => {
    dispatch({ type: GET_USER_DETAIL, payload: res.data });
  });
};
