import React from "react";
import { Label, Button, InputGroup, Row } from "reactstrap";

import "../SamllComponent/MultitabBtn.css";
import { Col, Form } from "react-bootstrap";
import CounterMultiThree from "./CounterMultiThree";
import { BsFillCalendarCheckFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const Step2Three = ({ currentStep, getOfferTime, getOfferDays }) => {
  const { t } = useTranslation();

  if (currentStep !== 2) {
    return null;
  }
  return (
    <div className="pt-5">
      <div className="multiform-postion">
        <div className="lightorage-color my-4 text-center">
          <h1 className=" py-3 fontSize24-600 orange-color">
            {t("Create Traveler Offer")}
          </h1>
        </div>
        <div className="text-center pt-3">
          <Button
            className="orange-background fontSize16-500 bordt-none"
            size="md"
            type="button"
          >
            {t("Select Time Period")}
          </Button>
        </div>
      </div>
      <div className="pt-115-thrdform">
        {/* <Form>
          <Form.Group
            className="mb-3 d-flex justify-content-center"
            controlId="formBasicCheckbox"
          >
            <Form.Check type="checkbox" label="As soon as possible" />
            <Form.Check
              type="checkbox"
              className="ms-4"
              label="As soon as possible"
            />
            <BsFillCalendarCheckFill className="orange-color font25-icn ms-3" />
          </Form.Group>
         
        </Form> */}
        <Label for="email" className="fontSize14-600 dimgray-color">
          {t("Date")}
          <span style={{ color: "red" }}>*</span>
        </Label>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <InputGroup className="mb-3">
              <Form.Control
                type="date"
                name="offerTime"
                aria-label="Date"
                aria-describedby="basic-addon2"
                onChange={getOfferTime}
                min={new Date().toISOString().split("T")[0]}
                onKeyDown={(e) => {
                  // e.preventDefault();
                }}
              />
            </InputGroup>
          </Col>
          <Col
            className="mt-1"
            lg={6}
            md={6}
            sm={12}
            style={{ alignItems: "center" }}
          >
            <CounterMultiThree getOfferDays={getOfferDays} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Step2Three;
