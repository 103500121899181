import React, { useState, useEffect, useTransition } from "react";

import Swal from "sweetalert2";
import button_loader from "../Assets/Loader/button_loader.gif";
// ... or with es2015
import LoadingScreen from "react-loading-screen";
import { Col, Container, FormGroup, Row } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import "../Assets/Css/Sidebar.css";
import { FiUser, FiLock } from "react-icons/fi";
import { MdOutlineVerifiedUser, MdMailOutline } from "react-icons/md";
import { BsBox } from "react-icons/bs";
import { IoIosAirplane } from "react-icons/io";
import { TbHeartHandshake } from "react-icons/tb";
import OtpCom from "../Otp/OtpCom";
// import otpImage from "../Assets/Images/otp.png";
import verificationImg from "../Assets/Images/verificationImg.jpg";
import verificationPhoneImg from "../Assets/Images/phoneVerification.svg";
// import logoo from "../Assets/Images/logoo1.png";
import congratulation from "../Assets/Images/congratulation.png";

import { BiLogIn } from "react-icons/bi";
import elboy from "../Assets/Images/default.png";
import user_logo from "../Assets/Images/user_logo.svg";
import { Alert, Form, Input, InputGroup, Label } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Dropzone from "../Component/Dropzone";
import { Button, Modal, ModalBody } from "reactstrap";
import { BsSearch } from "react-icons/bs";
import MyTravellerOffers from "../SamllComponent/MyTravellerOffers";
import SeeMyRating from "../SamllComponent/SeeMyRating";
import MySenderOffers from "../SamllComponent/MySenderOffers";
import nameAndLogo from "../Assets/Images/logoo1.png";

import {
  getUserData,
  completeProfileUser,
  verifyUserEmail,
  sendSupportMail,
  verifyUserPhone,
  uploadPassportImage,
  resetPassword,
  sendChatNotification,
} from "../../redux/actions/UserAction";
import { useDispatch, useSelector } from "react-redux";
import { data } from "jquery";
import PayTheBill from "../SamllComponent/PayTheBill";
import { useTranslation } from "react-i18next";

function MainProfile(props) {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [verificationModal, setVerificationModal] = useState(false);
  const [verificationModalText, setVerificationModalText] = useState();
  const [type, setType] = useState("e");
  const [modalOne, setModalOne] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [phoneSuccessModal, setPhoneSuccessModal] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [emailError, setEmailError] = useState("");
  const { t } = useTranslation();

  const phoneSuccessToggle = () => {
    setVerificationModal(false);
    setPhoneSuccessModal(!phoneSuccessModal);
  };

  const successToggle = () => setSuccessModal(!successModal);
  const toggle = () => setModal(!modal);
  const verificationToggle = () => setVerificationModal(!verificationModal);
  const handleClick = () => {
    setVerificationModal(!verificationModal);
    setSuccessModal(true);
  };
  const toggleOne = () => setModalOne(!modalOne);

  const [isReadonly, setIsReadonly] = useState(true);
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [lang, setLang] = useState("en");

  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [isPhoneVerify, setPhoneVerify] = useState(null);
  const [isEmailVerify, setEmailVerify] = useState(null);
  const [idImage, setIdImage] = useState(null);

  const [isIDVerify, setIDVerify] = useState(null);
  const [otp, setOtp] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [userProfilePic, setUserProfilePic] = useState();
  const [buttonLoader, setButtonLoader] = useState(false);
  // const [phone, setPhone] = useState(null)
  // const [phoneError, setPhoneError] = useState(null)

  const [passportImage, setPassportImage] = useState();
  const [placeholderText, setPlaceholderText] = useState(
    t("Please upload your Passport ID here")
  );

  const [imageUploading, setImageUploading] = useState(false);
  const { userDetails } = useSelector((state) => state.UserReducer);

  const location = useLocation();
  console.log(location?.search.split("=")[1], "asdasdadasdsad");
  const locationState = location?.search.split("=")[1];

  useEffect(() => {
    if (firstName) {
      setLoading(false);
    }
  }, [firstName]);
  useEffect(() => {
    // dispatch(getUserData());
  }, []);

  useEffect(() => {
    setPhoneVerify(userDetails?.user?.is_phone_verified);
    setEmailVerify(userDetails?.user?.is_email_verified);
    setIdImage(userDetails?.user?.id_image);
    setIDVerify(userDetails?.user?.is_id_verified);

    // console.log(JSON.stringify(userDetails.data)+"===================Token Data")
    setFirstName(userDetails?.user?.firstName);
    setLastName(userDetails?.user?.lastName);

    setMobileNumber(userDetails?.user?.phone_no);
    setEmail(userDetails?.user?.email);
    setAddress(userDetails?.user?.address);
    // setBio(userDetails?.user?.bio);
    setUserProfilePic(userDetails?.user?.avatar);
  }, [userDetails]);
  function logout() {
    localStorage.removeItem("token");
    navigate("/");
  }

  const handleOtp = (data) => {
    console.log(data);
  };

  const handlefirstNameChange = (event) => {
    setFirstName(event.target.value);
  };
  const handlelastNameChange = (event) => {
    setLastName(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePhoneChange = (event) => {
    setMobileNumber(event.target.value);
  };
  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  // const handleBioChange = (event) => {
  //   setBio(event.target.value);
  // };

  const handleResponse = (data) => {
    if (data.status) {
      // setVerificationModal(false)

      setIsReadonly(true);
      setShowPasswordField(false);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{3,})$/i;
    if (regex.test(email) === false) {
      setEmailError("Your email is not Valid");
    } else {
      setEmailError("");
      setLoading(true);
      let data = {
        firstname: firstName,
        lastname: lastName,
        email: email,
        phone_no: mobileNumber,
        address: address,
      };
      dispatch(completeProfileUser(data, handleResponse));
    }
  };
  const handleAddressSubmit = (e) => {
    let data = {
      firstname: firstName,
      lastname: lastName,
      email: email,
      phone_no: mobileNumber,
      address: address,
    };
    dispatch(completeProfileUser(data, handleResponse));
  };
  const verifyPhone = () => {
    setLoading(true);
    setVerificationModalText(t("Phone Verification"));
    let data = {
      phone: mobileNumber,
    };
    dispatch(verifyUserPhone(data, handleResponseVerification));
    setType("p");
  };
  const handleResponseVerification = (data) => {
    setLoading(false);
    if (data.status) {
      // setVerificationModal(false)
      verificationToggle(true);
    } else {
      Swal.fire({
        title: t("Failed"),
        type: t("failed"),
        text: "Please Provide a Valid Email",
      });
    }
  };

  const handleOtpResponse = (data) => {
    if (data.status) {
      // setVerificationModal(false)
      setIsReadonly(true);
      setShowPasswordField(false);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  const handleFilesAdded = (res) => {
    console.log(res + "====image select");
    setPassportImage(res);
  };
  const handleCloseModalOne = () => {
    setModalOne(false);
  };
  const verifyPassportImage = () => {
    if (!passportImage) return Swal.fire(t("please upload an image...!"));
    setButtonLoader(true);
    dispatch(uploadPassportImage(passportImage, handlePassportResponse));
  };
  const handlePassportResponse = (data) => {
    // console.log(JSON.stringify(data)+"=====image server response")
    setButtonLoader(false);
    Swal.fire({
      title: t("Success"),
      type: "success",
      text: t("Passport is Submitted For Verification"),
    });
    setPlaceholderText(t("Waiting For Approval"));
    setTimeout(() => {
      setModalOne(false);
    }, 3000);
  };
  const verifyEmail = () => {
    setLoading(true);
    // verificationToggle(true)
    setVerificationModalText(t("Email Verification"));
    let data = {
      email: email,
    };
    dispatch(verifyUserEmail(data, handleResponseVerification));
    setType("e");
  };

  // ----------------
  // const [value, setValue] = useState()
  const [text, setText] = useState(null);
  const [newpassword, setNewpassword] = useState(null);
  const [confirmpass, setConfirmpass] = useState(null);
  const handlecheack = () => {
    if (newpassword != confirmpass) {
      setErrorText(t("New Password and Confirm Password dose not match"));
    } else if (text === newpassword) {
      setErrorText(t("Old and New password should be different"));
    } else {
      setButtonLoader(true);
      setErrorText("");
      let data = {
        oldPassword: text,
        newPassword: newpassword,
      };
      dispatch(resetPassword(data, handleResetPasswordResponse));
    }
  };
  const handleResetPasswordResponse = (data) => {
    setButtonLoader(false);
    if (data.status) {
      handleClearClick();
      Swal.fire({
        title: t("Success"),
        type: t("success"),
        text: data.message,
      });
    } else {
      Swal.fire({
        title: t("Failed"),
        type: t("failed"),
        text: data.message,
      });
    }
  };

  const handleClearClick = () => {
    let newText = "";
    setText(newText);
    let newVal = "";
    setNewpassword(newVal);
    let newconfir = "";

    setConfirmpass(newconfir);
  };

  // Support Parameters
  const [name, setName] = useState();
  const [mail, setMail] = useState(userDetails?.email);
  const [textarea, setTextarea] = useState(null);
  useEffect(() => {
    // if (name === "") {
    setName(userDetails?.user?.firstName);
    setMail(userDetails?.user?.email);
    // }
  }, [userDetails]);

  const handelClear = () => {
    let newName = "";
    setName(newName);
    let newMail = "";
    setMail(newMail);
    let newArea = "";
    setTextarea(newArea);
  };
  const handleSupportData = () => {
    if (name == null && mail == null && textarea == null) {
      console.log("please fill the fileds");
    } else {
      setLoading(true);
      let data = {
        fullName: name,
        email: mail,
        message: textarea,
      };
      dispatch(sendSupportMail(data, handleSupportResponse));
    }
    setTextarea("");
  };
  const handleSupportResponse = (data) => {
    console.log(JSON.stringify(data) + "==========support api response");
    if (data.status) {
      setLoading(false);
      Swal.fire({
        title: t("Success"),
        type: t("success"),
        text: "Your Message is Conveyed",
      });
    } else {
      setLoading(false);
      Swal.fire({
        title: t("Failed"),
        type: t("failed"),
        text: "Oops! Your Request is Denied",
      });
    }
  };
  console.log(locationState?.activeKey, "locationState?.activeKey");

  return (
    <div>
      <LoadingScreen
        loading={loading}
        bgColor="#f1f1f1"
        spinnerColor="#9ee5f8"
        textColor="#676767"
        logoSrc={nameAndLogo}
        text={t("Hang tight while we do our magic!")}
      >
        {/* <div>Loadable content</div> */}
        <section className="bg-white">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={locationState ? locationState : "first"}
          >
            <Container fluid className="ml-oprofile ">
              <Row>
                <Col lg={3} md={3} sm={3} className="borderRight pe-0">
                  <div className="text-center pt-md-5">
                    <img
                      src={user_logo}
                      alt="elboy"
                      style={{ width: "110px" }}
                    />
                    <p className="fontSize16-500 pt-md-3">
                      {firstName + " " + lastName}
                    </p>
                  </div>
                  <Nav
                    variant=""
                    activeKey={locationState ? locationState : "first"}
                    className="flex-column"
                  >
                    <Nav.Item className="borderBottom">
                      <Nav.Link
                        eventKey="first"
                        className="fontSize16-500 font11-res text-dark d-flex align-items-center"
                      >
                        <p className="mb-0">
                          <FiUser className="me-4" />
                        </p>
                        {t("Profile")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="borderBottom">
                      <Nav.Link
                        eventKey="second"
                        className="fontSize16-500 text-dark font11-res d-flex align-items-center"
                      >
                        <p className="mb-0">
                          <MdOutlineVerifiedUser className="me-4" />
                        </p>
                        {t("Verifications")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="borderBottom">
                      <Nav.Link
                        eventKey="third"
                        className="fontSize16-500 text-dark font11-res d-flex align-items-center"
                      >
                        <p className="mb-0">
                          <BsBox className="me-4" />
                        </p>
                        {t("My Sender Orders")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="borderBottom">
                      <Nav.Link
                        eventKey="fourth"
                        className="fontSize16-500 text-dark font11-res d-flex align-items-center"
                      >
                        <p className="mb-0">
                          <IoIosAirplane className="me-4" />
                        </p>
                        {t("My Traveller Offers")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="borderBottom">
                      <Nav.Link
                        eventKey="nineth"
                        className="fontSize16-500 text-dark font11-res d-flex align-items-center"
                      >
                        <p className="mb-0">
                          <i className="me-4 fa-regular fa-star"></i>
                        </p>
                        {t("My Ratings")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="borderBottom">
                      <Nav.Link
                        eventKey="tenth"
                        className="fontSize16-500 text-dark font11-res d-flex align-items-center"
                      >
                        <p className="mb-0">
                          <i className="me-4 fa-regular fa-credit-card"></i>
                        </p>
                        {t("My Payments")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="borderBottom">
                      <Nav.Link
                        eventKey="fifth"
                        className="fontSize16-500 text-dark font11-res d-flex align-items-center"
                      >
                        <p className="mb-0">
                          <TbHeartHandshake className="me-4" />
                        </p>
                        {t("Support")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="borderBottom">
                      <Nav.Link
                        eventKey="six"
                        className="fontSize16-500 text-dark font11-res d-flex align-items-center"
                      >
                        <p className="mb-0">
                          <FiLock className="me-4" />
                        </p>
                        {t("Security Settings")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="borderBottom">
                      <Nav.Link
                        className="fontSize16-500 text-dark font11-res d-flex align-items-center"
                        onClick={logout}
                      >
                        <p className="mb-0">
                          <BiLogIn className="me-4" />
                        </p>
                        {t("Logout")}
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>

                <Col lg={9} md={9} sm={9} className="">
                  <Tab.Content className="profilfe-padl30px pt-5 px-3">
                    <Tab.Pane eventKey="first">
                      <Row>
                        <Col lg={12} md={12} sm={12}>
                          <div className="lightorage-color text-center">
                            <h1 className=" py-3 fontSize24-600 orange-color">
                              {t("Profile Settings")}
                            </h1>
                          </div>
                          <div className="pt-5">
                            <Form onSubmit={handleSubmit}>
                              <Row>
                                <Col lg={6} md={6} sm={12}>
                                  <FormGroup>
                                    <Label
                                      for="fname"
                                      className="fontSize14-600 dimgray-color"
                                    >
                                      {" "}
                                      {t("First Name")}{" "}
                                    </Label>
                                    <Input
                                      className="kasy"
                                      onChange={handlefirstNameChange}
                                      value={firstName}
                                      id="fname"
                                      name="fname"
                                      type="text"
                                      required
                                    />
                                    <FiUser className="mail-icon-profile" />
                                  </FormGroup>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                  <FormGroup>
                                    <Label
                                      for="fname"
                                      className="fontSize14-600 dimgray-color"
                                    >
                                      {" "}
                                      {t("Last Name")}{" "}
                                    </Label>
                                    <Input
                                      className="kasy"
                                      onChange={handlelastNameChange}
                                      value={lastName}
                                      id="fname"
                                      name="fname"
                                      type="text"
                                      required
                                    />
                                    <FiUser className="mail-icon-profile" />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={6} md={6} sm={12}>
                                  <FormGroup className="pt-3">
                                    <Label
                                      for="email"
                                      className="fontSize14-600 dimgray-color"
                                    >
                                      {" "}
                                      {t("Email")}{" "}
                                    </Label>
                                    <Input
                                      className="kasy"
                                      onChange={handleEmailChange}
                                      value={email}
                                      id="email"
                                      name="email"
                                      type="email"
                                      // required
                                      // pattern="/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i"
                                      // {...register("email", {
                                      //   required: true,
                                      //   pattern:
                                      //     /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                      // })}
                                    />
                                    {/* Check here too */}
                                    <MdOutlineVerifiedUser
                                      className={`mail-icon-profile ${
                                        isEmailVerify
                                          ? "text-success"
                                          : "text-danger"
                                      } `}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                  <FormGroup className="pt-3">
                                    <Label
                                      for="number"
                                      className="fontSize14-600 dimgray-color"
                                    >
                                      {" "}
                                      {t("WhatsApp Number")}{" "}
                                    </Label>

                                    <Input
                                      className="kasy"
                                      type="text"
                                      id="number"
                                      name="number"
                                      value={mobileNumber}
                                      placeholder="Enter WhatsApp Number"
                                      required
                                    />
                                    {/* Check Here  */}
                                    <MdOutlineVerifiedUser
                                      className={`mail-icon-profile ${
                                        isPhoneVerify
                                          ? "text-success"
                                          : "text-danger"
                                      }`}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              {/* <Row>
                                <Col lg={12} md={12} sm={12}>
                                  <FormGroup className="pt-3">
                                    <Label
                                      for="address"
                                      className="fontSize14-600 dimgray-color"
                                    >
                                      {" "}
                                      Address*{" "}
                                    </Label>
                                    <Input
                                      id="address"
                                      name="address"
                                      type="text"
                                      className="kasy"
                                      onChange={handleAddressChange}
                                      value={address}
                                      placeholder="This field is compulsory to fill, please enter your address"
                                      required
                                    />
                                    <MdMailOutline className="mail-icon-profile" />
                                  </FormGroup>
                                </Col>
                              </Row> */}
                              <div className="mt-2">
                                <span style={{ color: "red" }}>
                                  {emailError}
                                </span>
                              </div>
                              <div className="textright-iconf pt-5">
                                <Button
                                  type="submit"
                                  // onClick={}
                                  className="btnlanding-hov ms-4 profiletop-btnoutline mt-2 bordt-none fontSize20-600 wafa"
                                >
                                  {t("Save")}
                                </Button>
                              </div>
                            </Form>
                          </div>
                        </Col>
                      </Row>
                    </Tab.Pane>
                    {/* two */}
                    {/* <FullPageLoader /> */}

                    <Tab.Pane eventKey="second">
                      <Row>
                        <Col lg={12} md={12} sm={12}>
                          <div className="lightorage-color text-center">
                            <h1 className=" py-3 fontSize24-600 orange-color">
                              {t("Verifications")}
                            </h1>
                          </div>
                          <p>
                            {t('At Viahop, we prioritize the safety and security of our users. Identity verification is a crucial step in ensuring a trusted community and maintaining the integrity of our platform. By verifying your identity, we can provide a secure environment where senders and travelers can confidently engage in transactions, knowing that they are dealing with genuine and trustworthy individuals.')}
                          </p>
                          <p>
                            {t('Rest assured that your data is encrypted and handled with the utmost care, following strict privacy protocols. We only use your information for the purpose of identity verification and maintaining a secure platform. Your trust and peace of mind are our top priorities.')}
                          </p>
                          <div className="pt-1">
                            <Form>
                              <div className="fontSize14-600 dimgray-color pb-2">
                                {t("Email")}
                              </div>
                              <InputGroup>
                                <Input
                                  className="kasy"
                                  value={email}
                                  type="email"
                                  disabled
                                  required
                                />
                                {isEmailVerify == 1 ? (
                                  <Button type="button" className="greenback px-4 bordt-none">
                                    {t("Verified")}
                                    
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={verifyEmail}
                                    className="orange-background  bordt-none"
                                  >
                                    {t("Verify Now")}
                                  </Button>
                                )}
                              </InputGroup>
                              <div className="pt-4 pb-2 fontSize14-600 dimgray-color">
                                {t("WhatsApp Number")}
                              </div>
                              <InputGroup>
                                <Input
                                  className="kasy"
                                  value={mobileNumber}
                                  type="text"
                                  disabled
                                  required
                                />

                                {isPhoneVerify == 1 ? (
                                  <Button
                                    type="button"
                                    className="greenback px-4 bordt-none"
                                  >
                                    {t("Verified")}
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={verifyPhone}
                                    type="button"
                                    className="orange-background  bordt-none"
                                  >
                                    {t("Verify Now")}
                                  </Button>
                                )}
                              </InputGroup>
                              <div className="fontSize14-600 dimgray-color pt-4 pb-2">
                                {t("ID Verification Status")}
                              </div>
                              <InputGroup className="pb-4">
                                {isIDVerify === 1 ? (
                                  <>
                                    {/* Yahan uper isIDVrify ki jga null tha main ny 1 kr diya hy */}
                                    <Input
                                      className="kasy"
                                      placeholder={t(
                                        "Id Verified Successfully"
                                      )}
                                      disabled
                                      type="text"
                                      required
                                    />
                                    <Button type="button" className="greenback px-4 bordt-none">
                                      {t("Verified")}
                                    </Button>
                                  </>
                                ) : (idImage != null ?
                                    <>
                                    <Input
                                      className="kasy"
                                      onClick={toggleOne}
                                      type="text"
                                      disabled
                                      placeholder={t("Waiting For Approval")}
                                    />
                                    <Button className="orange-background  bordt-none" disabled>
                                      {t("Awaiting Approval")}
                                    </Button>
                                  </>:
                                  <>
                                    <Input
                                      className="kasy"
                                      onClick={toggleOne}
                                      type="text"
                                      required
                                      placeholder={t("Please upload your Passport ID here")}
                                    />
                                    <Button type="button" className="orange-background  bordt-none" disabled>
                                      {t("Verify Now")}
                                    </Button>
                                  </>
                                 )
                                 
                                }
                              </InputGroup>
                              <div className="fontSize14-600 dimgray-color pb-2">
                                {t("Address")}
                              </div>
                              <InputGroup className="pb-4">
                                <>
                                  <Input
                                    id="address"
                                    name="address"
                                    type="text"
                                    className="kasy"
                                    onChange={handleAddressChange}
                                    value={address}
                                    placeholder={t(
                                      "This field is compulsory to fill, please enter your address"
                                    )}
                                    required
                                  />
                                  <Button
                                    onClick={handleAddressSubmit}
                                    className={
                                      userDetails?.user?.address
                                        ? "greenback  bordt-none px-4 text-white"
                                        : "orange-background  bordt-none"
                                    }
                                  >
                                    {userDetails?.user?.address
                                      ? t("Verified")
                                      : t("Verify Now")}
                                  </Button>
                                </>
                              </InputGroup>
                            </Form>
                            {/* <Form>
                            <Row>
                              <Col lg={6} md={6} sm={12}>
                                <FormGroup>
                                  <Label
                                    for="fname"
                                    className="fontSize14-600 dimgray-color"
                                  >
                                    {" "}
                                    First Name{" "}
                                  </Label>
                                  <Input id="fname" name="fname" type="text" />
                                  <FiUser className="mail-icon-login" />
                                </FormGroup>
                              </Col>
                              <Col lg={6} md={6} sm={12}>
                                <FormGroup>
                                  <Label
                                    for="fname"
                                    className="fontSize14-600 dimgray-color"
                                  >
                                    {" "}
                                    First Name{" "}
                                  </Label>
                                  <Input id="fname" name="fname" type="text" />
                                  <FiUser className="mail-icon-login" />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={6} md={6} sm={12}>
                                <FormGroup className="pt-3">
                                  <Label
                                    for="email"
                                    className="fontSize14-600 dimgray-color"
                                  >
                                    {" "}
                                    Email{" "}
                                  </Label>
                                  <Input id="email" name="email" type="email" />
                                  <MdOutlineVerifiedUser className="mail-icon-login text-success" />
                                </FormGroup>
                              </Col>
                              <Col lg={6} md={6} sm={12}>
                                <FormGroup className="pt-3">
                                  <Label
                                    for="number"
                                    className="fontSize14-600 dimgray-color"
                                  >
                                    {" "}
                                    Phone Number{" "}
                                  </Label>
                                  <Input
                                    id="number"
                                    name="number"
                                    type="number"
                                  />
                                  <MdOutlineVerifiedUser className="mail-icon-login text-danger" />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={12} md={12} sm={12}>
                                <FormGroup className="pt-3">
                                  <Label
                                    for="number"
                                    className="fontSize14-600 dimgray-color"
                                  >
                                    {" "}
                                    Phone Number{" "}
                                  </Label>
                                  <Input
                                    id="number"
                                    name="number"
                                    type="text"
                                    className=""
                                  />
                                  <MdMailOutline className="mail-icon-login" />
                                </FormGroup>
                              </Col>
                            </Row>
                            <div className="textright-iconf py-5">
                              <Link to="/login">
                                <Button className="btnlanding-hov profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5">
                                  Cancel
                                </Button>
                              </Link>
                              <Link to="/login">
                                <Button className="btnlanding-hov ms-4 profiletop-btnoutline  mt-2 bordt-none fontSize20-600 px-5">
                                  Save
                                </Button>
                              </Link>
                            </div>
                          </Form> */}
                            <Form>
                              {/* <Row>
                              <Col lg={6} md={6} sm={12}>
                                <FormGroup>
                                  <Label
                                    for="fname"
                                    className="fontSize14-600 dimgray-color"
                                  >
                                    Full Name as per Passport
                                  </Label>
                                  <Input id="fname" name="fname" type="text" />
                                  <FiUser className="mail-icon-login" />
                                </FormGroup>
                              </Col>
                              <Col lg={6} md={6} sm={12}>
                                <FormGroup>
                                  <Label
                                    for="fname"
                                    className="fontSize14-600 dimgray-color"
                                  >
                                    Phone Number
                                  </Label>
                                  <Input id="fname" name="fname" type="tel" />
                                  <BiPhoneIncoming className="mail-icon-login" />
                                </FormGroup>
                              </Col>
                            </Row> */}

                              {/* <Row>
                              <Col lg={12} md={12} sm={12}>
                                <FormGroup className="pt-3">
                                  <Label
                                    for="number"
                                    className="fontSize14-600 dimgray-color"
                                  >
                                    Attach Your Passport Copy
                                  </Label>
                                  <Dropzone />
                               
                                </FormGroup>
                              </Col>
                            </Row>
                            <div className="pt-4">
                              <ul className="list-type p-0 fontSize16-500 dimgray-color">
                                <li>
                                  <span className="orange-color pr-2">
                                    <b>*</b>
                                  </span>
                                  <i>
                                    Please Upload a clear photo of your
                                    Passport.
                                  </i>
                                </li>
                                <li>
                                  <i>
                                    It must not hide any significant piece of
                                    information.
                                  </i>{" "}
                                </li>
                                <li>
                                  <i>The text must be legible. </i>
                                </li>
                                <li>
                                  <i>Maximum file size 5MB</i>{" "}
                                </li>
                                <li>
                                  <i>
                                    Supported file formats are JPG, PDF, PNG{" "}
                                  </i>
                                </li>
                              </ul>
                            </div>
                            <div className="textright-iconf pt-5">
                              <Link to="/login">
                                <Button className="btnlanding-hov profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5">
                                  Cancel
                                </Button>
                              </Link>
                              <Link to="#">
                                <Button
                                  onClick={toggle}
                                  className="btnlanding-hov ms-4 profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                                >
                                  Save
                                </Button>
                              </Link>
                            </div> */}
                            </Form>

                            {/* 4th */}
                          </div>
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <Row>
                        <div className="d-flex justify-content-end">
                          <div className="col-lg-6 col-md-8 col-sm-12">
                            <InputGroup className="">
                              <Input
                                className="kasy"
                                type="search"
                                placeholder={t("Search Orders...")}
                                required
                              />
                              <Button className="px-3 back-orange bordt-none">
                                {t("Search")} <BsSearch />
                              </Button>
                            </InputGroup>
                          </div>
                        </div>
                        <Col className="mt-5" lg={12} md={12} sm={12}>
                          <div className="lightorage-color ps-3">
                            <h1 className=" py-3 fontSize24-600 orange-color">
                              {t("My Sender Orders")}
                            </h1>
                          </div>

                          <div className="text-center justify-content-center mx-auto mt-5">
                            <MySenderOffers userType="sender" />
                          </div>
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth">
                      <Row>
                        <div className="d-flex justify-content-end">
                          <div className="col-lg-6 col-md-8 col-sm-12">
                            <InputGroup className="">
                              <Input
                                className="kasy"
                                type="search"
                                placeholder={t("Search Orders...")}
                                required
                              />
                              <Button className="px-3 back-orange bordt-none">
                                {t("Search")} <BsSearch />
                              </Button>
                            </InputGroup>
                          </div>
                        </div>
                        <Col className="mt-5" lg={12} md={12} sm={12}>
                          <div className="lightorage-color ps-3">
                            <h1 className=" py-3 fontSize24-600 orange-color">
                              {t("My Traveller Offers")}
                            </h1>
                          </div>

                          <div className="text-center justify-content-center mx-auto mt-5">
                            <MyTravellerOffers userType="traveller" />
                          </div>
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="nineth">
                      <Row>
                        <Col className="mt-5" lg={12} md={12} sm={12}>
                          <div className="lightorage-color ps-3">
                            <h1 className=" py-3 fontSize24-600 orange-color">
                              {t("My Ratings")}
                            </h1>
                          </div>

                          <div className="text-center justify-content-center mx-auto mt-5">
                            <SeeMyRating userType="sender" />
                          </div>
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="tenth">
                      <Row>
                        <Col className="mt-5" lg={12} md={12} sm={12}>
                          <div className="lightorage-color ps-3">
                            <h1 className=" py-3 fontSize24-600 orange-color">
                              {t("My Payments")}
                            </h1>
                          </div>

                          <div className="text-center justify-content-center mx-auto mt-5">
                            <PayTheBill userType="sender" />
                          </div>
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fifth">
                      <Row>
                        <Col lg={12} md={12} sm={12}>
                          <div className="lightorage-color">
                            <h1 className="py-3 ps-4 fontSize24-600 orange-color">
                              {t("Support")}
                            </h1>
                          </div>
                          <div className="pt-5">
                            <Form>
                              <Row>
                                <Col lg={6} md={6} sm={12}>
                                  <FormGroup>
                                    <Label
                                      for="fname"
                                      className="fontSize14-600 dimgray-color"
                                    >
                                      {t("Full Name")}
                                    </Label>
                                    <Input
                                      className="kasy"
                                      value={name}
                                      onChange={(e) => {
                                        setName(e.target.value);
                                      }}
                                      id="fname"
                                      name="fname"
                                      type="text"
                                      required
                                    />
                                    <FiUser className="mail-icon-profile" />
                                  </FormGroup>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                  <FormGroup className="">
                                    <Label
                                      for="email"
                                      className="fontSize14-600 dimgray-color"
                                    >
                                      {" "}
                                      {t("Email")}{" "}
                                    </Label>
                                    <Input
                                      className="kasy"
                                      value={mail}
                                      onChange={(e) => {
                                        setMail(
                                          e.target.value.toLocaleLowerCase()
                                        );
                                      }}
                                      id="email"
                                      name="email"
                                      type="email"
                                      required
                                    />
                                    <MdMailOutline className="mail-icon-profile" />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <FormGroup row className="pt-3">
                                <Label
                                  for="exampleText"
                                  sm={12}
                                  className="fontSize14-600 dimgray-color"
                                >
                                  {t("How may we Help you")}?
                                </Label>
                                <Col sm={12}>
                                  <Input
                                    value={textarea}
                                    onChange={(e) => {
                                      setTextarea(e.target.value);
                                    }}
                                    id="exampleText"
                                    name="text"
                                    type="textarea"
                                    className="hight-inp"
                                    required
                                  />
                                </Col>
                              </FormGroup>
                              <div className="textright-iconf pt-5">
                                <Button
                                  onClick={handelClear}
                                  className="navdropdown-twohov profiletop-btnoutline mt-2 wafa fontSize16-500 bordt-none"
                                  size="md"
                                >
                                  {t("Cancel")}
                                </Button>

                                <Button
                                  // type="submit"
                                  onClick={handleSupportData}
                                  className="navdropdown-twohov profiletop-btnoutline mt-2 wafa ms-4 fontSize16-500 bordt-none"
                                  size="md"
                                >
                                  {t("Submit")}
                                </Button>
                              </div>
                            </Form>
                          </div>
                        </Col>
                      </Row>
                    </Tab.Pane>

                    {/* Six */}
                    <Tab.Pane eventKey="six">
                      <Row>
                        <Col lg={12} md={12} sm={12}>
                          <div className="lightorage-color my-4 text-center">
                            <h1 className=" py-3 fontSize24-600 orange-color">
                              {t("Security Settings")}
                            </h1>
                          </div>
                          <div className="pt-5 ms-lg-0 ms-md-0 ms-1">
                            <Form>
                              <FormGroup className="py-3">
                                <Label
                                  for="password"
                                  className="fontSize14-600 dimgray-color"
                                >
                                  {" "}
                                  {t("Old Password")}{" "}
                                </Label>
                                <Input
                                  className="kasy"
                                  value={text}
                                  onChange={(e) => {
                                    setText(e.target.value);
                                  }}
                                  id="password"
                                  name="password"
                                  type="password"
                                  required
                                />
                                <FiLock className="mail-icon-profile" />
                              </FormGroup>
                              <FormGroup className="py-3">
                                <Label
                                  for="password"
                                  className="fontSize14-600 dimgray-color"
                                >
                                  {" "}
                                  {t("New Password")}{" "}
                                </Label>
                                <Input
                                  className="kasy"
                                  value={newpassword}
                                  onChange={(e) => {
                                    setNewpassword(e.target.value);
                                  }}
                                  id="password"
                                  name="password"
                                  type="password"
                                  required
                                />
                                <FiLock className="mail-icon-profile" />
                              </FormGroup>
                              <FormGroup className="py-3">
                                <Label
                                  for="password"
                                  className="fontSize14-600 dimgray-color"
                                >
                                  {" "}
                                  {t("Confirm New Password")}{" "}
                                </Label>
                                <Input
                                  className="kasy"
                                  value={confirmpass}
                                  onChange={(e) => {
                                    setConfirmpass(e.target.value);
                                  }}
                                  id="password"
                                  name="password"
                                  type="password"
                                  required
                                />
                                <FiLock className="mail-icon-profile" />
                              </FormGroup>
                              <Alert color="white" className="text-danger">
                                {errorText}
                              </Alert>
                              <Alert color="white" className="text-danger">
                                {t(
                                  "Your Password must be a combination of Lowercase letters,Upper case Letters,Numbers & symbols"
                                )}
                                .
                              </Alert>
                              <div className="textright-iconf pt-4">
                                <Button
                                  type="button"
                                  onClick={handleClearClick}
                                  className="navdropdown-twohov profiletop-btnoutline wafa mt-2 fontSize16-500 bordt-none"
                                  size="md"
                                >
                                  {t("Cancel")}
                                </Button>
                                <Button
                                  // type="submit"
                                  onClick={handlecheack}
                                  className="navdropdown-twohov profiletop-btnoutline wafa mt-2 ms-4 fontSize16-500 bordt-none"
                                  size="md"
                                  disabled={buttonLoader}
                                >
                                  {buttonLoader ? (
                                    <img
                                      className="img-fluid buttonLoaderImgBgTrans"
                                      src={button_loader}
                                      alt="loader"
                                      style={{
                                        backgroundColor: "inherit !important",
                                      }}
                                    />
                                  ) : (
                                    t("Submit")
                                  )}
                                </Button>
                              </div>
                            </Form>
                          </div>
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="seven">{/* <Sonnet /> */}</Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Container>
          </Tab.Container>
        </section>
        <Modal
          isOpen={verificationModal}
          fade={false}
          toggle={verificationToggle}
          className="lanprofmodal"
        >
          {/* <ModalHeader toggle={toggle}>Modal title</ModalHeader> */}
          <ModalBody className="py-5">
            <Row>
              <Col lg={12} md={12} sm={12}>
                <div className="lightorage-color text-center mt-5">
                  <h1 className=" py-3 fontSize24-600 orange-color">
                    {verificationModalText}
                  </h1>
                </div>
                <div className="text-center pt-4">
                  <img
                    src={
                      verificationModalText !== t("Phone Verification")
                        ? verificationImg
                        : verificationPhoneImg
                    }
                    alt="otp"
                    className="m-5 verimg-resp img-fluid"
                    width={300}
                  />
                </div>
                <OtpCom
                  type={type}
                  handleClose={handleClick}
                  phoneSuccessToggle={phoneSuccessToggle}
                />
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modal}
          fade={false}
          toggle={toggle}
          className="lanprofmodal"
        >
          {/* <ModalHeader toggle={toggle}>Modal title</ModalHeader> */}
          <ModalBody className="py-5">
            <p className="fontSize24-600 text-center pt-5 textgray">
              {t("Verification Process")}
            </p>
            <p className="text-center textgray fontSize14-400 mb-0">
              {t("Verification Process may take 24-72 hours")}.{" "}
            </p>
            <p className="text-center textgray pb-5 fontSize14-400">
              {t(
                "Your can always check your ID verification status in settings"
              )}
              .
              {/* <Link to="/otp_varified" onClick={toggle} className="dimgray-color text-decor"> */}
              {/* </Link> */}
            </p>
          </ModalBody>
        </Modal>

        {/* two */}
        <Modal
          isOpen={modalOne}
          fade={false}
          toggle={toggleOne}
          className="lanprofmodal"
        >
          {/* <ModalHeader toggle={toggle}>Modal title</ModalHeader> */}
          <ModalBody className="py-4 px-4">
            <Row>
              <Col lg={12} md={12} sm={12}>
                <div className="lightorage-color text-center">
                  <h1 className=" py-2 fontSize24-600 orange-color">
                    {t("ID Verifications")}
                  </h1>
                </div>
                <FormGroup className="pt-3">
                  <Label for="number" className="fontSize14-600 dimgray-color">
                    {t("Attach Your Passport Copy")}
                  </Label>
                  <Dropzone handleFiles={handleFilesAdded} disabled={true} />
                  {/* <BiCamera className="mail-icon-login" /> */}
                </FormGroup>
              </Col>
            </Row>
            <div className="pt-4">
              <ul className="list-type-number ps-2 fontSize16-500 redColor">
                <li>
                  <i>
                    {t("Make sure that the passport is valid and not expired")}.
                  </i>{" "}
                </li>

                <li>
                  <i>{t("Supported file formats are JPG, PNG")} </i>
                </li>
                <li>
                  <i>
                    {t(
                      "All your information and uploads are kept highly confidential and are not disclosed to ny third parties"
                    )}
                    .
                  </i>{" "}
                </li>
                <li>
                  <i>{t("Maximum file size 5MB")}</i>{" "}
                </li>
              </ul>
            </div>
            <div className="textright-iconf pt-5">
              <Button
                className="btnlanding-hov profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                onClick={handleCloseModalOne}
              >
                {t("Cancel")}
              </Button>
              <Button
                onClick={verifyPassportImage}
                className="btnlanding-hov ms-4 profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                disabled={buttonLoader}
              >
                {buttonLoader ? (
                  <>
                    {t("Processing...")}
                    <img
                      className="img-fluid buttonLoaderImg"
                      src={button_loader}
                      alt="loader"
                    />
                  </>
                ) : (
                  <>{t("Save")}</>
                )}
              </Button>
            </div>
          </ModalBody>
        </Modal>

        {/* Success Modal */}
        <Modal
          isOpen={successModal}
          fade={false}
          toggle={successToggle}
          className="otp-modal"
        >
          <ModalBody>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <div className=" text-center pt-4">
                  <h1 className=" py-2 fontSize32-600 orange-color">
                    {t("Congratulations")}!
                  </h1>
                </div>
                <div>
                  <img
                    src={congratulation}
                    alt="otp-verfy"
                    className="otpimg-cong"
                  />
                </div>
                <div className="pt-4">
                  <p className="darktext-color fontSize24-500 pb-4">
                    {t("Your Email has been Verified")}.
                  </p>
                </div>
              </Col>
              <Col lg={12}>
                <Button
                  onClick={successToggle}
                  className="btnlanding-hov ms-4 profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                >
                  {t("Close")}
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={phoneSuccessModal}
          fade={false}
          toggle={phoneSuccessToggle}
          className="otp-modal"
        >
          <ModalBody>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <div className=" text-center pt-4">
                  <h1 className=" py-2 fontSize32-600 orange-color">
                    {t("Congratulations")}!
                  </h1>
                </div>
                <div>
                  <img
                    src={congratulation}
                    alt="otp-verfy"
                    className="otpimg-cong"
                  />
                </div>
                <div className="pt-4">
                  <p className="darktext-color fontSize24-500 pb-4">
                    {t("Your WhatsApp Number has been Verified")}.
                  </p>
                </div>
              </Col>
              <Col lg={12}>
                <Button
                  onClick={phoneSuccessToggle}
                  className="btnlanding-hov ms-4 profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                >
                  {t("Close")}
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </LoadingScreen>
    </div>
  );
}

export default MainProfile;
