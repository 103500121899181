import { DETETE_USER_ACCOUNT } from "../types";

const initialState = {
  deleteUser: {},
};

const DeleteUserAccountReducer = function (state = initialState, action) {
  switch (action.type) {
    case DETETE_USER_ACCOUNT: {
      return {
        ...state,
        deleteUser: action.payload,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default DeleteUserAccountReducer;
