import thunk from "redux-thunk";
import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from "redux";
import RootReducer from "./reducers/index";
import { persistStore } from "redux-persist";

const initialState = {};
const middlewares = [thunk];

const store = createStore(
  RootReducer,
  initialState,
  compose(applyMiddleware(...middlewares))
);

// eslint-disable-next-line import/no-anonymous-default-export
export default { store, persistor: persistStore(store) };
