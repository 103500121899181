import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import LoggedinNavbar from "../View/Component/LoggedinNavbar";
import SecondNav from "../View/Component/SecondNav";
const ProtectedRoute = () => {
  const token = localStorage.getItem("token"); // determine if authorized, from context or however you're doing it

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return token ? (
    <>
      <LoggedinNavbar />
      {/* <SecondNav /> */}
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoute;
