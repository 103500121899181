import Multiselect from 'multiselect-react-dropdown'
import Select from "react-select";

import React,{useState} from 'react'
import { Label } from 'reactstrap'

function MultipleSlection({props,onChangeOption}) {
  const [selectedOptions, setSelectedOptions] = useState(null);
  const optionList = [
    { value: "luggage", label: "Luggage" },
    { value: "box", label: "Box" },
    { value: "document", label: "Document or Envelope" }
  ];
  function handleSelect(data) {
    onChangeOption(data)

    console.log(JSON.stringify(data)+"====package type")
    setSelectedOptions(data);
  }
  return (
    <div>
      <Label className=' className="fontSize14-600 dimgray-color"'>
        Select Package Type Able to Carry
      </Label>
      <Select
          options={optionList}
          placeholder="Select Package Type"
          value={selectedOptions}
          onChange={handleSelect}
          isSearchable={true}
          isMulti
        />
    </div>
  )
}

export default MultipleSlection
