import React, { useEffect, useState } from "react";
//React Strap Components
import { FormGroup, Label, Input, Button } from "reactstrap";

//CSS
import "../SamllComponent/MultitabBtn.css";

//React Bootstrap Components
import { Form, Row, Col } from "react-bootstrap";

import Modal from "react-bootstrap/Modal";
import MultipleSlection from "../Component/MultipleSlection";
import { useTranslation } from "react-i18next";

const Step2One = ({
  currentStep,
  dCountryObject,
  packageType,
  packageWight,
  packageHeight,
  sCountryObject,
  packageLenght,
  packageWidth,
  packageValue,
  isOrignalManufacturer,
  // handleInsurance,
  emptyTypes,
  termsandcondition,
  handleAllowedShipment,
  isInsurance,
}) => {
  const [show, setShow] = useState(false);
  const [isDisable, setDisable] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const [insuranceDisable, setInsuranceDisable] = useState(false);
  const [shipmentType, setShipmentType] = useState("");
  const [shipmentTypeValue, setshipmentTypeValue] = useState(null);
  const [allowedShipmentTypes, setAllowedShipmentTypes] = useState([]);

  const { t } = useTranslation();

  const [packageCost, setPackageCost] = useState("");

  const handlePackageCost = (event) => {
    let value = event.target.value;

    if (/^[0-9]\d*$/.test(value)) {
      setPackageCost(value);
      packageValue(value);
    } else {
      setPackageCost("");
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setDisable(false);
    setShow(true);
  };

  const [unit, setUnit] = useState("KG");
  const [weight, setWeight] = useState("");

  const [lengthUnit, setLengthUnit] = useState("CM");
  const [length, setLength] = useState(0);

  const [widthUnit, setWidthUnit] = useState("CM");
  const [width, setWidth] = useState(0);

  const [heightUnit, setHeightUnit] = useState("CM");
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setShipmentTypeList();

    // return setAllowedShipmentTypes([]);
  }, [dCountryObject]);
  useEffect(() => {
    if (emptyTypes) {
      setAllowedShipmentTypes([]);
    }
  }, [emptyTypes]);
  useEffect(() => {
    packageWight(weight + " " + unit);
  }, [packageWight, unit, weight]);

  useEffect(() => {
    packageType(shipmentTypeValue);
  }, [shipmentTypeValue]);

  useEffect(() => {
    packageHeight(height + " " + heightUnit);
  }, [heightUnit, height, packageHeight]);

  useEffect(() => {
    packageWidth(width + " " + widthUnit);
  }, [widthUnit, width, packageWidth]);

  useEffect(() => {
    packageLenght(length + " " + lengthUnit);
  }, [lengthUnit, length, packageLenght]);

  const handleMaxWeightUnit = (event) => {
    let value = event.target.value;
    if (/^[0-9]+(.[0-9]+)?$/.test(value)) {
      setWeight(event.target.value);
    } else {
      setWeight("");
    }
  };
  const handleUnit = (event) => {
    setUnit(event.target.value);
  };

  const handleWidth = (event) => {
    setWidth(event.target.value);
  };
  const handleWidthUnit = (event) => {
    setWidthUnit(event.target.value);
  };
  const handleLength = (event) => {
    setLength(event.target.value);
  };
  const handleLengthUnit = (event) => {
    setLengthUnit(event.target.value);
  };
  const handleHeight = (event) => {
    setHeight(event.target.value);
  };
  const handleHeightUnit = (event) => {
    setHeightUnit(event.target.value);
  };
  const handlePackageType = (event) => {
    let shipment = event.target.value;
    setShipmentType(shipment);
    setshipmentTypeValue(shipment);
    if (shipment == "document envelope") {
      setLength(0);
      setHeight(0);
      setWidth(0);
      setWidthUnit("CM");
      setLengthUnit("CM");
      setHeightUnit("CM");
      setDisableFields(true);
    } else {
      setDisableFields(false);
    }
  };
  console.log("asdfghjqwertyu", sCountryObject);
  const setShipmentTypeList = () => {
    // setAllowedShipmentTypes([])
    // console.log(
    //   JSON.stringify(dCountryObject) +
    //     "====== Destination Country object in step three"
    // );
    if (
      dCountryObject?.is_document_possible == 1 &&
      sCountryObject?.is_document_possible == 1 &&
      !allowedShipmentTypes.find((i) => i === "document envelope")
    ) {
      setAllowedShipmentTypes((allowedShipmentTypes) => [
        ...allowedShipmentTypes,
        "document envelope",
      ]);
    }
    if (
      dCountryObject?.is_small_box_possible == 1 &&
      sCountryObject?.is_small_box_possible == 1 &&
      !allowedShipmentTypes.find((i) => i === "small box")
    ) {
      setAllowedShipmentTypes((allowedShipmentTypes) => [
        ...allowedShipmentTypes,
        "small box"
      ]);
    }
    if (
      dCountryObject?.is_medium_box_possible == 1 &&
      sCountryObject?.is_medium_box_possible == 1 &&
      !allowedShipmentTypes.find((i) => i === "medium box")
    ) {
      setAllowedShipmentTypes((allowedShipmentTypes) => [
        ...allowedShipmentTypes,
        "medium box"
      ]);
    }
    if (
      dCountryObject?.is_large_box_possible == 1 &&
      sCountryObject?.is_large_box_possible == 1 &&
      !allowedShipmentTypes.find((i) => i === "large box")
    ) {
      setAllowedShipmentTypes((allowedShipmentTypes) => [
        ...allowedShipmentTypes,
        "large box",
      ]);
    }
    if (
      (dCountryObject?.is_other_package_a == 1 &&
        sCountryObject?.is_other_package_a == 1 &&
        !allowedShipmentTypes.find((i) => i === "other packages")) ||
      (dCountryObject?.is_other_package_b == 1 &&
        sCountryObject?.is_other_package_b == 1 &&
        !allowedShipmentTypes.find((i) => i === "other packages"))
    ) {
      setAllowedShipmentTypes((allowedShipmentTypes) => [
        ...allowedShipmentTypes,
        "other packages",
      ]);
    }
    if (
      dCountryObject?.is_insurance_possible == 0 ||
      sCountryObject?.is_insurance_possible == 0
    ) {
      setInsuranceDisable(true);
      isInsurance("0");
    } else if (
      dCountryObject?.is_insurance_possible == 1 ||
      sCountryObject?.is_insurance_possible == 1
    ) {
      setInsuranceDisable(false);
    }
    // handleAllowedShipment(setAllowedShipmentTypes);
  };
  const handleisInsurance = (event) => {
    // if (insuranceDisable) {
    //   handleInsurance(0);
    //   isInsurance(0);
    // } else {
    let insurance = event.target.value;
    // handleInsurance(insurance);
    isInsurance(insurance);
    // }
  };

  if (currentStep !== 3) {
    return null;
  }

  return (
    <div className="pt-5">
      <div className="multiform-postion">
        <div className="lightorage-color my-4 text-center">
          <h1 className=" py-3 fontSize24-600 orange-color">
            {t("Create Shipment offer")}
          </h1>
        </div>
        <div className="text-center pt-3">
          <Button
            type="button"
            className="orange-background fontSize16-500 bordt-none"
            size="md"
          >
            {t("What are you shipping")}?
          </Button>
        </div>
      </div>
      <div className="pt-115-thrdform">
        <Form>
          {/* <CollapseBtnOne /> */}
          {/* <MultipleSlection onChangeOption={packageType} /> */}
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-11 col-12">
              <FormGroup>
                <Label
                  for="exampleSelect"
                  className="fontSize14-600 dimgray-color"
                >
                  {t("Shipment Type")}
                  <span style={{ color: "red" }}>* </span>
                  {t(
                    "Selection should be allowed in both Destination and Origin Countries"
                  )}
                </Label>
                <select
                  className="form-control"
                  name="select"
                  id="exampleSelect"
                  onChange={handlePackageType}
                >
                  <option value="(Document, Parcel, Luggage)">
                    {t("Please Select")}
                  </option>
                  {allowedShipmentTypes?.length > 0 &&
                    allowedShipmentTypes.map((value) => {
                      return <option value={value}>{t(value)}</option>;
                    })}
                </select>
              </FormGroup>
              <FormGroup>
                <Label for="password" className="fontSize14-600 dimgray-color">
                  {t("Estimated Value of")} {t(shipmentType)} {t("in USD")}
                  <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  type="text"
                  name="packageValue"
                  id="packageValue"
                  placeholder="" // Prop: Puts data into the state
                  value={packageCost}
                  onChange={handlePackageCost}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="password" className="fontSize14-600 dimgray-color">
                  {t(shipmentType)} {t("Weight")}
                  <span style={{ color: "red" }}>*</span>
                </Label>

                <div className="input-group">
                  <input
                    type="text"
                    onChange={handleMaxWeightUnit}
                    value={weight}
                    className="form-control"
                    aria-label="Package Weight"
                  />
                  <div className="input-group-append">
                    <select
                      name=""
                      onChange={handleUnit}
                      className="form-control"
                      id=""
                    >
                      <option value="KG" selected>
                        {t("KG")}
                      </option>
                      <option value="Pounds">{t("Pounds")}</option>
                    </select>
                  </div>
                </div>
              </FormGroup>
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-4 col-12">
                  <FormGroup>
                    <Label
                      for="password"
                      className="fontSize14-600 dimgray-color"
                    >
                      {t(shipmentType)} {t("Length")}
                      <span style={{ color: "red" }}>*</span>
                    </Label>

                    <div className="input-group">
                      <input
                        type="number"
                        min={0}
                        required
                        onChange={handleLength}
                        className="form-control"
                        aria-label="Package Weight"
                        disabled={disableFields}
                      />
                      <div className="input-group-append">
                        <select
                          name=""
                          onChange={handleLengthUnit}
                          className="form-control"
                          id=""
                          disabled={disableFields}
                        >
                          <option value="CM" selected>
                            {t("CM")}
                          </option>
                          <option value="INCH">{t("INCH")}</option>
                        </select>
                      </div>
                    </div>
                  </FormGroup>
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                  <FormGroup>
                    <Label
                      for="password"
                      className="fontSize14-600 dimgray-color"
                    >
                      {t(shipmentType)} {t("Width")}
                      <span style={{ color: "red" }}> * </span>
                    </Label>

                    <div className="input-group">
                      <input
                        type="number"
                        min={0}
                        required
                        onChange={handleWidth}
                        className="form-control"
                        aria-label="Package Weight"
                        disabled={disableFields}
                      />
                      <div className="input-group-append">
                        <select
                          name=""
                          onChange={handleWidthUnit}
                          className="form-control"
                          id=""
                          disabled={disableFields}
                        >
                          <option value="CM" selected>
                            {t("CM")}
                          </option>
                          <option value="INCH">{t("INCH")}</option>
                        </select>
                      </div>
                    </div>
                  </FormGroup>
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                  <FormGroup>
                    <Label
                      for="password"
                      className="fontSize14-600 dimgray-color"
                    >
                      {t(shipmentType)} {t("Height")}
                      <span style={{ color: "red" }}>*</span>
                    </Label>

                    <div className="input-group">
                      <input
                        type="number"
                        required
                        min={0}
                        onChange={handleHeight}
                        className="form-control"
                        aria-label="Package Weight"
                        disabled={disableFields}
                      />
                      <div className="input-group-append">
                        <select
                          name=""
                          onChange={handleHeightUnit}
                          className="form-control"
                          id=""
                          disabled={disableFields}
                        >
                          <option value="CM" selected>
                            {t("CM")}
                          </option>
                          <option value="INCH">INCH</option>
                        </select>
                      </div>
                    </div>
                  </FormGroup>
                </div>
              </div>
              <FormGroup>
                <Label
                  for="exampleSelect"
                  className="fontSize14-600 dimgray-color"
                >
                  {t(
                    "Are the items new & sealed/boxed by original manufacturer"
                  )}
                  ?<span style={{ color: "red" }}>*</span>
                </Label>
                <select
                  className="form-control"
                  name="select"
                  id="exampleSelect"
                  disabled={disableFields}
                  onChange={isOrignalManufacturer}
                >
                  <option value="0">{t("Please Select")}</option>

                  <option value="1">{t("Yes")}</option>
                  <option value="0">{t("No")}</option>
                </select>
              </FormGroup>
              <FormGroup>
                <Label
                  for="exampleSelect"
                  className="fontSize14-600 dimgray-color"
                >
                  {t("Do you require transit insurance for your shipment")}{" "}
                  {t(shipmentType)}?<span style={{ color: "red" }}>*</span>
                </Label>
                <select
                  className="form-control"
                  name="select"
                  id="exampleSelect"
                  onChange={handleisInsurance}
                  disabled={insuranceDisable}
                >
                  <option value="0">{t("Please Select")}</option>
                  <option value="1">{t("Yes")}</option>
                  <option value="0">{t("No")}</option>
                </select>
              </FormGroup>
              <FormGroup check>
                <Label check className="fontSize14-600 dimgray-color">
                  <Input
                    type="checkbox"
                    onChange={(e) => {
                      termsandcondition(e.target.checked);
                      console.log(e.target.checked);
                    }}
                  />{" "}
                  {t("I agree to Packaging")}{" "}
                  <span className="orange-color" onClick={handleShow}>
                    {t("Terms & Conditions")}
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
        </Form>
      </div>
      <Modal show={show} onHide={handleClose} className="stepthree-mpdal">
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="py-5 px-4">
                <p className="orange-color fontSize24-600 text-center">
                  {t("Viahop Shipment Terms & Conditions")}
                </p>
                <p className="fontSize16-500">
                  {" "}
                  {t(
                    "I Agree that Shipment confirms to Viahop Shipment terms and conditions. No facilitation of illegal activity (in departure, arriving or transit country) is taking place with this offer or transit. All customs, taxes and other payments and withholdings have been or will be borne by me (if applicable). I remain the owner of the Shipment and its contents for the time of transit and I will be bearing any liability, fines and penalties if these arise during the time or after the transit."
                  )}
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="textright-iconf">
                <Button
                  type="button"
                  color="primary float-right"
                  onClick={handleClose}
                  className="profiletop-btnoutline navdropdown-twohov px-5 ms-3 bordt-none"
                >
                  {t("Agree & Continue")}
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default Step2One;
