import React from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";
import nav_flag from "../Assets/Images/nav_flag.png";
import { Link } from "react-router-dom";
import logoo from "../Assets/Images/logoo1.png";
import russianFlag from "../Assets/Images/russia-flag.png";

export default class Header extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  render() {
    return (
      <div>
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Navbar
                color=""
                expand="md"
                style={{ background: "transparent !important" }}
              >
                <Link to="/">
                  {" "}
                  <NavbarBrand className="">
                    <img src={logoo} alt="logoo" />
                  </NavbarBrand>{" "}
                </Link>
                <NavbarToggler onClick={this.toggle} className="bordt-none" />
                <Collapse isOpen={this.state.isOpen} navbar className="ms-auto">
                  <Nav className="ms-auto via-nav" navbar>
                    <NavItem>
                      <Link to="/">
                        {" "}
                        <NavLink
                          className="fontSize20-600 navi  ms-3"
                          style={{ color: "#F69110" }}
                        >
                          Home
                        </NavLink>
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link to="/about">
                        <NavLink
                          className="fontSize20-600 navi ms-3"
                          style={{ color: "#222222" }}
                        >
                          About Us
                        </NavLink>
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link to="/term">
                        <NavLink
                          className="fontSize20-600 navi ms-3"
                          style={{ color: "#222222" }}
                        >
                          Terms of Use
                        </NavLink>
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link to="/fq">
                        <NavLink
                          className="fontSize20-600 navi ms-3"
                          style={{ color: "#222222" }}
                        >
                          FAQs
                        </NavLink>
                      </Link>
                    </NavItem>
                  </Nav>
                  <NavbarText className="ms-auto">
                    <Link to="/login">
                      <Button className="orange-background bordt-none fontSize20-600 px-3 me-3">
                        Sign In/Register
                      </Button>
                    </Link>
                  </NavbarText>
                  <UncontrolledDropdown nav inNavbar className="ms-3 list-type">
                    <DropdownToggle nav caret className="fontSize20-600">
                      <img
                        src={nav_flag}
                        alt="nav_flag"
                        style={{ marginLeft: "5px" }}
                      />{" "}
                      En
                    </DropdownToggle>
                    <DropdownMenu right className="nav-dropoption flag_set">
                  
                   
                  <DropdownItem className="fontSize20-600 d-flex justify-content-around">
                    English{" "}
                    <span>
                      {" "}
                      <img
                        src={nav_flag}
                        style={{ marginLeft: "8px" }}
                        alt="nav_flag"
                      />
                    </span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem className="fontSize20-600 ">
                    Русский{" "}
                    <span>
                      {" "}
                      <img
                        src={russianFlag}
                        className="arabic_flag"
                        alt="nav_flag"
                      />
                    </span>
                  </DropdownItem>
                </DropdownMenu>
                  </UncontrolledDropdown>
                </Collapse>
              </Navbar>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
