import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, FormGroup, Row } from "react-bootstrap";
import {
  getSenderOfferDetails,
  getTravellerOfferDetails,
} from "../../redux/actions/TravellerOfferAction";
import "../Assets/Css/Sidebar.css";
import sender from "../Assets/Images/box-svgrepo-com.svg";
import triller from "../Assets/Images/triller.png";
import exploreSenderImg from "../Assets/Images/exploreSender.png";
import exploreTravellerImg from "../Assets/Images/exploreTraveller.png";
import { BsSearch } from "react-icons/bs";
import profile from "../Assets/Images/profile.png";
import Swal from "sweetalert2";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";

import {
  Button,
  Modal,
  ModalBody,
  Alert,
  Form,
  Input,
  InputGroup,
  Label,
  CardBody,
  CardTitle,
} from "reactstrap";
import {
  getAllTravellerOffers,
  getAllSenderOffers,
  createSenderCounterOffer,
  createTravellerCounterOffer,
  acceptUserSenderOffer,
  acceptUserTravellerOffer,
} from "../../redux/actions/TravellerOfferAction";
import { getBlogList } from "../../redux/actions/AdminAction";

import { getUserData } from "../../redux/actions/UserAction";
import { json, Link, Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoggedinNavbar from "../Component/LoggedinNavbar";

function Blogs(props) {
  const { blogPic, blogTitle, blogId } = props;
  const [isliked, setIsLiked] = useState(false);

  const handleLikeBlog = () => {
    setIsLiked(true);
  };
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { allBlogList } = useSelector((state) => state.AdminReducer);

  useEffect(() => {
    dispatch(getAllSenderOffers());
    dispatch(getAllTravellerOffers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getBlogList());
  }, [dispatch]);

  useEffect(() => {
    const scrollToLittleBottom = () => {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, [300]);
    };
    scrollToLittleBottom();
  }, []);

  console.log(JSON.stringify(allBlogList) + "======= all blog list");

  return (
    <>
      <LoggedinNavbar />
      <Container className="my-5">
        <Row>
          <Col lg={12} md={12} sm={12}>
            <Row>
              {allBlogList?.map((value) => {
                return (
                  <Col lg={4} md={6} sm={12} style={{ maxHeight: "400px" }}>
                    <div className="card h-100 borderNoneClass">
                      <div className="position-relative card_image">
                        <div className="blogCardImgDiv">
                          <img
                            src={value?.blogImages?.[0]?.image}
                            className="card-img-top"
                            alt="blog pic 1"
                          />
                        </div>
                        <div className="koibhi px-3">
                          <h5 className="card-title">{value?.title}</h5>
                          <button
                            className="btn btn-secondary"
                            onClick={handleLikeBlog}
                          >
                            {isliked === false ? (
                              <AiOutlineHeart size={25} />
                            ) : (
                              <AiFillHeart
                                size={25}
                                className="likedBlogHeart"
                              />
                            )}
                          </button>
                        </div>
                      </div>
                      <Link
                        to="/blogDetails"
                        state={{ blogDetail: value }}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <div className="card-body">
                          <h5 className="card-title">
                            {value?.short_description}
                          </h5>
                        </div>
                      </Link>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Blogs;
