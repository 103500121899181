import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Container, FormGroup, Row } from 'react-bootstrap'
import {
  getSenderOfferDetails,
  getTravellerOfferDetails,
} from '../../redux/actions/TravellerOfferAction'
import '../Assets/Css/Sidebar.css'
import sender from '../Assets/Images/box-svgrepo-com.svg'
import triller from '../Assets/Images/triller.png'
import exploreSenderImg from '../Assets/Images/exploreSender.png'
import exploreTravellerImg from '../Assets/Images/exploreTraveller.png'
import { BsSearch } from 'react-icons/bs'
import profile from '../Assets/Images/profile.png'
import Swal from 'sweetalert2'

import {
  Button,
  Modal,
  ModalBody,
  Alert,
  Form,
  Input,
  InputGroup,
  Label,
  CardBody,
  CardTitle,
} from 'reactstrap'
import {
  getAllTravellerOffers,
  getAllSenderOffers,
  createSenderCounterOffer,
  createTravellerCounterOffer,
  acceptUserSenderOffer,
  acceptUserTravellerOffer,
} from '../../redux/actions/TravellerOfferAction'
import { getUserData } from '../../redux/actions/UserAction'
import { json, Link, Navigate, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LoggedinNavbar from '../Component/LoggedinNavbar'

function Explore(props) {
  const navigate = useNavigate()
  const { userDetails } = useSelector((state) => state.UserReducer)
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const { senderOffersList } = useSelector(
    (state) => state.TravellerOfferReducer,
  )
  const { travellerOffersList } = useSelector(
    (state) => state.TravellerOfferReducer,
  )
  useEffect(() => {
    dispatch(getAllSenderOffers())
    dispatch(getAllTravellerOffers())
  }, [dispatch])

  useEffect(() => {
    if (senderOffersList?.length > 0) {
      setFilterData(senderOffersList)
      // console.log(JSON.stringify(senderOffersList) + "========= sender offer list in explore page")
    }
  }, [senderOffersList])
  useEffect(() => {
    if (travellerOffersList?.length > 0) {
      setTravelFilterData(travellerOffersList)
      console.log(
        JSON.stringify(travellerOffersList) +
          '========= traveller offer list in explore page',
      )
    }
  }, [travellerOffersList])

  const [phoneVerify, setPhoneVerify] = useState(null)
  const [emailVerify, setEmailVerify] = useState(null)
  const [filterData, setFilterData] = useState(senderOffersList)
  const [travelfilterData, setTravelFilterData] = useState(travellerOffersList)

  console.log(
    JSON.stringify(travelfilterData) + '=========== filterData in explore page',
  )
  useEffect(() => {
    console.log(JSON.stringify(userDetails) + '==================')
    setPhoneVerify(userDetails?.user?.is_phone_verified)
    setEmailVerify(userDetails?.user?.is_email_verified)
  }, [userDetails])

  let search = window.location.search
  let params = new URLSearchParams(search)
  let from = params.get('from')
  let type = params.get('type')

  useEffect(() => {
    if (from == 'link' && type == 'sender') {
      navigate('/senderOffers', { state: { filterData: filterData } })
    } else if (from == 'link' && type == 'traveller') {
      navigate('/travellerOffers', {
        state: { travelfilterData, travelfilterData },
      })
    } else {
    }
  }, [from, type])
  const handleFindYourSender = (e) => {
    e.preventDefault()
    navigate('/senderOffers', { state: { filterData: filterData } })
    // if (phoneVerify === 0) {
    //   Swal.fire({
    //     title: t("Whoops!"),
    //     type: "danger",
    //     text: t("You need to get verified before you can create the sender and traveler offer. Don’t sweat it - just finish your verification [address, email, WhatsApp number] and you’re ready to go"),
    //     footer:
    //       '<a href="/main_profile?key=second">'+ t('Link to Verification Page')+'</a>',
    //   });
    // } else if (emailVerify === 0) {
    //   Swal.fire({
    //     title: t("Whoops!"),
    //     type: "danger",
    //     text: t("You need to get verified before you can create the sender and traveler offer. Don’t sweat it - just finish your verification [address, email, WhatsApp number] and you’re ready to go"),
    //     footer:
    //       '<a href="/main_profile?key=second">'+ t('Link to Verification Page')+'</a>',
    //   });
    // } else if (
    //   userDetails?.user?.address == null ||
    //   userDetails?.user?.address == ""
    // ) {
    //   Swal.fire({
    //     title: t("Whoops!"),
    //     type: "danger",
    //     text: t("You need to get verified before you can create the sender and traveler offer. Don’t sweat it - just finish your verification [address, email, WhatsApp number] and you’re ready to go"),
    //     footer:
    //       '<a href="/main_profile?key=second">'+ t('Link to Verification Page')+'</a>',
    //   });
    // } else {
    //   navigate("/senderOffers", { state: { filterData: filterData } });
    // }
  }
  const handleFindYourTraveller = (e) => {
    e.preventDefault()
    navigate('/travellerOffers', {
      state: { travelfilterData, travelfilterData },
    })
    // if (phoneVerify === 0) {
    //   Swal.fire({
    //     title: t("Whoops!"),
    //     type: "danger",
    //     text: t("You need to get verified before you can create the sender and traveler offer. Don’t sweat it - just finish your verification [address, email, WhatsApp number] and you’re ready to go"),
    //     footer:
    //       '<a href="/main_profile?key=second">'+ t('Link to Verification Page')+'</a>',
    //   });
    // } else if (emailVerify === 0) {
    //   Swal.fire({
    //     title: t("Whoops!"),
    //     type: "danger",
    //     text: t("You need to get verified before you can create the sender and traveler offer. Don’t sweat it - just finish your verification [address, email, WhatsApp number] and you’re ready to go"),
    //     footer:
    //       '<a href="/main_profile?key=second">'+ t('Link to Verification Page')+'</a>',
    //   });
    // } else if (
    //   userDetails?.user?.address == null ||
    //   userDetails?.user?.address == ""
    // ) {
    //   Swal.fire({
    //     title: t("Whoops!"),
    //     type: "danger",
    //     text: t("You need to get verified before you can create the sender and traveler offer. Don’t sweat it - just finish your verification [address, email, WhatsApp number] and you’re ready to go"),
    //     footer:
    //       '<a href="/main_profile?key=second">'+ t('Link to Verification Page')+'</a>',
    //   });
    // } else {
    //   navigate("/travellerOffers", {
    //     state: { travelfilterData, travelfilterData },
    //   });
    // }
  }
  useEffect(() => {
    const scrollToLittleBottom = () => {
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, [300])
    }
    scrollToLittleBottom()
  }, [])

  return (
    <div>
      <LoggedinNavbar />
      <Container className="my-5">
        <Row>
          <Col lg={12} md={12} sm={12}>
            <Row>
              <Col lg={6} md={6} sm={12}>
                <div
                  className="card custom_tabel_border_explore rounded-2 my-2 text-center"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <div className="card-body explore-page-sender-image">
                    <img
                      src={exploreSenderImg}
                      alt="profile"
                      className="profile-imgone"
                      onClick={handleFindYourSender}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                  <button className="explore-btn"
                  onClick={handleFindYourSender}
                  >
                    {t('Explore Sender Offers')}
                  </button>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <div
                  className="card custom_tabel_border_explore rounded-2 my-2 text-center"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <div className="card-body explore-page-traveller-image">
                    <img
                      src={exploreTravellerImg}
                      alt="profile"
                      className="profile-imgone img-fluid"
                      onClick={handleFindYourTraveller}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                  <button
                    className=" explore-btn"
                    onClick={handleFindYourTraveller}
                  >
                    {t('Explore Traveller Offers')}
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Explore
