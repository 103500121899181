import React, { Component, useState } from 'react'
import {
  Form,
  Button,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  Container,
  Row,
  Col,
  Alert,
} from 'reactstrap'
import logoo from '../Assets/Images/logoo1.png'

import Step1One from './Step1One'
import Step2One from './Step2One'
import Step3One from './Step3One'
import Step4One from './Step4One'

import LoadingScreen from 'react-loading-screen'
// import Step4One from './Step4One';
import MultiStepProgressBarOne from './MultiStepProgressBarOne'
import { Modal, ModalBody } from 'reactstrap'
// import Step4One from "./Step4One";
// import Step5One from "./Step5One";
import cong from '../Assets/Images/cong.png'
import { HiOutlineArrowLeft, HiOutlineArrowRight } from 'react-icons/hi'

// import Step2 from "./Step2One";
import { createSenderOffer } from '../../redux/actions/TravellerOfferAction'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import SocialMediaButtons from '../Component/Share'
function CreateSenderOfferForm(props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [currentStep, setCurrentStep] = useState(1)
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [hitPaymentApi, setHitPaymentApi] = useState(0)
  //step 1
  //Source
  const [sCountry, setSourceCountry] = useState(null)
  const [sCityAddress, setSourceCity] = useState(null)
  const [sourceAddress, setSourceAddress] = useState(null)
  const [sCountryObject, setSCountryObject] = useState(null)
  const [sZoneId, setSZoneId] = useState(null)

  //Destination
  const [dCountry, setDestinationCountry] = useState(null)
  const [dCityAddress, setDestinationCity] = useState(null)
  const [destinationAddress, setDestinationAddress] = useState(null)
  const [dCountryObject, setDCountryObject] = useState(null)
  const [dZoneId, setDZoneId] = useState(null)

  const [lastMileDelivery, setLastMileDelivery] = useState(false)
  const [firstMilePickup, setFirstMilePickup] = useState(false)
  const [additionalNote, setAditionalNote] = useState(null)

  // Payment Formula states
  const [addOnFirstMile, setAddOnFirstMile] = useState(0)
  const [addOnLastMile, setAddOnLastMile] = useState(0)
  const [addOnServiceInsurance, setAddOnServiceInsurance] = useState(0)
  const [viahopCommission, setViahopCommission] = useState(0)

  //Step 2
  const [offerTime, setOfferTime] = useState(null)

  //Step 3
  const [packageType, setPackageType] = useState(null)
  const [packageValue, setPackageValue] = useState(null)
  const [maxWeight, setMaxWeight] = useState(null)
  const [packageLenght, setPackageLenght] = useState(null)
  const [packageWidth, setPackageWidth] = useState(null)
  const [packageHeight, setPackageHeight] = useState(null)
  const [offerPrice, setOfferPrice] = useState(null)
  const [termsAndCondition, setTermsAndCondition] = useState(false)
  //Step 4
  const [boxedByOriginalManufacturer, setBoxManufacturer] = useState(0)
  const [isInsurance, setInsurance] = useState(0)

  function toggle() {
    setModal(!modal)
  }
  // Use the submitted data to set the state
  function handleChange(event) {}

  // Test current step with ternary
  // _next and _previous function s will be called on button click

  const [errorArray, setError] = useState(null)

  console.log(JSON.stringify(errorArray) + '=====error array')
  async function _next() {
    let currentStepVar = currentStep
    if (currentStepVar === 1) {
      if (
        sCountry?.length > 0 &&
        sCityAddress?.length > 0 &&
        sourceAddress?.length > 0 &&
        dCountry?.length > 0 &&
        dCityAddress?.length > 0 &&
        destinationAddress?.length > 0
      ) {
        currentStepVar = currentStepVar >= 3 ? 4 : currentStepVar + 1
        setCurrentStep(currentStepVar)
        setError(null)
      } else {
        setError(t('Fill in the *starred* fields for a smooth ride!'))
      }
    } else if (currentStepVar === 2) {
      if (offerTime?.length > 0) {
        currentStepVar = currentStepVar >= 3 ? 4 : currentStepVar + 1
        setCurrentStep(currentStepVar)
        setError(null)
      } else {
        setError(t('Fill in the *starred* fields for a smooth ride!'))
      }
    } else if (currentStepVar === 3) {
      if (packageType === 'document envelope') {
        console.log('===================' + packageValue)
        if (
          packageType?.length > 0 &&
          // packageHeight.length > 0 &&
          maxWeight?.length > 0 &&
          // packageLenght.length > 0 &&
          // packageWidth?.length > 0 &&
          packageValue?.length > 0 &&
          // boxedByOriginalManufacturer?.length > 0 &&
          isInsurance?.length > 0 &&
          termsAndCondition === true
        ) {
          currentStepVar = currentStepVar >= 3 ? 4 : currentStepVar + 1
          setCurrentStep(currentStepVar)
          setHitPaymentApi(1)
          setError(null)
        } else {
          setError(t('Fill in the *starred* fields for a smooth ride!'))
        }
      } else {
        if (
          packageType?.length > 0 &&
          packageHeight.length > 0 &&
          maxWeight.length > 0 &&
          packageLenght.length > 0 &&
          packageWidth?.length > 0 &&
          packageValue.length > 0 &&
          boxedByOriginalManufacturer?.length > 0 &&
          isInsurance?.length > 0
        ) {
          currentStepVar = currentStepVar >= 3 ? 4 : currentStepVar + 1
          setCurrentStep(currentStepVar)
          setHitPaymentApi(1)
          setError(null)
        } else {
          setError(t('Fill in the *starred* fields for a smooth ride!'))
        }
      }
    }

    // If the current step is 1 or 2, then add one on "next" button click
  }

  const [emptyTypes, setEmptyTypes] = useState(false)
  function _prev() {
    let currentStepVar = currentStep

    if (currentStepVar == 3) {
      setEmptyTypes(true)
    }
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStepVar = currentStepVar <= 1 ? 1 : currentStepVar - 1

    setCurrentStep(currentStepVar)
  }

  // The "next" and "previous" button functions
  function previousButton() {
    let currentStepVar = currentStep

    // If the current step is not 1, then render the "previous" button
    if (currentStepVar !== 1) {
      return (
        <div className="textright-iconf">
          <Button
            color=" float-left"
            className="profiletop-btnoutline px-4 navdropdown-twohov flexOnSmallScreen"
            onClick={_prev}
          >
            {' '}
            <HiOutlineArrowLeft className="me-2" />
            {t('Back')}
          </Button>
        </div>
      )
    }

    // ...else return nothing
    return null
  }

  function nextButton() {
    let currentStepVarcurrentStepVar = currentStep
    // If the current step is not 3, then render the "next" button
    if (currentStepVarcurrentStepVar < 4) {
      return (
        <div className="textright-iconf">
          {' '}
          <Button
            color="primary float-right"
            className="profiletop-btnoutline px-4 ms-3 navdropdown-twohov  bordt-none"
            onClick={_next}
          >
            {t('Next')}
            <HiOutlineArrowRight className="ms-2" />
          </Button>
        </div>
      )
    }
    // ...else render nothing
    return null
  }

  function submitButton() {
    let currentStepVar = currentStep

    // If the current step is the last step, then render the "submit" button
    if (currentStepVar > 3) {
      return (
        <div className="textright-iconf">
          <Button
            type="button"
            color="primary float-right"
            onClick={handleSubmit}
            className="profiletop-btnoutline navdropdown-twohov px-5 ms-3 bordt-none"
          >
            {t('Done')}
          </Button>
        </div>
      )
    }
    // ...else render nothing
    return null
  }

  // Step 1
  const handleAdditionalNote = (data) => {
    console.log(data.target.value + '=======handle Additional Note')
    setAditionalNote(data.target.value)
  }

  //Source
  const handleSourceCity = (data) => {
    setSourceCity(data)
  }
  const handleSourceCountry = (data) => {
    setSourceCountry(data)
  }

  const handleSourceAddress = (data) => {
    setSourceAddress(data)
  }

  const handleSourceCountryObject = (data) => {
    let sZoneId = data?.zone_id
    setSZoneId(sZoneId)
    setSCountryObject(data)
  }

  //Destination
  const handleDestinationCity = (data) => {
    setDestinationCity(data)
  }
  const handleDestinationCountry = (data) => {
    setDestinationCountry(data)
  }
  const handleDestinantionAddress = (data) => {
    setDestinationAddress(data)
  }
  const handleLastMileDelivery = (data) => {
    // console.log(data+"=======handle Last Mile Delivwery")
    setLastMileDelivery(data)
  }
  const handleFirstMilePickup = (data) => {
    // console.log(data+"=======handle First Mile Pickup")
    setFirstMilePickup(data)
  }

  const handleDestinationCountryObject = (data) => {
    let DzoneId = data?.zone_id
    setDZoneId(DzoneId)
    setDCountryObject(data)
  }

  // Step 2
  const handlePackageType = (data) => {
    console.log(data, '-----------handle packasge type')
    setPackageType(data)
  }

  const handlePackageValue = (data) => {
    setPackageValue(data)
  }

  const handlePackageWidth = (data) => {
    setPackageWidth(data)
  }

  const handlePackagelenght = (data) => {
    setPackageLenght(data)
  }

  const handlePackageHeight = (data) => {
    setPackageHeight(data)
  }
  const handleManufacturer = (data) => {
    console.log(data.target.value)
    setBoxManufacturer(data.target.value)
  }
  const handleInsurance = (data) => {
    // console.log(data.target.value+"======handle insurance master form")
    setInsurance(data)
  }
  const handleTermsAndCondition = (data) => {
    setTermsAndCondition(data)
  }
  const handlePackageWeight = (data) => {
    console.log(data + '=========handle package Wight')
    setMaxWeight(data)
  }

  const handleOfferPrice = (data) => {
    console.log(data + '====offer price')
    // console.log(data.target.value)
    setOfferPrice(data)
  }
  const handleOfferTime = (e) => {
    console.log(e.target.value + '============handle offer Time')
    setOfferTime(e.target.value)
  }
  const handleOfferDays = (e) => {
    console.log(e + '============handle offer Days')
    if (e == 0) {
      setOfferTime(null)
    } else {
      setOfferTime(e + 'Days')
    }
  }
  const handleAsapValue = (e) => {
    if (e.target.checked) {
      console.log(e.target.value + '============handle offer Asap')
      setOfferTime('ASAP')
    } else {
      setOfferTime('')
    }
  }
  useEffect(() => {
    const scrollToLittleBottom = () => {
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, [300])
    }
    scrollToLittleBottom()
  }, [])

  const [senderOfferID, setSenderOfferId] = useState(null)

  const handleResponse = (data) => {
    setLoading(false)
    if (data.status) {
      console.log(JSON.stringify(data) + '=====sender offer response')
      setSenderOfferId(data.offer?.id)
      setModal(true)
      // setVerificationModal(false)
    } else {
      setLoading(false)
    }
  }

  const [vcFee, setVcFee] = useState()
  const [insurFee, setInsurFee] = useState()
  const [firstMileFeePrice, setfirstMileFeePrice] = useState()
  const [lastMileFeePrice, setlastMileFeePrice] = useState()
  const [servicePriceCost, setservicePriceCost] = useState()

  const handleOtherPrices = (data) => {
    const { vc, insur, firstMileFee, lastMileFee, servicePrice } = data
    setVcFee(Math.round(vc))
    setInsurFee(Math.round(insur))
    setfirstMileFeePrice(Math.round(firstMileFee))
    setlastMileFeePrice(Math.round(lastMileFee))
    setservicePriceCost(Math.round(servicePrice))
  }
  // Trigger an alert on form submission
  const handleSubmit = () => {
    setLoading(true)
    let data = {
      sCountry: sCountry,
      sCity: sCityAddress,
      sAddress: sourceAddress,
      dCountry: dCountry,
      dCity: dCityAddress,
      dAddress: destinationAddress,
      additionalNotes: additionalNote,
      offerTime: offerTime,
      packageType: packageType,
      packageValue: packageValue,
      maxWeight: maxWeight,
      packageLenght: packageLenght,
      packageWidth: packageWidth,
      packageHeight: packageHeight,
      boxedByOriginalManufacturer: boxedByOriginalManufacturer,
      requireTransitInsurance: isInsurance,
      offerAmount: offerPrice,
      payment_status: 'pending',
      isFirstMilePickUp: firstMilePickup,
      isLastMileDelivery: lastMileDelivery,
      FirstMilePickUpCost: firstMileFeePrice,
      LastMileDeliveryCost: lastMileFeePrice,
      insuranceCost: insurFee,
      serviceCost: servicePriceCost,
      viahopCommisionCost: vcFee,
      totalCost: offerPrice,
    }
    dispatch(createSenderOffer(data, handleResponse))
  }
  return (
    <LoadingScreen
      loading={loading}
      bgColor="#f1f1f1"
      spinnerColor="#9ee5f8"
      textColor="#676767"
      logoSrc={logoo}
      text={t('Hang tight while we do our magic!')}
    >
      <div className="bg-white respons-main">
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Form onSubmit={handleSubmit}>
                <CardBody>
                  <CardTitle className="py-5 mrt-pr">
                    <Row className="justify-content-center">
                      <Col lg={8} md={8} sm={12}>
                        <MultiStepProgressBarOne currentStep={currentStep} />
                      </Col>
                    </Row>
                  </CardTitle>
                  <CardText />
                  <Step1One
                    currentStep={currentStep}
                    handleChange={handleChange}
                    additionalNote={handleAdditionalNote}
                    sourceCity={handleSourceCity}
                    sourceCountry={handleSourceCountry}
                    sourceAddress={handleSourceAddress}
                    destinationCity={handleDestinationCity}
                    destinationCountry={handleDestinationCountry}
                    destinationAddress={handleDestinantionAddress}
                    lastMileDelivery={handleLastMileDelivery}
                    firstMilePickup={handleFirstMilePickup}
                    sourceCountryObject={handleSourceCountryObject}
                    destinationCountryObject={handleDestinationCountryObject}
                  />
                  <Step4One
                    currentStep={currentStep}
                    getOfferTime={handleOfferTime}
                    getOfferDays={handleOfferDays}
                    getAsapValue={handleAsapValue}
                  />
                  <Step2One
                    currentStep={currentStep}
                    emptyTypes={emptyTypes}
                    dCountryObject={dCountryObject}
                    sCountryObject={sCountryObject}
                    packageType={handlePackageType}
                    packageWight={handlePackageWeight}
                    packageHeight={handlePackageHeight}
                    packageLenght={handlePackagelenght}
                    packageWidth={handlePackageWidth}
                    packageValue={handlePackageValue}
                    isOrignalManufacturer={handleManufacturer}
                    isInsurance={handleInsurance}
                    termsandcondition={handleTermsAndCondition}
                    // handleAllowedShipment={}
                  />
                  <Step3One
                    currentStep={currentStep}
                    sCountryObject={sCountryObject}
                    dCountryObject={dCountryObject}
                    hitPaymentApi={hitPaymentApi}
                    offerPrice={handleOfferPrice}
                    isInsurance={isInsurance}
                    isOrignalManufacturer={boxedByOriginalManufacturer}
                    lastMileDelivery={lastMileDelivery}
                    firstMilePickup={firstMilePickup}
                    packageType={packageType}
                    addOnFirstMile={addOnFirstMile}
                    addOnLastMile={addOnLastMile}
                    addOnServiceInsurance={addOnServiceInsurance}
                    viahopCommission={viahopCommission}
                    maxWeight={maxWeight}
                    packageValue={packageValue}
                    handleOtherPrices={handleOtherPrices}
                  />
                  {errorArray && (
                    <Alert style={{ marginTop: '10px' }} color="warning">
                      {errorArray}
                    </Alert>
                  )}
                </CardBody>
                <CardFooter className="mutistep-btn-direction my-5">
                  {previousButton()}
                  {nextButton()}
                  {submitButton()}
                </CardFooter>
              </Form>

              <Modal
                isOpen={modal}
                toggle={toggle}
                className={props.className}
                backdrop="static"
              >
                {/* <ModalHeader toggle={toggle}>Modal title</ModalHeader> */}
                <ModalBody>
                  <Row>
                    <Col lg={12} md={12} sm={12}>
                      <div className="text-center pt-4">
                        <img src={cong} alt="cong" width="100" />
                      </div>
                      <div className="pb-4">
                        <h2 className="orange-color fontSize32-600 pt-4 text-center">
                          {t('Congratulations')}!
                        </h2>
                        <p className="fontSize18-500 text-center">
                          {t('Order')}#SO-{senderOfferID}
                        </p>
                        <p className="fontSize14-400 text-center mb-0">
                          {t('We have recieved your order details')},
                        </p>
                        <p className="fontSize14-400 text-center">
                          {t('You’ll be shortly notified on your contact info')}
                          !
                        </p>
                        <div className="text-center">
                          <Link to="/main_profile">
                            <Button
                              onClick={toggle}
                              className="orange-background fontSize16-500 px-5 bordt-none"
                              size="md"
                            >
                              {t('Done')}
                            </Button>
                          </Link>
                        </div>
                        <div className="text-right" style={{textAlign:'right'}}>
                          <SocialMediaButtons
                            url={
                              'https://viahop.net/explore?from=link&type=sender'
                            }
                            title={'New Offer'}
                            text={'Explore Sender Offers'}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Container>
      </div>
    </LoadingScreen>
  )
}

export default CreateSenderOfferForm
