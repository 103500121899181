import React from "react";
import Header from "../Component/Header";
import LoggedinNavbar from "../Component/LoggedinNavbar";
import SecondNav from "./SecondNav";

export default function StripePaymentCancel() {
  const token = localStorage.getItem("token");
  return (
    <>
      {!token ? (
        <Header />
      ) : (
        <>
          <LoggedinNavbar />
          {/* <SecondNav /> */}
        </>
      )}
      <div className="container mt-5 pt-5 mb-5 pb-4">
        <div className="row justify-content-center head-about">
          <div className="col-8 text-center">
            <h1>Payment Cancel</h1>
            {/* <h1 style={{color:'black'}}>Our Users Say About Us</h1> */}
            <div className="mt-5">
              <h3>Oops! Something Went Wrong</h3>
              {/* <h3 style={{fontWeight:'700',color:'#F69110'}}>Jaydon Bator</h3>
            <p>Professional Artist</p> */}
              <p>While trying to reserve money from your account</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
