import axios from "axios";
import { ApiUrl } from "../../ApiUrl";
import { GET_ALL_CITIES } from "../types";

export const getAllCities = () => (dispatch) => {
  const accessToken = localStorage.getItem("token");
  var config = {
    method: "get",
    url: ApiUrl.server_url + "auth/city-list",
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  };

  axios(config).then((res) => {
    // if (!res.data.status) {
    //   localStorage.removeItem("token");
    // }
    dispatch({ type: GET_ALL_CITIES, payload: res.data.data });
  });
};
