import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

function CounterMultiThree(props) {
  const { t } = useTranslation();
  const [counter, setCounter] = useState(0);
  const [disableMinus, setDisableMinus] = useState(true);

  const handleClick1 = () => {
    setCounter(counter + 1);
    props.getOfferDays(counter + 1);
    if (counter + 1 > 0) {
      setDisableMinus(false);
    } else {
      setDisableMinus(true);
    }
  };
  const handleClick2 = () => {
    setCounter(counter - 1);
    props.getOfferDays(counter - 1);
    if (counter <= 1) {
      setDisableMinus(true);
    } else {
      setDisableMinus(false);
    }
  };
  return (
    <div>
      <Button
        className="profiletop-btnoutline bordt-none counterbtn navdropdown-twohov"
        onClick={handleClick2}
        disabled={disableMinus}
      >
        {" "}
        -
      </Button>
      <span className="mx-2">{counter}</span>
      <Button
        className="profiletop-btnoutline bordt-none counterbtn navdropdown-twohov"
        onClick={handleClick1}
      >
        +
      </Button>
      <span className="fontSize18-500 ms-3">{t("Days Flexible")}</span>
    </div>
  );
}

export default CounterMultiThree;
