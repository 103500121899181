import { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Link, useNavigate } from 'react-router-dom'
import '../SamllComponent/MultitabBtn.css'
import MyOfferDetails from './MyOfferDetails'
import {
  getTravellerOffers,
  updateOfferStatus,
} from '../../redux/actions/TravellerOfferAction'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, Label, Input, Button } from 'reactstrap'
import RateYourDelivery from './RateYourDelivery'
import OfferDetails from './OfferDetails'
import { useTranslation } from 'react-i18next'

function MyTravellerOffers() {
  const dispatch = useDispatch()
  const [paymentStatus, setPaymentStatus] = useState(null)
  //Reducer
  const { travellerOfferDetail } = useSelector(
    (state) => state.TravellerOfferReducer,
  )

  const { userTravellerOffers } = useSelector(
    (state) => state.TravellerOfferReducer,
  )
  const [isDetail, setShowDetail] = useState(false)
  const [isDetailSenderOffer, setShowSenderOfferDetail] = useState(false)

  const [isRated, setRateDelivery] = useState(false)
  const [deliveryId, setDeliveryID] = useState(null)
  const [userId, setUserId] = useState(null)
  const [eventKey, setEventKey] = useState('home')
  const [offerId, setOfferID] = useState(null)
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    console.log(
      JSON.stringify(travellerOfferDetail) +
        '==========traveller offer details',
    )
    setPaymentStatus(travellerOfferDetail?.payment_status)
  })

  useEffect(() => {
    dispatch(getTravellerOffers())

    console.log(
      JSON.stringify(userTravellerOffers) +
        '=============user traveller offer in multi tab button',
    )
  }, [dispatch])

  const [offerDetailData, setOfferDetailData] = useState(null)
  const [offerType, setOfferType] = useState('')

  const handleOfferDetail = (data, type) => {
    console.log(type, '====================')
    setOfferDetailData(data)
    if (type == 'sender') {
      setShowSenderOfferDetail(true)
    } else {
      setShowDetail(true)
    }
  }
  const handleShowDetails = () => {
    setShowDetail(false)
    setEventKey('home')
  }
  const handleShowDetailsSenderOffer = () => {
    setShowSenderOfferDetail(false)
    setEventKey('home')
  }

  // Nauman Code Start's
  const handleRateDelivery = (value, type) => {
    setOfferType(type)
    setRateDelivery(true)
    setDeliveryID(value?.id)
    setUserId(value?.user_id)
    console.log(value, 'asdfghjk')
  }
  const handleRatePage = () => {
    setRateDelivery(false)
    setEventKey('profile')
  }
  if (isRated) {
    return (
      <RateYourDelivery
        offerType={offerType}
        userId={userId}
        isRatingPage={handleRatePage}
        id={deliveryId}
      />
    )
  }
  // Nauman Code End's
  if (isDetail) {
    return (
      <MyOfferDetails
        userType={'traveller'}
        offerType={'traveller'}
        isShowDetails={handleShowDetails}
        id={offerId}
        offerAcceptedbyMe={false}
        offerDetailData={offerDetailData}
        paymentStatus={paymentStatus}
      />
    )
  }

  if (isDetailSenderOffer) {
    return (
      <MyOfferDetails
        userType={'traveller'}
        offerAcceptedbyMe={true}
        offerType={'sender'}
        isShowDetails={handleShowDetailsSenderOffer}
        id={offerId}
        offerDetailData={offerDetailData}
        paymentStatus={paymentStatus}
      />
    )
  }
  return (
    <Tabs
      defaultActiveKey={eventKey}
      id="fill-tab-example"
      className="mb-3 mx-auto multi-btntwo fontSize16-500 "
      fill
    >
      <Tab
        eventKey="home"
        title={t('Active')}
        style={{ overflow: 'scroll' }}
        // className="py-3 "
      >
        <>
          <Button
            className="px-3 ms-2 mb-3 back-orange bordt-none"
            onClick={() => {
              navigate('/create_traveller_offer')
            }}
          >
            {t('Create Traveller Offer')}
          </Button>
          <p className="fontSize24-600 txtleft darkgraytext pb-0">
            {t('Your Traveller Offers')}
          </p>
          <table className="table custom_tabel_border_2">
            <thead style={{ backgroundColor: '#F69110', color: 'white' }}>
              <tr>
                <th scope="col">#</th>
                <th scope="col">{t('Order')}#</th>
                <th scope="col">{t('Order Created Date')}</th>
                <th scope="col">{t('From')}</th>
                <th scope="col">{t('To')}</th>
                <th scope="col">{t('Type')}</th>
                <th scope="col">{t('Order Date')}</th>
                <th scope="col">{t('Offer Status')}</th>
                {/* <th scope="col">{t('Payment')}</th> */}
                <th scope="col">{t('Action')}</th>
              </tr>
            </thead>
            <tbody>
              {userTravellerOffers?.travelerOffers?.length > 0 ? (
                userTravellerOffers?.travelerOffers.map((value, index) => {
                  if (value?.status !== 'Complete and Paid') {
                    return (
                      <tr>
                        <td>{index + 1} </td>
                        <td>
                          {t('TO')}-{value.id}{' '}
                        </td>
                        <td>{value?.createdAt?.split('T')[0]}</td>
                        <td> {value?.s_country + ' '}</td>
                        <td>{' ' + value?.d_country}</td>
                        <td>{t(value?.package_type)}</td>{' '}
                        <td>{value?.offer_time}</td>
                        <td>{t(value?.status)}</td>
                        {/* <td>{value?.offer_price}$</td> */}
                        <td>
                          <a
                            href="#"
                            onClick={() =>
                              handleOfferDetail(value, 'traveller')
                            }
                            className="table_detail_a"
                          >
                            {t('See Details')}
                          </a>
                        </td>
                      </tr>
                    )
                  }
                })
              ) : (
                <tr>
                  <td colspan="10">
                    <h3 style={{ color: 'gray', fontSize: '1.2rem' }}>
                      {t('There is no Active Order')}
                    </h3>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <p className="fontSize24-600 txtleft darkgraytext pb-0">
            {t('Your Accepted Offers')}
          </p>
          <table className="table custom_tabel_border_2">
            <thead style={{ backgroundColor: '#F69110', color: 'white' }}>
              <tr>
                <th scope="col">#</th>
                <th scope="col">{t('Order')}#</th>
                <th scope="col">{t('Order Created Date')}</th>
                <th scope="col">{t('From')}</th>
                <th scope="col">{t('To')}</th>
                <th scope="col">{t('Type')}</th>
                <th scope="col">{t('Order Date')}</th>
                <th scope="col">{t('Offer Status')}</th>
                <th scope="col">{t('Payment')}</th>
                <th scope="col">{t('Action')}</th>
              </tr>
            </thead>
            <tbody>
              {userTravellerOffers?.acceptedSenderOffers?.length > 0 ? (
                userTravellerOffers?.acceptedSenderOffers.map(
                  (value, index) => {
                    return (
                      <>
                        <tr>
                          <td>{++index}</td>
                          <td>SO-{value?.senderOffer?.id}</td>
                          <td>{value?.createdAt?.split('T')[0]}</td>
                          <td>{value?.senderOffer?.s_country}</td>
                          <td>{value?.senderOffer?.d_country}</td>
                          <td>{t(value?.senderOffer?.package_type)}</td>
                          <td>{t(value?.senderOffer?.offer_time)}</td>
                          <td>{t(value?.senderOffer?.status)}</td>
                          <td>{value?.senderOffer?.service_cost + ' $'}</td>

                          <td>
                            <a
                              href="#"
                              onClick={() =>
                                handleOfferDetail(value?.senderOffer, 'sender')
                              }
                              className="table_detail_a"
                            >
                              {t('See Details')}
                            </a>
                          </td>
                        </tr>
                      </>
                    )
                  },
                )
              ) : (
                <tr>
                  <td colspan="10">
                    <h3 style={{ color: 'gray', fontSize: '1.2rem' }}>
                      {t('There is no Accepted Offer')}
                    </h3>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      </Tab>
      <Tab
        eventKey="profile"
        style={{ overflow: 'scroll' }}
        title={t('History')}
      >
        <Button
          className="px-3 ms-2 mb-3 back-orange bordt-none"
          onClick={() => {
            navigate('/create_sender_offer')
          }}
        >
          {t('Create Traveller Offer')}
        </Button>
        <p className="fontSize24-600 txtleft darkgraytext pb-0">
          {t('Your Created Offer')}
        </p>

        <table className="table custom_tabel_border_2">
          <thead style={{ backgroundColor: '#F69110', color: 'white' }}>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{t('Order')}#</th>
              <th scope="col">{t('From')}</th>
              <th scope="col">{t('To')}</th>
              <th scope="col">{t('Type')}</th>
              <th scope="col">{t('When')}</th>
              <th scope="col">{t('Offer Status')}</th>
              <th scope="col">{t('Payment')}</th>
              {/* <th scope="col">Verification</th> */}
              <th scope="col">{t('Delivery status')}</th>
              <th scope="col">{t('Rate Your Delivery')}</th>
            </tr>
          </thead>
          <tbody>
            {userTravellerOffers?.length > 0 ? (
              userTravellerOffers.map((value, index) => {
                if (value?.status == 'Complete and Paid') {
                  return (
                    <>
                      <tr>
                        <td>{++index}</td>
                        <td>
                          {t('TO')}-{value?.id}
                        </td>
                        <td>{value?.s_country}</td>
                        <td>{value?.d_country}</td>
                        <td>{t(value?.package_type)}</td>
                        <td>{t(value?.offer_time)}</td>
                        <td>{t(value?.status)}</td>
                        <td>{value?.offer_price}</td>
                        <td>{t(value?.status)}</td>
                        <td>
                          {value?.rating ? (
                            <span className="orange-color">{t('Rated')}</span>
                          ) : (
                            <a
                              href="#"
                              onClick={() =>
                                handleRateDelivery(value, 'travelerOffer')
                              }
                              className="table_detail_a"
                              style={{ textDecoration: 'none' }}
                            >
                              <span className="orange-color">
                                {t('Add Rating')}
                              </span>
                            </a>
                          )}
                        </td>
                      </tr>
                    </>
                  )
                }
              })
            ) : (
              <tr>
                <td colspan="10">
                  <h3 style={{ color: 'gray', fontSize: '1.2rem' }}>
                    {t('There is no Active Order')}
                  </h3>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <p className="fontSize24-600 txtleft darkgraytext pb-0">
          {t('Your Accepted Offer in')}:
        </p>

        <table className="table custom_tabel_border_2">
          <thead style={{ backgroundColor: '#F69110', color: 'white' }}>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{t('Order')}#</th>
              <th scope="col">{t('From')}</th>
              <th scope="col">{t('To')}</th>
              <th scope="col">{t('Type')}</th>
              <th scope="col">{t('When')}</th>
              <th scope="col">{t('Offer Status')}</th>
              <th scope="col">{t('Payment')}</th>
              <th scope="col">{t('Delivery status')}</th>
              <th scope="col">{t('Rate Your Delivery')}</th>
            </tr>
          </thead>
          <tbody>
            {userTravellerOffers?.acceptedSenderOffers?.length > 0 ? (
              userTravellerOffers?.acceptedSenderOffers?.map((value, index) => {
                if (value?.senderOffer?.status === 'Complete and Paid') {
                  return (
                    <>
                      <tr>
                        <td>{++index}</td>
                        <td>SO-{value?.senderOffer?.id}</td>
                        <td>{value?.senderOffer?.s_country}</td>
                        <td>{value?.senderOffer?.d_country}</td>
                        <td>{t(value?.senderOffer?.package_type)}</td>
                        <td>{value?.senderOffer?.offer_time}</td>
                        <td>{t(value?.senderOffer?.status)}</td>
                        <td>{value?.senderOffer?.total_cost + ' $'}</td>
                        <td>{t(value?.senderOffer?.status)}</td>
                        <td>
                          {value?.senderOffer?.rating ? (
                            <span className="orange-color">{t('Rated')}</span>
                          ) : (
                            <a
                              href="#"
                              onClick={() =>
                                handleRateDelivery(
                                  value?.senderOffer,
                                  'senderOffer',
                                )
                              }
                              className="table_detail_a"
                              style={{ textDecoration: 'none' }}
                            >
                              <span className="orange-color">
                                {t('Add Rating')}
                              </span>
                            </a>
                          )}
                        </td>
                      </tr>
                    </>
                  )
                }
              })
            ) : (
              <tr>
                <td colspan="10">
                  <h3 style={{ color: 'gray', fontSize: '1.2rem' }}>
                    {t('There is no Accepted Order')}
                  </h3>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {/* <div className="borderbotm py-3">
          <p className="fontSize24-600 txtleft darkgraytext pb-0">
            Florida to Puerto Rico
          </p>
          <ul className="fontSize16-500 darkgraytext  txtleft list-type p-0 m-0 ">
            <li className=" text-left">Order#: TR123323 </li>
            <li>Recepients address: 34th west street 11, Florida </li>
            <li> Delivery Date: 21-11-2022</li>
            <li>Packages: 2, 3.5Kg </li>
            <li>Address: 34th west street 11, Florida </li>
            <li>Status: In Transit </li>
            <li>Payment: Unpaid </li>
          </ul>
        </div> */}
        {/* <div className="borderbotm py-3">
          <p className="fontSize24-600 txtleft darkgraytext pb-0">
            Florida to Puerto Rico
          </p>
          <ul className="fontSize16-500 darkgraytext  txtleft list-type p-0 m-0 ">
            <li className=" text-left">Order#: TR123323 </li>
            <li> Delivery Date: 21-11-2022</li>
            <li>Packages: 2, 3.5Kg </li>
            <li>Address: 34th west street 11, Florida </li>
            <li>Status: In Transit </li>
            <li>
              Payment: <span className="text-danger"> Unpaid</span>
              <span className="orange-color">(Click to Pay) </span>{' '}
            </li>
          </ul>
        </div> */}
      </Tab>
    </Tabs>
  )
}

export default MyTravellerOffers
