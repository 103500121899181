import { t } from 'i18next'
import React from 'react'
import { Button } from 'react-bootstrap'

const SocialMediaButtons = ({ url, title, text }) => {
  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          url: url,
          title: title,
          text: text,
        })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.log('Error sharing:', error))
    } else {
      console.log('Native share not supported')
    }
  }

  return (
    <button
      onClick={handleShare}
      className=" fontSize16-500 bordt-none"
      size="md"
    >
    <span> {t('Share')}</span>
      <i style={{marginLeft:'6px'}} class="fas fa-share"></i>
    </button>
  )
}

export default SocialMediaButtons
