import React, { useEffect, useRef, useState } from 'react'
// import Carousel from 'react-bootstrap/Carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useTranslation } from 'react-i18next'
import '../SamllComponent/Skidertes.css'

import russianSlide1 from '../Assets/slider/russian/slide1.png'
import russianSlide2 from '../Assets/slider/russian/slide2.jpg'
import russianSlide3 from '../Assets/slider/russian/slide3.jpg'

import englishSlide1 from '../Assets/slider/english/slide1.png'

// import elboy from '../Assets/Images/elboy.png'

function HeroSectionSlider(props) {

  const videoRef = useRef(null)
  const { t } = useTranslation()
  const russianImageSliderData = [
    {
      image: russianSlide1,
    },
    {
      image: russianSlide2,
    },
    {
      image: russianSlide3,
    },
  ]

  const englishImageSliderData = [
    {
      image: englishSlide1,
      type: 'image',
    },
    {
      image: englishSlide1,
      type: 'video',
    },
  ]

  return (
    <Carousel variant="dark  h-25"  showIndicators={true}
    autoPlay={true}
    infiniteLoop={true}
    showArrows={false}
    showThumbs={false}
    interval={3000}>
      {props.images == 'russian'
        ? russianImageSliderData?.map((value, index) => {
            return (
              
                <div className="pt-0">
                  <img
                    src={value?.image}
                    alt="profile"
                    className="profile-imgone pt-0"
                    width="100%"
                  />
                </div>
            )
          })
        : englishImageSliderData?.map((value, index) => {
            if (value?.type == 'image') {
              return (
                  <div className="pt-0">
                    <img
                      src={value?.image}
                      alt="profile"
                      className="profile-imgone pt-0"
                      width="100%"
                    />
                  </div>
              )
            } else {
              return (
                  <div className="pt-0">
                    <video controls style={{width:'100%'}}>
                      <source
                        src="https://hhq.acr.mybluehost.me/viahop/viahop-english.mov"
                        type="video/mp4"
                        autoPlay={true}
                      />
                    </video>
                    {/* <Iframe url="https://hhq.acr.mybluehost.me/viahop/viahop-english.mov"
        width="640px"
        height="320px"
        id=""
        className=""
        display="block"
        position="relative"/> */}
                  </div>
              )
            }
          })}
    </Carousel>
  )
}

export default HeroSectionSlider
