import axios from "axios";
import { ApiUrl } from "../../ApiUrl";
import { GIVE_RATING, GET_MY_RATINGS, SEE_USER_RATINGS } from "../types";
import { getTravellerOffers } from "./TravellerOfferAction";

export const giveRatingToUser = (data, handleResponse) => (dispatch) => {
  const accessToken = localStorage.getItem("token");

  var config = {
    method: "post",
    url: ApiUrl.server_url + "auth/give-rating",
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios(config)
    .then((res) => {
      handleResponse(res.data);
      dispatch({
        type: GIVE_RATING,
        payload: res.data.data,
      });
      dispatch(getTravellerOffers())
    })
    .catch((error) => {
      handleResponse(error?.response);
    });
};
export const getAllMyRating = () => (dispatch) => {
  const accessToken = localStorage.getItem("token");

  var config = {
    method: "get",
    url: ApiUrl.server_url + "auth/my-ratings",
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  };

  axios(config).then((res) => {
    dispatch({
      type: GET_MY_RATINGS,
      payload: res.data.data,
    });
  });
};

export const getSingleUserRating = (data, handleResponse) => (dispatch) => {
  const accessToken = localStorage.getItem("token");
  var config = {
    method: "post",
    url: ApiUrl.server_url + "auth/user-ratings",
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then((res) => {
      handleResponse(res.data);
      dispatch({
        type: SEE_USER_RATINGS,
        payload: res.data.data,
      });
    })
    .catch((error) => {
      handleResponse(error?.response);
    });
};
