import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import profile from "../Assets/Images/profile.png";

// import LoggedinNavbar from '../Components/LoggedinNavbar';

function LoggedInUser() {
  return (
    <div>
        
          <section className='py-5'>
        <Container className='py-4'>
          <Row className="py-5">
            <Col lg={5} md={6} sm={12}>
              <div>
                <h1 className="fontSize64-700 text-dark">
                  The <span className="orange-color">Best Move </span> you’ll
                  ever make!
                </h1>
                <p className="fontSize24-400 pt-5">
                  We connect users with travellers heading to or leaving from
                  any location in the world so they may send, order and receive
                  packages.
                </p>
                <h3 className="orange-color text-center fontSize32-600">
                  Get Started As:
                </h3>
              </div>
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <div className="d-grid gap-2 py-3">
                    <Button
                      className="profiletop-btnoutline py-2 fontSize18-500 bordt-none"
                      size="lg"
                      block
                    >
                      Sender
                    </Button>
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <div className="d-grid gap-2 py-3">
                    <Button
                      className=" py-2 profiletop-btnoutline fontSize18-500 bordt-none"
                      size="lg"
                      block
                    >
                      Traveler
                    </Button>
                  </div>
                </Col>
              </Row>
              <div>
                <div className="d-grid gap-2 py-3">
                  <Button
                    className=" py-2 profiletop-btnoutline fontSize18-500 bordt-none"
                    size="lg"
                    block
                  >
                    How It Works?
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg={7} md={6} sm={12}>
              <div className="pt-5">
                <img
                  src={profile}
                  alt="profile"
                  className="profile-imgone"
                  width="100%"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default LoggedInUser