import React from "react";
import { useTranslation } from "react-i18next";
import { Col, FormGroup, Label, Row } from "reactstrap";

export default function PayTheBill(props) {
  const { t } = useTranslation();
  return (
    <Row className="card">
      <div className="card-body">
        <Col lg={12} md={12} sm={12}>
          {/* -----------Ahmad changing------------ */}

          <p style={{ textAlign: "right" }}></p>
          <FormGroup>
            <Label className="ms-1 d-flex mb-0">
              {t("Select your desired payment method")}:
            </Label>
            <br />
            <div className="input-group">
              <select className="form-control">
                <option selected>{t("Select Payment Method")}</option>
                <option value="Stripe">{t("Stripe")}</option>
                <option value="paypal">{t("Paypal")}</option>
              </select>
            </div>
          </FormGroup>
        </Col>
      </div>
    </Row>
  );
}
