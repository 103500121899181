import React, { useState } from "react";
import { getPricingDetails } from "../../redux/actions/TravellerOfferAction";
import { getSingleCountryPaymentMethod } from "../../redux/actions/PaymentFormulaAction";
import { useDispatch, useSelector } from "react-redux";
import nameAndLogo from "../Assets/Images/logoo1.png";
import LoadingScreen from "react-loading-screen";
//React Strap Components
import {
  FormGroup,
  Label,
  Row,
  Col,
  Button,
  Input,
  Container,
} from "reactstrap";

//React Bootstrap Components
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

//CSS
import "../SamllComponent/MultitabBtn.css";

// Assets
import cong from "../Assets/Images/cong.png";
import { useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const Step3One = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { offerPricing } = useSelector((state) => state.TravellerOfferReducer);
  const { singleCountryPaymentMethod } = useSelector(
    (state) => state.PaymentMethodReducer
  );

  //props
  //   const {
  // currentStep,
  // sCountryObject,
  // dCountryObject,
  // hitPaymentApi,
  // offerPrice,
  // isInsurance,
  // isOrignalManufacturer,
  // lastMileDelivery,
  // firstMilePickup,
  // packageType,
  // addOnFirstMile,
  // addOnLastMile,
  // addOnServiceInsurance,
  // vc:viahopCommission

  //   }= props

  //-------------states-------------
  const [loading, setLoading] = useState(false);
  const [sZoneId, setSZoneId] = useState(null);
  const [dZoneId, setDZoneId] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);
  const [show, setShow] = useState(false);
  const [viahopCommission, setViahopCommission] = useState(0);
  const [giveOwnOffer, setOwnOffer] = useState(false);
  const [lastMileDisable, setLastMileDisable] = useState(false);
  const [firstMileDisable, setFirstMileDisable] = useState(false);
  const [insuranceDisable, setInsuranceDisable] = useState(false);
  const [servicePriceBefore, setServicePriceBefore] = useState(0);
  const [servicePrice, setServicePrice] = useState(0);
  const [serviceCost, setServiceCost] = useState(null);
  const [insuranceFee, setInsurance] = useState(0);
  const [insuranceChecked, setInsuranceCheck] = useState(false);
  const [insura, setInsura] = useState(0);
  const [firstMileChecked, setFirstMileCheck] = useState(false);
  const [firstMileFee, setFirstMileFee] = useState(0);
  const [lastMileChecked, setLastMileCheck] = useState(false);
  const [lastMileFee, setLastMileFee] = useState(0);
  const [ownCost, setOwnCost] = useState(0);
  const [isEnableConfirm, setEnable] = useState(false);
  const [costWarningMessage, setCostWarningMessage] = useState(null);

  // const {t} = useTranslation()

  useEffect(() => {
    dispatch(getSingleCountryPaymentMethod());
  }, []);

  // console.log(
  //   JSON.stringify(props.sCountryObject) +
  //     "=========== source country object in the last step"
  // );
  // console.log(
  //   JSON.stringify(props.dCountryObject) +
  //     "=========== destination country object in the last step"
  // );
  // console.log(
  //   JSON.stringify(singleCountryPaymentMethod) +
  //     "======= single country payment method in last step"
  // );

  // check last mile and inssurence available or not useEffect
  useEffect(() => {
    let lastmilePossible = props.dCountryObject?.is_last_mile_delivery_possible;
    if (lastmilePossible == 0) {
      setLastMileDisable(true);
    } else {
      setLastMileDisable(false);
    }
    let inssurancePossible = props.dCountryObject?.is_insurance_possible;
    if (inssurancePossible == 0) {
      setInsuranceDisable(true);
    } else {
      setInsuranceDisable(false);
    }
    let DzoneId = props.dCountryObject?.zone_id;
    setDZoneId(DzoneId);
  }, [props.dCountryObject]);

  // check first mile available or not useEffect
  useEffect(() => {
    let firstMilePossible = props.sCountryObject?.is_first_mile_pickup_possible;
    if (firstMilePossible == 0) {
      setFirstMileDisable(true);
    } else {
      setFirstMileDisable(false);
    }
    let sZoneId = props.sCountryObject?.zone_id;
    // console.log(sZoneId + "============== setting s zone id");
    setSZoneId(sZoneId);
  }, [props.sCountryObject]);

  useEffect(() => {
    dispatch(getPricingDetails());
  }, []);

  const { t } = useTranslation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleAcceptOffer = () => {
    setOwnOffer(false);
  };

  const handleOwnOffer = () => {
    setOwnOffer(true);
  };

  //Payment Formula Extractor Function
  const handleGetCountryPaymentFormula = () => {
    setLoading(true);
    if (sZoneId != null || dZoneId != null) {
      // console.log("==========payment Formula function");
      // console.log(sZoneId + "======from and to zone id");
      // console.log(dZoneId + "======to and to zone id");

      let data = {
        from_zone_id: sZoneId,
        to_zone_id: dZoneId,
      };
      dispatch(
        getSingleCountryPaymentMethod(data, handlePaymentFormulaResponse)
      );
    } else {
      console.log("payment formula else");
    }
  };
  // let servicePrice = 0;

  const handlePaymentFormulaResponse = (data) => {
    if (data.status) {
      setLoading(false);
      console.log(
        JSON.stringify(data) +
          "========================================================================= payment api response"
      );
      
        if (props.packageType == 'document envelope') {
          // console.log("1");
          let w = props.maxWeight.split(" ")[0] * data?.data?.document_weight_surcharge;
          console.log(w + "============ max Weight");
          let totalPrice =
            data?.data?.document_variable_rate +
            data?.data?.document_fixed_handling_rate +
            props.maxWeight.split(" ")[0] * data?.data?.document_weight_surcharge;
          // if(totalPrice != undefined){
          setServicePrice(totalPrice);
          setServicePriceBefore(totalPrice);
          // }
        }else if(props.packageType == 'small box') {
          // console.log("1");
          let w = props.maxWeight.split(" ")[0] * data?.data?.small_box_weight_surcharge;
          console.log(w + "============ max Weight");
          let totalPrice =
            data?.data?.small_box_variable_rate +
            data?.data?.small_box_fixed_handling_rate +
            props.maxWeight.split(" ")[0] * data?.data?.small_box_weight_surcharge;
          // if(totalPrice != undefined){
          setServicePrice(totalPrice);
          setServicePriceBefore(totalPrice);
          // }
        }else if(props.packageType == 'medium box') {
          // console.log("1");
          let w = props.maxWeight.split(" ")[0] * data?.data?.medium_box_weight_surcharge;
          console.log(w + "============ max Weight");
          let totalPrice =
            data?.data?.medium_box_variable_rate +
            data?.data?.medium_box_fixed_handling_rate +
            props.maxWeight.split(" ")[0] * data?.data?.medium_box_weight_surcharge;
          // if(totalPrice != undefined){
          setServicePrice(totalPrice);
          setServicePriceBefore(totalPrice);
          // }
        }else if(props.packageType == 'large box') {
          // console.log("1");
          let w = props.maxWeight.split(" ")[0] * data?.data?.large_box_weight_surcharge;
          console.log(w + "============ max Weight");
          let totalPrice =
            data?.data?.large_box_variable_rate +
            data?.data?.large_box_fixed_handling_rate +
            props.maxWeight.split(" ")[0] * data?.data?.large_box_weight_surcharge;
          // if(totalPrice != undefined){
          setServicePrice(totalPrice);
          setServicePriceBefore(totalPrice);
          // }
        }
      
      console.log(servicePrice + "============ service price");
      // console.log(w + "============ max Weight");
    } else {
      setLoading(false);
      // console.log(data.message);
      Swal.fire({
        title: t("Whoops!"),
        type: "danger",
        text: t(
          "No Payment Formula Added by the admin for this zone, Contact Admin for any help"
        ),
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/main_profile");
        }
      });
    }
  };

  const handleInsuranceFee = () => {
    if (insuranceFee > 0) {
      setInsurance(0);
    } else {
      let insurrencefees = singleCountryPaymentMethod?.add_on_service_insurance;
      // console.log(insurrencefees + "======== insurrence Fees");
      setInsurance(insurrencefees);
    }
    setInsuranceCheck(!insuranceChecked);
  };
  //First Mile Fee
  // console.log(props.firstMilePickup + "=====First Mile Pickup Fee");

  const handleFirstMileFee = () => {
    if (firstMileFee > 0) {
      setFirstMileFee(0);
    } else {
      setFirstMileFee(singleCountryPaymentMethod?.add_on_service_first_mile);
    }
    setFirstMileCheck(!firstMileChecked);
  };

  //Last Mile Fee
  // console.log(props.lastMileDelivery + "=====Last Mile Delivery Fee");

  const handleLastMileFee = () => {
    if (lastMileFee > 0) {
      setLastMileFee(0);
    } else {
      setLastMileFee(singleCountryPaymentMethod?.add_on_service_last_mile);
    }
    setLastMileCheck(!lastMileChecked);
  };

  useEffect(() => {
    //last Mile Use Effect
    setLastMileCheck(props.lastMileDelivery == 1 ? true : false);
    setLastMileFee(
      props.lastMileDelivery == 1
        ? singleCountryPaymentMethod?.add_on_service_last_mile
        : 0
    );

    //first Mile
    setFirstMileCheck(props.firstMilePickup == 1 ? true : false);
    setFirstMileFee(
      props.firstMilePickup == 1
        ? singleCountryPaymentMethod?.add_on_service_first_mile
        : 0
    );

    //Insurance Fee
    setInsuranceCheck(props.isInsurance == 1 ? true : false);
    setInsurance(
      props.isInsurance == 1
        ? singleCountryPaymentMethod?.add_on_service_insurance
        : 0
    );

    setViahopCommission(singleCountryPaymentMethod?.viahop_commission);
  }, [singleCountryPaymentMethod]);

  const handlecancelOwnOffer = () => {
    setOwnOffer(false);
    setCostWarningMessage(null);
    props.offerPrice(
      parseFloat(servicePrice) +
        parseFloat(lastMileFee) +
        parseFloat(firstMileFee) +
        parseFloat(insuranceFee)
    );
    setOwnCost(0);
    setServiceCost(parseFloat(servicePriceBefore));
    setServicePrice(parseFloat(servicePriceBefore));
  };

  const confirmOwnOffer = () => {
    let totalCost =
      parseFloat(servicePrice) +
      parseFloat(lastMileFee) +
      parseFloat(firstMileFee) +
      parseFloat(insuranceFee);
    if (parseFloat(ownCost) > 0) {
      props.offerPrice(ownCost);
      setServicePrice(ownCost);
      setOwnOffer(false);
      setCostWarningMessage(null);
    } else {
      setCostWarningMessage(
        t("Your given Service Cost Offer should be more 0")
      );
    }
  };
  useEffect(() => {
    if (props.hitPaymentApi == 1) {
      handleGetCountryPaymentFormula();
    }
  }, [props.hitPaymentApi]);
  useEffect(() => {
    if (offerPricing?.service_cost) {
      setServiceCost(servicePrice);
      props.offerPrice(servicePrice);
    }
  }, [offerPricing]);
  const [ownOfferAmount, setOwnOfferAmount] = useState("");

  const handleOfferPrice = (e) => {
    console.log("asasasasasasasasa");
    if (/^[1-9]\d*$/.test(e.target.value)) {
      console.log("passss");
      if (e.target.value < 0) {
        setEnable(true);
        return false;
      } else {
        setOwnOfferAmount(e.target.value);

        setEnable(false);
      }
      props.offerPrice(e.target.value);
      setOwnCost(e.target.value);
    } else {
      setOwnOfferAmount("");
      console.log("please put valid nmbr");
    }
  };

  if (props.currentStep !== 4) {
    return null;
  }
  // else{
  //   if (props.hitPaymentApi == 1) {
  //     handleGetCountryPaymentFormula();
  //   }
  // }
  console.log("insurance fee", insuranceFee);
  let insur = Math.round((insuranceFee * props.packageValue) / 100);
  let vc =
    (viahopCommission *
      (parseFloat(servicePrice) +
        parseFloat(lastMileFee) +
        parseFloat(firstMileFee) +
        parseFloat(insur))) /
    100;
  console.log("vc========");
  /** calculating total price  */
  function TotalPrice() {
    // let insur = Math.round((insuranceFee * 100) / props.packageValue);

    // let vc =
    //   (viahopCommission * 100) / parseFloat(servicePrice) +
    //   parseFloat(lastMileFee) +
    //   parseFloat(firstMileFee) +
    // insur;

    let total = Math.round(
      parseFloat(servicePrice) +
        parseFloat(lastMileFee) +
        parseFloat(firstMileFee) +
        parseFloat(insur) +
        parseFloat(vc)
    );
    props.handleOtherPrices({
      servicePrice: parseFloat(servicePrice),
      lastMileFee: parseFloat(lastMileFee),
      firstMileFee: parseFloat(firstMileFee),
      insur: parseFloat(insur),
      vc: parseFloat(vc),
    });

    return Math.round(total);
  }
  console.log(TotalPrice(), "helooooo");
  props.offerPrice(
    Math.round(
      parseFloat(servicePrice) +
        parseFloat(lastMileFee) +
        parseFloat(firstMileFee) +
        parseFloat(insur) +
        parseFloat(vc)
    )
  );

  return (
    <LoadingScreen
      loading={loading}
      bgColor="#f1f1f1"
      spinnerColor="#9ee5f8"
      textColor="#676767"
      logoSrc={nameAndLogo}
      text={t("Hang tight while we do our magic!")}
    >
      <div className="pt-5">
        <div className="multiform-postion">
          <div className="lightorage-color my-4 text-center">
            <h1 className=" py-3 fontSize24-600 orange-color">
              {t("Create Shipment offer")}
            </h1>
          </div>
          <div className="text-center pt-3">
            <Button
              type="button"
              className="orange-background fontSize16-500 bordt-none"
              size="md"
            >
              {t("Your price")}
            </Button>
          </div>
        </div>
        <div className="row ms-lg-0 ms-md-0 ms-2 justify-content-center pttwo-multotab">
          <div className="col-lg-8 col-md-8 col-12">
            <p className="fontSize16-500 py-2 mb-0 darkgraytext text-center">
              <b>
                {" "}
                {t("Viahop recommended minimum Service Cost")}:{" "}
                <span className="orange-color">
                  <b>{servicePrice}$</b>
                </span>
                .
              </b>
            </p>
          </div>
        </div>
        <Container className="px-lg-5 px-md-5 px-0">
          <div className="row ms-lg-0 ms-md-0 ms-2 px-lg-5 px-md-5 px-0">
            <div className="col-lg-12 col-md-12 col-12 text-center">
              <p className="text-center fontSize16-500 py-2 mb-0 darkgraytext">
                <b> {t("Want to offer other than recommended price?")}</b>
              </p>
            </div>
        
          </div>
        </Container>
        <Container className="masterFormOneStep4Container ">
          <Row
            className="justify-content-center p-3"
            style={{ justifyContent: "center" }}
          >
            <Col lg={5} md={5} sm={12}>
              <div className="">
                {/* <p className="fontSize16-500 py-2 mb-0 darkgraytext">
              We recommended you setting following price for your shipment offer.
            </p> */}
                <p className="py-2 mb-0 darkgraytext mt-3" style={{fontWeight:"600"}}>
                  {t("Cost Summary")}
                </p>
                <div
                  className="50% "
                  style={{
                    height: "360px",
                    margin: "auto",
                    border: "0.4px solid #F69110",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      justifyContent: "space-between",
                      display: "flex",
                      padding: "10px",
                    }}
                  >
                    <p>{t("Service Cost")}:</p>
                    <p>{servicePrice}$</p>
                  </div>
                  <hr style={{ margin: 0 }} />
                  <div
                    style={{
                      display: "flex",
                      padding: "10px",
                    }}
                  >
                    <Form.Check
                      onChange={handleInsuranceFee}
                      type="checkbox"
                      checked={insuranceChecked}
                      disabled={insuranceDisable}
                    />
                    <p className="me-auto ms-2">{t("Insurance")}:</p>
                    <div className="d-flex" style={{ alignItems: "baseline" }}>
                      <p style={{ padding: "5px", margin: 0 }}>{insur}$</p>
                    </div>
                  </div>
                  <hr style={{ margin: 0 }} />
                  <div
                    style={{
                      display: "flex",
                      padding: "10px",
                    }}
                  >
                    <Form.Check
                      onChange={handleFirstMileFee}
                      type="checkbox"
                      checked={firstMileChecked}
                      disabled={firstMileDisable}
                    />
                    <p className="me-auto ms-2">{t("First mile pickup")}:</p>
                    <div className="d-flex" style={{ alignItems: "baseline" }}>
                      <p style={{ padding: "5px", margin: 0 }}>
                        {firstMileFee}$
                      </p>
                    </div>
                  </div>
                  <hr style={{ margin: 0 }} />
                  <div
                    style={{
                      display: "flex",
                      padding: "10px",
                    }}
                  >
                    <Form.Check
                      onChange={handleLastMileFee}
                      type="checkbox"
                      checked={lastMileChecked}
                      disabled={lastMileDisable}
                    />
                    <p className="me-auto ms-2">{t("Last mile delivery")}:</p>

                    <div className="d-flex" style={{ alignItems: "baseline" }}>
                      <p style={{ padding: "5px", margin: 0 }}>
                        {lastMileFee}$
                      </p>
                    </div>
                  </div>
                  <hr style={{ margin: 0 }} />
                  <div
                    style={{
                      display: "flex",
                      padding: "10px",
                    }}
                  >
                    <Form.Check
                      onChange={handleLastMileFee}
                      type="checkbox"
                      checked
                      disabled
                    />
                    <p className="me-auto ms-2">{t("Viahop Commission")}:</p>

                    <div className="d-flex" style={{ alignItems: "baseline" }}>
                      <p style={{ padding: "5px", margin: 0 }}>
                        {Math.round(vc)}$
                      </p>
                    </div>
                  </div>

                  <hr
                    style={{
                      margin: "0",
                      borderTop: "4px solid",
                      color: "inherit",
                      opacity: ".25",
                    }}
                  />
                  <div
                    style={{
                      justifyContent: "space-between",
                      display: "flex",
                      padding: "10px",
                    }}
                  >
                    <p>
                      <b>{t("Total Shipment Cost")}:</b>{" "}
                    </p>
                    <p>
                      <b>
                        {/* {parseFloat(servicePrice) +
                          parseFloat(lastMileFee) +
                          parseFloat(firstMileFee) +
                          parseFloat(insuranceFee) +
                          parseFloat(viahopCommission)}{" "} */}
                        {TotalPrice()} $
                      </b>
                    </p>
                  </div>
                </div>
                <p className="fontSize14-400 text-center mt-3">
                  {t("Services Cost")}{" "}
                  {parseFloat(insuranceFee) > 0
                    ? "+ " + t("Insaurance Price")
                    : ""}{" "}
                  {parseFloat(firstMileFee) > 0
                    ? "+ " + t("First Mile Pickup")
                    : ""}{" "}
                  {parseFloat(lastMileFee) > 0
                    ? "+ " + t("Last Mile Delivery")
                    : ""}{" "}
                  {parseFloat(viahopCommission) > 0
                    ? "+ " + t("Viahop Commission")
                    : ""}
                  = {t("Total Shipment Cost")}
                </p>
                <h1 className="orange-color text-center fontSize50-700">
                  {/* {parseFloat(servicePrice) +
                    parseFloat(lastMileFee) +
                    parseFloat(firstMileFee) +
                    parseFloat(insuranceFee) +
                    parseFloat(viahopCommission)}{" "}
                  $ */}
                  {TotalPrice()} $
                  <input
                    type="hidden"
                    onChange={props.offerPrice}
                    value={
                      parseFloat(servicePrice) +
                      parseFloat(lastMileFee) +
                      parseFloat(firstMileFee) +
                      parseFloat(insuranceFee)
                    }
                    required
                  />
                </h1>
              </div>
            </Col>
            <Col lg={7} md={7} sm={12} className="mt-4">
              <div>
                <Button
                  onClick={handleOwnOffer}
                  className="profiletop-btnoutline navdropdown-twohov  py-2 align-items-center justify-content-center  fontSize16-500 bordt-none"
                  size="lg"
                  style={{ textDecoration: "underline", marginTop: 40 }}
                >
                  {t("Give your Offer")}
                </Button>
              </div>
              {giveOwnOffer && (
                <p className=" fontSize16-500 py-2 mb-0 darkgraytext">
                  {t("Your Own Offer")}{" "}
                  <span className="orange-color">
                    <b>{ownCost}</b>
                  </span>
                </p>
              )}
              <div className="text-center pt-4"></div>
              {giveOwnOffer && (
                <Form>
                  <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-12 col-sm-12">
                      <FormGroup>
                        <Label for="email" className="fontSize14-600 w-100 ">
                          {t("Give Your Offer")}
                        </Label>
                        <Input
                          className="profiletop-btnoutline py-1 text-dark border-rad10 fontSize32-600 "
                          type="text"
                          value={ownOfferAmount}
                          onChange={handleOfferPrice}
                          required
                        />
                      </FormGroup>
                    </div>
                    <div className="col-lg-7 col-md-12 col-sm-12 happy-new-year p-0">
                      <Button
                        disabled={isEnableConfirm}
                        type="button"
                        onClick={confirmOwnOffer}
                        className="profiletop-btnoutline navdropdown-twohov p-1 ps-2 pe-2 fontSize16-500 bordt-none"
                        style={{ marginTop: "30px" }}
                        size="lg"
                      >
                        {t("Confirm")}
                      </Button>
                      <Button
                        type="button"
                        onClick={handlecancelOwnOffer}
                        className="profiletop-btnoutline navdropdown-twohov p-1 ms-2 ps-2 pe-2 fontSize16-500 bordt-none"
                        style={{ marginTop: "30px" }}
                        size="lg"
                      >
                        {t("Cancel")}
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
              {costWarningMessage && (
                <p className="text-center fontSize16-500 py-2 mb-0 darkgraytext">
                  {costWarningMessage}
                </p>
              )}
            </Col>
          </Row>
        </Container>

        <Modal show={show} onHide={handleClose} className="stepthree-mpdal" backdrop="static">
          {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
          <Modal.Body>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <div className="py-5 px-4 text-center">
                  <img src={cong} alt="cong" width="200" />
                  <p className="orange-color fontSize32-600 text-center">
                    {t("Congratulations")}!
                  </p>
                  <p className="textgray fontSize16-500 text-center">
                    {t("Order")}#TR-1232-24234-4324-5435
                  </p>
                  <p className="fontSize16-500 text-center mb-0">
                    {t("We have recieved your order details")},
                  </p>
                  <p className="fontSize16-500 text-center mb-0">
                    {t("You’ll be shortly notified on your contact info")}!
                  </p>
                  <div className="text-center pt-4">
                    <Button
                      className="orange-background fontSize16-500 bordt-none px-5"
                      size="md"
                    >
                      {t("Login")}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Modal.Body>
          {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
        </Modal>
      </div>
    </LoadingScreen>
  );
};

export default Step3One;
