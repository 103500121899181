import { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Link, useNavigate } from 'react-router-dom'
import '../SamllComponent/MultitabBtn.css'
// Importing toastify module
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'

// Import toastify css file
import 'react-toastify/dist/ReactToastify.css'

import {
  getTravellerOfferDetails,
  createSenderCounterOffer,
  acceptCounterTravellerOffer,
  acceptCounterSenderOffer,
  updateOfferStatus,
  senderOfferPayment,
  getSenderOffers,
} from '../../redux/actions/TravellerOfferAction'
import { Col, Container, FormGroup, Row } from 'react-bootstrap'
import {
  Button,
  Modal,
  ModalBody,
  Alert,
  Form,
  Input,
  InputGroup,
  Label,
} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'
import { sendChatNotification } from '../../redux/actions/UserAction'

// toast.configure();

function MyOfferDetails(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  //Reducer
  const [travellerOfferDetail, setOfferDetailData] = useState(null)

  useEffect(() => {
    console.log(JSON.stringify(props?.offerDetailData), '===================')
    if (props?.offerDetailData?.id > 0) {
      setOfferId(props?.offerDetailData?.id)
      setOfferDetailData(props?.offerDetailData)
    }
  }, [props.offerDetailData])
  const handleResponse = (data) => {
    console.log(data)
    if (data.status) {
      // setVerificationModal(false)
    } else {
      //   setLoading(false);
    }
  }
  const [counterOffers, setCounterOffers] = useState([])

  useEffect(() => {
    if (props.userType == 'sender') {
      setCounterOffers(travellerOfferDetail?.counterSenderOffers)
    } else {
      setCounterOffers(travellerOfferDetail?.counterTravelerOffers)
    }
  }, [
    props.userType,
    travellerOfferDetail?.counterSenderOffers,
    travellerOfferDetail?.counterTravelerOffers,
  ])

  const [counterOfferAmount, setCounterOfferAmount] = useState('')
  const [counterOfferDescription, setCounterOfferDescription] = useState('')
  const [offerId, setOfferId] = useState('')
  const [counterOfferModal, setCounterOfferModal] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const { t } = useTranslation()

  const toggleCounterOffer = () => {
    setCounterOfferModal(!counterOfferModal)
  }
  const handleCounterOfferAmount = (event) => {
    setCounterOfferAmount(event.target.value)
  }
  const handleCounterOfferDescription = (event) => {
    setCounterOfferDescription(event.target.value)
  }
  const handleResponseCounterOffer = (data) => {
    if (data.status) {
      setCounterOfferModal(!counterOfferModal)
      // setVerificationModal(false)
    } else {
      //   setLoading(false);
    }
  }
  const handleSubmitCounterOffer = (e) => {
    e.preventDefault()
    let data = {
      offerId: offerId,
      description: counterOfferDescription,
      offeredAmount: counterOfferAmount,
    }
    dispatch(createSenderCounterOffer(data, handleResponseCounterOffer))
    dispatch(getSenderOffers())
  }
  const [counterOfferId, setCounterOfferId] = useState()
  useEffect(() => {
    if (travellerOfferDetail?.id) {
      setOfferId(travellerOfferDetail?.id)

      if (travellerOfferDetail?.payment_status == 'pending') {
        setDisabled(true)
      } else {
        setDisabled(false)
      }
    }
  }, [travellerOfferDetail])
  const [acceptCounterOffer, setAcceptCounterOffer] = useState(false)
  const toggleAcceptCounterOffer = (id) => {
    setCounterOfferId(id)
    console.log(id + '==traveller offer id')
    setAcceptCounterOffer(!acceptCounterOffer)
  }
  const handleSubmitTravellerCounterOffer = () => {
    let data = {
      counterTravelerOfferId: counterOfferId,
      travelerOfferId: offerId,
    }
    dispatch(
      acceptCounterTravellerOffer(
        data,
        handleResponseAcceptCounterTravellerOffer,
      ),
    )
    dispatch(getSenderOffers())
    props.isShowDetails()
    Swal.fire({
      title: 'Success',
      type: 'success',
      text: 'Counter offer accepted successfully',
    })
  }
  const handleSubmitSenderCounterOffer = () => {
    let data = {
      counterSenderOfferId: counterOfferId,
      senderOfferId: offerId,
    }
    dispatch(
      acceptCounterSenderOffer(data, handleResponseAcceptCounterSenderOffer),
    )
    dispatch(getSenderOffers())
    props.isShowDetails()
    Swal.fire({
      title: 'Success',
      type: 'success',
      text: 'Counter offer accepted successfully',
    })
  }
  const handleResponseAcceptCounterSenderOffer = (data) => {
    console.log(
      JSON.stringify(data) + '======sender counter Offer accept response',
    )
    if (data?.status) {
      setAcceptCounterOffer(!acceptCounterOffer)
    } else {
      //   setLoading(false);
    }
  }
  const handleResponseAcceptCounterTravellerOffer = (data) => {
    console.log(
      JSON.stringify(data) + '======sender counter Offer accept response',
    )
    if (data?.status) {
      setAcceptCounterOffer(!acceptCounterOffer)
    } else {
      //   setLoading(false);
    }
  }
  console.log(props, 'dlajdlajasldj')
  const handleStatusUpdate = (e, userType) => {
    // if (userType !== "sender") {
    let data = {
      offerId: offerId,
      offerType: props.offerType == 'sender' ? 'senderOffer' : 'travelerOffer',
      status: e.target.value,
    }
    let notificationData = {
      to:
        props.userType !== 'traveller'
          ? props.offerDetailData?.acceptedSenderOffer?.user?.device_token
          : props.offerDetailData?.acceptedTravelerOffer?.user?.device_token,
      notification: {
        body: {
          offerType:
            props.offerType == 'sender' ? 'senderOffer' : 'travelerOffer',
          status: e.target.value,
        },
        title: 'Offer Status Changed...',
        subtitle: `${offerId} status changed`,
      },
    }
    dispatch(updateOfferStatus(data, handleResponseOfferStatus))
    dispatch(sendChatNotification(notificationData, handleResponse))
    // } else {
    //   let data = {
    //     offerId: offerId,
    //     offerType: "travelerOffer",
    //     status: e.target.value,
    //   };
    //   dispatch(updateOfferStatus(data, handleResponseOfferStatus));
    // }
  }
  const handleResponseOfferStatus = (data) => {
    console.log(JSON.stringify(data) + '========== status update')
    if (data.status) {
      // alert("toaster if");
      toast(data.message)
    } else {
      // alert("toaster else");
      toast(data.message)
    }
  }

  const contactToTraveller = (
    offerId,
    userId,
    user,
    offerType,
    DeviceToken,
  ) => {
    navigate(
      '/chat?offerid=' +
        offerId +
        '&DeviceToken=' +
        DeviceToken +
        '&OfferType=' +
        offerType +
        '&userId=' +
        userId +
        '&user=' +
        user,
    )
  }
  useEffect(() => {
    console.log(props, 'qwertyuioljhgfda')
  }, [props])

  const [senderOfferId, setSenderOfferId] = useState(false)
  console.log(travellerOfferDetail, '=============asdasdasdadasdasqweqe')

  //sender Offer Payment
  const handleSubmitSenderOfferPayment = () => {
    let data = {
      offerType: 'senderOffer',
      offerId: senderOfferId,
    }
    dispatch(senderOfferPayment(data, handleResponseSenderOfferPayment))
  }
  const handleResponseSenderOfferPayment = (data) => {
    if (data?.status) {
      console.log(data?.session?.url + '======checkout session')
      window.location.href = data?.session?.url
    }
  }

  const [paymentText, setPaymentText] = useState('Click to Pay')

  //sender Offer Payment
  const handleSubmitTravellerOfferPayment = (offerId) => {
    setPaymentText('Processing...')
    let data = {
      offerType: props.offerType == 'sender' ? 'senderOffer' : 'travelerOffer',
      offerId: offerId,
    }
    dispatch(senderOfferPayment(data, handleResponseTravellerOfferPayment))
  }
  const handleResponseTravellerOfferPayment = (data) => {
    if (data?.status) {
      console.log(data?.session?.url + '======checkout session')
      window.location.href = data?.session?.url
    }
  }
  return (
    <>
      <Row>
        <Col lg={12} md={12} sm={12} className="py-4">
          <div>
            <Row style={{ overflowX: 'auto' }}>
              <Col lg={12} md={12} sm={12}>
                <div className="card">
                  <div className="card-body">
                    {travellerOfferDetail?.is_accepted == 1 &&
                      travellerOfferDetail?.payment_status === 'pending' && (
                        <Alert color="warning">
                          {props?.userType == 'sender' ? (
                            <>
                              {props.offerAcceptedbyMe == true
                                ? t('You have accepted this offer')
                                : t('Your Offer is accepted')}
                              , {t('now you need to pay')}{' '}
                              {props.offerType == 'sender'
                                ? travellerOfferDetail?.total_cost
                                : travellerOfferDetail?.offer_price}{' '}
                              $ {t('to start your Order')}
                              <span className="orange-color">
                                <p
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    handleSubmitTravellerOfferPayment(
                                      travellerOfferDetail?.id,
                                    )
                                  }
                                >
                                  {t(paymentText)}
                                </p>
                              </span>
                            </>
                          ) : (
                            t(
                              'Please wait for the Sender to pay for this offer! You will get email from Viahop to start the order',
                            )
                          )}
                        </Alert>
                      )}
                    <p style={{ textAlign: 'right' }}>
                      <button
                        style={{
                          margin: '5px',
                          borderRadius: '50%',
                          color: 'white',
                          padding: '2px 10px 2px 10px',
                        }}
                        type="button"
                        className="btn back-orange"
                        onClick={props.isShowDetails}
                      >
                        X
                      </button>
                    </p>
                    <h3 className="card-title">
                      {' '}
                      {t(travellerOfferDetail?.s_country) + ' '}
                      {t('to')}
                      {' ' + t(travellerOfferDetail?.d_country)}
                    </h3>
                    <p style={{ textAlign: 'center', fontWeight: 'bold' }}>
                      #{props.offerType == 'sender' ? 'SO' : 'TO'}-
                      {t(travellerOfferDetail?.id)}
                    </p>
                    {travellerOfferDetail?.is_accepted == 1 && (
                      <>
                        <p style={{ textAlign: 'left', fontWeight: 'bold' }}>
                          {props.offerAcceptedbyMe == true
                            ? t('Offer Created By')
                            : t('Offer Accepted By')}
                        </p>
                        <table className="table" style={{ textAlign: 'left' }}>
                          <thead>
                            <tr>
                              <th scope="col">{t('User Name')}:</th>{' '}
                              {/* {travellerOfferDetail?.package_type}{' '} */}
                              <th scope="col">{t('User Address')}:</th>
                              <th scope="col">{t('Contact')}:</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {props.offerType == 'sender' ? (
                                <>
                                  <td>
                                    {props.offerAcceptedbyMe == true
                                      ? travellerOfferDetail?.user?.firstName +
                                        ' ' +
                                        travellerOfferDetail?.user?.lastName
                                      : travellerOfferDetail
                                          ?.acceptedSenderOffer?.user
                                          ?.firstName +
                                        ' ' +
                                        travellerOfferDetail
                                          ?.acceptedSenderOffer?.user?.lastName}
                                  </td>
                                  <td>
                                    {props.offerAcceptedbyMe == true
                                      ? travellerOfferDetail?.user?.address
                                      : travellerOfferDetail
                                          ?.acceptedSenderOffer?.user?.address}
                                  </td>
                                  <td>
                                    {' '}
                                    <span className={`orange-color`}>
                                      {travellerOfferDetail?.payment_status !=
                                        'pending' ? (
                                        <p
                                          style={{ cursor: 'pointer' }}
                                          onClick={() =>
                                            travellerOfferDetail?.is_accepted ==
                                              1 &&
                                            contactToTraveller(
                                              travellerOfferDetail?.id,
                                              props.offerAcceptedbyMe == true
                                                ? travellerOfferDetail?.user?.id
                                                : travellerOfferDetail
                                                    ?.acceptedSenderOffer?.user
                                                    ?.id,
                                              props.offerAcceptedbyMe == true
                                                ? travellerOfferDetail?.user
                                                    ?.firstName +
                                                    ' ' +
                                                    travellerOfferDetail?.user
                                                      ?.lastName
                                                : travellerOfferDetail
                                                    ?.acceptedSenderOffer?.user
                                                    ?.firstName +
                                                    ' ' +
                                                    travellerOfferDetail
                                                      ?.acceptedSenderOffer
                                                      ?.user?.lastName,
                                              'senderOffer',
                                              props.offerAcceptedbyMe == true
                                                ? travellerOfferDetail?.user
                                                    ?.device_token
                                                : travellerOfferDetail
                                                    ?.acceptedSenderOffer?.user
                                                    ?.device_token,
                                            )
                                          }
                                        >
                                          {t('Go to Inbox')}{' '}
                                        </p>
                                      ):
                                      <p>{t('unavailable')}</p>
                                      }
                                    
                                    </span>
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td>
                                    {props.offerAcceptedbyMe == true
                                      ? travellerOfferDetail?.user?.firstName
                                      : travellerOfferDetail
                                          ?.acceptedTravelerOffer?.user
                                          ?.firstName}{' '}
                                    {props.offerAcceptedbyMe == true
                                      ? travellerOfferDetail?.user?.lastName
                                      : travellerOfferDetail
                                          ?.acceptedTravelerOffer?.user
                                          ?.lastName}
                                  </td>
                                  <td>
                                    {props.offerAcceptedbyMe == true
                                      ? travellerOfferDetail?.user?.address
                                      : travellerOfferDetail
                                          ?.acceptedTravelerOffer?.user
                                          ?.address}
                                  </td>
                                  <td>
                                    {' '}
                                    <span className="orange-color">
                                    {travellerOfferDetail?.payment_status !=
                                        'pending' ? (
                                          <p
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                          contactToTraveller(
                                            travellerOfferDetail?.id,
                                            props.offerAcceptedbyMe == true
                                              ? travellerOfferDetail?.user?.id
                                              : travellerOfferDetail
                                                  ?.acceptedTravelerOffer?.user
                                                  ?.id,
                                            props.offerAcceptedbyMe == true
                                              ? travellerOfferDetail?.user
                                                  ?.firstName +
                                                  ' ' +
                                                  travellerOfferDetail?.user
                                                    ?.lastName
                                              : travellerOfferDetail
                                                  ?.acceptedTravelerOffer?.user
                                                  ?.firstName +
                                                  ' ' +
                                                  travellerOfferDetail
                                                    ?.acceptedTravelerOffer
                                                    ?.user?.lastName,
                                            'travellerOffer',
                                            props.offerAcceptedbyMe == true
                                              ? travellerOfferDetail?.user
                                                  ?.device_token
                                              : travellerOfferDetail
                                                  ?.acceptedTravelerOffer?.user
                                                  ?.device_token,
                                          )
                                        }
                                      >
                                        {' '}
                                        {t('Go to Inbox')}{' '}
                                      </p>
                                        ):
                                        <p>{t('unavailable')}</p>
                                    }
                                     
                                    </span>
                                  </td>
                                </>
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </>
                    )}
                    <p style={{ textAlign: 'left', fontWeight: 'bold' }}>
                      {t('Package Information')}
                    </p>
                    <table className="table" style={{ textAlign: 'left' }}>
                      <thead>
                        <tr>
                          <th scope="col">{t('Package Type')}:</th>{' '}
                          <th scope="col">{t('Package Weight')}:</th>
                          {props.offerType == 'sender' && (
                            <>
                              <th scope="col">{t('Package Value')}:</th>
                              <th scope="col">{t('Package Height')}:</th>{' '}
                              <th scope="col">{t('Package Width')}:</th>
                              <th scope="col">{t('Package Length')}:</th>
                            </>
                          )}
                          {/* <th scope="col">{t("Address")}</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{t(travellerOfferDetail?.package_type)}</td>

                          {props.offerType == 'sender' ? (
                            <td>{travellerOfferDetail?.package_weight}</td>
                          ) : (
                            <td>{travellerOfferDetail?.max_weight}</td>
                          )}
                          {props.offerType == 'sender' && (
                            <>
                              <td>${travellerOfferDetail?.package_value}</td>
                              <td>{travellerOfferDetail?.package_height}</td>
                              <td>{travellerOfferDetail?.package_width}</td>
                              <td>{travellerOfferDetail?.package_length}</td>
                            </>
                          )}
                          {/* <td>{travellerOfferDetail?.d_address}</td> */}
                        </tr>
                      </tbody>
                    </table>
                    <p style={{ textAlign: 'left', fontWeight: 'bold' }}>
                      {t('Offer Information')}
                    </p>
                    <div className="traveller_offer_detail_table">
                      <table className="table" style={{ textAlign: 'left' }}>
                        <thead>
                          <tr>
                            <th scope="col">{t('Created Date')}</th>
                            <th scope="col">{t('Shipment Date')}</th>
                            {props.offerType == 'sender' &&
                            <th scope="col">{t('Offer Price')}</th>
                            }
                            <th scope="col">{t('Status')}</th>
                            <th scope="col">{t('Payment Status')}</th>
                            {/* <th scope="col">Address</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {travellerOfferDetail?.createdAt?.split('T')[0]}
                            </td>

                            <td>
                              {travellerOfferDetail?.offer_time?.split(
                                'D',
                              )?.[1] == 'ays'
                                ? travellerOfferDetail?.offer_time?.split(
                                    'D',
                                  )?.[0] + t('Days')
                                : t(travellerOfferDetail?.offer_time)}
                            </td>

                              {props.offerType == 'sender' &&
                            <td>
                                 {travellerOfferDetail?.total_cost} $
                            </td>
                               }
                            <td>
                              {/* {travellerOfferDetail?.status} */}
                              <select
                                style={{
                                  width: '100%',
                                }}
                                className="form-control"
                                name="select"
                                id="exampleSelect"
                                onChange={(e) =>
                                  handleStatusUpdate(e, props.userType)
                                }
                                disabled={disabled}
                              >
                                <option
                                  value={travellerOfferDetail?.status}
                                  selected
                                >
                                  {t(travellerOfferDetail?.status)}
                                </option>
                                {props.userType == 'sender' &&
                                  travellerOfferDetail?.status !==
                                    'Delivery Confirmed' && (
                                    <>
                                      <option
                                        data-offerId={travellerOfferDetail?.id}
                                        value="Delivery Confirmed"
                                      >
                                        {t('Delivery Confirmed')}
                                      </option>
                                    </>
                                  )}
                                {props.userType !== 'sender' &&
                                  travellerOfferDetail?.status !==
                                    'Delivery in Process' && (
                                    <>
                                      <option
                                        data-offerId={travellerOfferDetail?.id}
                                        value="Delivery in Process"
                                      >
                                        {t('Delivery in Process')}
                                      </option>
                                    </>
                                  )}
                                {props.userType !== 'sender' &&
                                  travellerOfferDetail?.status !==
                                    'Delivery Complete by Traveller' && (
                                    <>
                                      <option
                                        data-offerId={travellerOfferDetail?.id}
                                        value="Delivery Complete by Traveller"
                                      >
                                        {t('Delivery Complete by Traveller')}
                                      </option>
                                    </>
                                  )}
                                {travellerOfferDetail?.status !==
                                  'Cancelled' && (
                                  <>
                                    <option
                                      data-offerId={travellerOfferDetail?.id}
                                      value="Cancelled"
                                    >
                                      {t('Cancelled')}
                                    </option>
                                  </>
                                )}
                              </select>
                            </td>
                            <td>{t(travellerOfferDetail?.payment_status)}</td>
                            {/* <td>{travellerOfferDetail?.payment_status}</td> */}
                            {/* <td>{travellerOfferDetail?.d_address}</td> */}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p style={{ textAlign: 'left', fontWeight: 'bold' }}>
                      {t('Source and Destination Address')}
                    </p>
                    <div className="traveller_offer_detail_table">
                      <table className="table" style={{ textAlign: 'left' }}>
                        <thead>
                          <tr>
                            <th scope="col">{t('From Country')}</th>{' '}
                            <th scope="col">{t('From City')}</th>
                            <th scope="col">{t('Address')}</th>
                            {/* <th scope="col">Address</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{travellerOfferDetail?.s_country}</td>
                            <td>{travellerOfferDetail?.s_city}</td>
                            <td>{t(travellerOfferDetail?.s_address)}</td>
                            {/* <td>{travellerOfferDetail?.d_address}</td> */}
                          </tr>
                        </tbody>
                        <thead>
                          <tr>
                            <th scope="col">{t('To Country')}</th>{' '}
                            <th scope="col">{t('To City')}</th>
                            <th scope="col">{t('Address')}</th>
                            {/* <th scope="col">Address</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{travellerOfferDetail?.d_country}</td>
                            <td>{travellerOfferDetail?.d_city}</td>
                            <td>{t(travellerOfferDetail?.d_address)}</td>
                            {/* <td>{travellerOfferDetail?.d_address}</td> */}
                          </tr>
                        </tbody>
                        {travellerOfferDetail?.additional_notes && (
                          <>
                            <thead>
                              <tr>
                                <th>{t('Description')}</th>{' '}
                                {/* <th scope="col">Address</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {travellerOfferDetail?.additional_notes}
                                </td>
                              </tr>
                            </tbody>
                          </>
                        )}
                      </table>
                    </div>

                    {travellerOfferDetail?.is_accepted != 1 && (
                      <>
                        <p style={{ textAlign: 'left', fontWeight: 'bold' }}>
                          {t('Counter Offers')}
                        </p>
                        <div className="counter_traveller_offers_table">
                          <table
                            className="table"
                            style={{ textAlign: 'left' }}
                          >
                            <thead>
                              <tr>
                                <th scope="col">{t('Offer Amount')}:</th>{' '}
                                {/* {travellerOfferDetail?.package_type}{' '} */}
                                <th scope="col">{t('User Name')}:</th>
                                <th scope="col">{t('Description')}:</th>
                                <th scope="col">{t('Actions')}:</th>
                              </tr>
                            </thead>
                            <tbody>
                              {counterOffers?.length > 0 ? (
                                counterOffers.map((value) => {
                                  return (
                                    <tr>
                                      <td>${value?.offered_amount}</td>
                                      <td>
                                        {value?.user?.firstName}{' '}
                                        {value?.user?.lastName}
                                      </td>
                                      <td> {value?.description}</td>
                                      <td>
                                        {value?.status === 'accepted' ? (
                                          <p
                                            className="greencolor"
                                            style={{
                                              cursor: 'pointer',
                                              margin: 0,
                                              textDecoration: 'none',
                                              listStyle: 'none',
                                            }}
                                          >
                                            {' '}
                                            ({t('Accepted')}){' '}
                                          </p>
                                        ) : (
                                          <p
                                            className="orange-color"
                                            onClick={() =>
                                              toggleAcceptCounterOffer(
                                                value?.id,
                                              )
                                            }
                                            style={{
                                              cursor: 'pointer',
                                              margin: 0,
                                              textDecoration: 'underline',
                                              listStyle: 'none',
                                              fontWeight:'bold'
                                            }}
                                          >
                                           
                                          {t('Accept Counter Offer')}
                                          </p>
                                        )}
                                      </td>
                                    </tr>
                                  )
                                })
                              ) : (
                                <tr>
                                  <td colSpan={3}>
                                    {t('There is No Counter Offer')}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Modal
        isOpen={counterOfferModal}
        toggle={toggleCounterOffer}
        className="how-work-modal"
        backdrop="static"
      >
        <ModalBody>
          <Row>
            <Col lg={12} md={12} sm={12} className="py-4">
              <div>
                <Form>
                  <Row>
                    <div className="lightorage-color text-center">
                      <h1 className=" py-3 fontSize24-600 orange-color">
                        {t('Create Counter Offer')}
                      </h1>
                    </div>
                    <Col lg={12} md={12} sm={12}>
                      <FormGroup className="pt-3">
                        <Label
                          for="counterOfferAmount"
                          className="fontSize14-600 dimgray-color"
                        >
                          {' '}
                          {t('Offer Amount')}{' '}
                        </Label>
                        <Input
                          onChange={handleCounterOfferAmount}
                          id="counterOfferAmount"
                          name="counterOfferAmount"
                          type="counterOfferAmount"
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={12} md={12} sm={12}>
                      <FormGroup className="pt-3">
                        <Label
                          for="number"
                          className="fontSize14-600 dimgray-color"
                        >
                          {' '}
                          {t('Offer Description')}{' '}
                        </Label>

                        <Input
                          type="textarea"
                          onChange={handleCounterOfferDescription}
                          id="number"
                          name="number"
                          placeholder={t('Enter Offer Descriptions')}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="textright-iconf py-5">
                    <Button
                      type="button"
                      onClick={toggleCounterOffer}
                      className="btnlanding-hov profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                    >
                      {t('Cancel')}
                    </Button>
                    {/* <Link to="/login"> */}
                    <Button
                      type="button"
                      onClick={handleSubmitCounterOffer}
                      className="btnlanding-hov ms-4 profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                    >
                      {t('Save')}
                    </Button>
                    {/* </Link> */}
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      {/* Accept Sender Counter Offer */}
      <Modal
        isOpen={acceptCounterOffer}
        toggle={toggleAcceptCounterOffer}
        className="how-work-modal"
        backdrop="static"
      >
        <ModalBody>
          <Row>
            <Col lg={12} md={12} sm={12} className="py-4">
              <div>
                <Form>
                  <Row>
                    <div className="lightorage-color text-center">
                      <h1 className=" py-3 fontSize24-600 orange-color">
                        {t('Accept Traveller Counter Offer')}
                      </h1>
                    </div>

                    <Col lg={12} md={12} sm={12}>
                      <FormGroup className="pt-3">
                        <Label
                          for="number"
                          className="fontSize14-600 dimgray-color"
                        >
                          {' '}
                          {t('Add Personal Note')} {'(Optional)'}
                        </Label>

                        <Input
                          type="textarea"
                          // onChange={handleCounterOfferDescription}
                          id="number"
                          name="number"
                          placeholder={t('Enter Offer Descriptions')}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="textright-iconf py-5">
                    <Button
                      type="button"
                      onClick={toggleAcceptCounterOffer}
                      className="btnlanding-hov profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                    >
                      {t('Cancel')}
                    </Button>
                    {/* <Link to="/login"> */}
                    <Button
                      type="button"
                      onClick={
                        props.userType == 'sender'
                          ? handleSubmitSenderCounterOffer
                          : handleSubmitTravellerCounterOffer
                      }
                      className="btnlanding-hov ms-4 profiletop-btnoutline mt-2  bordt-none fontSize20-600 px-5"
                    >
                      {t('Save')}
                    </Button>
                    {/* </Link> */}
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  )
}

export default MyOfferDetails
