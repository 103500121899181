import axios from "axios";
import { ApiUrl } from "../../ApiUrl";
import {
  GET_ALL_TRAVELER_OFFERS,
  OFFER_PRICING,
  GET_USER_TRAVELER_OFFERS,
  GET_TRAVELER_OFFER,
  GET_ALL_SENDER_OFFERS,
  GET_SENDER_OFFER,
  SENDER_OFFERS,
  TRAVELER_OFFERS,
  HOW_IT_WORK_DATA
} from "../types";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { HERO_SECTION_DATA } from "../types";
//Create Traveller Offer
export const createTravellerOffer = (data, handleResponse) => (dispatch) => {
  const accessToken = localStorage.getItem("token");
  const allData = { ...data, lang: localStorage.getItem("i18nextLng") };
  axios
    .post(ApiUrl.server_url + "auth/create-traveler-offer", allData, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((res) => {
      dispatch(getTravellerOffers());
      handleResponse(res?.data);
    })
    .catch((error) => {
      handleResponse(error?.response?.data);
    });
};
//Create Counter Traveller Offer
export const createTravellerCounterOffer =
  (data, handleResponse) => (dispatch) => {
    const accessToken = localStorage.getItem("token");
    axios
      .post(ApiUrl.server_url + "auth/create-counter-traveler-offer", data, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => {
        let tempdata = {
          travelerOfferId: data.offerId,
        };
        dispatch(getTravellerOfferDetails(tempdata));
        handleResponse(res?.data);
      })
      .catch((error) => {
        handleResponse(error?.response?.data);
      });
  };
//Accept Travelelr Counter Offer
export const acceptUserTravellerOffer =
  (data, handleResponse) => (dispatch) => {
    const accessToken = localStorage.getItem("token");
    axios
      .post(ApiUrl.server_url + "auth/accept-traveler-offer", data, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => {
        let tempdata = {
          travelerOfferId: data.travelerOfferId,
        };
        dispatch(getTravellerOfferDetails(tempdata));
        handleResponse(res?.data);
      })
      .catch((error) => {
        handleResponse(error?.response?.data);
      });
  };
//Accept Traveller Counter Offer
export const acceptCounterTravellerOffer =
  (data, handleResponse) => (dispatch) => {
    const accessToken = localStorage.getItem("token");
    axios
      .post(ApiUrl.server_url + "auth/accept-counter-traveler-offer", data, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => {
        let tempdata = {
          travelerOfferId: data.travelerOfferId,
        };
        dispatch(getTravellerOfferDetails(tempdata));
        dispatch(getTravellerOffers());
        handleResponse(res?.data);
      })
      .catch((error) => {
        handleResponse(error?.response?.data);
      });
  };

//Create Sender Offer
export const createSenderOffer = (data, handleResponse) => (dispatch) => {
  const accessToken = localStorage.getItem("token");
  const allData = { ...data, lang: localStorage.getItem("i18nextLng") };
  axios
    .post(ApiUrl.server_url + "auth/create-sender-offer", allData, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((res) => {
      dispatch(getTravellerOffers());
      handleResponse(res?.data);
    })
    .catch((error) => {
      handleResponse(error?.response?.data);
    });
};
//Create Sender Counter Offer
export const createSenderCounterOffer =
  (data, handleResponse) => (dispatch) => {
    const accessToken = localStorage.getItem("token");
    axios
      .post(ApiUrl.server_url + "auth/create-counter-sender-offer", data, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => {
        let tempdata = {
          senderOfferId: data.offerId,
        };
        dispatch(getSenderOfferDetails(tempdata));
        handleResponse(res?.data);
      })
      .catch((error) => {
        handleResponse(error?.response?.data);
      });
  };

//Accept Sender Counter Offer
export const acceptUserSenderOffer = (data, handleResponse) => (dispatch) => {
  const accessToken = localStorage.getItem("token");
  axios
    .post(ApiUrl.server_url + "auth/accept-sender-offer", data, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((res) => {
      let tempdata = {
        senderOfferId: data.senderOfferId,
      };
      dispatch(getSenderOfferDetails(tempdata));
      handleResponse(res?.data);
    })
    .catch((error) => {
      handleResponse(error?.response?.data);
    });
};

//Accept Sender Counter Offer
export const acceptCounterSenderOffer =
  (data, handleResponse) => (dispatch) => {
    const accessToken = localStorage.getItem("token");
    const { t } = useTranslation();

    axios
      .post(ApiUrl.server_url + "auth/accept-counter-sender-offer", data, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => {
        // dispatch(getTravellerOffers());
        Swal.fire({
          title: t("Success"),
          type: "success",
          text: "Counter offer accepted successfully",
        });
        handleResponse(res?.data);
      })
      .catch((error) => {
        handleResponse(error?.response?.data);
        Swal.fire({
          title: t("Failed"),
          type: "error",
          text: t("Something went wrong"),
        });
      });
  };

//Get Traveller Offer by selected User
export const getTravellerOffers = () => (dispatch) => {
  const accessToken = localStorage.getItem("token");

  var config = {
    method: "get",
    url: ApiUrl.server_url + "auth/user-traveler-offers",
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  };
  axios(config).then((res) => {
    // console.log(JSON.stringify(res?.data)+"============User Traveller Offer List")
    dispatch({ type: GET_USER_TRAVELER_OFFERS, payload: res?.data });
  });
};

//Get Sender Offer by Selected User
export const getSenderOffers = () => (dispatch) => {
  const accessToken = localStorage.getItem("token");

  var config = {
    method: "get",
    url: ApiUrl.server_url + "auth/user-sender-offers",
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  };
  axios(config).then((res) => {
    // console.log(JSON.stringify(res.data)+"============data from api")
    dispatch({ type: GET_ALL_SENDER_OFFERS, payload: res?.data });
  });
};

//Get Sender Details
export const getSenderOfferDetails = (data, handleResponse) => (dispatch) => {
  const accessToken = localStorage.getItem("token");
  axios
    .post(ApiUrl.server_url + "auth/sender-offer-details", data, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((res) => {
      dispatch({ type: GET_SENDER_OFFER, payload: res.data.senderOffer });
      handleResponse(res.data);
    })
    .catch((error) => {
      handleResponse(error?.response?.data);
    });
};

//Sender Offer Payment
export const senderOfferPayment = (data, handleResponse) => (dispatch) => {
  const accessToken = localStorage.getItem("token");
  axios
    .post(ApiUrl.server_url + "auth/pay", data, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((res) => {
      // dispatch(getTravellerOffers());
      handleResponse(res?.data);
    })
    .catch((error) => {
      handleResponse(error?.response?.data);
    });
};
//Update Payment status
export const updatePaymentStatus = (data, handleResponse) => (dispatch) => {
  const accessToken = localStorage.getItem("token");
  axios
    .post(ApiUrl.server_url + "auth/payment-success", data, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((res) => {
      // dispatch(getTravellerOffers());
      handleResponse(res?.data);
    })
    .catch((error) => {
      handleResponse(error?.response?.data);
    });
};

//Update Offer Status
export const updateOfferStatus = (data, handleResponse) => (dispatch) => {
  const accessToken = localStorage.getItem("token");
  const allData = { ...data, lang: localStorage.getItem("i18nextLng") };
  axios
    .post(ApiUrl.server_url + "auth/update-offer-status", allData, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((res) => {
      dispatch(getTravellerOffers());
      handleResponse(res?.data);
    })
    .catch((error) => {
      handleResponse(error?.response?.data);
    });
};
//All Sender and Traveller Offer

//List of All Traveller Offer By All Traveller
export const getAllTravellerOffers = () => (dispatch) => {
  const accessToken = localStorage.getItem("token");

  var config = {
    method: "get",
    url: ApiUrl.server_url + "auth/all-traveler-offers",
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  };
  axios(config).then((res) => {
    dispatch({ type: TRAVELER_OFFERS, payload: res.data.travlerOffers });
  });
};

//Get Traveller Details
export const getTravellerOfferDetails =
  (data, handleResponse) => (dispatch) => {
    const accessToken = localStorage.getItem("token");
    axios
      .post(ApiUrl.server_url + "auth/traveler-offer-details", data, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => {
        dispatch({ type: GET_TRAVELER_OFFER, payload: res.data.travelerOffer });
        handleResponse(res.data);
      })
      .catch((error) => {
        handleResponse(error?.response?.data);
      });
  };

//List of All Sender Offer By All Sener
export const getAllSenderOffers = () => (dispatch) => {
  const accessToken = localStorage.getItem("token");

  var config = {
    method: "get",
    url: ApiUrl.server_url + "auth/all-sender-offers",
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  };
  axios(config).then((res) => {
    // console.log(JSON.stringify(res.data)+"============data from api")
    dispatch({ type: SENDER_OFFERS, payload: res?.data?.senderOffers });
  });
};

export const getPricingDetails = () => (dispatch) => {
  const accessToken = localStorage.getItem("token");

  var config = {
    method: "get",
    url: ApiUrl.server_url + "auth/get-pricings",
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  };
  axios(config).then((res) => {
    dispatch({ type: OFFER_PRICING, payload: res.data.pricings });
  });
};

export const getCountryName  = (handleResponse) => (dispatch) =>{
  axios.get('https://ipapi.co/json/').then((res)=>{
    var countryData = {
      country:res.data.country_name
    }
    dispatch(getHeroSectionData(countryData))
    dispatch(getHowItWorksData(countryData))
    console.log("countryName "+ res.data.country_name);
  }).catch((error) => {
    console.log(error)
  });

  
}


export const  getHeroSectionData=
  (data, handleResponse) => (dispatch) => {
    const accessToken = localStorage.getItem("token");
    axios
      .post(ApiUrl.server_url + "auth/hero-section-media-list", data, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => {
        dispatch({ type: HERO_SECTION_DATA, payload: res.data.data });
        
      })
      .catch((error) => {
        console.log(error)
      });
  };
  export const  getHowItWorksData=
  (data, handleResponse) => (dispatch) => {
    const accessToken = localStorage.getItem("token");
    axios
      .post(ApiUrl.server_url + "auth/how-it-work-media-list", data, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => {
        dispatch({ type:  HOW_IT_WORK_DATA, payload: res.data.data });
        
      })
      .catch((error) => {
        console.log(error)
      });
  };