import {
  GET_USER_DETAIL,
  COMPLETE_PROFILE_USER,
  GET_ALL_STATES,
  GET_OFFER_CHAT_MESSAGES,
  GET_DEVICE_TOKEN,
} from "../types";

const initialState = {
  userDetails: {},
  userCreate: {},
  allCategories: [],
  allStates: [],
  allCosts: [],
  chatMessages: [],
  deviceToken: "",
};

const UserReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_DETAIL: {
      return {
        ...state,
        userDetails: action.payload,
      };
    }
    case GET_DEVICE_TOKEN: {
      return {
        ...state,
        deviceToken: action.payload,
      };
    }

    case COMPLETE_PROFILE_USER: {
      return {
        ...state,
        userCreate: action.payload,
      };
    }

    case GET_ALL_STATES: {
      return {
        ...state,
        allStates: [...action.payload.data],
      };
    }

    case GET_OFFER_CHAT_MESSAGES: {
      return {
        ...state,
        chatMessages: [...action.payload.data],
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default UserReducer;
