import React, { useState } from "react";

//React Strap Components
import { Label, Button, InputGroup, Row, Col } from "reactstrap";

//React Icons
import { BsFillCalendarCheckFill } from "react-icons/bs";

//React Bootstrap Components
import { Form } from "react-bootstrap";
//CSS
import "../SamllComponent/MultitabBtn.css";

// Assets
import CounterMultiThree from "./CounterMultiThree";
import { useTranslation } from "react-i18next";

const Step3One = (props) => {
  const [isDisable, setDisable] = useState(false);
  const { t } = useTranslation();
  const handleAsapValue = (e) => {
    props.getAsapValue(e);
    if (e.target.checked) {
      // setOfferTime("ASAP")
      setDisable(true);
    } else {
      // setOfferTime("")
      setDisable(false);
    }
  };

  if (props.currentStep !== 2) {
    return null;
  }
  return (
    <div className="pt-5">
      <div className="multiform-postion">
        <div className="lightorage-color my-4 text-center">
          <h1 className=" py-3 fontSize24-600 orange-color">
            {t("Create Shipment offer")}{" "}
          </h1>
        </div>
        <div className="text-center pt-3">
          <Button
            className="orange-background fontSize16-500 bordt-none"
            size="md"
            type="button"
          >
            {t("Select Shipment Date")}
          </Button>
        </div>
      </div>
      <div className="pt-115-thrdform">
        <Form>
          <Form.Group className="mb-3 d-flex" controlId="formBasicCheckbox">
            {/* <Form.Check type="checkbox" label="As soon as possible" /> */}
            <Form.Check
              type="checkbox"
              value="ASAP"
              onChange={handleAsapValue}
              className=""
              label={t("As soon as possible")}
            />
            <BsFillCalendarCheckFill className="orange-color font25-icn ms-3" />
          </Form.Group>
        </Form>
        <Row>
          <Label for="offerTime" className="fontSize14-600 dimgray-color">
            {t("Date")}
            <span style={{ color: "red" }}>*</span>
          </Label>
          <Col lg={6} md={6} sm={12}>
            <InputGroup className="mb-3">
              <Form.Control
                disabled={isDisable}
                type="date"
                name="offerTime"
                placeholder="Offer Days"
                aria-label="Offer Days"
                aria-describedby="basic-addon2"
                onChange={props.getOfferTime}
                min={new Date().toISOString().split("T")[0]}
              />
            </InputGroup>
          </Col>
          <Col lg={6} md={6} sm={12} className="mt-1">
            <CounterMultiThree getOfferDays={props.getOfferDays} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Step3One;
