import { Col, Row } from 'react-bootstrap'
import Carousel from 'react-bootstrap/Carousel'
import { useTranslation } from 'react-i18next'
import '../SamllComponent/Skidertes.css'


// import elboy from '../Assets/Images/elboy.png'

function HowItWorkVideo(props) {
  const { t } = useTranslation()

  return (
   
    <video controls>
    <source src={props.src} type="video/mp4" autoPlay={true}/>
  </video>
  )
}

export default HowItWorkVideo
