import React from "react";
import "./MultiStepProgressBarOne.css";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { AiFillDollarCircle } from "react-icons/ai";
import { BsBox } from "react-icons/bs";
import { AiOutlineClockCircle } from 'react-icons/ai'
import { RiRoadMapFill } from "react-icons/ri";

const MultiStepProgressBarOne = props => {
  var stepPercentage = 0;

  if (props.currentStep === 1) {
    stepPercentage = 0;
  } else if (props.currentStep === 2) {
    stepPercentage = 34;
  }
  else if (props.currentStep === 3) {
    stepPercentage = 67;
  }
  else if (props.currentStep === 4) {
    stepPercentage = 100;
  }
  else {
    stepPercentage = 0;
  }

  return (
    <ProgressBar percent={stepPercentage}>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
          >
             <RiRoadMapFill className="font25-icon" />
            {/* {index + 1} */}
          </div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? 'accomplished' : null}`}
          >
            <AiOutlineClockCircle className="font25-icon" />
            {/* {index + 1} */}
          </div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
          >
            <BsBox className="font25-icon" />
            {/* {index + 1} */}
          </div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
          >
           <AiFillDollarCircle className="font25-icon" />
            {/* {index + 1} */}
          </div>
        )}
      </Step>
    
  
    </ProgressBar>
  );
};

export default MultiStepProgressBarOne;
