import React, { useState } from 'react'
//React Bootstrap Components
import { Container, Row } from 'react-bootstrap'

//React Strap Components
import { Col } from 'reactstrap'

// Assets
import logo from '../Assets/Images/logo.png'
import logoAndName from '../Assets/Images/logoo1.png'
// import otp from "../Assets/Images/otp.png";
import verificationImg from '../Assets/Images/verificationImg.jpg'

//User Defined Components
import OtpCom from '../Otp/OtpCom'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { t } from 'i18next'

function AcountVerify() {
  const location = useLocation()
  const { mobileNumber } = location.state
  const [mobileNumberFromForget, setMobileNumberFromForget] = useState(
    mobileNumber,
  )

  // const parameter = new URLSearchParams(location.mobileNumber);
  // useEffect(()=>{
  //   console.log(parameter + "======== mobile number from location parameter");
  // })
  return (
    <div>
      <section>
        <Container fluid className="m-0 p-0">
          <Row>
            <Col lg={12} md={12} sm={12} className="mb-2">
              <Row>
                <Col lg={12} md={12} sm={12} className="p-0">
                  <div className="lightorage-color mt-2 text-center">
                    <h1 className=" py-3 fontSize24-600 ">
                      Account{' '}
                      <span className="orange-color"> Verification</span>
                    </h1>
                  </div>
                  <div className="text-center pt-2 pb-2">
                    {/* <img src={verificationImg} alt="otp" className="fotg-imgres" /> */}
                    <img
                      src={logoAndName}
                      alt="logo"
                      style={{ maxWidth: '200px', height: 'auto' }}
                    />
                  </div>
                  <div className="pt-4">
                    <p className="mb-0 fontSize16-400 text-center">
                      {t(
                        'Please check your WhatsApp and enter the 5 digit code',
                      )}
                    </p>
                  </div>
                  <OtpCom
                    type="f"
                    mobileNumberFromForget={mobileNumberFromForget}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default AcountVerify
