import axios from "axios";
import { ApiUrl } from "../../ApiUrl";
import { GET_BLOG_LIST } from "../types";

export const getBlogList = () => (dispatch) => {
  const accessToken = localStorage.getItem("token");
  var config = {
    method: "get",
    url: ApiUrl.server_url + "auth/blog-list",
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  };

  axios(config).then((res) => {
    if(res.data.status){
        dispatch({ type: GET_BLOG_LIST, payload: res.data.data });
    }
  });
};
