import React, { useEffect, useState } from "react";
import { useNavigate, useNavigation, useSearchParams } from "react-router-dom";
import Header from "../Component/Header";
import LoggedinNavbar from "../Component/LoggedinNavbar";
import { updatePaymentStatus } from "../../redux/actions/TravellerOfferAction";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";

export default function StripePaymentSuccess() {
  const dispatch = useDispatch();

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let offerId = params.get("offerId");
  let offofferTypeer = params.get("offerType");
  const navigate = useNavigate();

  const [lang, setLang] = useState("en");

  useEffect(() => {
    setLang(localStorage.getItem("i18nextLng"));
    let data = {
      offerId: offerId,
      offerType: offofferTypeer,
      lang: lang,
    };
    dispatch(updatePaymentStatus(data, handlePaymentResponse));
  }, []);
  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, [6000]);
  }, []);
  const handlePaymentResponse = (data) => {};
  const token = localStorage.getItem("token");
  return (
    <>
      {!token ? <Header /> : <LoggedinNavbar />}
      <div className="container mt-5 pt-5 mb-5 pb-4">
        <div className="row justify-content-center head-about">
          <div className="col-8 text-center">
            {/* <h1 style={{color:'black'}}>Our Users Say About Us</h1> */}
            <div className="mt-5">
              <p className="success-page">
                <svg viewBox="0 0 512 512" width="100" title="check-circle">
                  <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z" />
                </svg>
              </p>
              <h1>{t("Congratulations! Payment Successfull")}</h1>
              {/* <h3 style={{fontWeight:'700',color:'#F69110'}}>Jaydon Bator</h3>
            <p>Professional Artist</p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
