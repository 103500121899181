import React, { useEffect, useState, useRef } from "react";
import { Alert } from "react-bootstrap";
import "../Component/Chat.css";

import UserImage from "../Assets/Images/user.png";
import { useDispatch, useSelector } from "react-redux";

import {
  getOfferChatMessages,
  sendChatMessage,
  sendChatNotification,
} from "../../redux/actions/UserAction";
import { useNavigate } from "react-router-dom";
export default function Chat() {
  const dispatch = useDispatch();
  let search = window.location.search;
  const navigate = useNavigate();
  let params = new URLSearchParams(search);
  const offerId = params.get("offerid");
  const offerType = params.get("OfferType");
  const DeviceToken = params.get("DeviceToken");
  const name = params.get("user");

  const userId = params.get("userId");
  const location = window.location.pathname;

  function getNewMessages() {
    setTimeout(() => {
      if (location.includes("/chat") === true) {
        let data = {
          startFromId: 0,
          offerId: offerId,
          offerType: offerType,
        };
        dispatch(getOfferChatMessages(data, handleRespons));
      }
    }, 5000);
  }

  const { chatMessages, userDetails } = useSelector(
    (state) => state.UserReducer
  );
  const handleRespons = (data) => {
    if (data?.status) {
      getNewMessages();
    }
  };
  const handleNotificationRespons = (data) => {};

  useEffect(() => {
    // console.log(chatMessages)
    if (chatMessages?.length > 0) {
      Settext(chatMessages);
    }
  }, [chatMessages]);
  const inputReference = useRef(null);
  const scrollRef = useRef(null);
  const [getInputVal, setGetInputVal] = useState("");

  useEffect(() => {
    getNewMessages();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [offerId, offerType, chatMessages]);

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 2000);
  }, []);

  //  array of objects
  const messesges = [];
  // will ad to another array
  const [text, Settext] = useState(messesges);
  //  onclick of send button
  const addItems = () => {
    if (!getInputVal) {
      Alert("add some value");
    } else {
      Settext([...text, { user: "sen", message: getInputVal, name: "ahmad" }]);
      let data = {
        offer_type: offerType,
        offer_id: offerId,
        message: getInputVal,
      };
      let notificationData = {
        to: DeviceToken,
        notification: {
          body: {
            Message: getInputVal,
          },
          title: "New Message Received",
          subtitle: ".....",
        },
      };
      dispatch(sendChatMessage(data, handleRespons));
      dispatch(
        sendChatNotification(notificationData, handleNotificationRespons)
      );
      setGetInputVal("");
      inputReference.current.focus();
    }
  };
  // time date
  // let greeting ="";
  let Curtime = new Date().toLocaleTimeString();
  // console.log(Curtime)
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // 👇 Get input value
      Settext([...text, { user: "sen", message: getInputVal }]);
      let data = {
        offer_type: offerType,
        offer_id: offerId,
        message: getInputVal,
      };
      let notificationData = {
        to: DeviceToken,
        notification: {
          body: {
            Message: getInputVal,
          },
          title: "New Message Received",
          subtitle: ".....",
        },
      };
      dispatch(sendChatMessage(data, handleRespons));
      setGetInputVal("");
      dispatch(
        sendChatNotification(notificationData, handleNotificationRespons)
      );
    }
  };
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [getInputVal]);

  return (
    <>
      <div className="container-fluid mt-3 chat-section ">
        <div className="row justify-content-center p-lg-3 p-md-3 p-sm-3 p-2 ">
          <div className="col-lg-11 col-md-11 col-12 chat">
            <div className="card">
              <div className="card-header msg_head p-3">
                <div className="d-flex bd-highlight">
                  <div
                    style={{
                      display: "flex",
                      paddingRight: 20,
                      paddingLeft: 20,
                      alignItems: "center",
                    }}
                    onClick={() => navigate(-1)}
                  >
                    <i
                      className="fa-solid fa-arrow-left"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </div>
                  <div className="img_cont">
                    <img src={UserImage} className="rounded-circle user_img" />
                    <span className="online_icon"></span>
                  </div>
                  <div className="user_info">
                    <span>{name}</span>
                    <p>
                      {offerType} #{offerId}
                    </p>
                  </div>
                </div>
              </div>

              <div className="scroll">
                {text?.length > 0 &&
                  text.map((value, ind) => {
                    if (value.sent_by == userId) {
                      return (
                        <div className="d-flex justify-content-start mb-1 p-lg-3 p-md-3 p-sm-2 p-1">
                          <div className="img_cont_msg">
                            <img
                              src={UserImage}
                              className="rounded-circle user_img_msg"
                            />
                          </div>
                          <div>
                            <div className="msg_cotainer msg_respons">
                              {value.message}
                            </div>
                            <div className="msg_time ms-4 msg_time_respons">
                              {value?.createdAt?.split("T")[0]}
                            </div>
                          </div>
                          <div ref={scrollRef} />
                        </div>
                      );
                    } else {
                      return (
                        <div className="d-flex justify-content-end mb-4 mt-1 p-lg-3 p-md-3 p-sm-2 p-1">
                          <div>
                            <div className="msg_cotainer_send msg_respons">
                              {value.message}
                            </div>
                            <div className=" msg_time ms-3 msg_time_respons">
                              {value?.createdAt?.split("T")[0]}
                            </div>
                          </div>
                          <div className="img_cont_msg">
                            <img
                              src={UserImage}
                              className="rounded-circle user_img_msg"
                            />
                          </div>
                        </div>
                      );
                    }
                  })}
                <div ref={messagesEndRef} />
              </div>
              <div className="card-footer" style={{ color: "#f2f2f2" }}>
                <div className="input-group">
                  <input
                    className="form-control  type_msg"
                    ref={inputReference}
                    id="input"
                    type="text"
                    value={getInputVal}
                    style={{
                      color: "black",
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
                    }}
                    onChange={(e) => setGetInputVal(e.target.value)}
                    autoFocus={true}
                    onBlur={false}
                    placeholder="Type your text"
                    onKeyDown={handleKeyDown}
                  />

                  <span className="input-group-text send_btn">
                    <button className="btn-snd">
                      <i
                        className="fas fa-location-arrow btn-inner-icon"
                        onClick={addItems}
                      ></i>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
