import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import LoggedinNavbar from "../View/Component/LoggedinNavbar";
const VerificationProtectedRoute = () => {
  const { userDetails } = useSelector((state) => state.UserReducer);
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return userDetails?.user?.is_email_verified === 1 &&
    userDetails?.user?.is_id_verified === 1 &&
    userDetails?.user?.is_phone_verified === 1 &&
    userDetails?.user?.address !== "" &&
    userDetails?.user?.address !== null &&
    userDetails?.user?.address !== undefined ? (
    <>
      {/* <LoggedinNavbar /> */}
      <Outlet />
    </>
  ) : (
    <Navigate to="/main_profile?key=second" />
  );
};

export default VerificationProtectedRoute;
