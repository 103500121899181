import { Button, Col, Row } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "../SamllComponent/MultitabBtn.css";
import logo from "../Assets/Images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

function MultitabButton() {
  const { userDetails } = useSelector((state) => state.UserReducer);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleStartGivingServices = (str) => {
    if (token) {
      if (
        userDetails?.user?.address != null &&
        userDetails?.user?.address != "" &&
        userDetails?.user?.is_phone_verified != 0 &&
        userDetails?.user?.is_id_verified != 0 &&
        userDetails?.user?.is_email_verified != 0
      ) {
        navigate("/create_sender_offer");
      } else {
        Swal.fire({
          title: t("Whoops!"),
          type: "danger",
          text: t("You need to get verified before you can create the sender and traveler offer. Don’t sweat it - just finish your verification [address, email, WhatsApp number] and you’re ready to go"),
          footer:
            '<a href="/main_profile?key=second">'+ t('Link to Verification Page')+'</a>',
        });
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <Tabs
      defaultActiveKey="profile"
      id="fill-tab-example"
      className="mb-3 mx-auto multi-btntwo fontSize16-500 "
      fill
    >
      <Tab eventKey="home" title={t("For Traveler")}>
        <div>
          <Row className="pt-5 justify-content-center">
            <Col lg={1} md={1} sm={2} className="pe-0">
              <div className="pb-3 textcenter-425">
                {/* <img src={logoo} alt="logo" /> */}
                <h1 style={{ fontWeight: "700", color: "#F69110" }}>1.</h1>
              </div>
            </Col>
            <Col lg={8} md={9} sm={9} className="padding-Start-10">
              <div className="textcenter-425 mx-lg-0 mx-md-0 mx-5">
                <p className="fontSize18-700-MultiButton">
                  {t("Create Your Offer")}
                </p>
                <p className="fontSize16-400 dimgray-color">
                  {t(
                    "List your upcoming trip or select from the list of existing sender offers"
                  )}
                </p>
              </div>
            </Col>
          </Row>
          <Row className="pt-3 justify-content-center">
            <Col lg={1} md={1} sm={2} className="pe-0">
              <div className="pb-3 textcenter-425">
                <h1 style={{ fontWeight: "700", color: "#F69110" }}>2.</h1>
              </div>
            </Col>
            <Col lg={8} md={9} sm={9} className="padding-Start-10">
              <div className="textcenter-425 mx-lg-0 mx-md-0 mx-5">
                <p className="fontSize18-700-MultiButton">
                  {t("Accept or Receive")}
                </p>
                <p className="fontSize16-400 dimgray-color">
                  {t(
                    "Accept sender offer and receive package from first mile pick up or sender or pick it from our center"
                  )}
                </p>
              </div>
            </Col>
          </Row>
          <Row className="pt-3 justify-content-center">
            <Col lg={1} md={1} sm={2} className="pe-0">
              <div className="pb-3 textcenter-425">
                <h1 style={{ fontWeight: "700", color: "#F69110" }}>3.</h1>
              </div>
            </Col>
            <Col lg={8} md={9} sm={9} className="padding-Start-10">
              <div className="textcenter-425 mx-lg-0 mx-md-0 mx-5">
                <p className="fontSize18-700-MultiButton">
                  {t("Deliver and get paid")}
                </p>
                <p className="fontSize16-400 dimgray-color">
                  {t("Deliver to last mile delivery or airport and get paid")}
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={6} md={6} sm={12}>
              <div className="d-grid gap-2 py-3 mx-lg-0  mx-md-0 mx-5">
                <Button
                  className="profiletop-btnoutline btnlanding-hov py-2 fontSize18-500 bordt-none mb-lg-0  mb-md-5 mb-5"
                  size="lg"
                  onClick={handleStartGivingServices}
                  block
                >
                  {t("Get Started As Traveller")}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Tab>
      <Tab eventKey="profile" title={t("For Sender")}>
        <div>
          <Row className="pt-5 justify-content-center">
            <Col lg={1} md={1} sm={2} className="pe-0">
              <div className="pb-3 textcenter-425">
                <h1 style={{ fontWeight: "700", color: "#F69110" }}>1.</h1>
              </div>
            </Col>
            <Col lg={8} md={9} sm={9} className="padding-Start-10">
              <div className="textcenter-425 mx-lg-0 mx-md-0 mx-5">
                <p className="fontSize18-700-MultiButton">
                  {t("Create Shipment Offer")}
                </p>
                <p className="fontSize16-400 dimgray-color">
                  {t(
                    "List your shipment details or select from the list of existing traveler offers"
                  )}
                </p>
              </div>
            </Col>
          </Row>
          <Row className="pt-3 justify-content-center">
            <Col lg={1} md={1} sm={2} className="pe-0">
              <div className="pb-3 textcenter-425">
                <h1 style={{ fontWeight: "700", color: "#F69110" }}>2.</h1>
              </div>
            </Col>
            <Col lg={8} md={9} sm={9} className="padding-Start-10">
              <div className="textcenter-425 mx-lg-0 mx-md-0 mx-5">
                <p className="fontSize18-700-MultiButton">
                  {t("Accept Offer and Hand the Package")}
                </p>
                <p className="fontSize16-400 dimgray-color">
                  {t(
                    "Accept traveler offer and hand your package to first mile pick up or drop it at our center or hand it to traveler"
                  )}
                </p>
              </div>
            </Col>
          </Row>
          <Row className="pt-3 justify-content-center">
            <Col lg={1} md={1} sm={2} className="pe-0">
              <div className="textcenter-425">
                <h1 style={{ fontWeight: "700", color: "#F69110" }}>3.</h1>
              </div>
            </Col>
            <Col lg={8} md={9} sm={9} className="padding-Start-10">
              <div className="textcenter-425 mx-lg-0 mx-md-0 mx-5">
                <p className="fontSize18-700-MultiButton">
                  {t("Confirm a delivery")}
                </p>
                <p className="fontSize16-400 dimgray-color">
                  {t(
                    "Confirm a delivery to get the payment released to the traveller"
                  )}
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={6} md={6} sm={12}>
              <div className="d-grid gap-2 py-3 mx-lg-0 mx-md-0 mx-5">
                <Button
                  className="profiletop-btnoutline btnlanding-hov py-2 fontSize18-500 bordt-none mb-lg-0 mb-md-5 mb-5"
                  size="lg"
                  onClick={handleStartGivingServices}
                  block
                >
                  {t("Get Started As Sender")}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Tab>
    </Tabs>
  );
}

export default MultitabButton;
