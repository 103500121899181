import React, { useEffect } from "react";
import { FormGroup, Label, Alert, Input, Button } from "reactstrap";
import { ImLocation2 } from "react-icons/im";
import { usePlacesWidget } from "react-google-autocomplete";
import ToAddress from "./ToAdress";
import ToCity from "./ToCity";

import { useState } from "react";
import FromCity from "./FromCity";
import { useDispatch, useSelector } from "react-redux";

import { getAllCountriesList } from "../../redux/actions/CountriesAction";
import { getAllCities } from "../../redux/actions/CitiesAction";

import SourceCityList from "../MultiStepTwo/SourceCityList";
import { useTranslation } from "react-i18next";

// const Step1Three = ({  sourceAddress,currentStep,getFromCountry,getToAddress}) => {

const Step1Three = ({
  currentStep,
  getFromAddress,
  getFromCountry,
  getFromCity,
  fromCountryObject,

  getToCountry,
  getToCity,
  getToAddress,
  toCountryObject,
}) => {
  const [region, setRegion] = useState("");
  const [sourceCountryId, setSourceCountryId] = useState(null);
  const [destinationCountryId, setDestinationCountryID] = useState(null);

  const dispatch = useDispatch();
  const { allCountriesList } = useSelector((state) => state.CountriesReducer);
  const { allCitiesList } = useSelector((state) => state.CitiesReducer);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAllCountriesList());
    dispatch(getAllCities());
  }, []);
  let filteredSourceCities = allCitiesList.map((value) => {
    if (value?.country_id == sourceCountryId) {
      return value?.name;
    }
  });
  let filterDestinationCities = allCitiesList.map((value) => {
    if (value?.country_id == destinationCountryId) {
      return value?.name;
    }
  });
  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyBtgKhG2FTRTdF2tggTyS27nJz2Y46EYT8",
    onPlaceSelected: (place) => {
      console.log(place?.address_components?.[0]?.short_name + "=====region");
      setRegion(place?.address_components?.[0]?.short_name);
      getFromCountry(place?.formatted_address);
    },
    options: {
      types: ["country"],
    },
  });

  // New Code Starts Here
  const handleSelectSourceCountry = (event) => {
    var index = event.target.selectedIndex;
    var optionElement = event.target.childNodes[index];
    var option = optionElement.getAttribute("data-val");
    let selectedZone = JSON.parse(option);
    setSourceCountryId(selectedZone?.id);
    getFromCountry(selectedZone?.country);
    fromCountryObject(selectedZone);

    console.log(selectedZone?.country + "selected zone");
  };

  const handleSelectDestinationCountry = (event) => {
    var index = event.target.selectedIndex;
    var optionElement = event.target.childNodes[index];
    var option = optionElement.getAttribute("data-val");
    let selectedDestinationZone = JSON.parse(option);
    setDestinationCountryID(selectedDestinationZone?.id);
    getToCountry(selectedDestinationZone?.country);
    toCountryObject(selectedDestinationZone);
  };

  const handlesourceCity = (event) => {
    let sourceCityS = event.target.value;
    getFromCity(sourceCityS);
  };
  const handleDestinationCity = (event) => {
    let destinationCityD = event.target.value;
    getToCity(destinationCityD);
  };
  // New Code Ends Here

  const getPlaceName = (e) => {
    console.log(e.target.value);
  };
  if (currentStep !== 1) {
    return null;
  }

  return (
    <div className="pt-5">
      <div className="multiform-postion">
        <div className="lightorage-color mt-5 text-center">
          <h1 className=" py-3 fontSize24-600 orange-color">
            {t("Create Traveler Offer")}{" "}
          </h1>
        </div>
        <div className="text-center pt-3">
          <Button
            type="button"
            className="orange-background fontSize16-500 bordt-none"
            size="md"
          >
            {t("Select Departure & Destination")}{" "}
          </Button>
        </div>
      </div>
      {/*  */}
      <div className="pttwo-multotab">
        <Alert color="warning">
          {t("Note")}: {t("Fill in the *starred* fields for a smooth ride")}!
        </Alert>
      </div>
      {/*  */}
      <div className="main-position mt-5">
        <div className="ps-3 pe-3 pt-4 pb-4 main-1">
          <p className="inner-1">{t("From")}</p>

          <FormGroup>
            <Label for="email" className="fontSize14-600 dimgray-color">
              {t("Country")} <span style={{ color: "red" }}>*</span>
            </Label>
            <div className="input-group">
              <select
                name="sCountry"
                id="sCountry"
                className="form-control"
                onChange={handleSelectSourceCountry}
              >
                <option selected>{t("Select Source Country")}</option>
                {allCountriesList?.length > 0 &&
                  allCountriesList.map((value) => {
                    if (value?.is_service_enabled == 1) {
                      return (
                        <option data-val={JSON.stringify(value)} value={value}>
                          {value?.country + " "}
                        </option>
                      );
                    }
                  })}
              </select>
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="email" className="fontSize14-600 dimgray-color">
              {t("City")}
              <span style={{ color: "red" }}>*</span>
            </Label>
            <div className="input-group">
              <select
                name="sCity"
                id="sCity"
                className="form-control"
                onChange={handlesourceCity}
              >
                <option selected>{t("Select Source City")}</option>
                {filteredSourceCities?.length > 0 &&
                  filteredSourceCities.map((value) => {
                    if (value != undefined) {
                      return (
                        <option
                          // data-val={JSON.stringify(value)}
                          value={value}
                        >
                          {value + " "}
                        </option>
                      );
                    }
                  })}
              </select>
            </div>
            <ImLocation2 className="mail-icon-login orange-color position-relative" />
          </FormGroup>
          <FormGroup>
            <Label for="package" className="fontSize14-600 dimgray-color">
              {t("Address")}
              <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              type="textarea"
              name="getFromAddress"
              id="getFromAddress"
              onChange={getFromAddress}
              className="h-muttistep-textarea"
              placeholder={t("Please Enter Detailed Address")}
            />
          </FormGroup>
        </div>
      </div>
      <div className="main-position mt-5">
        <div className="ps-3 pe-3 pt-4 pb-4 main-1">
          <p className="inner-1">{t("To")}</p>

          <FormGroup>
            <Label for="email" className="fontSize14-600 dimgray-color">
              {t("Country")} <span style={{ color: "red" }}>*</span>
            </Label>
            <div className="input-group">
              <select
                name="sCountry"
                id="sCountry"
                className="form-control"
                onChange={handleSelectDestinationCountry}
              >
                <option selected>{t("Select Destination Country")}</option>
                {allCountriesList?.length > 0 &&
                  allCountriesList.map((value) => {
                    if (value?.is_service_enabled == 1) {
                      return (
                        <option data-val={JSON.stringify(value)} value={value}>
                          {value?.country + " "}
                        </option>
                      );
                    }
                  })}
              </select>
            </div>
            <ImLocation2 className="mail-icon-login orange-color" />
          </FormGroup>
          <FormGroup>
            <Label for="dcity" className="fontSize14-600 dimgray-color">
              {t("City")}
              <span style={{ color: "red" }}>*</span>
            </Label>
            <div className="input-group">
              <select
                name="dcity"
                id="dcity"
                className="form-control"
                onChange={handleDestinationCity}
              >
                <option selected>{t("Select Destination City")}</option>
                {filterDestinationCities?.length > 0 &&
                  filterDestinationCities.map((value) => {
                    if (value != undefined) {
                      return (
                        <option
                          // data-val={JSON.stringify(value)}
                          value={value}
                        >
                          {value + " "}
                        </option>
                      );
                    }
                  })}
              </select>
            </div>
            <ImLocation2 className="mail-icon-login orange-color position-relative" />
          </FormGroup>
          <FormGroup>
            <Label for="package" className="fontSize14-600 dimgray-color">
              {t("Address")}
              <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              type="textarea"
              name="getToAddress"
              id="getToAddress"
              onChange={getToAddress}
              className="h-muttistep-textarea"
              placeholder={t("Please Enter Detailed Address")}
            />
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

export default Step1Three;
