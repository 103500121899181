import React, { Component, useState } from "react";
import {
  Form,
  Button,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  Container,
  Row,
  Col,
  Alert,
} from "reactstrap";
import logoo from "../Assets/Images/logoo1.png";
import Step1Three from "./Step1Three";
import Step2Three from "./Step2Three";
import Step3Three from "./Step3Three";
import MultiStepProgressBarThree from "./MultiStepProgressBarThree";
import LoadingScreen from "react-loading-screen";
import { Modal, ModalBody } from "reactstrap";
import cong from "../Assets/Images/cong.png";
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi";
import { createTravellerOffer } from "../../redux/actions/TravellerOfferAction";
import { useDispatch, useSelector } from "react-redux";
import Step4Three from "./Step4Three";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import SocialMediaButtons from "../Component/Share";
// import Step2 from "./Step2One";

function CreateTravellerOfferForm(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [hitPaymentApi, setHitPaymentApi] = useState(0);
  // Set the intiial input values

  const [currentStep, setCurrentStep] = useState(1);
  const [modal, setModal] = useState(false);
  const [from_country, setFromCountry] = useState("");
  const [from_city, setFromCity] = useState("");
  const [from_address, setFromAddress] = useState("");
  const [fCountryObject, setFCountryObject] = useState(null);

  const [to_country, setToCountry] = useState("");
  const [to_city, setToCity] = useState("");
  const [to_address, setToAddress] = useState("");
  const [tCountryObject, setTCountryObject] = useState(null);

  const [offerTime, setOfferTime] = useState("");
  const [maxWeight, setMaxWeight] = useState(null);
  const [packageType, setPackageType] = useState("");
  const [packageLenght, setPackageLenght] = useState(null);
  const [packageWidth, setPackageWidth] = useState(null);
  const [packageHeight, setPackageHeight] = useState(null);
  const [offerPrice, setOfferPrice] = useState("");
  const [loading, setLoading] = useState(false);

  // function

  function toggle() {
    setModal(!modal);
  }
  // Use the submitted data to set the state

  function handleMultiselect(data) {
    setPackageType(data);

    console.log(data + "====packageType");
  }
  const handlePackageWidth = (data) => {
    console.log(data + "=========handle package width");

    setPackageWidth(data);
  };
  const handlePackagelenght = (data) => {
    console.log(data + "=========handle package Length");

    setPackageLenght(data);
  };

  const handlePackageHeight = (data) => {
    console.log(data + "=========handle package Height");

    setPackageHeight(data);
  };
  // Trigger an alert on form submission

  // Test current step with ternary
  // _next and _previous functions will be called on button click
  const [errorArray, setError] = useState(null);

  function _next() {
    let currentStepVar = currentStep;
    if (currentStepVar === 1) {
      if (
        from_address?.length > 0 &&
        to_address?.length > 0 &&
        to_country?.length > 0 &&
        to_city?.length > 0 &&
        from_country?.length > 0 &&
        from_city?.length > 0
      ) {
        currentStepVar = currentStepVar >= 3 ? 4 : currentStepVar + 1;
        setCurrentStep(currentStepVar);
        setError(null);
      } else {
        setError(t("Fill in the *starred* fields for a smooth ride!"));
      }
    } else if (currentStep === 2) {
      if (offerTime?.length > 0) {
        currentStepVar = currentStepVar >= 3 ? 4 : currentStepVar + 1;
        setCurrentStep(currentStepVar);
        setError(null);
      } else {
        setError(t("Fill in the *starred* fields for a smooth ride!"));
      }
    } else if (currentStep === 3) {
      if (packageType !== "document envelope") {
        if (
          packageType?.length > 0 &&
          maxWeight.length > 3 &&
          packageHeight?.length > 1 &&
          packageLenght?.length > 1 &&
          packageWidth?.length > 1
        ) {
          currentStepVar = currentStepVar >= 3 ? 4 : currentStepVar + 1;
          setCurrentStep(currentStepVar);
          setHitPaymentApi(1);
          setError(null);
        } else {
          setError(t("Fill in the *starred* fields for a smooth ride!"));
        }
      } else {
        if (packageType?.length > 0 && maxWeight.length > 1) {
          currentStepVar = currentStepVar >= 3 ? 4 : currentStepVar + 1;
          setCurrentStep(currentStepVar);
          setHitPaymentApi(1);
          setError(null);
        } else {
          setError(t("Fill in the *starred* fields for a smooth ride!"));
        }
      }
    }
    // If the current step is 1 or 2, then add one on "next" button click
  }
 
  function _prev() {
    let currentStepVar = currentStep;
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStepVar = currentStepVar <= 1 ? 1 : currentStepVar - 1;

    setCurrentStep(currentStepVar);
  }

  // The "next" and "previous" button functions
  function previousButton() {
    let currentStepVar = currentStep;

    // If the current step is not 1, then render the "previous" button
    if (currentStepVar !== 1) {
      return (
        <div className="textright-iconf">
          <Button
            color=" float-left"
            className="profiletop-btnoutline px-4  navdropdown-twohov flexOnSmallScreen"
            onClick={_prev}
          >
            <HiOutlineArrowLeft className="me-2" />
            {t("Back")}
          </Button>
        </div>
      );
    }

    // ...else return nothing
    return null;
  }

  function nextButton() {
    let currentStepVar = currentStep;
    // If the current step is not 3, then render the "next" button
    if (currentStepVar < 3) {
      return (
        <div className="textright-iconf">
          {" "}
          <Button
            color="primary float-right"
            className="profiletop-btnoutline px-4 ms-3 navdropdown-twohov  bordt-none"
            onClick={_next}
          >
            {t("Next")}
            <HiOutlineArrowRight className="ms-2" />
          </Button>
        </div>
      );
    }
    // ...else render nothing
    return null;
  }

  useEffect(() => {
    const scrollToLittleBottom = () => {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, [300]);
    };
    scrollToLittleBottom();
  }, []);

  function submitButton() {
    let currentStepVar = currentStep;

    // If the current step is the last step, then render the "submit" button
    if (currentStepVar > 2) {
      return (
        <div className="textright-iconf">
          <Button
            color="primary float-right"
            type="button"
            onClick={handleSubmit}
            className="profiletop-btnoutline navdropdown-twohov px-5 ms-3 bordt-none"
          >
            {t("Done")}
          </Button>
        </div>
      );
    }
    // ...else render nothing
    return null;
  }
  const handleFromCountry = (data) => {
    console.log(data + "============handle From Country");
    setFromCountry(data);
  };
  const handleFromAddress = (data) => {
    console.log(data.target.value + "============handle from address");
    setFromAddress(data?.target?.value);
  };
  const handleFromCity = (data) => {
    console.log(data + "============handle from City");
    setFromCity(data);
  };
  const handleToCountry = (data) => {
    console.log(data + "============handle To Country");
    setToCountry(data);
  };
  const handleToAddres = (data) => {
    console.log(data.target.value + "============handle to address");
    setToAddress(data.target.value);
  };
  const handleToCity = (data) => {
    console.log(data + "============handle To City");
    setToCity(data);
  };
  const handleFromCountryObject = (data) => {
    console.log(
      JSON.stringify(data) + "selected from country object in masterform three"
    );
    setFCountryObject(data);
  };

  const handleToCountryObject = (data) => {
    console.log(
      JSON.stringify(data) + "selected to country object in masterform three"
    );
    setTCountryObject(data);
  };

  // Step One End

  const handleOfferTime = (e) => {
    console.log(e.target.value + "============handle offer Time");

    setOfferTime(e.target.value);
  };
  const handleOfferDays = (e) => {
    console.log(e + "============handle offer Days");
    if (e == 0) {
      setOfferTime(null);
    } else {
      setOfferTime(e + "Days");
    }
  };
  const handleMaxWeight = (e) => {
    console.log(e + "============handle Max Weight");
    setMaxWeight(e);
  };
  const handleOfferPrice = (data) => {
    console.log(data + "========offer prices");
    setOfferPrice(data);
  };
  const [senderOfferID, setTravellerOfferId] = useState(null);

  const handleResponse = (data) => {
    setLoading(false);
    if (data.status) {
      console.log(JSON.stringify(data) + "=====traveller offer response");
      setTravellerOfferId(data.offer?.id);
      // setVerificationModal(false)
      setModal(true);
    } else {
      setModal(true);
      setLoading(false);
    }
  };
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    let data = {
      sCountry: from_country,
      dCountry: to_country,
      sCity: from_city,
      dCity: to_city,
      sAddress: from_address,
      dAddress: to_address,
      offerTime: offerTime,
      packageType: packageType,
      maxWeight: maxWeight,
      packageLenght: packageLenght,
      packageWidth: packageWidth,
      packageHeight: packageHeight,
      offerPrice: offerPrice,
    };
    dispatch(createTravellerOffer(data, handleResponse));
  };
  console.log(offerPrice, "abcdefghi");

  return (
    <LoadingScreen
      loading={loading}
      bgColor="#f1f1f1"
      spinnerColor="#9ee5f8"
      textColor="#676767"
      logoSrc={logoo}
      text={t("Hang tight while we do our magic!")}
    >
      <div className="bg-white respons-main">
        <Container>
          <Row className="">
            <Col lg={12} md={12} sm={12}>
              <Form onSubmit={handleSubmit}>
                <CardBody>
                  <CardTitle className="py-5 mrt-pr">
                    <Row className="justify-content-center">
                      <Col lg={8} md={8} sm={12}>
                        <MultiStepProgressBarThree currentStep={currentStep} />
                      </Col>
                    </Row>
                  </CardTitle>
                  <CardText />
                  <Step1Three
                    currentStep={currentStep}
                    getFromCountry={handleFromCountry}
                    getFromCity={handleFromCity}
                    getFromAddress={handleFromAddress}
                    getToCountry={handleToCountry}
                    getToCity={handleToCity}
                    getToAddress={handleToAddres}
                    fromCountryObject={handleFromCountryObject}
                    toCountryObject={handleToCountryObject}
                  />
                  <Step2Three
                    currentStep={currentStep}
                    getOfferTime={handleOfferTime}
                    getOfferDays={handleOfferDays}
                  />
                  <Step3Three
                    currentStep={currentStep}
                    tCountryObject={tCountryObject}
                    fCountryObject={fCountryObject}
                    getMultiselectValue={handleMultiselect}
                    maxWeight={handleMaxWeight}
                    packageHeight={handlePackageHeight}
                    packageLenght={handlePackagelenght}
                    packageWidth={handlePackageWidth}
                    // packageType={handlePackageType}
                  />
                  {/* <Step4Three
                    currentStep={currentStep}
                    tCountryObject={tCountryObject}
                    fCountryObject={fCountryObject}
                    packageType={packageType}
                    hitPaymentApi={hitPaymentApi}
                    offerPrice={handleOfferPrice}
                    maxWeight={maxWeight}
                  /> */}
                  {/* <Step4One
             currentStep={currentStep}
             handleChange={handleChange}
             email={password}
            />
            <Step5One 
             currentStep={currentStep}
             handleChange={handleChange}
             email={password}
            /> */}
                  {errorArray && (
                    <Alert style={{ marginTop: "10px" }} color="warning">
                      {errorArray}
                    </Alert>
                  )}
                </CardBody>
                <CardFooter className="mutistep-btn-direction my-5">
                  {previousButton()}
                  {nextButton()}
                  {submitButton()}
                </CardFooter>
              </Form>

              <Modal isOpen={modal} toggle={toggle} className={props.className} backdrop="static">
                {/* <ModalHeader toggle={toggle}>Modal title</ModalHeader> */}
                <ModalBody>
                  <Row>
                    <Col lg={12} md={12} sm={12}>
                      <div className="text-center pt-4">
                        <img src={cong} alt="cong" width="100" />
                      </div>
                      <div className="pb-4">
                        <h2 className="orange-color fontSize32-600 pt-4 text-center">
                          {t("Congratulations")}!
                        </h2>
                        <p className="fontSize18-500 text-center">
                          {t("Order")}#TO-{senderOfferID}
                        </p>
                        <p className="fontSize14-400 text-center mb-0">
                          {t("We have recieved your order details")},
                        </p>
                        <p className="fontSize14-400 text-center">
                          {t("You’ll be shortly notified on your contact info")}
                          !
                        </p>
                        <div className="text-center">
                          <Link to="/main_profile">
                            <Button
                              type="button"
                              className="orange-background fontSize16-500 px-5 bordt-none"
                              size="md"
                            >
                              {t("Done")}
                            </Button>
                          </Link>
                        </div>
                        <div className="text-right" style={{textAlign:'right'}}>
                          <SocialMediaButtons
                            url={
                              'https://viahop.net/explore?from=link&type=traveller'
                            }
                            title={'New Offer'}
                            text={'Explore Traveller Offers'}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Container>
      </div>
    </LoadingScreen>
  );
}

export default CreateTravellerOfferForm;
